import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { config } from '../../utils/config';
import { routes } from '../../utils/routes';
import getSessionId from '../../utils/localeStorage/getSessionId';
import HomePageDesktop from './Desktop/HomePageDesktop';
import HomePageMobile from './Mobile/HomePageMobile';
import { getAllVideos, getAllArticles } from '../../utils/http';


class HomeModule extends Component {
  state = {
    isMobile: window.innerWidth <= config.mobileWidth,
    videos: [],
    articles: [],
  }

  componentDidMount() {
    Promise.all([getAllVideos(), getAllArticles()])
      .then((rsp) => this.setState({ videos: rsp[0], articles: rsp[1] }));
  }

  onRegisterClick = () => {
    const { history } = this.props;
    if (getSessionId()) {
      history.push(routes.profile);
    } else {
      history.push({
        pathname: routes.login,
        state: 'client',
      });
    }
  }

  onRegisterPsychoClick = () => {
    const { history } = this.props;
    history.push(routes.specialists);
  }

  render() {
    const {
      isMobile, videos, articles,
    } = this.state;
    if (isMobile) {
      return (
        <HomePageMobile
          onRegisterClick={this.onRegisterClick}
          onRegisterPsychoClick={this.onRegisterPsychoClick}
        />
      );
    }
    return (
      <HomePageDesktop
        onRegisterClick={this.onRegisterClick}
        onRegisterPsychoClick={this.onRegisterPsychoClick}
        videos={videos}
        articles={articles}
      />
    );
  }
}

HomeModule.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(HomeModule);
