import React from 'react';
import {
  Spin, Col, Row, Table, Tabs, Input,
} from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import './OrgPromoCodesPageDesktop.css';
import formatSumWithSymbol from '../../../utils/formatSumWithSymbol';
import getColumns from '../utils/getColumns';
import getStatColumns from '../utils/getStatColumns';


const { TabPane } = Tabs;
const { Search } = Input;
function OrgPromoCodesPageDesktop({
  loading, orgInfo, detailedDataSourse, statDataSourse, onSearchStat, onSearchDetailed,
}) {
  if (loading || !orgInfo) {
    return (
      <div>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 100, zIndex: 100 }} spin />} style={{ position: 'absolute', top: '40%', left: '40%' }} />
      </div>
    );
  }
  return (
    <Col span={24} style={{ padding: '40px' }}>
      <Row style={{ width: '100%' }}>
        {orgInfo.name}
      </Row>
      <Row style={{ width: '100%' }}>
        {`Депозит: ${formatSumWithSymbol(orgInfo.deposit)}`}
      </Row>
      <Row style={{ width: '100%', marginTop: '20px' }}>
        <Tabs style={{ width: '100%' }}>
          <TabPane tab="Детальная" key="detailed">
            <Search
              placeholder="Имя или телефон"
              onChange={(e) => onSearchDetailed(e.target.value)}
            />
            <Table
              columns={getColumns()}
              rowKey="id"
              dataSource={detailedDataSourse}
              pagination={false}
              style={{ width: '100%', marginTop: '20px' }}
            />
          </TabPane>
          <TabPane tab="Общая" key="stat">
            <Search
              placeholder="Имя или телефон"
              onChange={(e) => onSearchStat(e.target.value)}
            />
            <Table
              columns={getStatColumns()}
              rowKey="id"
              dataSource={statDataSourse}
              pagination={false}
              style={{ width: '100%', marginTop: '20px' }}
            />
          </TabPane>
        </Tabs>
      </Row>
    </Col>
  );
}

OrgPromoCodesPageDesktop.propTypes = {
  loading: PropTypes.bool.isRequired,
  orgInfo: PropTypes.shape(),
  detailedDataSourse: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  statDataSourse: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSearchStat: PropTypes.func.isRequired,
  onSearchDetailed: PropTypes.func.isRequired,
};

OrgPromoCodesPageDesktop.defaultProps = {
  orgInfo: null,
};

export default OrgPromoCodesPageDesktop;
