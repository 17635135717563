import React, { Component } from 'react';
import { notification, Spin, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import md5 from 'md5';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import getUserId from '../../utils/localeStorage/getUserId';
import { config } from '../../utils/config';
import ClientGroupSessionsPageDesktop from './Desktop/ClientGroupSessionsPageDesktop';
import ClientGroupSessionsPageMobile from './Mobile/ClientGroupSessionsPageMobile';
import LoginModule from '../Login/LoginModule';
import getSessionId from '../../utils/localeStorage/getSessionId';
import getIsValidClient from '../../utils/localeStorage/getIsValidClient';
import {
  getApprovedGroupSessions, editGroupSessionEnroll, clientGroupSessions, getPaymentURL,
} from '../../utils/http';
import { routes } from '../../utils/routes';


class ClientGroupSessionsModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
      initLoading: true,
      loading: false,
      data: [],
      list: [],
      currentPage: 0,
      isShowLoadMore: true,
      clientSessions: [],
      isShowPaySessionModal: false,
      selectedSession: null,
      selectCancelOptionModalStep: 0,
      declineComment: null,
      currentTab: '1',
      isShowLoginModal: false,
      enrollGroupId: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    Promise.all([getApprovedGroupSessions(getUserId() || '1', 0), clientGroupSessions(getUserId())])
      .then((res) => {
        this.setState({
          initLoading: false,
          data: res[0].list,
          list: res[0].list,
          isShowLoadMore: res[0].totalPage > 1,
          clientSessions: res[1].filter((r) => r.statusID !== 'done'),
          currentTab: res[1].length > 0 ? '2' : '1',
          doneSessions: res[1].filter((r) => r.statusID === 'done'),
        });
      });
  }

  onLoadMore = () => {
    this.setState((prevState) => ({
      loading: true,
      list: prevState.data.concat(
        [...new Array(3)].map(() => ({ loading: true, specialist: {}, groupSessionEnroll: [] })),
      ),
    }));
    const { currentPage } = this.state;
    getApprovedGroupSessions(getUserId(), currentPage + 1)
      .then((res) => {
        this.setState((prevState) => ({
          data: prevState.data.concat(res.list),
          list: prevState.data.concat(res.list),
          loading: false,
          currentPage: currentPage + 1,
          isShowLoadMore: res.totalPage > currentPage + 2,
        }),
        () => {
          window.dispatchEvent(new Event('resize'));
        });
      });
  };

  onEnroll = (id) => {
    if (!getSessionId() || !getUserId()) {
      this.setState({ isShowLoginModal: true, enrollGroupId: id });
    } else {
      this.setState({ loading: true, isShowLoginModal: false });
      editGroupSessionEnroll({
        client_id: getUserId(), groupSession_id: id, id: md5(`${getUserId()}${id}`),
      })
        .then(() => {
          if (getIsValidClient() === 'true') {
            window.location.reload();
          } else {
            const { history } = this.props;
            history.push(routes.profile);
          }
        });
    }
  }

  onPaySessionModal = (selectedSession) => {
    if (!selectedSession || selectedSession.cost === 0) {
      this.setState((prevState) => ({
        isShowPaySessionModal: !prevState.isShowPaySessionModal,
        selectedSession,
      }));
    } else {
      this.onPaySession(selectedSession.cost, selectedSession);
    }
  }

  onPaySession = (cost, session) => {
    const { selectedSession } = this.state;
    getPaymentURL({
      groupSessionEnroll_id: selectedSession?.id || session.id,
      date: moment().toISOString(),
      cost,
    })
      .then((result) => {
        if (cost > 0 && !result.paymentURL) {
          notification.error({ message: 'Ошибка проведения оплаты. Пожалуйста, попробуйте позже' });
        } if (cost === 0) {
          this.onPaySessionModal(null);
          window.location.reload();
        } else {
          window.open(result.paymentURL, '_self');
        }
      });
  }

  onCloseSelectCancelOptionModal = () => {
    this.setState({ selectCancelOptionModalStep: 0, selectedSession: null, declineComment: null });
  }

  onShowCancelSessionModal = (selectedSession) => {
    this.setState({ selectCancelOptionModalStep: 1, selectedSession });
  }

  onChangeDeclineComment = (e) => {
    this.setState({ declineComment: e.target.value });
  }

  onDeclineSession = () => {
    const { selectedSession, declineComment } = this.state;
    editGroupSessionEnroll({ id: selectedSession.id, declineComment, status_id: 'canceled' })
      .then(() => window.location.reload());
  }

  changeTab = (currentTab) => {
    this.setState({ currentTab });
  }

  render() {
    const {
      isMobile, list, loading, initLoading, isShowLoadMore, clientSessions, isShowPaySessionModal, selectCancelOptionModalStep,
      currentTab, doneSessions, isShowLoginModal, enrollGroupId,
    } = this.state;
    if (initLoading) {
      return (
        <div className="wrapper-100per">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
        </div>
      );
    }
    if (isMobile) {
      return (
        <div className="wrapper-100per">
          <ClientGroupSessionsPageMobile
            list={list}
            loading={loading}
            initLoading={initLoading}
            onLoadMore={this.onLoadMore}
            isShowLoadMore={isShowLoadMore}
            onEnroll={this.onEnroll}
            clientSessions={clientSessions}
            isShowPaySessionModal={isShowPaySessionModal}
            onPaySessionModal={this.onPaySessionModal}
            onPaySession={this.onPaySession}
            selectCancelOptionModalStep={selectCancelOptionModalStep}
            onCloseSelectCancelOptionModal={this.onCloseSelectCancelOptionModal}
            onShowCancelSessionModal={this.onShowCancelSessionModal}
            onChangeDeclineComment={this.onChangeDeclineComment}
            onDeclineSession={this.onDeclineSession}
            currentTab={currentTab}
            changeTab={this.changeTab}
            doneSessions={doneSessions}
          />
          <Modal visible={isShowLoginModal} onCancel={() => this.setState({ isShowLoginModal: false })} footer={null}>
            <LoginModule callBack={() => this.onEnroll(enrollGroupId)} />
          </Modal>
        </div>
      );
    }
    return (
      <div className="wrapper-100per">
        <ClientGroupSessionsPageDesktop
          list={list}
          loading={loading}
          initLoading={initLoading}
          onLoadMore={this.onLoadMore}
          isShowLoadMore={isShowLoadMore}
          onEnroll={this.onEnroll}
          clientSessions={clientSessions}
          isShowPaySessionModal={isShowPaySessionModal}
          onPaySessionModal={this.onPaySessionModal}
          onPaySession={this.onPaySession}
          selectCancelOptionModalStep={selectCancelOptionModalStep}
          onCloseSelectCancelOptionModal={this.onCloseSelectCancelOptionModal}
          onShowCancelSessionModal={this.onShowCancelSessionModal}
          onChangeDeclineComment={this.onChangeDeclineComment}
          onDeclineSession={this.onDeclineSession}
          currentTab={currentTab}
          changeTab={this.changeTab}
          doneSessions={doneSessions}
        />
        <Modal visible={isShowLoginModal} onCancel={() => this.setState({ isShowLoginModal: false })} footer={null}>
          <LoginModule callBack={() => this.onEnroll(enrollGroupId)} />
        </Modal>
      </div>
    );
  }
}

ClientGroupSessionsModule.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(ClientGroupSessionsModule);
