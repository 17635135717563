export const adminPhones = [
  '00001111111',
  '79258217138',
  '79645154359',
];

export const adminIds = [
  '5ba48df551d652d1bb77e08082932302',
  'cfcd208495d565ef66e7dff9f98764da',
  '1fbcdf69d9e654b1daec4d6073631d4a',
];

