/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { orderBy, groupBy } from 'lodash';
import {
  Popover, Modal, Col, Card, Row, Button, Tag, Popconfirm, notification, Form, Divider, Input, Select, Alert, TimePicker, DatePicker, Menu, Dropdown,
} from 'antd';
import PropTypes from 'prop-types';
import {
  FormOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import MobiMenu from '../../../assets/icons/mobi-menu.svg';
import MobiLogo from '../../../assets/icons/mobi-logo.svg';
import './CalendarPageMobile.css';
import getUserId from '../../../utils/localeStorage/getUserId';
import timeArray from '../utils/timeArray';
import getUserRole from '../../../utils/localeStorage/getUserRole';
import adminButtonsArray from '../../../components/SideBar/utils/adminButtonsArray';
import specialistButtonsArray from '../../../components/SideBar/utils/specialistButtonsArray';
import clientButtonsArray from '../../../components/SideBar/utils/clientButtonsArray';
import getIsValidClient from '../../../utils/localeStorage/getIsValidClient';
import getIsValidSpecialist from '../../../utils/localeStorage/getIsValidSpecialist';
import logout from '../../../utils/logout';
import * as roles from '../../../utils/roles';
import {
  editGroupSession, getGroupTypeCatalogue, editUser, getUserAfterLogin,
} from '../../../utils/http';
import getSessionId from '../../../utils/localeStorage/getSessionId';
import { routes } from '../../../utils/routes';
import { setLocalStorage } from '../../../utils/localeStorage/storage';


const { Meta } = Card;
const { CheckableTag } = Tag;
const { TextArea } = Input;
const { Option } = Select;
class CalendarPageMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: '1',
      clientViewID: null,
      groupViewIDS: [],
      selectedDateGroup: null,
      selectedStartTime: null,
      selectedSession: null,
      selectedGroupType: null,
      groupTypeCatalogue: [],
      groupSessions: this.props.groupSessions,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { defaultActiveView } = this.props;
    getGroupTypeCatalogue()
      .then((groupTypeCatalogue) => this.setState({
        groupTypeCatalogue,
        view: defaultActiveView,
      }));
  }

    getDaysArray = (month, year) => {
      month.set('year', year);
      let daysInMonth = month.daysInMonth();
      const arrDays = [];

      while (daysInMonth) {
        const current = moment().set('year', year).set('month', month.month()).date(daysInMonth)
          .startOf('D');
        arrDays.push(current.format('D'));
        daysInMonth -= 1;
      }

      return arrDays.reverse();
    }

    onAddGroupSessionModal = (selectedSession) => {
      this.setState({
        selectedGroupType: selectedSession.groupTypeId,
        selectedSession,
        selectedDateGroup: selectedSession.startDateAndTime,
        selectedStartTime: selectedSession.startDateAndTime ? selectedSession.startDateAndTime.format('HH:mm') : null,
      });
    }


  onCloseGroupSessionModal = () => {
    this.setState({
      selectedSession: null,
      selectedDateGroup: null,
      selectedStartTime: null,
    });
  }

  onSaveAddGroupSession = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        const {
          selectedStartTime, selectedDateGroup, selectedSession, selectedGroupType, groupTypeCatalogue,
        } = this.state;
        const groupType = groupTypeCatalogue.find((g) => g.id === selectedGroupType);
        const selectedStartMinutes = Number(selectedStartTime.split(':')[0]) * 60 + Number(selectedStartTime.split(':')[1]);
        const calculatedEndTime = `${(`0${Math.floor((groupType.minutesLength + selectedStartMinutes) / 60)}`).slice(-2)}:${(`0${((groupType.minutesLength + selectedStartMinutes) % 60).toFixed(0)}`).slice(-2)}`;
        const objToSave = {
          id: selectedSession.id,
          description: values.description,
          name: values.name,
          endDateAndTime: moment(`${selectedDateGroup.format('YYYY-MM-DD')} ${calculatedEndTime}`),
          startDateAndTime: moment(`${selectedDateGroup.format('YYYY-MM-DD')} ${selectedStartTime}`),
          specialist_id: getUserId(),
          groupTypeCatalogue_id: selectedGroupType,
          status_id: 'onApproval',
        };
        editGroupSession(objToSave)
          .then((id) => {
            this.setState((prevState) => ({
              groupSessions: selectedSession.id
                ? prevState.groupSessions.map((g) => (g.id === selectedSession.id ? {
                  ...g, ...objToSave, groupTypeId: groupType.id, statusId: 'onApproval',
                } : g))
                : [...prevState.groupSessions, {
                  ...objToSave,
                  statusId: 'onApproval',
                  id,
                  groupSessionEnroll: [],
                  groupTypeName: groupType.name,
                }],
            }));
            notification.success({ message: 'Сохранено успешно' });
            this.onCloseGroupSessionModal();
          });
      });
  }

  getDisabledHoursStart = () => {
    const {
      selectedDateGroup, groupSessions, selectedGroupType, groupTypeCatalogue,
    } = this.state;
    const groupType = groupTypeCatalogue.find((g) => g.id === selectedGroupType);
    const { sessions } = this.props;
    const disabledHours = [];
    const selectedDateSessionsTimes = sessions
      .filter((s) => moment(s.sessionDateAndTime.format('DD/MM/YYYY'), 'DD/MM/YYYY').isSame(selectedDateGroup.startOf('day')))
      .map((s) => Number(s.sessionDateAndTime.format('HH')));
    selectedDateSessionsTimes.forEach((t) => disabledHours.push(t - Math.floor(groupType.minutesLength / 60)));
    disabledHours.push(...selectedDateSessionsTimes);
    const selectedDateGroupSessions = groupSessions
      .filter((s) => moment(s.startDateAndTime.format('DD/MM/YYYY'), 'DD/MM/YYYY').isSame(selectedDateGroup.startOf('day')));
    selectedDateGroupSessions.forEach((s) => {
      for (let i = Number(s.startDateAndTime.format('HH')); i <= Number(s.endDateAndTime.format('HH')); i += 1) {
        if (!disabledHours.includes(i)) {
          disabledHours.push(i);
        }
      }
    });
    return disabledHours;
  }

  handleMenuButtonClick = (route) => {
    const { history } = this.props;
    history.push(route);
  }

  changeRole = () => {
    const { history } = this.props;
    editUser({ id: getUserId(), role: getUserRole() === roles.CLIENT ? roles.SPECIALIST : roles.CLIENT })
      .then(() => getUserAfterLogin(getUserId(), getSessionId())
        .then((user) => {
          const payload = {
            ...user,
            isValidSpecialist: user.isValidSpecialist && !!user.paymentType,
          };
          setLocalStorage(payload);
          // window.location.reload();
          history.push(routes.profile);
        }));
  }

  render() {
    const {
      view, clientViewID, groupViewIDS, groupSessions, selectedDateGroup, selectedGroupType, groupTypeCatalogue, selectedSession,
    } = this.state;
    const {
      onWeekSelect, firstDayOfWeek, slots, onCheckBoxChange, sessions, onSave, onQuikSelectClick, onShowCloseTherapyModal,
      onChangeSessionStatus, onMonthSelect, selectedMonth, selectedDay, onDaySelect, selectedYear, onYearSelect, formRef,
      onStartSession, onShowProfile, onEditClientNameClick, onAddSession, isShowAddSession, isShowCloseTherapyModal, onSaveCloseTherapyModal,
      selectedDate, selectedTime, onEnroll, onCloseAddSession, onSelectTimeAndDate, onSaveEditClientName, onCancelEditClientName, editClientId,
      onChangeEditClientName, editClientName, onCloseCloseTherapyModal, history,
    } = this.props;
    const monthDays = this.getDaysArray(selectedMonth, selectedYear);
    const thisDaySession = orderBy(sessions.filter((s) => moment(s.sessionDateAndTime).startOf('D').isSame(moment(selectedDay).startOf('D'))), ['sessionDateAndTime'], ['asc']);
    const groupedClients = groupBy(sessions, 'clientID');
    const clientsIDS = Object.keys(groupedClients);

    let buttonsArray = [];
    switch (getUserRole()) {
      case roles.ADMIN:
        buttonsArray = adminButtonsArray;
        break;
      case roles.CLIENT:
        buttonsArray = clientButtonsArray;
        break;
      case roles.SPECIALIST:
        buttonsArray = specialistButtonsArray;
        break;
      default: break;
    }
    let isDisabled = true;
    if (getUserRole() === roles.CLIENT && getIsValidClient() === 'true') {
      isDisabled = false;
    }
    if (getUserRole() === roles.ADMIN) {
      isDisabled = false;
    }
    if (getUserRole() === roles.SPECIALIST && getIsValidSpecialist() === 'true') {
      isDisabled = false;
    }
    const menu = (
      <Menu>
        {buttonsArray
          .filter((b) => b.id !== 'help')
          .map((b) => (
            <Menu.Item
              key={b.id}
              disabled={isDisabled}
              onClick={() => this.handleMenuButtonClick(b.route)}
            >
              {b.name}
            </Menu.Item>
          ))}
        <Menu.Divider />
        {getUserId() && <Menu.Item key="2" onClick={this.changeRole}>Сменить роль</Menu.Item>}
        {getUserId() && <Menu.Divider />}
        <Menu.Item key="3" onClick={() => logout(history)} style={{ cursor: 'pointer' }}>Выйти</Menu.Item>
      </Menu>
    );
    return (
      <div className="calendarPageMobile-wrapper">
        <div className="profile-wrapper__header mobile-header__wrapper">
          <div className="mobi-logo"><a href="https://gestalt.online"><img src={MobiLogo} alt="Вернуться на главную" /></a></div>
          <span className="profile-title mobile-header__title">Работа</span>
          <div className="profile-header-menu">
            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
              <img src={MobiMenu} alt="Мобильное меню" />
            </Dropdown>
          </div>
        </div>
        <div className="calendarPageMobile-header-wrapper">
          <div className="calendarPageMobile-row">
            <div className={view === '1' ? 'calendarPageMobile-row-element-active' : 'calendarPageMobile-row-element'} onClick={() => this.setState({ view: '1' })}>Текущие записи</div>
            <div className={view === '2' ? 'calendarPageMobile-row-element-active' : 'calendarPageMobile-row-element'} onClick={() => this.setState({ view: '2' })}>Часы работы</div>
            <div className={view === '3' ? 'calendarPageMobile-row-element-active' : 'calendarPageMobile-row-element'} onClick={() => this.setState({ view: '3' })}>Клиенты</div>
            <div className={view === '4' ? 'calendarPageMobile-row-element-active' : 'calendarPageMobile-row-element'} onClick={() => this.setState({ view: '4' })}>Группы</div>
          </div>
        </div>
        <div className="calendarPageMobile-body">
          {view === '1' && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="calendarPageMobile-current-filter">
                <div className="calendarPageMobile-current-filter-wrapper">
                  <span className="calendarPageMobile-current-filter-label">Год</span>
                  <select defaultValue={selectedYear} className="calendarPageMobile-current-select" value={selectedYear} onChange={(e) => onYearSelect(e.target.value)}>
                    {[2021, 2022, 2023, 2024, 2025].map((el) => (
                      <option key={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="calendarPageMobile-current-filter-wrapper">
                  <span className="calendarPageMobile-current-filter-label">Месяц</span>
                  <select
                    defaultValue={selectedMonth.format('M')}
                    value={selectedMonth.format('M')}
                    className="calendarPageMobile-current-select"
                    onChange={(e) => onMonthSelect(moment().set('year', selectedYear).startOf('year').add(e.target.value - 1, 'M'))}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((el) => {
                      const month = moment().set('year', selectedYear).startOf('year').add(el - 1, 'M');
                      return (
                        <option key={el} value={el}>
                          {month.format('MMMM')}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="calendarPageMobile-current-filter-wrapper">
                  <span className="calendarPageMobile-current-filter-label">День</span>
                  <select
                    defaultValue={selectedDay.format('D')}
                    value={selectedDay.format('D')}
                    className="calendarPageMobile-current-select"
                    onChange={(e) => onDaySelect(moment().set('year', selectedYear).set('month', selectedMonth.month()).date(e.target.value))}
                  >
                    {monthDays.map((d) => (
                      <option key={d} value={d}>
                        {d}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="calendarPageMobile-current-result">
                {thisDaySession.map((s) => (
                  <div
                    key={s.id}
                    className="calendarPageMobile-current-result__wrapper"
                  >
                    <div className="calendarSession-time">{s.sessionDateAndTime.format('HH:00')}</div>
                    <div className="calendarSession__wrapper">
                      <div className="calendarSession__block">
                        <div className="calendarSession__block__param">
                          <div className="calendarSession__block__name">
                            <div className="client-nickname">{`${s.clientName} ${s.clientDisplayName ? `(${s.clientDisplayName})` : ''}`}</div>
                            <div className="client-status"><FormOutlined onClick={() => onEditClientNameClick(s.clientDisplayName, s.clientID)} /></div>
                          </div>
                          <div className="calendarSession__block__pay">
                            <div className="calendarSession__block__pay__title">Статус:</div>
                            <div className="calendarSession__block__pay__value">{s.statusValue}</div>
                          </div>
                        </div>
                        <div className="calendarSession__sub-block">
                          <div className="calendarSession-anketa" onClick={() => onShowProfile(s)}>Анкета клиента</div>
                          {s.hostURL && s.statusID === 'paid' && <div onClick={() => onChangeSessionStatus(s.id, 'canceled')}>Сессия не состоялась</div>}
                          {s.hostURL && s.statusID === 'paid' && <div onClick={() => onChangeSessionStatus(s.id, 'done')}>Сессия завершена</div>}
                          {s.statusID === 'paid' && (
                          <div className="btn-default btn_green" onClick={() => onStartSession(s.id)} alt="Начать сессию">Начать</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {view === '2' && (
          <div style={{ display: 'flex', flexDirection: 'column', fontSize: '10px' }}>
            <div className="calendarPageMobile-time-filter-button__wrapper">
              <div className="calendarPageMobile-time-filter-button" onClick={() => onQuikSelectClick(1)}>c 10 до 20 б/вых</div>
              <div className="calendarPageMobile-time-filter-button" onClick={() => onQuikSelectClick(2)}>c 10 до 20 +вых</div>
              <div className="calendarPageMobile-time-filter-button" onClick={() => onQuikSelectClick(3)}>c 16 до 21 б/вых</div>
              <div className="calendarPageMobile-time-filter-button" onClick={() => onQuikSelectClick(4)}>c 16 до 21 +вых</div>
            </div>
            <div className="calendarPageMobile-time-filter-button__wrapper_3">
              <div className="calendarPageMobile-time-filter-button" onClick={onShowCloseTherapyModal}>Закрыть прием</div>
              <div className="calendarPageMobile-time-filter-button" onClick={() => onQuikSelectClick(5)}>Сбросить все</div>
              <div className="calendarPageMobile-time-filter-button calendarPageMobile-time-filter-button_green" onClick={onSave}>Сохранить</div>
            </div>
            <div className="calendarPageMobile__result-week">
              <div onClick={() => onWeekSelect(moment(firstDayOfWeek).add(-1, 'weeks').startOf('isoWeek'))}>{'<'}</div>
              <div style={{ fontWeight: 'bold', margin: '0px 10px' }}>{`${moment(firstDayOfWeek).startOf('isoWeek').format('DD')} - ${moment(firstDayOfWeek).endOf('isoWeek').format('DD')} ${moment(firstDayOfWeek).endOf('isoWeek').format('MMMM')}`}</div>
              <div onClick={() => onWeekSelect(moment(firstDayOfWeek).add(1, 'weeks').startOf('isoWeek'))}>{'>'}</div>
            </div>
            <div className="calendarPageMobile-time-body-wrapper">
              <div className="calendarPageMobile-time-calendaer_day">
                <div className="calendarPageMobile-time-calendaer_day_time" />
                <div className="calendarPageMobile-time-calendaer_day_col">ПН</div>
                <div className="calendarPageMobile-time-calendaer_day_col">ВТ</div>
                <div className="calendarPageMobile-time-calendaer_day_col">СР</div>
                <div className="calendarPageMobile-time-calendaer_day_col">ЧТ</div>
                <div className="calendarPageMobile-time-calendaer_day_col">ПТ</div>
                <div className="calendarPageMobile-time-calendaer_day_col">СБ</div>
                <div className="calendarPageMobile-time-calendaer_day_col">ВС</div>
              </div>
              {timeArray.map((t) => (
                <div key={t} className="calendarPageMobile-time__slots">
                  <div className="calendarPageMobile-time-day calendarPageMobile-time-day__title">
                    <div style={{ justifyContent: 'space-around', display: 'flex', flexDirection: 'row' }}>
                      {t}
                    </div>
                  </div>
                  {[0, 1, 2, 3, 4, 5, 6].map((i) => {
                    const slot = slots.find((s) => s.date.startOf('day').isSame(moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day')));
                    const isTime = (slot && slot.timesArray.includes(t)) || false;
                    const session = sessions.find((s) => moment(s.sessionDateAndTime).startOf('day').isSame(moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day')) && moment(s.sessionDateAndTime).format('HH:00') === t);
                    const checkBox = moment(`${moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day').format('YYYY-MM-DD')} ${t}`);
                    const groupSession = groupSessions.find((s) => checkBox.isBetween(s.startDateAndTime, s.endDateAndTime, 'minute', '[]'));
                    return (
                      <div key={i} className="calendarPageMobile-time-day">
                        {!!groupSession && (
                        <Popover content={(
                          <div>
                            <div>{`${moment(groupSession.startDateAndTime).format('HH:00')}-${moment(groupSession.endDateAndTime).format('HH:00')}`}</div>
                            <div>{groupSession.name}</div>
                          </div>
)}
                        >
                          <input
                            type="checkbox"
                            disabled
                            checked={isTime}
                          />
                        </Popover>
                        )}
                        {!!session && (
                        <Popover content={(
                          <div>
                            <div>{moment(session.sessionDateAndTime).format('DD MMMM, HH:00')}</div>
                            <div>{session.clientName}</div>
                          </div>
)}
                        >
                          <input
                            type="checkbox"
                            disabled
                            checked={isTime}
                          />
                        </Popover>
                        )}
                        {!session && !groupSession && (
                        <input
                          type="checkbox"
                          onChange={(e) => onCheckBoxChange(moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day'), t, e.target.checked, slot)}
                          checked={isTime}
                        />
                        )}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
          )}
          {view === '3' && (
          <div>
            {clientsIDS.map((clientID) => {
              const session = sessions.find((s) => s.clientID === clientID);
              return (
                <div
                  className="calendarPageMobile-clients-wrapper"
                  key={clientID}
                >
                  <div className="calendarPageMobile-clients__block-info">
                    <div style={{ color: '#212121', fontSize: '18px' }}>{session.clientDisplayName || session.clientName}</div>
                    {(!clientViewID || clientViewID === clientID)
                        && (
                        <div
                          className="calendarPageMobile-clients__open-tab"
                          onClick={() => this.setState((prevState) => ({ clientViewID: prevState.clientViewID ? null : clientID }))}
                        >
                          {clientViewID === clientID ? 'Закрыть' : 'Открыть'}
                        </div>
                        )}
                  </div>
                  {clientViewID === clientID && (
                  <div className="my-clients_mobile__one-block__edit">
                    <div className="my-clients__one-block__edit"><div className="btn-default btn_red" type="primary" onClick={() => onAddSession(clientID)}>Записать клиента</div></div>
                    {sessions.filter((s) => s.clientID === clientID).map((s) => (
                      <div key={s.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>{s.sessionDateAndTime.format('DD MMMM, HH:mm, dd')}</div>
                        <div>{s.statusValue}</div>
                      </div>
                    ))}
                  </div>
                  )}
                </div>
              );
            })}
          </div>
          )}
          {view === '4' && (
            <div className="calendarPageMobile-group">
              <div className="btn-default btn_green btn_mini-margin" onClick={() => this.onAddGroupSessionModal({})}>Добавить групповую сессию</div>
              {orderBy(groupSessions, ['startDateAndTime'], ['desc']).map((gs) => {
                const totalClients = gs.groupSessionEnroll;
                const paidClients = gs.groupSessionEnroll.filter((e) => e.statusID === 'paid');
                const canceledClients = gs.groupSessionEnroll.filter((e) => e.statusID === 'canceled');
                const approvedClients = gs.groupSessionEnroll.filter((e) => e.statusID === 'approved');
                return (
                  <div key={gs.id} className="calendarPageMobile-group-wrapper">
                    <div className="calendarPageMobile-group-wrapper__item">
                      <div
                        className="calendarPageMobile-group-wrapper__item-arrow"
                        onClick={() => this.setState((prevState) => ({
                          groupViewIDS: prevState.groupViewIDS.includes(gs.id)
                            ? prevState.groupViewIDS.filter((gvID) => gvID !== gs.id)
                            : [...prevState.groupViewIDS, gs.id],
                        }))}
                      />
                      <div className="calendarPageMobile-group-wrapper__item-title">{gs.name}</div>
                      {gs.VOXSpecialistURL && gs.statusId === 'approved' && (
                        <div className="groupItem__icon-play btn-default btn_green" type="primary" onClick={() => window.open(gs.VOXSpecialistURL, '_self')}><span>Начать</span></div>
                      )}
                      <div className="PageMobile__groupItem__status">
                        <span>{gs.statusValue}</span>
                      </div>
                    </div>
                    {groupViewIDS.includes(gs.id) && (
                    <div className="PageMobile__groupItem__desc">
                      <div className="PageMobile__groupItem__desc__item">
                        <div className="calendarPageMobile-group-label">Дата:</div>
                        <div className="calendarPageMobile-group-value">{gs.startDateAndTime.format('DD MMMM YYYY')}</div>
                      </div>
                      <div className="PageMobile__groupItem__desc__item">
                        <div className="calendarPageMobile-group-label">Время:</div>
                        <div className="calendarPageMobile-group-value">{`c ${gs.startDateAndTime.format('HH:mm')} до ${gs.endDateAndTime.format('HH:mm')}`}</div>
                      </div>
                      <div className="PageMobile__groupItem__desc__item">
                        <div className="calendarPageMobile-group-label">Ограничение:</div>
                        <div className="calendarPageMobile-group-value">{`c ${gs.maxNumberOfClients} человек`}</div>
                      </div>
                      <div className="PageMobile__groupItem__desc__item PageMobile__groupItem__desc__item_last">
                        <div className="calendarPageMobile-group-value">{gs.description}</div>
                      </div>

                      <div className="PageMobile__groupItem__process-title">{`Зарегистрировано: ${totalClients.length}`}</div>
                      {totalClients.length > 0 && (
                        <div className="PageMobile__groupItem__process_wrapper">
                          {totalClients.map((c) => (
                            <div key={c.id} className="PageMobile__groupItem__process">
                              <div className="PageMobile__groupItem__process-col PageMobile__groupItem__process-name">{c.clientName}</div>
                              <div className="PageMobile__groupItem__process-col PageMobile__groupItem__process-cont">
                                <div className="PageMobile__groupItem__process-phone">{c.clientPhone}</div>
                                <div className="PageMobile__groupItem__process-email">{c.clientEmail}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="PageMobile__groupItem__process-title">{`Подтверждено: ${approvedClients.length + paidClients.length}`}</div>
                      {[...paidClients, ...approvedClients].length > 0 && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {[...paidClients, ...approvedClients].map((c) => (
                            <div key={c.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                              <div>{c.clientName}</div>
                              <div>{c.clientPhone}</div>
                              <div>{c.clientEmail}</div>
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="PageMobile__groupItem__process-title">{`Оплатило: ${paidClients.length}`}</div>
                      {paidClients.length > 0 && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {paidClients.map((c) => (
                            <div key={c.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                              <div>{c.clientName}</div>
                              <div>{c.clientPhone}</div>
                              <div>{c.clientEmail}</div>
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="PageMobile__groupItem__process-title">{`Отменило: ${canceledClients.length}`}</div>
                      {canceledClients.length > 0 && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {canceledClients.map((c) => (
                            <div key={c.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                              <div>{c.clientName}</div>
                              <div>{c.clientPhone}</div>
                              <div>{c.clientEmail}</div>
                            </div>
                          ))}
                        </div>
                      )}

                    </div>
                    )}
                  </div>
                );
              })}

            </div>
          )}
          {isShowAddSession && (
            <Modal
              visible
              title=""
              onCancel={onCloseAddSession}
              maskClosable
              footer={null}
              width="80vw"
              bodyStyle={{ height: '70vh' }}
            >
              <Col>
                <Row style={{ height: '60vh', overflow: 'scroll' }}>
                  <Card
                    hoverable={false}
                    style={{ borderColor: '#bfbdbb', width: '100%' }}
                    id="TIME"
                  >
                    <Meta
                      description={(
                        <Col span={24}>
                          {slots
                            .filter((slot) => slot.date.isAfter(moment()))
                            .map((slot) => (
                              <Row style={{ margin: '10px' }} key={slot.id}>
                                <Col span={24} key={slot.id}>
                                  <Row style={{ color: '#333333' }}>{slot.date.format('DD MMMM, dddd')}</Row>
                                  <Row gutter={12}>
                                    {slot.timesArray.map((time) => (
                                      <CheckableTag
                                        key={time + slot.date}
                                        checked={selectedTime === time && slot.date.isSame(selectedDate)}
                                        onChange={() => onSelectTimeAndDate(time, slot.date)}
                                      >
                                        {time}
                                      </CheckableTag>
                                    ))}
                                  </Row>
                                </Col>
                              </Row>
                            ))}
                        </Col>
              )}
                    />
                  </Card>
                </Row>
                {selectedDate && selectedTime && (
                <Row style={{ marginTop: '20px' }}>
                  <Popconfirm
                    title="Вы уверены, что хотите записать клиента на эту дату?"
                    onConfirm={onEnroll}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button className="btn-default btn_green" type="primary">Записать</Button>
                  </Popconfirm>
                </Row>
                )}
              </Col>
            </Modal>
          )}
          {selectedSession
         && (
         <Modal
           visible
           title=""
           onCancel={this.onCloseGroupSessionModal}
           maskClosable
           footer={[
             <Button className="btn-default btn_green" type="primary" key="1" onClick={this.onSaveAddGroupSession}>Отправить на модерацию</Button>,
           ]}
         >
           <Form
             layout="vertical"
             ref={this.formRef}
           >
             <div className="modal-form__createGroup">
               <div className="modal-form__createGroup__name">
                 <Form.Item
                   className="anketa__input-hor anketa__input_input"
                   rules={[{ required: true, message: '' }]}
                   name="name"
                   label="Название"
                   initialValue={selectedSession.name}
                 >
                   <Input />
                 </Form.Item>
               </div>
               <div className="modal-form__createGroup__desc">
                 <Form.Item
                   className="anketa__text"
                   rules={[{ required: true, message: '' }]}
                   name="description"
                   label="Описание"
                   initialValue={selectedSession.description}
                 >
                   <TextArea className="anketa__textarea" rows={4} />
                 </Form.Item>
               </div>
               <div>
                 <div>
                   <Form.Item
                     className="anketa__input-hor anketa__input_format"
                     rules={[{ required: true, message: '' }]}
                     name="groupType"
                     label="Вид группы"
                     initialValue={selectedSession.groupTypeId}
                   >
                     <Select
                       onChange={(e) => this.setState({ selectedGroupType: e })}
                     >
                       {groupTypeCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.name}</Option>)}
                     </Select>
                   </Form.Item>
                 </div>
               </div>
               {selectedGroupType && (
               <Row>
                 <Col span={24}>
                   <Form.Item
                     rules={[{ required: true, message: '' }]}
                     name="date"
                     label="Дата проведения"
                     initialValue={selectedSession.startDateAndTime}
                   >
                     <DatePicker style={{ width: '100%' }} onChange={(date) => this.setState({ selectedDateGroup: date })} />
                   </Form.Item>
                 </Col>
               </Row>
               )}
               {selectedDateGroup && selectedGroupType && (
               <Row>
                 <Col span={24}>
                   <Form.Item
                     rules={[{ required: true, message: '' }]}
                     name="timeStart"
                     label="Время начала"
                     initialValue={selectedSession.startDateAndTime}
                   >
                     <TimePicker
                       style={{ width: '100%' }}
                       format="HH:mm"
                       onChange={(date, time) => this.setState({ selectedStartTime: time })}
                       disabledHours={() => this.getDisabledHoursStart()}
                       showNow={false}
                     />
                   </Form.Item>
                 </Col>
               </Row>
               )}
               {selectedSession.statusId === 'declined' && <Divider />}
               {selectedSession.statusId === 'declined' && (
               <Alert
                 message="Требуется доработка"
                 description={(
                   <div>
                     <div style={{ whiteSpace: 'break-spaces' }}>{selectedSession.moderatorComment}</div>
                   </div>
          )}
                 type="error"
               />
               )}
             </div>
           </Form>
         </Modal>
         )}
          {editClientId
      && (
      <Modal
        title="Никнейм клиента"
        onCancel={onCancelEditClientName}
        visible
        footer={[
          <Button key="back" onClick={onCancelEditClientName}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" onClick={onSaveEditClientName}>
            Сохранить
          </Button>,
        ]}
      >
        <Col span={24}>
          <Row style={{ marginBottom: '5px' }}>Это имя будет отображаться только у Вас. Клиент его не увидит</Row>
          <Row>
            <Input onChange={(e) => onChangeEditClientName(e.target.value)} defaultValue={editClientName} style={{ width: '100%', marginBottom: '15px' }} />
          </Row>
        </Col>
      </Modal>
      )}
          {isShowCloseTherapyModal
        && (
        <Modal
          title="Закрыть прием"
          visible
          onOk={onSaveCloseTherapyModal}
          onCancel={onCloseCloseTherapyModal}
          okText="Закрыть"
          cancelText="Отмена"
        >
          <Form
            ref={formRef}
          >
            <Form.Item
              label="Дата начала"
              name="dateStart"
              rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label="Дата окончания"
              name="dateEnd"
              rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
            >
              <DatePicker />
            </Form.Item>
          </Form>
        </Modal>
        )}
        </div>
      </div>
    );
  }
}

CalendarPageMobile.propTypes = {
  onWeekSelect: PropTypes.func.isRequired,
  firstDayOfWeek: PropTypes.shape().isRequired,
  slots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onCheckBoxChange: PropTypes.func.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSave: PropTypes.func.isRequired,
  onQuikSelectClick: PropTypes.func.isRequired,
  onShowCloseTherapyModal: PropTypes.func.isRequired,
  isShowCloseTherapyModal: PropTypes.bool.isRequired,
  onSaveCloseTherapyModal: PropTypes.func.isRequired,
  onCloseCloseTherapyModal: PropTypes.func.isRequired,
  formRef: PropTypes.shape().isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  selectedMonth: PropTypes.shape().isRequired,
  onDaySelect: PropTypes.func.isRequired,
  selectedDay: PropTypes.shape().isRequired,
  selectedYear: PropTypes.number.isRequired,
  onYearSelect: PropTypes.func.isRequired,
  onStartSession: PropTypes.func.isRequired,
  onChangeSessionStatus: PropTypes.func.isRequired,
  onShowProfile: PropTypes.func.isRequired,
  groupSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onEditClientNameClick: PropTypes.func.isRequired,
  onAddSession: PropTypes.func.isRequired,
  isShowAddSession: PropTypes.bool.isRequired,
  selectedDate: PropTypes.shape(),
  selectedTime: PropTypes.string,
  onEnroll: PropTypes.func.isRequired,
  onCloseAddSession: PropTypes.func.isRequired,
  onSelectTimeAndDate: PropTypes.func.isRequired,
  onSaveEditClientName: PropTypes.func.isRequired,
  onCancelEditClientName: PropTypes.func.isRequired,
  editClientId: PropTypes.string,
  editClientName: PropTypes.string,
  onChangeEditClientName: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  defaultActiveView: PropTypes.string.isRequired,
};

CalendarPageMobile.defaultProps = {
  selectedDate: null,
  selectedTime: null,
  editClientId: null,
  editClientName: '',
};

export default withRouter(CalendarPageMobile);
