import React from 'react';
import {
  Row, Col, Tag, Popconfirm, Button,
} from 'antd';
import PropTypes from 'prop-types';


const { CheckableTag } = Tag;
function SpecialistSlots({
  slots, selectedTime, onSelect, selectedDate, onEnroll, isNew,
}) {
  return (
    <Col>
      {slots.map((slot) => (
        <Row style={{ margin: '10px 0px' }} key={slot.id}>
          <Col span={24} key={slot.id}>
            <Row style={{ color: '#333333' }}>{slot.date.format('DD MMMM, dddd')}</Row>
            <Row gutter={12}>
              {slot.timesArray.map((time) => (
                <CheckableTag
                  key={time + slot.date}
                  checked={selectedTime === time && slot.date.isSame(selectedDate)}
                  onChange={() => onSelect(time, slot.date)}
                >
                  {time}
                </CheckableTag>
              ))}
            </Row>
          </Col>
        </Row>
      ))}
      {selectedDate && selectedTime && (
      <Row style={{ marginTop: '40px' }}>
        <Popconfirm
          title={isNew ? 'Вы уверены, что хотите записаться?' : 'Вы уверены, что хотите перенести встречу?'}
          onConfirm={onEnroll}
          okText="Да"
          cancelText="Нет"
        >
          <Button type="primary">Записаться</Button>
        </Popconfirm>
      </Row>
      )}
    </Col>
  );
}
SpecialistSlots.propTypes = {
  slots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedTime: PropTypes.string,
  selectedDate: PropTypes.shape(),
  onSelect: PropTypes.func.isRequired,
  onEnroll: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
};


SpecialistSlots.defaultProps = {
  selectedTime: null,
  selectedDate: null,
  isNew: false,
};

export default SpecialistSlots;
