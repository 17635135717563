import React from 'react';
import { routes } from '../../../utils/routes';


const specialistButtonsArray = [
  {
    name: 'Профиль',
    id: 'profile',
    icon: <div className="sideBarButton__icon sideBarButton__icon_profile" />,
    route: routes.profile,
  },
  {
    name: 'Сессии',
    id: 'specialistSessions',
    icon: <div className="sideBarButton__icon sideBarButton__icon_meetings" />,
    route: routes.specialistSessions,
  },
  {
    name: 'Группы',
    id: 'specialistGroups',
    icon: <div className="sideBarButton__icon sideBarButton__icon_group" />,
    route: routes.specialistGroups,
  },
  {
    name: 'Расписание',
    id: 'specialistTimetable',
    icon: <div className="sideBarButton__icon sideBarButton__icon_calendar" />,
    route: routes.specialistTimetable,
  },
];

export default specialistButtonsArray;
