const getAdminLoginColumns = () => [
  {
    title: 'Дата',
    dataIndex: 'date',
    key: 'date',
    render: (text) => (text ? text.format('DD MMMM YYYY HH:mm') : ''),
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Роль',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Клиент',
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: 'Результат',
    dataIndex: 'rsp_code',
    key: 'rsp_code',
  },
];

export default getAdminLoginColumns;
