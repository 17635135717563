/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Col, Button, Popconfirm, Row,
} from 'antd';


const getClientSessionsColumns = (onCancelSession, onCancelSelectOption) => [
  {
    title: 'Дата',
    dataIndex: 'sessionDateAndTime',
    key: 'sessionDateAndTime',
    render: (text) => (text ? text.format('DD MMMM, HH:mm, dd') : ''),
    width: '20%',
  },
  {
    title: 'Специалист',
    dataIndex: 'specialist_id',
    key: 'specialist_id',
    width: '30%',
    render: (text) => <div>{text ? `${text.lastName} ${text.firstName} ${text.middleName}` : ''}</div>,
  },
  {
    title: 'Статус',
    dataIndex: 'statusValue',
    key: 'statusValue',
    width: '20%',
  },
  {
    title: '',
    dataIndex: '',
    key: '',
    width: '30%',
    render: (text, object) => (
      <Row justify="space-between">
        {object.statusID === 'paid' && <Col><Popconfirm title="Вы уверены, что хотите перенести?" onConfirm={() => onCancelSelectOption('old', object)} okText="Да" cancelText="Нет"><Button type="primary">Перенести встречу</Button></Popconfirm></Col>}
        {(object.statusID === 'toBePaid' || object.statusID === 'paid') && <Col><Button onClick={() => onCancelSession(object)}>Отменить</Button></Col>}
      </Row>
    ),
  },
];

export default getClientSessionsColumns;

