import { orderBy } from 'lodash';


const getOrderedSpecList = (specList, searchObj) => {
  const result = [];
  specList.forEach((s) => {
    let orderIndex = 0;
    let isPush = true;
    if (searchObj.gender === 'Иное' && s.isLGBTQ) {
      orderIndex += 1;
    }
    if (searchObj.whoWillUse === 'meAndPartner' && !s.isGroupSession) {
      isPush = false;
    }
    if (searchObj.whoWillUse === 'child' && s.isWorkWithChildren) {
      orderIndex += 1;
    }
    orderIndex += searchObj.userSpecializations.filter((value) => s.specializationCatalogue_ids.includes(value)).length;
    if (isPush) {
      result.push({ ...s, orderIndex });
    }
  });
  return orderBy(result, 'orderIndex', 'desc');
};

export default getOrderedSpecList;
