/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Modal, Input,
} from 'antd';
import PinInput from 'react-pin-input';
import ProfileField from './ProfileField';
import getIsValidClient from '../../../../utils/localeStorage/getIsValidClient';
import { getTimeZoneCatalogue } from '../../../../utils/http';
import getNickName from '../../../../utils/localeStorage/getNickName';
import { routes } from '../../../../utils/routes';


class ProfileClient extends Component {
  state = {
    fieldName: null,
    comment: '',
    fieldValue: '',
    timeZoneCatalogue: [],
  };

  componentDidMount() {
    getTimeZoneCatalogue()
      .then((timeZoneCatalogue) => this.setState({
        timeZoneCatalogue,
      }));
  }

  onChangeClick = (fieldName) => {
    this.setState({ fieldName });
  }

  onCancelChange = () => {
    this.setState({ fieldName: null, fieldValue: '', comment: '' });
  }

  onSaveClick = () => {
    const { onPersonalDataChangeRequest } = this.props;
    const { fieldValue, fieldName, comment } = this.state;
    onPersonalDataChangeRequest({ fieldValue, fieldName, comment });
    this.onCancelChange();
  }

  onEditField = (fieldValue) => {
    this.setState({ fieldValue });
  }

  onEditComment = (comment) => {
    this.setState({ comment });
  }

  render() {
    const {
      user, lockedFields, fieldLoading, onSaveForm, formRef, onRedirect, onSendEmailVerificationCode, onChangeEmailInput,
      isShowApproveEmailInput, onCloseShowApproveEmailInput, onFinishApproveEmailInput, isLoadingEmailInput, isValidEmailInput,
      gender, onValueChange,
    } = this.props;
    const { fieldName, timeZoneCatalogue } = this.state;
    return (
      <div className="profilePageDesktop">
        <Form
          ref={formRef}
        >
          <div className="profilePageDesktop-form-wrapper">
            <div className="profilePageDesktop-form-column-wrapper">
              <h1 className="profilePageDesktop-form-column-title">Персональные данные</h1>
              <div className="profilePageDesktop-form-column-wrapper">
                <ProfileField
                  initialValue={user.nickName}
                  fieldName="nickName"
                  fieldVisibleName="Как вас зовут?"
                  disabled={lockedFields.find((e) => e.value === 'lastName').isLocked}
                  loading={fieldLoading}
                  fieldInEdit={fieldName}
                  onSaveClick={this.onSaveClick}
                  onCancelChange={this.onCancelChange}
                  setNewValue={this.onEditField}
                  setComment={this.onEditComment}
                  onChangeClick={this.onChangeClick}
                  fieldType="input"
                  catalogue={[]}
                  showSearch={false}
                  placeholder="Ваше имя или всевдоним"
                  required
                />
                <ProfileField
                  initialValue={user.gender}
                  fieldName="gender"
                  fieldVisibleName="Ваш пол"
                  disabled={lockedFields.find((e) => e.value === 'gender').isLocked}
                  loading={fieldLoading}
                  fieldInEdit={fieldName}
                  onSaveClick={this.onSaveClick}
                  onCancelChange={this.onCancelChange}
                  setNewValue={this.onEditField}
                  setComment={this.onEditComment}
                  onChangeClick={this.onChangeClick}
                  fieldType="select"
                  catalogue={[{ id: 'Мужской', value: 'Мужской' }, { id: 'Женский', value: 'Женский' }, { id: 'Иное', value: 'Иное' }]}
                  showSearch={false}
                  onValueChange={onValueChange}
                />
                {gender === 'Иное' && (
                <ProfileField
                  initialValue={user.genderOther}
                  fieldName="genderOther"
                  fieldVisibleName="Как к вам обращаться"
                  disabled={lockedFields.find((e) => e.value === 'gender').isLocked}
                  loading={fieldLoading}
                  fieldInEdit={fieldName}
                  onSaveClick={this.onSaveClick}
                  onCancelChange={this.onCancelChange}
                  setNewValue={this.onEditField}
                  setComment={this.onEditComment}
                  onChangeClick={this.onChangeClick}
                  fieldType="input"
                  catalogue={[]}
                  showSearch={false}
                  onValueChange={onValueChange}
                />
                )}

                <ProfileField
                  initialValue={user.phone}
                  fieldName="phone"
                  fieldVisibleName="Номер телефона"
                  disabled={lockedFields.find((e) => e.value === 'phone').isLocked}
                  loading={fieldLoading}
                  fieldInEdit={fieldName}
                  onSaveClick={this.onSaveClick}
                  onCancelChange={this.onCancelChange}
                  setNewValue={this.onEditField}
                  setComment={this.onEditComment}
                  onChangeClick={this.onChangeClick}
                  fieldType="phone"
                  catalogue={[]}
                  showSearch={false}
                />
                <div className="profileField-form-column-block">
                  <div className="profileField-form-column-block-row">
                    <div className="profileField-form-column-block-column">
                      <Form.Item
                        name="email"
                        initialValue={user.email}
                        label="E-mail"
                      >
                        <Input
                          disabled={fieldLoading}
                          style={{ borderColor: user.isEmailVerified ? 'inherit' : 'red' }}
                        />
                      </Form.Item>
                      <div className="profileField-form-column-block-placeholder">
                        <span style={{ color: 'red' }}>Внимание! </span>
                        E-mail нужен для отправки чека об оплате
                      </div>
                    </div>
                    {!user.isEmailVerified && <Button type="link" onClick={onSendEmailVerificationCode} className="btn-default btn_link">Подтвердить</Button>}
                  </div>
                </div>
                <ProfileField
                  initialValue={user.timeZone}
                  fieldName="timeZone"
                  fieldVisibleName="Часовой пояс"
                  disabled={lockedFields.find((e) => e.value === 'timeZone').isLocked}
                  loading={fieldLoading}
                  fieldInEdit={fieldName}
                  onSaveClick={this.onSaveClick}
                  onCancelChange={this.onCancelChange}
                  setNewValue={this.onEditField}
                  setComment={this.onEditComment}
                  onChangeClick={this.onChangeClick}
                  fieldType="select"
                  catalogue={timeZoneCatalogue}
                  showSearch
                  required
                />
                <div className="btn-block__inside">
                  <Button className="btn-default btn_transparent" onClick={onSaveForm}>Сохранить</Button>
                  <Button className="btn-default btn_red" type="primary" disabled={!getNickName() || getIsValidClient() !== 'true'} onClick={() => onRedirect(routes.list)}>Выбор специалиста</Button>
                  <Button className="btn-default btn_green" type="primary" disabled={!getNickName() || getIsValidClient() !== 'true'} onClick={() => onRedirect(routes.groupSessions)}>Найти группу</Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
        {isShowApproveEmailInput
      && (
      <Modal
        title=""
        onCancel={onCloseShowApproveEmailInput}
        visible
        footer={null}
      >
        <div>Введите, пожалуйста, код, полученный на указанный email</div>
        <PinInput
          length={6}
          initialValue=""
          secret={false}
          type="numeric"
          inputMode="number"
          onChange={onChangeEmailInput}
          inputStyle={{
            color: 'black',
            backgroundColor: isLoadingEmailInput ? '#f0f0f0' : '#ffffff',
            borderColor: isValidEmailInput ? '#f0f0f0' : '#f5222d',
            borderRadius: '6px',
            border: isLoadingEmailInput ? '1px solid rgb(240, 240, 240)' : '1px solid',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
          }}
          inputFocusStyle={{
            borderColor: isValidEmailInput ? '#E79F8B' : '#f5222d',
          }}
          disabled={isLoadingEmailInput}
          onComplete={(value) => onFinishApproveEmailInput(value)}
          autoSelect
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </Modal>
      )}
      </div>
    );
  }
}


ProfileClient.propTypes = {
  onPersonalDataChangeRequest: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  lockedFields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fieldLoading: PropTypes.bool.isRequired,
  onSaveForm: PropTypes.func.isRequired,
  formRef: PropTypes.shape().isRequired,
  onSendEmailVerificationCode: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  isShowApproveEmailInput: PropTypes.bool.isRequired,
  onCloseShowApproveEmailInput: PropTypes.func.isRequired,
  onFinishApproveEmailInput: PropTypes.func.isRequired,
  isLoadingEmailInput: PropTypes.bool.isRequired,
  isValidEmailInput: PropTypes.bool.isRequired,
  onChangeEmailInput: PropTypes.func.isRequired,
  gender: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,

};

export default ProfileClient;
