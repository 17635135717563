import React, { Component } from 'react';
import { notification } from 'antd';
import { config } from '../../utils/config';
import AdminSpecialistsPageDesktop from './Desktop/AdminSpecialistsPageDesktop';
import {
  getAllSpecialistsShort, getSpecialistProfile, editSpecialistStatus, editClientStatus,
} from '../../utils/http';


class AdminSpecialistsPageModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
      loading: true,
      specialists: [],
      originalSpecialists: [],
      view: 'onApproval',
      selectedSpecialist: null,
      isShowDeclineModal: false,
      // clients: [],
      originalClients: [],
      selectedClient: null,
      declineStatus: null,
    };
    this.formRef = React.createRef();
    this.anketaFormRef = React.createRef();
  }

  componentDidMount() {
    Promise.all([getAllSpecialistsShort()])
      .then((res) => this.setState({
        loading: false,
        specialists: res[0],
        originalSpecialists: res[0],
        // clients: res[1],
        originalClients: res[1],
      }));
  }

  onChangeView = (view) => {
    this.setState({ view });
  }

  onSelect = (id, view) => {
    if (view === 'specialists') {
      getSpecialistProfile(id)
        .then((selectedSpecialist) => this.setState({ selectedSpecialist }));
    }
    // if (view === 'clients') {
    //   this.setState({ selectedClient: id });
    // }
  }

  onCloseModal = () => {
    this.setState({ selectedSpecialist: null, selectedClient: null });
  }

  onApprove = () => {
    const { selectedSpecialist } = this.state;
    getSpecialistProfile(selectedSpecialist.id)
      .then((user) => {
        const specialistToSave = { id: selectedSpecialist.id, status: 'approved', isValidSpecialist: true };
        if (user.CardId) {
          specialistToSave.isPhysicalPaymentValid = true;
          specialistToSave.paymentType = 'physical';
        }
        if (user.shopCode) {
          specialistToSave.isLegalPaymentValid = true;
          specialistToSave.paymentType = 'legal';
        }
        editSpecialistStatus(specialistToSave)
          .then((specialist) => this.setState((prevState) => ({
            specialists: prevState.specialists.map((s) => (s.id === specialist.id ? specialist : s)),
            selectedSpecialist: null,
          })));
      });
  }

  onDecline = (declineStatus) => {
    this.setState({ isShowDeclineModal: true, declineStatus });
  }

  onCloseDeclineModal = () => {
    this.setState({ isShowDeclineModal: false, declineStatus: null });
  }

  onConfirmDecline = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        const { selectedSpecialist, declineStatus } = this.state;
        editSpecialistStatus({ id: selectedSpecialist.id, status: declineStatus, moderatorComment: values.moderatorComment })
          .then((specialist) => this.setState((prevState) => ({
            specialists: prevState.specialists.map((s) => (s.id === specialist.id ? specialist : s)),
            originalSpecialists: prevState.originalSpecialists.map((s) => (s.id === specialist.id ? specialist : s)),
            selectedSpecialist: null,
            isShowDeclineModal: false,
            declineStatus: null,
          })));
      })
      .catch(() => notification.error({ message: 'Заполните обязательные поля' }));
  }

  onSearch = (e) => {
    const { value } = e.target;
    if (value === '') {
      // if (view === 'specialists') {
      this.setState((prevState) => ({ specialists: prevState.originalSpecialists }));
      // }
      // if (view === 'clients') {
      //   this.setState((prevState) => ({ clients: prevState.originalClients }));
      // }
    } else {
      // if (view === 'specialists') {
      this.setState((prevState) => ({
        specialists: prevState.originalSpecialists
          .filter((element) => element.phone.toLowerCase().indexOf(value.toLowerCase()) >= 0
          || element.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
          || element.email.toLowerCase().indexOf(value.toLowerCase()) >= 0),
      }));
      // }
      // if (view === 'clients') {
      //   this.setState((prevState) => ({
      //     clients: prevState.originalClients
      //       .filter((element) => element.phone.toLowerCase().indexOf(value.toLowerCase()) >= 0
      //     || element.nickName.toLowerCase().indexOf(value.toLowerCase()) >= 0
      //     || element.email.toLowerCase().indexOf(value.toLowerCase()) >= 0),
      //   }));
      // }
    }
  }

  onBlockClient = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        const { selectedClient } = this.state;
        editClientStatus({ id: selectedClient.id, isBlocked: true, moderatorComment: values.moderatorComment })
          .then(() => {
            notification.success({ message: 'Пользователь заблокирован' });
            this.setState((prevState) => ({
              clients: prevState.clients
                .map((s) => (s.id === selectedClient.id ? { ...s, isBlocked: true, moderatorComment: values.moderatorComment } : s)),
              originalClients: prevState.originalClients
                .map((s) => (s.id === selectedClient.id ? { ...s, isBlocked: true, moderatorComment: values.moderatorComment } : s)),
              selectedClient: null,
            }));
          });
      })
      .catch(() => notification.error({ message: 'Заполните обязательные поля' }));
  }

  render() {
    const {
      isMobile, loading, specialists, view, selectedSpecialist, isShowDeclineModal, selectedClient, declineStatus,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <AdminSpecialistsPageDesktop
        loading={loading}
        specialists={specialists}
        onChangeView={this.onChangeView}
        view={view}
        onSelect={this.onSelect}
        selectedSpecialist={selectedSpecialist}
        onCloseModal={this.onCloseModal}
        onApprove={this.onApprove}
        onDecline={this.onDecline}
        isShowDeclineModal={isShowDeclineModal}
        onConfirmDecline={this.onConfirmDecline}
        formRef={this.formRef}
        onCloseDeclineModal={this.onCloseDeclineModal}
        onSearch={this.onSearch}
        // clients={clients}
        selectedClient={selectedClient}
        onBlockClient={this.onBlockClient}
        declineStatus={declineStatus}
      />
    );
  }
}

export default AdminSpecialistsPageModule;
