import React, { Component } from 'react';
import { config } from '../../utils/config';
import AdminDataChangeRequestsPageDesktop from './Desktop/AdminDataChangeRequestsPageDesktop';
import { getPersonalDataChangeRequests, getLockedFields } from '../../utils/http';


class AdminDataChangeRequestsModule extends Component {
  state = {
    isMobile: window.innerWidth <= config.mobileWidth,
    loading: true,
    personalDataChangeRequestsClient: [],
    personalDataChangeRequestsSpecialist: [],
    originalPersonalDataChangeRequestsSpecialist: [],
    lockedFields: [],
    view: 'client',
  }

  componentDidMount() {
    Promise.all([getPersonalDataChangeRequests(), getLockedFields()])
      .then((resp) => this.setState({
        loading: false,
        personalDataChangeRequestsClient: resp[0].filter((r) => r.role === 'client'),
        personalDataChangeRequestsSpecialist: resp[0].filter((r) => r.role === 'specialist'),
        originalPersonalDataChangeRequestsSpecialist: resp[0].filter((r) => r.role === 'specialist'),
        lockedFields: resp[1],
      }));
  }

  onChangeView = (view) => {
    this.setState({ view });
  }

  onSearch = (e) => {
    const { value } = e.target;
    if (value === '') {
      this.setState((prevState) => ({ personalDataChangeRequestsSpecialist: prevState.originalPersonalDataChangeRequestsSpecialist }));
    } else {
      this.setState((prevState) => ({
        personalDataChangeRequestsSpecialist: prevState.originalPersonalDataChangeRequestsSpecialist
          .filter((element) => element.userName.toLowerCase().indexOf(value.toLowerCase()) >= 0
          || element.userName.toLowerCase().indexOf(value.toLowerCase()) >= 0),
      }));
    }
  }

  render() {
    const {
      isMobile, loading, personalDataChangeRequestsClient, lockedFields, personalDataChangeRequestsSpecialist, view,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <AdminDataChangeRequestsPageDesktop
        loading={loading}
        personalDataChangeRequestsClient={personalDataChangeRequestsClient}
        personalDataChangeRequestsSpecialist={personalDataChangeRequestsSpecialist}
        lockedFieldsClient={lockedFields.filter((f) => f.isClient)}
        lockedFieldsSpec={lockedFields.filter((f) => f.isSpecialist)}
        onChangeView={this.onChangeView}
        view={view}
        onSearch={this.onSearch}
      />
    );
  }
}

export default AdminDataChangeRequestsModule;
