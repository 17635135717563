/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { notification } from 'antd';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { config } from '../../utils/config';
import { routeGenerator, routes } from '../../utils/routes';
import CleverPsychoListPageDesktop from './Desktop/CleverPsychoListPageDesktop';
import {
  getCleverSearch, getSpecialistsForCatalogue, getClientCurrentSpecialistAndAllSessions,
} from '../../utils/http';
import getOrderedSpecList from './utils/getOrderedSpecList';
import getUserId from '../../utils/localeStorage/getUserId';


class CleverPsychoListModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSessionExist: false,
      isMobile: window.innerWidth <= config.mobileWidth,
      loading: true,
      specializationCatalogue: [],
      languagesCatalogue: [],
      userSpecializations: [],
      userSpecializationsValues: [],
      specialistAgeCatalogue: {
        min: 18,
        max: 55,
      },
      sessionPriceCatalogue: {
        min: 4000,
        max: 15000,
      },
      specList: [],
      allSpecList: [],
      gender: '',
      step: 0,
      values: {
        whoWillUse: 'onlyMe',
        isHavePreviousExperince: false,
        specialistGender: 'Иное',
        specialistAge: [18, 55],
        sessionPrice: [4000, 15000],
        languages: ['ru'],
      },
      otherSpecialisation: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { history } = this.props;
    const searchObj = get(history, 'location.state.searchObj', null);
    Promise.all([getSpecialistsForCatalogue(), getClientCurrentSpecialistAndAllSessions(getUserId())])
      .then((res) => {
        if (!searchObj) {
          this.setState((prevState) => ({
            specializationCatalogue: res[0].specializationCatalogue,
            languagesCatalogue: res[0].languagesCatalogue,
            specialistAgeCatalogue: res[0].specialistAgeCatalogue,
            sessionPriceCatalogue: res[0].sessionPriceCatalogue,
            values: {
              ...prevState.values,
              specialistAge: [res[0].specialistAgeCatalogue.min, res[0].specialistAgeCatalogue.max],
              sessionPrice: [res[0].sessionPriceCatalogue.min, res[0].sessionPriceCatalogue.max],
            },
            isSessionExist: !!res[1].currentSpecialist,
            loading: false,
          }));
        } else {
          this.setState(JSON.parse(searchObj));
        }
      });
  }

  onSearch = () => {
    this.setState({ loading: true });
    const { values } = this.state;
    const { userSpecializations } = this.state;
    const searchObj = {
      sessionPriceFrom: values.sessionPrice[0],
      sessionPriceTo: values.sessionPrice[1],
      languages: values.languages.join(','),
      specialistGender: values.specialistGender === 'Иное' ? 'Мужской,Женский' : values.specialistGender,
      birthdayDateFrom: moment().subtract(values.specialistAge[1], 'years').toISOString(),
      birthdayDateTo: moment().subtract(values.specialistAge[0], 'years').toISOString(),
      userSpecializations: userSpecializations.join(','),
    };
    getCleverSearch(searchObj)
      .then((specList) => this.setState({
        loading: false,
        allSpecList: getOrderedSpecList(specList, {
          ...searchObj,
          gender: values.gender,
          userSpecializations,
          whoWillUse: values.whoWillUse,
          step: 5,
        }),
        specList: getOrderedSpecList(specList, {
          ...searchObj,
          gender: values.gender,
          userSpecializations,
          whoWillUse: values.whoWillUse,
          step: 5,
        }).splice(0, 6),
      }));
  }

  onSpecializaionCheckbox = (id, value, name) => {
    this.setState((prevState) => ({
      userSpecializations: value ? [...prevState.userSpecializations, id] : prevState.userSpecializations.filter((s) => s !== id),
      userSpecializationsValues: value
        ? [...prevState.userSpecializationsValues, name] : prevState.userSpecializationsValues.filter((s) => s !== name),
    }));
  }

  onGenderChange = (gender) => {
    this.setState({ gender });
  }

  onNextStep = () => {
    const { step } = this.state;
    if (step === 3) {
      this.onSearch();
    }
    this.formRef.current.validateFields()
      .then((values) => {
        if (values.age && values.age < 16) {
          notification.error({ message: 'Мы не можем предоставить вам наши услуги' });
          return;
        }
        this.setState((prevState) => ({ step: prevState.step + 1, values: { ...prevState.values, ...values } }));
      });
  }

  onPreviousStep = () => {
    this.setState((prevState) => ({ step: prevState.step - 1 }));
  }

  onPsychoClick = (selectedPsycho) => {
    const { history } = this.props;
    const { state } = this;
    history.push({
      pathname: routeGenerator.specialist(selectedPsycho.specialistID),
      state: { searchObj: JSON.stringify(state), from: history.location.pathname },
    });
  }

  onRedirectPsychoList = () => {
    const { history } = this.props;
    const { state } = this;
    history.push({
      pathname: routes.list,
      state: { searchObj: JSON.stringify(state), from: history.location.pathname },
    });
  }

  onShowMore = () => {
    this.setState((prevState) => ({
      specList: [...prevState.specList, ...prevState.allSpecList.splice(prevState.specList.length, prevState.specList.length + 6)],
    }));
  }

  onChangeOtherSpecialisation = (e) => {
    this.setState({ otherSpecialisation: e.target.value });
  }

  render() {
    const {
      isMobile, loading, specializationCatalogue, languagesCatalogue, userSpecializations, specList, step,
      gender, values, specialistAgeCatalogue, sessionPriceCatalogue, isSessionExist, allSpecList, otherSpecialisation,
    } = this.state;
    if (isMobile) {
      return <div />;
    }
    return (
      <CleverPsychoListPageDesktop
        loading={loading}
        specializationCatalogue={specializationCatalogue}
        formRef={this.formRef}
        languagesCatalogue={languagesCatalogue}
        onSpecializaionCheckbox={this.onSpecializaionCheckbox}
        userSpecializations={userSpecializations}
        specList={specList}
        onGenderChange={this.onGenderChange}
        gender={gender}
        onNextStep={this.onNextStep}
        onPreviousStep={this.onPreviousStep}
        step={step}
        values={values}
        onPsychoClick={this.onPsychoClick}
        specialistAgeCatalogue={specialistAgeCatalogue}
        sessionPriceCatalogue={sessionPriceCatalogue}
        onRedirectPsychoList={this.onRedirectPsychoList}
        isSessionExist={isSessionExist}
        onShowMore={this.onShowMore}
        allSpecListLength={allSpecList.length}
        otherSpecialisation={otherSpecialisation}
        onChangeOtherSpecialisation={this.onChangeOtherSpecialisation}
      />
    );
  }
}

CleverPsychoListModule.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(CleverPsychoListModule);
