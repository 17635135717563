/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { notification } from 'antd';
import moment from 'moment';
import { orderBy } from 'lodash';
import { config } from '../../utils/config';
import AdminManualUsersPageDesktop from './Desktop/AdminManualUsersPageDesktop';
import {
  getTimeZoneCatalogue, editUser, getAllClients, getSpecialistList, getSpecialistSlots, editSession, getAllClientSessions, sessionEnroll,
} from '../../utils/http';


class AdminManualUsersModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
      loading: false,
      manualUsers: [],
      originalManualUsers: [],
      selectedClient: null,
      timeZoneCatalogue: [],
      page: 0,
      specialistList: [],
      selectedPsychoSlots: [],
      selectedDate: null,
      selectedTime: null,
      selectedPsycho: null,
      clientSessions: [],
      isShowSelectCancelOptionModal: false,
      selectCancelOptionModalStep: 0,
      declineComment: '',
      currentSession: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    Promise.all([getAllClients(), getTimeZoneCatalogue(), getSpecialistList()])
      .then((res) => this.setState({
        timeZoneCatalogue: res[1], manualUsers: res[0], originalManualUsers: res[0], specialistList: res[2],
      }));
  }

  parseSlots = (slots, beforeEnrollTime) => {
    const startAvailableDate = moment().add(beforeEnrollTime + 1, 'h').startOf('day');
    const availablePychoSlots = [];
    const filteredSlots = slots.filter((slot) => moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isAfter(startAvailableDate) || moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isSame(startAvailableDate));
    filteredSlots.forEach((slot) => {
      let timesArray = [];
      if (moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isSame(startAvailableDate)) {
        const startAvailableTime = moment().add(beforeEnrollTime + 1, 'h').format('HH:00');
        slot.timesArray.forEach((t) => t >= startAvailableTime && timesArray.push(t));
      } else if (slot.timesArray.length > 0 && slot.timesArray[0] !== '') {
        timesArray = slot.timesArray.sort();
      }
      if (timesArray.length > 0) {
        availablePychoSlots.push({ id: slot.id, date: slot.date, timesArray: timesArray.sort() });
      }
    });
    return orderBy(availablePychoSlots, 'date', 'asc');
  }

  onClientClick = (selectedClient) => {
    if (selectedClient.id) {
      getAllClientSessions(selectedClient.id)
        .then((clientSessions) => {
          this.setState({
            page: 1,
            clientSessions: orderBy(clientSessions, 'sessionDateAndTime', 'desc'),
            selectedClient,
          });
        });
    } else {
      this.setState({ selectedClient });
    }
  }

  onCloseClient = () => {
    this.setState({
      selectedClient: null, page: 0, selectedPsychoSlots: [], selectedPsycho: null,
    });
  }

  onNextPage = () => {
    const { page } = this.state;
    if (page === 0) {
      this.formRef.current.validateFields()
        .then((values) => {
          editUser({ ...values, id: values.phone })
            .then((user) => {
              getAllClientSessions(user.id)
                .then((clientSessions) => {
                  this.setState((prevState) => ({
                    manualUsers: values.id ? prevState.manualUsers.map((m) => (m.id === values.id ? user : m)) : [...prevState.manualUsers, user],
                    originalManualUsers: values.id
                      ? prevState.originalManualUsers.map((m) => (m.id === values.id ? user : m)) : [...prevState.originalManualUsers, user],
                    page: 1,
                    clientSessions: orderBy(clientSessions, 'sessionDateAndTime', 'desc'),
                  }));
                });
            });
        })
        .catch(() => notification.error({ message: 'Не сохранено. Заполните обязательные поля' }));
    }
    if (page === 1) {
      this.setState({
        page: 2,
      });
    }
  }

  onSaveProfile = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        editUser({ ...values, currentSpecialist_id: values.currentSpecialist_id || null })
          .then((user) => {
            this.setState((prevState) => ({
              manualUsers: prevState.manualUsers.map((m) => (m.id === values.id ? user : m)),
            }));
          });
      })
      .catch(() => notification.error({ message: 'Не сохранено. Заполните обязательные поля' }));
  }

  onPsychoClick = (selectedPsycho) => {
    const { selectedClient } = this.state;
    getSpecialistSlots(selectedPsycho.id, Number(selectedClient.timeDifference))
      .then((selectedPsychoSlots) => this.setState({
        selectedPsychoSlots: this.parseSlots(selectedPsychoSlots.slots, selectedPsycho.beforeEnrollTime),
        page: 3,
        selectedPsycho,
      }));
  }

  onBackPage = () => {
    this.setState((prevState) => ({
      page: prevState.page - 1,
      selectedDate: null,
      selectedTime: null,
    }));
  }

  onSelect = (selectedTime, selectedDate) => {
    this.setState({ selectedTime, selectedDate });
  }

  onEnroll = () => {
    const {
      selectedTime, selectedDate, selectedPsycho, selectedClient,
    } = this.state;
    const timeDifference = new Date().getTimezoneOffset();
    const cleanDate = moment(`${selectedDate.startOf('day').format('YYYY-MM-DD')}T${selectedTime}`, 'YYYY-MM-DDTHH:00').add(Number(selectedClient.timeDifference) || timeDifference, 'm').format('YYYY-MM-DDTHH:00');
    sessionEnroll({
      sessionDate: cleanDate.split('T')[0],
      sessionTime: cleanDate.split('T')[1],
      client_id: selectedClient.id,
      specialist_id: selectedPsycho.id,
      sessionDateAndTime: moment(`${selectedDate.startOf('day').format('DD.MM.YYYY')} ${selectedTime}`, 'DD.MM.YYYY HH:00').toISOString(),
    })
      .then(() => this.onCloseClient());
  }

  onCancelSession = (currentSession) => {
    if (moment().add(24, 'h').isAfter(currentSession.sessionDateAndTime)) {
      const message = 'Отмена с возвратом возможна за 24 часа. Иначе нужно сообщять клиенту, что сессия не может быть отменена (потом уже текст придумаем точный).';
      notification.error({ message });
      return;
    }
    this.setState({ isShowSelectCancelOptionModal: true, currentSession });
  }

  onCancelSelectOption = (variant, currentSession = this.state.currentSession) => {
    if (variant === 'old') {
      const { selectedClient } = this.state;
      getSpecialistSlots(currentSession.specialist.id, Number(selectedClient.timeDifference))
        .then((slots) => this.setState({
          selectedPsychoSlots: this.parseSlots(slots.slots, currentSession.specialist.beforeEnrollTime),
          page: 3,
          isShowSelectCancelOptionModal: false,
          currentSession: null,
        }));
    }
    if (variant === 'new') {
      editSession({ id: currentSession.id, declineReason: 'Хочу поменять психолога', status_id: 'canceled' })
        .then(() => getSpecialistList()
          .then((specialistList) => {
            this.setState({
              page: 2,
              specialistList,
              isShowSelectCancelOptionModal: false,
              currentSession: null,
            });
          }));
    }
    if (variant === 'other') {
      if (currentSession.statusID === 'paid') {
        editSession({ id: currentSession.id, declineReason: 'Хочу полностью отменить консультацию. Уже оплатил', status_id: 'canceled' })
          .then(() => {
            this.setState({ isShowSelectCancelOptionModal: false, currentSession: null });
            notification.success({ message: 'Ваша консультация уже была оплачена, для возврата денег с вами свяжется наш сотрудник' });
          });
      }
      if (currentSession.statusID === 'toBePaid') {
        editSession({ id: currentSession.id, declineReason: 'Хочу полностью отменить консультацию. Еще не оплатил', status_id: 'canceled' })
          .then(() => {
            this.setState({ isShowSelectCancelOptionModal: false, currentSession: null });
            notification.success({ message: 'Ваша консультация была отменена' });
          });
      }
    }
  }

  onCloseSelectCancelOptionModal = () => {
    const { selectCancelOptionModalStep } = this.state;
    if (selectCancelOptionModalStep === 2) {
      this.setState({ isShowSelectCancelOptionModal: false, currentSession: null });
    } else {
      this.setState({ isShowSelectCancelOptionModal: false, currentSession: null });
    }
  }


  onLeaveDeclineComment = () => {
    const { declineComment, currentSession } = this.state;
    editSession({
      id: currentSession.id,
      declineComment,
    })
      .then(() => this.setState({ isShowSelectCancelOptionModal: false, currentSession: null }));
  }

  onChangeDeclineComment = (e) => {
    this.setState({ declineComment: e.target.value });
  }

  onSearch = (e) => {
    const { value } = e.target;
    if (value === '') {
      this.setState((prevState) => ({ manualUsers: prevState.originalManualUsers }));
    } else {
      this.setState((prevState) => ({
        manualUsers: prevState.originalManualUsers
          .filter((element) => element.phone.toLowerCase().indexOf(value.toLowerCase()) >= 0
          || element.nickName.toLowerCase().indexOf(value.toLowerCase()) >= 0
          || element.email.toLowerCase().indexOf(value.toLowerCase()) >= 0),
      }));
    }
  }

  render() {
    const {
      isMobile, loading, manualUsers, selectedClient, timeZoneCatalogue, page, specialistList, selectedPsychoSlots,
      selectedDate, selectedTime, clientSessions, isShowSelectCancelOptionModal, selectCancelOptionModalStep,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <AdminManualUsersPageDesktop
        loading={loading}
        manualUsers={manualUsers}
        onClientClick={this.onClientClick}
        selectedClient={selectedClient}
        onCloseClient={this.onCloseClient}
        formRef={this.formRef}
        timeZoneCatalogue={timeZoneCatalogue}
        onNextPage={this.onNextPage}
        page={page}
        specialistList={specialistList}
        onPsychoClick={this.onPsychoClick}
        selectedPsychoSlots={selectedPsychoSlots}
        onBackPage={this.onBackPage}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        onSelect={this.onSelect}
        onEnroll={this.onEnroll}
        clientSessions={clientSessions}
        onSaveProfile={this.onSaveProfile}
        onCancelSession={this.onCancelSession}
        onCancelSelectOption={this.onCancelSelectOption}
        isShowSelectCancelOptionModal={isShowSelectCancelOptionModal}
        onLeaveDeclineComment={this.onLeaveDeclineComment}
        onChangeDeclineComment={this.onChangeDeclineComment}
        selectCancelOptionModalStep={selectCancelOptionModalStep}
        onCloseSelectCancelOptionModal={this.onCloseSelectCancelOptionModal}
        onSearch={this.onSearch}
      />
    );
  }
}

export default AdminManualUsersModule;
