import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { routes } from '../../utils/routes';
import { config } from '../../utils/config';
import getSessionId from '../../utils/localeStorage/getSessionId';
import RegisterPsychoPageDesktop from './Desktop/RegisterPsychoPageDesktop';
import RegisterPsychoPageMobile from './Mobile/RegisterPsychoPageMobile';


class RegisterPsychoModule extends Component {
  state = {
    isMobile: window.innerWidth <= config.mobileWidth,
  }


  componentDidMount() {
    this.onRegisterClick();
  }

  redirectHome = () => {
    const { history } = this.props;
    history.push(routes.home);
  }

  onRegisterClick = () => {
    const { history } = this.props;
    if (getSessionId()) {
      history.push(routes.profile);
    } else {
      history.push({
        pathname: routes.login,
        state: 'specialist',
      });
    }
  }


  render() {
    const {
      isMobile,
    } = this.state;
    if (isMobile) {
      return (
        <RegisterPsychoPageMobile
          onRegisterClick={this.onRegisterClick}
          redirectHome={this.redirectHome}
        />
      );
    }
    return (
      <RegisterPsychoPageDesktop
        onRegisterClick={this.onRegisterClick}
        redirectHome={this.redirectHome}
      />
    );
  }
}

RegisterPsychoModule.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(RegisterPsychoModule);

