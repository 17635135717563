/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import logo from '../../../assets/icons/logo-main.png';
import './RegisterPsychoPageDesktop.css';


class RegisterPsychoPageDesktop extends Component {
  render() {
    const {
      onRegisterClick, redirectHome,
    } = this.props;
    return (
      <div className="registerPsychoPageDesktop-wrapper">
        <div className="registerPsychoPageDesktop-header-wrapper">
          <img src={logo} className="registerPsychoPageDesktop-header-logo" alt="МИГиП" onClick={redirectHome} />
          <div className="registerPsychoPageDesktop-header-buttons" style={{ marginLeft: '50px' }}>
            <Button type="primary" className="registerPsychoPageDesktop-header-buttons-join" onClick={onRegisterClick}>Получить доступ</Button>
          </div>
        </div>
      </div>
    );
  }
}

RegisterPsychoPageDesktop.propTypes = {
  onRegisterClick: PropTypes.func.isRequired,
  redirectHome: PropTypes.func.isRequired,
};

export default RegisterPsychoPageDesktop;
