import React from 'react';
import {
  TeamOutlined,
} from '@ant-design/icons';
import { routes } from '../../../utils/routes';


const orgButtonsArray = [
  {
    name: 'Промокоды',
    id: 'promoCodes',
    icon: <TeamOutlined />,
    route: routes.promoCodes,
  },
];

export default orgButtonsArray;
