/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker, Select } from 'antd';
import editSvg from '../../../../assets/icons/edit.svg';


const { Option } = Select;
class PaymentField extends Component {
  render() {
    const {
      fieldName, fieldValue, fieldInEdit, onEditFieldClick, onSaveFieldClick, onCancelFieldClick, fieldLabel, onChangeFieldValue, inputType,
      catalogue,
    } = this.props;
    return (
      <div className="payment-block-column">
        <div className="payment-block-row">
          <div className="payment-block-label">{fieldLabel}</div>
          {!fieldInEdit && <img src={editSvg} className="payment-block-edit-icon" alt="edit" onClick={() => onEditFieldClick(fieldName, fieldValue)} />}
        </div>
        {inputType !== 'datePicker' && fieldInEdit !== fieldName && <div className="payment-block-value">{fieldValue || 'Не заполнено'}</div>}
        {inputType === 'datePicker' && fieldInEdit !== fieldName && <div className="payment-block-value">{fieldValue ? fieldValue.format('DD MMMM YYYY') : 'Не заполнено'}</div>}
        {fieldInEdit === fieldName && inputType === 'input' && <input onChange={(e) => onChangeFieldValue(e.target.value)} defaultValue={fieldValue} bordered={false} style={{ borderBottom: '1px solid', borderRadius: '0px' }} />}
        {fieldInEdit === fieldName && inputType === 'datePicker' && <DatePicker defaultValue={fieldValue || moment()} onChange={(e) => onChangeFieldValue(e)} />}
        {fieldInEdit === fieldName && inputType === 'select' && (
        <Select className="payment-block-select" onChange={(e) => onChangeFieldValue(e)} defaultValue={fieldValue}>
          {catalogue.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.value}
            </Option>
          ))}
        </Select>
        )}

        {fieldInEdit === fieldName && (
        <div className="payment-block-row" style={{ marginTop: '5px' }}>
          <div className="btn-default btn_red" onClick={onSaveFieldClick}>Сохранить</div>
          <div className="btn-default btn_white" onClick={onCancelFieldClick}>Отменить</div>
        </div>
        )}
      </div>
    );
  }
}

PaymentField.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  fieldInEdit: PropTypes.string.isRequired,
  onEditFieldClick: PropTypes.func.isRequired,
  onSaveFieldClick: PropTypes.func.isRequired,
  onCancelFieldClick: PropTypes.func.isRequired,
  onChangeFieldValue: PropTypes.func.isRequired,
  inputType: PropTypes.string.isRequired,
  catalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};


export default PaymentField;
