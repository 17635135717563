/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Spin, Switch, Tabs, Col,
} from 'antd';
import './AdminFieldsPageDesktop.css';


const { TabPane } = Tabs;
class AdminFieldsPageDesktop extends Component {
    onLockedFieldChange = (value) => {
      const { onLockedFiledEdit } = this.props;
      onLockedFiledEdit(value);
    }

    render() {
      const {
        loading, lockedFieldsClient, lockedFieldsSpec, fieldsLoading, onChangeView, view,
      } = this.props;
      if (loading) {
        return (
          <div className="adminFieldsPageDesktop-wrapper" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
          </div>
        );
      }
      return (
        <Col span={22} className="adminFieldsPageDesktop-wrapper">
          <div style={{ marginBottom: '20px', fontSize: '20px' }}>Заблокированные поля в профиле </div>
          <Tabs defaultActiveKey={view} onChange={onChangeView}>
            <TabPane tab="Клиенты" key="client">
              <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                {lockedFieldsClient.map((f) => (
                  <div
                    style={{
                      display: 'flex', flexDirection: 'row', margin: '5px 0px',
                    }}
                    key={f.id}
                  >
                    <Switch loading={fieldsLoading} style={{ marginRight: '10px' }} onChange={(isLocked) => this.onLockedFieldChange({ id: f.id, isLocked })} checked={f.isLocked} />
                    <div>{f.text}</div>
                  </div>
                ))}
              </div>
            </TabPane>
            <TabPane tab="Специалисты" key="specialist">
              <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                {lockedFieldsSpec.map((f) => (
                  <div
                    style={{
                      display: 'flex', flexDirection: 'row', margin: '5px 0px',
                    }}
                    key={f.id}
                  >
                    <Switch loading={fieldsLoading} style={{ marginRight: '10px' }} onChange={(isLocked) => this.onLockedFieldChange({ id: f.id, isLocked })} checked={f.isLocked} />
                    <div>{f.text}</div>
                  </div>
                ))}
              </div>
            </TabPane>
          </Tabs>
        </Col>
      );
    }
}


AdminFieldsPageDesktop.propTypes = {
  loading: PropTypes.bool.isRequired,
  lockedFieldsClient: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  lockedFieldsSpec: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onLockedFiledEdit: PropTypes.func.isRequired,
  onChangeView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  fieldsLoading: PropTypes.bool.isRequired,
};

export default AdminFieldsPageDesktop;
