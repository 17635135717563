/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { notification } from 'antd';
import { config } from '../../utils/config';
import AdminGroupSessionsPageDesktop from './Desktop/AdminGroupSessionsPageDesktop';
import {
  getAllGroupSessions, editGroupSession, editGroupSessionEnroll, getGroupTypeCatalogue,
} from '../../utils/http';


class AdminGroupSessionsModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
      groupSessions: [],
      selectedSession: null,
      groupTypeCatalogue: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    Promise.all([getAllGroupSessions(), getGroupTypeCatalogue()])
      .then((result) => this.setState({ groupSessions: result[0], groupTypeCatalogue: result[1] }));
  }

  onSelectSession = (selectedSession) => {
    this.setState({ selectedSession });
  }

  onCloseSelectSession = () => {
    this.setState({ selectedSession: null });
  }

  onDecide = (statusId) => {
    this.formRef.current.validateFields()
      .then((values) => {
        const { selectedSession } = this.state;
        if (statusId === 'hide') {
          editGroupSession({
            id: selectedSession.id,
            isHidden: true,
          })
            .then(() => {
              this.setState((prevState) => ({
                selectedSession: null,
                groupSessions: prevState.groupSessions
                  .map((g) => (g.id === selectedSession.id
                    ? {
                      ...g, isHidden: true,
                    }
                    : g)),
              }));
              notification.success({ message: 'Сохранено успешно' });
            });
          return;
        }
        editGroupSession({
          id: selectedSession.id,
          status_id: statusId,
          description: values.description,
          moderatorComment: values.moderatorComment,
        })
          .then(() => {
            this.setState((prevState) => ({
              selectedSession: null,
              groupSessions: prevState.groupSessions
                .map((g) => (g.id === selectedSession.id
                  ? {
                    ...g, description: values.description, moderatorComment: values.moderatorComment, statusId,
                  }
                  : g)),
            }));
            notification.success({ message: 'Сохранено успешно' });
          });
      });
  }

  onConfirm = (obj) => {
    editGroupSessionEnroll({ id: obj.id, status_id: 'approved' })
      .then(() => this.setState((prevState) => ({
        groupSessions: prevState.groupSessions.map((s) => (s.id === obj.groupSessionID
          ? { ...s, groupSessionEnroll: s.groupSessionEnroll.map((e) => (e.id === obj.id ? { ...e, statusID: 'approved' } : e)) }
          : s)),
      })));
  }

  onCancel = (obj) => {
    editGroupSessionEnroll({ id: obj.id, status_id: 'canceled' })
      .then(() => this.setState((prevState) => ({
        groupSessions: prevState.groupSessions.map((s) => (s.id === obj.groupSessionID
          ? { ...s, groupSessionEnroll: s.groupSessionEnroll.map((e) => (e.id === obj.id ? { ...e, statusID: 'canceled' } : e)) }
          : s)),
      })));
  }

  render() {
    const {
      isMobile, groupSessions, selectedSession, groupTypeCatalogue,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <AdminGroupSessionsPageDesktop
        groupSessions={groupSessions}
        onSelectSession={this.onSelectSession}
        selectedSession={selectedSession}
        onCloseSelectSession={this.onCloseSelectSession}
        onDecide={this.onDecide}
        formRef={this.formRef}
        onConfirm={this.onConfirm}
        onCancel={this.onCancel}
        groupTypeCatalogue={groupTypeCatalogue}
      />
    );
  }
}

export default AdminGroupSessionsModule;
