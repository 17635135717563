import React, { Component } from 'react';
import { orderBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { config } from '../../utils/config';
import PsychoListPageDesktop from './Desktop/PsychoListPageDesktop';
import PsychoListPageMobile from './Mobile/PsychoListPageMobile';
import {
  getSpecialistList, getClientCurrentSpecialistAndAllSessions, getSpecialistsForCatalogue, getSpecialistSlots,
} from '../../utils/http';
import { routeGenerator, routes } from '../../utils/routes';
import getUserId from '../../utils/localeStorage/getUserId';
import getSessionId from '../../utils/localeStorage/getSessionId';


const timePeriods = {
  Утро: ['07:00', '08:00', '09:00', '10:00', '11:00'],
  День: ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00'],
  Вечер: ['18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
};
class PsychoListModule extends Component {
  state = {
    isMobile: window.innerWidth <= config.mobileWidth,
    originalSpecList: [],
    languagesCatalogue: [],
    specialistAgeCatalogue: {
      min: 18,
      max: 55,
    },
    sessionPriceCatalogue: {
      min: 4000,
      max: 15000,
    },
    isSessionExist: false,
    sortValue: 'priceDesc',
    filters: [
      { id: 'search', value: '' },
      { id: 'age', value: [0, 0] },
      { id: 'sessionPrice', value: [0, 0] },
      { id: 'language', value: ['ru'] },
      { id: 'gender', value: ['Мужской', 'Женский'] },
      { id: 'time', value: ['Утро', 'День', 'Вечер'] },
    ],
    isInitLoading: true,
  }

  componentDidMount() {
    const promiseArray = [getSpecialistsForCatalogue(), getSpecialistList()];
    if (getSessionId() && getUserId()) {
      promiseArray.push(getClientCurrentSpecialistAndAllSessions(getUserId()));
    }
    Promise.all(promiseArray)
      .then((res) => getSpecialistSlots(res[1].map((s) => s.id).join(','))
        .then((slots) => {
          if (res[2] && !!res[2].currentSpecialist) {
            const { history } = this.props;
            history.push(routes.meetings);
          } else {
            this.setState((prevState) => ({
              languagesCatalogue: res[0].languagesCatalogue,
              specialistAgeCatalogue: res[0].specialistAgeCatalogue,
              sessionPriceCatalogue: res[0].sessionPriceCatalogue,
              filters: prevState.filters.map((filter) => {
                if (filter.id === 'age') {
                  return ({ id: 'age', value: [res[0].specialistAgeCatalogue.min, res[0].specialistAgeCatalogue.max] });
                }
                if (filter.id === 'sessionPrice') {
                  return ({ id: 'sessionPrice', value: [res[0].sessionPriceCatalogue.min, res[0].sessionPriceCatalogue.max] });
                }
                return filter;
              }),
              isSessionExist: res[2] && !!res[2].currentSpecialist,
              isInitLoading: false,
              originalSpecList: res[1].map((specialist) => ({
                ...specialist,
                slots: this.parseSlots(slots.slots.filter((slot) => slot.user_id === specialist.id), specialist.beforeEnrollTime),
              })),
            }));
          }
        }));
  }

  parseSlots = (slots, beforeEnrollTime) => {
    const startAvailableDate = moment().add(beforeEnrollTime + 1, 'h').startOf('day');
    const availablePychoSlots = [];
    const filteredSlots = slots.filter((slot) => moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isAfter(startAvailableDate) || moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isSame(startAvailableDate));
    filteredSlots.forEach((slot) => {
      let timesArray = [];
      if (moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isSame(startAvailableDate)) {
        const startAvailableTime = moment().add(beforeEnrollTime + 1, 'h').format('HH:00');
        slot.timesArray.forEach((t) => t >= startAvailableTime && timesArray.push(t));
      } else if (slot.timesArray.length > 0 && slot.timesArray[0] !== '') {
        timesArray = slot.timesArray.sort();
      }
      if (timesArray.length > 0) {
        availablePychoSlots.push({ id: slot.id, date: slot.date, timesArray: timesArray.sort() });
      }
    });
    return orderBy(availablePychoSlots, 'date', 'asc');
  }

  onPsychoClick = (selectedPsycho) => {
    const { history } = this.props;
    history.push({
      pathname: routeGenerator.specialist(selectedPsycho.id),
      state: {
        // searchObj: historyState.searchObj,
        from: history.location.pathname,
      },
    });
  }

  setFilter = (value, filter) => {
    this.setState((prevState) => ({
      filters: prevState.filters.map((f) => (f.id === filter ? { id: filter, value } : f)),
    }));
  }

  onFilter = () => {
    const { filters, originalSpecList } = this.state;
    const resultList = originalSpecList
      .filter((element) => {
        let result = true;
        filters.forEach((f) => {
          switch (f.id) {
            case 'search':
              if (result) {
                if (f.value === '') {
                  result = true;
                } else {
                  result = element.lastName.toLowerCase().indexOf(f.value.toLowerCase()) >= 0
              || element.firstName.toLowerCase().indexOf(f.value.toLowerCase()) >= 0;
                }
              }
              break;
            case 'age':
              if (result) {
                result = element.birthdayDate
               && (element.birthdayDate.isAfter(moment().subtract(f.value[1], 'years')) || element.birthdayDate.isSame(moment().subtract(f.value[1], 'years'), 'year'))
              && (moment().subtract(f.value[0], 'years').isAfter(element.birthdayDate) || moment().subtract(f.value[0], 'years').isSame(element.birthdayDate, 'year'));
              }
              break;
            case 'sessionPrice': {
              if (result) {
                result = element.sessionPrice >= f.value[0] && element.sessionPrice <= f.value[1];
              }
              break;
            }
            case 'language':
              if (result) {
                result = element.languagesCatalogue_ids.map((l) => l.id).some((r) => f.value.indexOf(r) >= 0);
              }
              break;
            case 'gender':
              if (result) {
                result = f.value.includes(element.gender);
              }
              break;
            case 'time':
              if (result) {
                const selectedPeriods = [];
                f.value.forEach((period) => selectedPeriods.push(...timePeriods[period]));
                const specialistPeriods = [];
                element.slots.forEach((slot) => specialistPeriods.push(...slot.timesArray));
                result = selectedPeriods.some((r) => specialistPeriods.includes(r));
              }
              break;
            default: break;
          }
        });
        return result;
      });
    return resultList;
  }

  onSortSelect = (event) => {
    this.setState({ sortValue: event.target.value });
  }

  sortByValue = (data) => {
    const { sortValue } = this.state;
    switch (sortValue) {
      case 'priceDesc':
        return orderBy(data, ['sessionPrice'], ['desc']);
      case 'priceAsc':
        return orderBy(data, ['sessionPrice'], ['asc']);
      default: return data;
    }
  }

  render() {
    const {
      isMobile, isSessionExist, languagesCatalogue, filters, sortValue, specialistAgeCatalogue, sessionPriceCatalogue, isInitLoading,
    } = this.state;
    const { history } = this.props;
    if (isMobile) {
      return (
        <PsychoListPageMobile
          specList={this.sortByValue(this.onFilter())}
          onPsychoClick={this.onPsychoClick}
          isSessionExist={isSessionExist}
          history={history}
        />
      );
    }
    return (
      <PsychoListPageDesktop
        specList={this.sortByValue(this.onFilter())}
        isSessionExist={isSessionExist}
        onPsychoClick={this.onPsychoClick}
        languagesCatalogue={languagesCatalogue}
        setFilter={this.setFilter}
        filters={filters}
        onSortSelect={this.onSortSelect}
        sortValue={sortValue}
        specialistAgeCatalogue={specialistAgeCatalogue}
        sessionPriceCatalogue={sessionPriceCatalogue}
        isInitLoading={isInitLoading}
      />
    );
  }
}

PsychoListModule.propTypes = {
  history: PropTypes.shape().isRequired,
};


export default withRouter(PsychoListModule);
