import { createBrowserHistory as createHistory } from 'history';
import { get, groupBy, uniqBy } from 'lodash';
import moment from 'moment';
import getTimeZoneDifference from './localeStorage/getTimeZoneDifference';
import api from './directualAPI/api';


const timeDifference = new Date().getTimezoneOffset();
function handleError(error) {
  if (get(error, 'response.status', null) === 403) {
    return createHistory({ forceRefresh: true }).push('/login');
  }
  throw new Error(error);
}


function parsePersonalDataChangeRequest(req) {
  return {
    id: get(req, 'id', null),
    fieldName: get(req, 'fieldName', null),
    fieldValue: get(req, 'fieldValue', null),
    comment: get(req, 'comment', null),
    userName: `${get(req, 'webUser_id.lastName', '')} ${get(req, 'webUser_id.firstName', '')}`,
    role: get(req, 'webUser_id.role', ''),
    // user: parseUserFull(get(req, 'webUser_id', {})),
    status: get(req, 'status', null),
  };
}

function parseLoginAttempt(attempt) {
  return {
    id: get(attempt, 'id', null),
    role: get(attempt, 'role', ''),
    rsp_code: get(attempt, 'rsp_code', ''),
    phone: get(attempt, 'phone', ''),
    userName: `${get(attempt, 'webUser_id.lastName', '')} ${get(attempt, 'webUser_id.firstName', '')}`,
    date: get(attempt, 'date', null) ? moment(get(attempt, 'date', null)) : null,
  };
}

// Тянем спраовочники----------------------------------------------------------------------------------------------
function parseCatalogue(value) {
  return {
    id: get(value, 'id', null),
    value: get(value, 'value', null),
    group: get(value, 'group', null),
    timeDifference: Number(get(value, 'timeDifference', 0)),
  };
}

function parseLockedFields(field) {
  return {
    id: get(field, 'id', null),
    value: get(field, 'value', null),
    isLocked: get(field, 'isLocked', false),
    text: get(field, 'text', ''),
    isClient: get(field, 'isClient', false),
    isSpecialist: get(field, 'isSpecialist', false),
  };
}

export function getTimeZoneCatalogue() {
  return api
    .structure('timeZoneCatalogue')
    .getData('getTimeZoneCatalogue', { pageSize: 40 })
    .then((resp) => get(resp, 'payload', []).map((zone) => parseCatalogue(zone)))
    .catch(handleError);
}

export function getLanguagesCatalogue() {
  return api
    .structure('languagesCatalogue')
    .getData('getLanguagesCatalogue')
    .then((resp) => get(resp, 'payload', []).map((zone) => parseCatalogue(zone)))
    .catch(handleError);
}

export function getSpecializationCatalogue() {
  return api
    .structure('specializationCatalogue')
    .getData('getSpecializationCatalogue')
    .then((resp) => get(resp, 'payload', []).map((zone) => parseCatalogue(zone)))
    .catch(handleError);
}

export function getLockedFields() {
  return api
    .structure('lockedFields')
    .getData('getLockedFields')
    .then((resp) => get(resp, 'payload', []).map((field) => parseLockedFields(field)))
    .catch(handleError);
}
// -------------------------------------------------------------------------------------------------------------------

// Авторизация--------------------------------------------------------------
function parseUserLogin(user) {
  return {
    id: get(user, 'id', null),
    logoURL: get(user, 'logo_id.urlLink', null),
    isVerified: get(user, 'isVerified', false),
    role: get(user, 'role', ''),
    firstName: get(user, 'firstName', ''),
    lastName: get(user, 'lastName', ''),
    middleName: get(user, 'middleName', ''),
    nickName: get(user, 'nickName', ''),
    isValidClient: get(user, 'isValidClient', false),
    isValidSpecialist: get(user, 'isValidSpecialist', false),
    paymentType: get(user, 'paymentType', null),
    isOrg: get(user, 'isOrg', false),
    timeZoneTimeDifference: get(user, 'timeZone.timeDifference', null) ? Number(get(user, 'timeZone.timeDifference')) : null,
    email: get(user, 'email', null),
    isEmailVerified: get(user, 'isEmailVerified', null),
  };
}

export function getUserAfterLogin(userID, sessionID) {
  return api
    .structure('WebUser')
    .getData('getUser', {
      userID,
      sessionID,
    })
    .then((resp) => parseUserLogin(get(resp, 'payload[0]', {})))
    .catch(handleError);
}

export function getSMS(values) {
  return api
    .structure('WebUserInput')
    .sendData('getSMS', values)
    .then((resp) => get(resp, 'result[0].id', null))
    .catch(handleError);
}

export function checkSMS(values) {
  return api
    .structure('WebUserInput')
    .sendData('checkSMS', values)
    .then((resp) => ({
      code: get(resp, 'result[0].rsp_code', null),
      message: get(resp, 'result[0].rsp_message', null),
      token: get(resp, 'result[0].webUserSession_id', null),
      webUserId: get(resp, 'result[0].webUser_id', null),
    }))
    .catch(handleError);
}

// ----------------------------------------------------------------------------


// Профиль клиента------------------------------------------------------------
function parseClientProfile(user) {
  return {
    id: get(user, 'id', null),
    nickName: get(user, 'nickName', ''),
    gender: get(user, 'gender', ''),
    genderOther: get(user, 'genderOther', ''),
    age: get(user, 'age', ''),
    timeZone: get(user, 'timeZone.id', ''),
    timeZoneValue: get(user, 'timeZone.value', ''),
    phone: get(user, 'phone', ''),
    email: get(user, 'email', ''),
    isEmailVerified: get(user, 'isEmailVerified', false),
    createDate: get(user, 'createDate', null) ? moment(get(user, 'createDate', null)) : null,
    currentSpecialistId: get(user, 'currentSpecialist_id.id', null),
  };
}

export function getClientProfile(userID) {
  return api
    .structure('WebUser')
    .getData('getClientProfile', {
      userID,
    })
    .then((resp) => parseClientProfile(get(resp, 'payload[0]', {})))
    .catch(handleError);
}

export function editClientProfile(values) {
  return api
    .structure('WebUser')
    .sendData('editClientProfile', values)
    .then((resp) => parseClientProfile(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function editEmailVerificationRequests(values) {
  return api
    .structure('emailVerificationRequests')
    .sendData('editEmailVerificationRequests', values)
    .catch(handleError);
}

export function checkEmailCode(values) {
  return api
    .structure('WebUser')
    .sendData('checkEmailCode', values)
    .then((resp) => get(resp, 'result[0].isEmailVerified', false))
    .catch(handleError);
}
// ----------------------------------------------------------------------------

// Профиль специалиста------------------------------------------------------------
function parseFile(file) {
  return {
    id: get(file, 'id', null),
    originalFileName: get(file, 'originalFileName', ''),
    urlLink: get(file, 'urlLink', null),
    name: get(file, 'originalFileName', ''),
  };
}

function parseSpecialistProfile(user) {
  return {
    id: get(user, 'id', null),
    gender: get(user, 'gender', ''),
    timeZone: get(user, 'timeZone.id', ''),
    timeZoneValue: get(user, 'timeZone.value', ''),
    phone: get(user, 'phone', ''),
    email: get(user, 'email', ''),
    firstName: get(user, 'firstName', ''),
    lastName: get(user, 'lastName', ''),
    middleName: get(user, 'middleName', ''),
    birthdayDate: get(user, 'birthdayDate', null) ? moment(get(user, 'birthdayDate', null)) : null,
    mainEducation: get(user, 'mainEducation', ''),
    secondaryEducation: get(user, 'secondaryEducation', ''),
    isLGBTQ: get(user, 'isLGBTQ', null),
    experience: get(user, 'experience', ''),
    experienceOnline: get(user, 'experienceOnline', ''),
    descriptionClients: get(user, 'descriptionClients', ''),
    descriptionModerator: get(user, 'descriptionModerator', ''),
    beforeEnrollTime: get(user, 'beforeEnrollTime', ''),
    languagesCatalogue_ids: get(user, 'languagesCatalogue_ids', [''])[0] === '' ? undefined : get(user, 'languagesCatalogue_ids').map((e) => e.id),
    languagesCatalogueValues: get(user, 'languagesCatalogue_ids', []).map((e) => e.value),
    sessionPrice: get(user, 'sessionPrice', ''),
    isGroupSession: get(user, 'isGroupSession', null),
    groupSessionPrice: get(user, 'groupSessionPrice', ''),
    supervision: get(user, 'supervision', null),
    supervisionStartDate: get(user, 'supervisionStartDate', null) ? moment(get(user, 'supervisionStartDate', null)) : null,
    supervisionTime: get(user, 'supervisionTime', ''),
    supervisionPeriod: get(user, 'supervisionPeriod', [''])[0] === '' ? undefined : get(user, 'supervisionPeriod'),
    supervisionFormat: get(user, 'supervisionFormat', ''),
    supervisionSpecialist: get(user, 'supervisionSpecialist', ''),
    therapy: get(user, 'therapy', null),
    therapyTime: get(user, 'therapyTime', ''),
    therapyPeriod: get(user, 'therapyPeriod', [''])[0] === '' ? undefined : get(user, 'therapyPeriod'),
    therapyFormat: get(user, 'therapyFormat', ''),
    therapyApproach: get(user, 'therapyApproach', ''),
    specializationCatalogue_ids: get(user, 'specializationCatalogue_ids', [''])[0] === '' ? [] : get(user, 'specializationCatalogue_ids'),
    moderatorComment: get(user, 'moderatorComment', ''),
    status: get(user, 'status', ''),
    isValidSpecialist: get(user, 'isValidSpecialist', false),
    isWorkWithChildren: get(user, 'isWorkWithChildren', null),
    educationFiles: get(user, 'educationFiles_ids', []).map((f) => parseFile(f)),
    logo: get(user, 'logo_id.urlLink', null),
    isEmailVerified: get(user, 'isEmailVerified', false),
    isLegalPaymentValid: get(user, 'isLegalPaymentValid', false),
    isPhysicalPaymentValid: get(user, 'isPhysicalPaymentValid', false),
    cardPan: get(user, 'Pan', ''),
    isSuccessAddCard: get(user, 'isSuccessAddCard', false),
    CardId: get(user, 'CardId', ''),
    paymentType: get(user, 'paymentType', ''),
    billingDescriptor: get(user, 'billingDescriptor', ''),
    fullName: get(user, 'fullName', ''),
    name: get(user, 'name', ''),
    inn: get(user, 'inn', ''),
    kpp: get(user, 'kpp', ''),
    ogrn: get(user, 'ogrn', ''),
    paymentEmail: get(user, 'paymentEmail', ''),
    siteUrl: get(user, 'siteUrl', ''),
    addressesType: get(user, 'addressesType', ''),
    addressesZip: get(user, 'addressesZip', ''),
    addressesCountry: get(user, 'addressesCountry', ''),
    addressesCity: get(user, 'addressesCity', ''),
    addressesStreet: get(user, 'addressesStreet', ''),
    ceoLastName: get(user, 'ceoLastName', ''),
    ceoFirstName: get(user, 'ceoFirstName', ''),
    ceoMiddleName: get(user, 'ceoMiddleName', ''),
    ceoBirthDate: get(user, 'ceoBirthDate', null) ? moment(get(user, 'ceoBirthDate', null)) : null,
    ceoPhone: get(user, 'ceoPhone', ''),
    ceoCountry: get(user, 'ceoCountry', ''),
    bankAccountAccount: get(user, 'bankAccountAccount', ''),
    bankAccountBankName: get(user, 'bankAccountBankName', ''),
    bankAccountBik: get(user, 'bankAccountBik', ''),
    bankAccountDetails: get(user, 'bankAccountDetails', ''),
    fee: get(user, 'fee', 0),
    shopCode: get(user, 'shopCode', ''),
  };
}

export function getSpecialistProfile(userID) {
  return api
    .structure('WebUser')
    .getData('getSpecialistProfile', {
      userID,
    })
    .then((resp) => parseSpecialistProfile(get(resp, 'payload[0]', {})))
    .catch(handleError);
}

export function editSpecialistProfile(values) {
  return api
    .structure('WebUser')
    .sendData('editSpecialistProfile', values)
    .then((resp) => parseSpecialistProfile(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function editUserLogo(values) {
  return api
    .structure('WebUser')
    .sendData('editUserLogo', values)
    .then((resp) => get(resp, 'result[0].logo_id.urlLink', ''))
    .catch(handleError);
}

export function uploadFile(file, headers) {
  return api
    .structure('inputFile')
    .sendData('uploadFile', file, headers)
    .then((resp) => parseFile(get(resp, 'result[0].file', {})))
    .catch(handleError);
}
// ----------------------------------------------------------------------------

// Уравление специалистами и клиентами------------------------------------------------------------
function parseSpecialistShort(user) {
  return {
    id: get(user, 'id', null),
    firstName: get(user, 'firstName', ''),
    lastName: get(user, 'lastName', ''),
    middleName: get(user, 'middleName', ''),
    name: `${get(user, 'lastName', '')} ${get(user, 'firstName', '')} ${get(user, 'middleName', '')}`,
    phone: get(user, 'phone', ''),
    email: get(user, 'email', ''),
    status: get(user, 'status', ''),
    createDate: get(user, 'createDate', null) ? moment(get(user, 'createDate')) : null,
  };
}

function parseClienSession(session) {
  return {
    id: get(session, 'id', null),
    statusID: get(session, 'status_id', null),
  };
}

function parseAllClient(user) {
  return {
    id: get(user, 'id', null),
    nickName: get(user, 'nickName', ''),
    gender: get(user, 'gender', ''),
    genderOther: get(user, 'genderOther', ''),
    age: get(user, 'age', ''),
    timeZone: get(user, 'timeZone.value', ''),
    phone: get(user, 'phone', ''),
    email: get(user, 'email', ''),
    isBlocked: get(user, 'isBlocked', ''),
    moderatorComment: get(user, 'moderatorComment', ''),
    isManual: get(user, 'isManual', false),
    timeZoneId: get(user, 'timeZone.id', ''),
    timeDifference: get(user, 'timeZone.timeDifference', ''),
    createDate: get(user, 'createDate', null) ? moment(get(user, 'createDate')) : null,
    currentSpecialistId: get(user, 'currentSpecialist_id.id', null),
    currentSpecialistName: `${get(user, 'currentSpecialist_id.lastName', '')} ${get(user, 'currentSpecialist_id.firstName', '')} ${get(user, 'currentSpecialist_id.middleName', '')}`,
    isDeleted: get(user, 'isDeleted', false),
    isValidClient: get(user, 'isValidClient', false),
    numberOfSessions: get(user, 'sessions_ids', []).map((s) => parseClienSession(s)).filter((s) => s.statusID === 'done').length,
  };
}

function parseCancelRequest(r) {
  return {
    id: get(r, 'id', null),
    date: get(r, 'date', null) ? moment(get(r, 'date', null)) : null,
    declineReason: get(r, 'declineReason', null),
    sessionDate: get(r, 'session_id.sessionDateAndTime', null) ? moment(get(r, 'session_id.sessionDateAndTime', null)) : null,
    clientPhone: get(r, 'session_id.client_id.phone', null),
    clientName: get(r, 'session_id.client_id.nickName', ''),
    specialistPhone: get(r, 'session_id.specialist_id.phone', null),
    specialistName: `${get(r, 'session_id.specialist_id.lastName', '')} ${get(r, 'session_id.specialist_id.firstName', '')} ${get(r, 'session_id.specialist_id.middleName', '')}`,
    status: get(r, 'status', null),
  };
}

function parseSpecialistRequest(r) {
  return {
    id: get(r, 'id', null),
    date: get(r, 'date', null) ? moment(get(r, 'date', null)) : null,
    comment: get(r, 'comment', null),
    status: get(r, 'status', null),
    clientPhone: get(r, 'client_id.phone', null),
    clientName: get(r, 'client_id.nickName', ''),
    specialistPhone: get(r, 'specialist_id.phone', null),
    specialistName: `${get(r, 'specialist_id.lastName', '')} ${get(r, 'specialist_id.firstName', '')} ${get(r, 'specialist_id.middleName', '')}`,
  };
}

export function getAllClients() {
  return api
    .structure('WebUser')
    .getData('getAllClients', { pageSize: 1000 })
    .then((resp) => get(resp, 'payload', []).map((field) => parseAllClient(field)))
    .catch(handleError);
}

export function getAllSpecialistsShort() {
  return api
    .structure('WebUser')
    .getData('getAllSpecialistsShort', { pageSize: 1000 })
    .then((resp) => get(resp, 'payload', []).map((field) => parseSpecialistShort(field)))
    .catch(handleError);
}

export function editSpecialistStatus(values) {
  return api
    .structure('WebUser')
    .sendData('editSpecialistStatus', values)
    .then((resp) => parseSpecialistShort(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function editClientStatus(values) {
  return api
    .structure('WebUser')
    .sendData('editClientStatus', values)
    .catch(handleError);
}

export function getAllCancelSessionRequest() {
  return api
    .structure('cancelSessionRequest')
    .getData('getAllCancelSessionRequest')
    .then((resp) => get(resp, 'payload', []).map((field) => parseCancelRequest(field)))
    .catch(handleError);
}

export function getAllChangeSpecialistRequest() {
  return api
    .structure('changeSpecialistRequest')
    .getData('getAllChangeSpecialistRequest')
    .then((resp) => get(resp, 'payload', []).map((field) => parseSpecialistRequest(field)))
    .catch(handleError);
}

export function editChangeSpecialistRequest(values) {
  return api
    .structure('changeSpecialistRequest')
    .sendData('editChangeSpecialistRequest', values)
    .then((resp) => get(resp, 'result[0].id', null))
    .catch(handleError);
}

// ----------------------------------------------------------------------------


// Интеграция с tinkoff--------------------------------------------------------------
function parseAddPaymentInfo(user) {
  return {
    addCardUrl: get(user, 'addCardUrl', null),
    shopCode: get(user, 'shopCode', null),
  };
}


export function getPaymentURL(values) {
  return api
    .structure('sessionPayment')
    .sendData('getPaymentURL', values)
    .then((resp) => ({
      paymentURL: get(resp, 'result[0].paymentURL', null),
      getPaymentLinkStatus: get(resp, 'result[0].getPaymentLinkStatus', null),
    }))
    .catch(handleError);
}

export function addPaymentInfo(values) {
  return api
    .structure('WebUser')
    .sendData('addPaymentInfo', values)
    .then((resp) => parseAddPaymentInfo(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function checkCard(values) {
  return api
    .structure('WebUser')
    .sendData('checkCard', values)
    .then((resp) => get(resp, 'result[0].Pan', null))
    .catch(handleError);
}


// ----------------------------------------------------------------------------

// Список специалистов--------------------------------------------------------------
function parseSpecialistProfileList(user) {
  return {
    gender: get(user, 'gender', ''),
    firstName: get(user, 'firstName', ''),
    lastName: get(user, 'lastName', ''),
    birthdayDate: get(user, 'birthdayDate', null) ? moment(get(user, 'birthdayDate', null)) : null,
    specializationCatalogue_ids: get(user, 'specializationCatalogue_ids', []).map((c) => parseCatalogue(c)),
    languagesCatalogue_ids: get(user, 'languagesCatalogue_ids', []).map((c) => parseCatalogue(c)),
    sessionPrice: Number(get(user, 'sessionPrice', 0)),
    logoURL: get(user, 'logo_id.urlLink', null),
    id: get(user, 'id', ''),
    experience: get(user, 'experience', ''),
    beforeEnrollTime: get(user, 'beforeEnrollTime', 24),
  };
}

export function getSpecialistList() {
  return api
    .structure('WebUser')
    .getData('getSpecialistList', { pageSize: 100 })
    .then((resp) => get(resp, 'payload', []).map((field) => parseSpecialistProfileList(field)))
    .catch(handleError);
}
// ----------------------------------------------------------------------------

// Умный подбор------------------------------------------------------------
function parseSpecialistsForCatalogue(user) {
  return {
    sessionPrice: get(user, 'sessionPrice', ''),
    birthdayDate: get(user, 'birthdayDate', null) ? moment(get(user, 'birthdayDate', null)) : null,
    specializationCatalogue_ids: get(user, 'specializationCatalogue_ids', []).map((c) => parseCatalogue(c)),
    languagesCatalogue_ids: get(user, 'languagesCatalogue_ids', []).map((c) => parseCatalogue(c)),
  };
}

function convertResToCatalogues(res) {
  const sessionPriceCatalogue = {
    min: null,
    max: null,
  };
  const specialistAgeCatalogue = {
    min: null,
    max: null,
  };
  const languagesCatalogue = [];
  const specializationCatalogue = [];
  res.forEach((e) => {
    if (!sessionPriceCatalogue.min || e.sessionPrice < sessionPriceCatalogue.min) {
      sessionPriceCatalogue.min = Number(e.sessionPrice);
    }
    if (!sessionPriceCatalogue.max || e.sessionPrice > sessionPriceCatalogue.max) {
      sessionPriceCatalogue.max = Number(e.sessionPrice);
    }
    const age = moment().diff(e.birthdayDate, 'years');
    if (!specialistAgeCatalogue.min || age < specialistAgeCatalogue.min) {
      specialistAgeCatalogue.min = age - 1;
    }
    if (!specialistAgeCatalogue.max || age > specialistAgeCatalogue.max) {
      specialistAgeCatalogue.max = age + 1;
    }
    languagesCatalogue.push(...e.languagesCatalogue_ids);
    specializationCatalogue.push(...e.specializationCatalogue_ids);
  });
  return {
    sessionPriceCatalogue,
    specialistAgeCatalogue,
    languagesCatalogue: uniqBy(languagesCatalogue, 'id'),
    specializationCatalogue: uniqBy(specializationCatalogue, 'id'),
  };
}

export function getCleverSearch(values) {
  return api
    .structure('WebUser')
    .getData('getCleverSearch', values)
    .then((resp) => get(resp, 'payload', []).map((field) => parseSpecialistProfileList(field)))
    .catch(handleError);
}

export function getSpecialistsForCatalogue(values) {
  return api
    .structure('WebUser')
    .getData('getSpecialistsForCatalogue', { ...values, pageSize: 100 })
    .then((resp) => get(resp, 'payload', []).map((field) => parseSpecialistsForCatalogue(field)))
    .then((res) => convertResToCatalogues(res))
    .catch(handleError);
}
// ----------------------------------------------------------------------------

// Карточка специалиста для пользователей---------------------------------------------------------
function parseSpecialist(user) {
  return {
    firstName: get(user, 'firstName', ''),
    middleName: get(user, 'middleName', ''),
    lastName: get(user, 'lastName', ''),
    id: get(user, 'id', ''),
    descriptionClients: get(user, 'descriptionClients', ''),
    mainEducation: get(user, 'mainEducation', ''),
    logoURL: get(user, 'logo_id.urlLink', null),
    beforeEnrollTime: get(user, 'beforeEnrollTime', 24),
    secondaryEducation: get(user, 'secondaryEducation', ''),
    specializationCatalogue_ids: get(user, 'specializationCatalogue_ids', []).map((c) => parseCatalogue(c)),
    sessionPrice: get(user, 'sessionPrice', ''),
    experience: get(user, 'experience', ''),
    birthdayDate: get(user, 'birthdayDate', null) ? moment(get(user, 'birthdayDate', null)) : null,
    languagesCatalogue_ids: get(user, 'languagesCatalogue_ids', []).map((c) => parseCatalogue(c)),
  };
}

function addTimeZoneToSlots(slots, clientTimeDifferenced) {
  let slotsToSave = [];
  const dates = [];
  slots.forEach((slot) => {
    slot.timesArray.forEach((time) => {
      const newdate = moment(`${slot.date.startOf('day').format('YYYY-MM-DD')}T${time}`, 'YYYY-MM-DDTHH:00').subtract(clientTimeDifferenced || getTimeZoneDifference() || timeDifference, 'm').format('YYYY-MM-DDTHH:00');
      dates.push({ date: newdate, user_id: slot.user_id });
    });
  });

  dates.forEach((date) => {
    const slotToSave = slotsToSave.find((s) => s.date === date.date.split('T')[0]);
    if (slotToSave) {
      slotsToSave = slotsToSave.map((s) => (s.date === slotToSave.date ? { ...s, timesArray: [...s.timesArray, date.date.split('T')[1]] } : s));
    } else {
      slotsToSave.push({
        user_id: date.user_id,
        date: date.date.split('T')[0],
        timesArray: [date.date.split('T')[1]],
      });
    }
  });
  slotsToSave = slotsToSave.map((s) => ({ ...s, date: moment(s.date, 'YYYY-MM-DD').startOf('day') }));
  return slotsToSave;
}

function parseAddTimeZoneToSlots(slots, clientTimeDifferenced) {
  const groupedByUser = groupBy(slots, 'user_id');
  const userKeys = Object.keys(groupedByUser);
  const result = [];
  userKeys.forEach((key) => {
    const userSlots = groupedByUser[key];
    result.push(...addTimeZoneToSlots(userSlots, clientTimeDifferenced));
  });
  return result;
}

function parseSpecialistSlots(slot) {
  return {
    id: get(slot, 'id', null),
    date: get(slot, 'date', null) ? moment(get(slot, 'date', null)) : null,
    timesArray: get(slot, 'timesArray', []).length === 1 && get(slot, 'timesArray', [])[0] === '' ? [] : get(slot, 'timesArray', []),
    user_id: get(slot, 'user_id', null),
  };
}

export function getSpecialist(userId) {
  return api
    .structure('WebUser')
    .getData('getSpecialist', { userId })
    .then((resp) => parseSpecialist(get(resp, 'payload[0]', {})))
    .catch(handleError);
}

export function getSpecialistSlots(userId, clientTimeDifferenced) {
  return api
    .structure('specialistSlots')
    .getData('getSpecialistSlots', { userId, pageSize: 1000 })
    .then((resp) => ({
      slots: parseAddTimeZoneToSlots(get(resp, 'payload', []).map((field) => parseSpecialistSlots(field)), clientTimeDifferenced),
      slotsIds: get(resp, 'payload', []).map((field) => parseSpecialistSlots(field)).map((s) => s.id),
    }))
    .catch(handleError);
}

export function editSession(values) {
  return api
    .structure('session')
    .sendData('editSession', values)
    .then((resp) => get(resp, 'result[0].id'))
    .catch(handleError);
}

export function sessionEnroll(values) {
  return api
    .structure('session')
    .sendData('sessionEnroll', values)
    .then((resp) => ({
      id: get(resp, 'result[0].id'),
      code: get(resp, 'result[0].rsp_code'),
    }))
    .catch(handleError);
}
// ----------------------------------------------------------------------------
// Карточка специалиста для пользователей----------------------------------------
function parseClientSession(session) {
  return {
    id: get(session, 'id', null),
    statusID: get(session, 'status_id.id', null),
    cost: get(session, 'cost', 0),
    statusValue: get(session, 'status_id.value', null),
    sessionDateAndTime: get(session, 'sessionDateAndTime', null) ? moment(get(session, 'sessionDateAndTime', null)) : null,
    specialist_id: get(session, 'specialist_id', null),
    // specialist: parseSpecialist(get(session, 'specialist_id', {})),
  };
}
function parseClientCurrentSpecialistAndAllSessions(client) {
  return {
    // currentSession: get(client, 'currentSession_id.id', null) ? parseClientSession(get(client, 'currentSession_id')) : null,
    currentSpecialist: get(client, 'currentSpecialist_id', null) ? parseSpecialist(get(client, 'currentSpecialist_id')) : null,
    sessions: get(client, 'sessions_ids', []).map((s) => parseClientSession(s)),
    changeSpecialistRequestID: get(client, 'changeSpecialistRequest_id', null),
  };
}
export function getClientCurrentSpecialistAndAllSessions(userID) {
  return api
    .structure('WebUser')
    .getData('getClientCurrentSpecialistAndAllSessions', { userID })
    .then((resp) => parseClientCurrentSpecialistAndAllSessions(get(resp, 'payload[0]', {})))
    .catch(handleError);
}

export function getSessionURL(ID) {
  return api
    .structure('session')
    .getData('getSessionURL', { ID })
    .then((resp) => get(resp, 'payload[0].VOXClientURL', null))
    .catch(handleError);
}

// ----------------------------------------------------------------------------

// Календарь специалиста-------------------------------------------------------
function parseGroupSessionEnroll(session) {
  return {
    id: get(session, 'id', null),
    statusId: get(session, 'status_id', null),
    clientName: get(session, 'client_id.nickName', ''),
    clientId: get(session, 'client_id.id', null),
    clientEmail: get(session, 'client_id.email', ''),
    clientPhone: get(session, 'client_id.phone', ''),
  };
}

function parseGroupTypeCatalogue(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'name', null),
    minutesLength: Number(get(object, 'minutesLength', null)),
  };
}

function parseSession(session) {
  return {
    id: get(session, 'id', null),
    statusValue: get(session, 'status_id.value', null),
    statusID: get(session, 'status_id.id', null),
    sessionDateAndTime: get(session, 'sessionDateAndTime', null) ? moment(get(session, 'sessionDateAndTime', null)) : null,
    clientName: get(session, 'client_id.nickName', ''),
    clientDisplayName: get(session, 'client_id.clientDisplayName', ''),
    clientID: get(session, 'client_id.id', ''),
    hostURL: get(session, 'hostURL', null),
    specializationArray: get(session, 'specializationArray', '').split(';'),
    gender: get(session, 'gender', ''),
    whoWillUse: get(session, 'whoWillUse', ''),
  };
}

function parseGroupSession(object) {
  return {
    id: get(object, 'id', null),
    startDateAndTime: get(object, 'startDateAndTime', null) ? moment(get(object, 'startDateAndTime', null)) : null,
    endDateAndTime: get(object, 'endDateAndTime', null) ? moment(get(object, 'endDateAndTime', null)) : null,
    name: get(object, 'name', null),
    description: get(object, 'description', null),
    maxNumberOfClients: Number(get(object, 'maxNumberOfClients', null)),
    minNumberOfClients: Number(get(object, 'minNumberOfClients', null)),
    statusId: get(object, 'status_id.id', null),
    statusValue: get(object, 'status_id.value', null),
    groupTypeName: get(object, 'groupTypeCatalogue_id.name', null),
    groupTypeId: get(object, 'groupTypeCatalogue_id.id', null),
    cost: Number(get(object, 'cost', 0)),
    moderatorComment: get(object, 'moderatorComment', null),
    groupSessionEnroll: get(object, 'groupSessionEnroll_ids', []).map((c) => parseGroupSessionEnroll(c)),
    specialist: parseSpecialist(get(object, 'specialist_id', {})),
    VOXSpecialistURL: get(object, 'VOXSpecialistURL', null),
    isHidden: get(object, 'isHidden', false),
  };
}

export function getGroupTypeCatalogue() {
  return api
    .structure('groupTypeCatalogue')
    .getData('getGroupTypeCatalogue')
    .then((resp) => get(resp, 'payload', []).map((field) => parseGroupTypeCatalogue(field)))
    .catch(handleError);
}

export function getSpecialistSessions(userID) {
  return api
    .structure('session')
    .getData('getSpecialistSessions', { userID, pageSize: 30 })
    .then((resp) => {
      const tableSize = get(resp, 'pageInfo.tableSize', 0);
      if (tableSize > 30) {
        return api
          .structure('session')
          .getData('getSpecialistSessions', { userID, pageSize: tableSize })
          .then((response) => get(response, 'payload', []).map((field) => parseSession(field)));
      }
      return get(resp, 'payload', []).map((field) => parseSession(field));
    })
    .catch(handleError);
}

export function editSpecialistSlots(values) {
  const toBeDeleted = values.filter((s) => s.isDeleted).map((s) => s.id);
  return api
    .structure('specialistSlots')
    .sendData('editSpecialistSlots', values)
    .then((resp) => ({
      slots: parseAddTimeZoneToSlots(get(resp, 'result', []).map((field) => parseSpecialistSlots(field)).filter((s) => !toBeDeleted.includes(s.id))),
      slotsIds: get(resp, 'result', []).map((field) => parseSpecialistSlots(field)).map((s) => s.id).filter((s) => !toBeDeleted.includes(s.id)),
    }))
    .catch(handleError);
}

export function startSessionZoom(ID) {
  return api
    .structure('session')
    .sendData('startSessionZoom', { id: ID })
    .then((resp) => get(resp, 'result[0].VOXSpecialistURL', null))
    .catch(handleError);
}

export function editGroupSession(values) {
  return api
    .structure('groupSessions')
    .sendData('editGroupSession', values)
    .then((resp) => get(resp, 'result[0].id', null))
    .catch(handleError);
}

export function getSpecialistGroupSessions(userID) {
  return api
    .structure('groupSessions')
    .getData('getSpecialistGroupSessions', { userID })
    .then((resp) => get(resp, 'payload', []).map((field) => parseGroupSession(field)))
    .catch(handleError);
}

export function getAllGroupSessions() {
  return api
    .structure('groupSessions')
    .getData('getAllGroupSessions', { pageSize: 100 })
    .then((resp) => get(resp, 'payload', []).map((field) => parseGroupSession(field)))
    .catch(handleError);
}

export function getApprovedGroupSessions(clientID, page) {
  return api
    .structure('groupSessions')
    .getData('getApprovedGroupSessions', { pageSize: 3, page, clientID })
    .then((resp) => ({
      list: get(resp, 'payload', []).map((field) => parseGroupSession(field)),
      totalPage: get(resp, 'pageInfo.totalPage', 1),
    }))
    .catch(handleError);
}

export function editGroupSessionEnroll(values) {
  return api
    .structure('groupSessionEnroll')
    .sendData('editGroupSessionEnroll', values)
    .then((resp) => get(resp, 'result[0].id', null))
    .catch(handleError);
}

export function clientGroupSessions(clientID) {
  return api
    .structure('groupSessionEnroll')
    .getData('clientGroupSessions', { clientID })
    .then((resp) => get(resp, 'payload', []).map((s) => ({
      ...parseGroupSession(get(s, 'groupSession_id', {})),
      statusID: get(s, 'status_id.id', null),
      statusValue: get(s, 'status_id.value', null),
      VOXClientURL: get(s, 'VOXClientURL', null),
      id: get(s, 'id', null),
    })))
    .catch(handleError);
}

// ----------------------------------------------------------------------------

// Главная ---------------------------------------------------------------
function parseArticle(article) {
  return {
    id: get(article, 'id', null),
    title: get(article, 'title', ''),
    date: get(article, 'date', null) ? moment(get(article, 'date', null)) : null,
  };
}

function parseVideo(video) {
  return {
    id: get(video, 'id', null),
    title: get(video, 'title', ''),
    viewCount: get(video, 'viewCount', 0),
    urlLink: get(video, 'urlLink', null),
  };
}

export function getAllVideos() {
  return api
    .structure('videos')
    .getData('getAllVideos', { pageSize: 4 })
    .then((resp) => get(resp, 'payload', []).map((a) => parseVideo(a)))
    .catch(handleError);
}
export function getAllArticles() {
  return api
    .structure('articles')
    .getData('getAllArticles', { pageSize: 4 })
    .then((resp) => get(resp, 'payload', []).map((a) => parseArticle(a)))
    .catch(handleError);
}

// ----------------------------------------------------------------------------

// Админ ручное добавление клиентов -------------------------------------------
export function getManualUsers() {
  return api
    .structure('WebUser')
    .getData('getManualUsers', { pageSize: 100 })
    .then((resp) => get(resp, 'payload', []).map((field) => parseClientProfile(field)))
    .catch(handleError);
}

export function editUser(values, headers) {
  return api
    .structure('WebUser')
    .sendData('editUser', values, headers)
    .then((resp) => parseClientProfile(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function getAllClientSessions(clientID) {
  return api
    .structure('session')
    .getData('getAllClientSessions', { clientID })
    .then((resp) => get(resp, 'payload', []).map((field) => parseClientSession(field)))
    .catch(handleError);
}
//--------------------------------------------------------------------

// Поддержка----------------------------
function parseHelpRequestsComments(object) {
  return {
    id: get(object, 'id', null),
    content: get(object, 'comment', ''),
    datetime: get(object, 'date', null) ? moment(get(object, 'date', null)).format('DD MMMM YYYY: HH:mm') : '',
    date: get(object, 'date', null) ? moment(get(object, 'date', null)) : null,
    author: get(object, 'author_id.nickName', null) || `${get(object, 'author_id.lastName', '')} ${get(object, 'author_id.firstName', '')} ${get(object, 'author_id.middleName', '')}`,
  };
}

function parseHelpRequests(object) {
  return {
    id: get(object, 'id', null),
    status: get(object, 'status', ''),
    displayID: get(object, 'displayID', ''),
    createDate: get(object, 'createDate', null) ? moment(get(object, 'createDate', null)) : null,
    theme: get(object, 'theme', null),
    description: get(object, 'description', null),
    helpRequestsComments: get(object, 'helpRequestsComments_ids', []).map((o) => parseHelpRequestsComments(o)),
    authorPhone: get(object, 'author_id.phone', null),
    authorEmail: get(object, 'author_id.email', null),
    author: get(object, 'author_id.nickName', null) || `${get(object, 'author_id.lastName', '')} ${get(object, 'author_id.firstName', '')} ${get(object, 'author_id.middleName', '')}`,
    adminUnreadCount: get(object, 'adminUnreadCount', 0),
    authorUnreadCount: get(object, 'authorUnreadCount', 0),
  };
}

export function getUserHelpRequests(userID) {
  return api
    .structure('helpRequests')
    .getData('getUserHelpRequests', { userID, pageSize: 100 })
    .then((resp) => get(resp, 'payload', []).map((field) => parseHelpRequests(field)))
    .catch(handleError);
}

export function getAdminHelpRequests() {
  return api
    .structure('helpRequests')
    .getData('getAdminHelpRequests', { pageSize: 100 })
    .then((resp) => get(resp, 'payload', []).map((field) => parseHelpRequests(field)))
    .catch(handleError);
}

export function editHelpRequests(values) {
  return api
    .structure('helpRequests')
    .sendData('editHelpRequests', values)
    .then((resp) => parseHelpRequests(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function editHelpRequestsComments(values) {
  return api
    .structure('helpRequestsComments')
    .sendData('editHelpRequestsComments', values)
    .then((resp) => parseHelpRequestsComments(get(resp, 'result[0]', {})))
    .catch(handleError);
}

//-----------------------------------------------------------------


export function registerUser(values) {
  return api
    .structure('WebUser')
    .sendData('registerUser', values)
    .catch(handleError);
}


export function personalDataChangeRequest(values) {
  return api
    .structure('personalDataChangeRequest')
    .sendData('personalDataChangeRequest', values)
    .catch(handleError);
}

export function editLockedField(values) {
  return api
    .structure('lockedFields')
    .sendData('editLockedField', values)
    .then((resp) => parseLockedFields(get(resp, 'result[0]', {})))
    .catch(handleError);
}


export function getPersonalDataChangeRequests() {
  return api
    .structure('personalDataChangeRequest')
    .getData('getPersonalDataChangeRequests')
    .then((resp) => get(resp, 'payload', []).map((field) => parsePersonalDataChangeRequest(field)))
    .catch(handleError);
}

export function totalLogout(values) {
  return api
    .structure('WebUser')
    .sendData('totalLogout', values)
    .catch(handleError);
}

export function getLoginAttempts() {
  return api
    .structure('WebUserInput')
    .getData('getLoginAttempts')
    .then((resp) => get(resp, 'payload', []).map((field) => parseLoginAttempt(field)))
    .catch(handleError);
}

function parseShopCodeList(object) {
  return {
    shopCode: get(object, 'shopCode', null),
    fullName: `${get(object, 'lastName', '')}  ${get(object, 'firstName', '')}  ${get(object, 'middleName', '')}`,
  };
}

function parseCardIdList(object) {
  return {
    CardId: get(object, 'CardId', null),
    Pan: get(object, 'Pan', null),
    fullName: `${get(object, 'lastName', '')}  ${get(object, 'firstName', '')}  ${get(object, 'middleName', '')}`,
  };
}

export function getShopCodeList() {
  return api
    .structure('WebUser')
    .getData('getShopCodeList', { pageSize: 100 })
    .then((resp) => get(resp, 'payload', []).map((field) => parseShopCodeList(field)))
    .catch(handleError);
}

export function getCardIdList() {
  return api
    .structure('WebUser')
    .getData('getCardIdList', { pageSize: 100 })
    .then((resp) => get(resp, 'payload', []).map((field) => parseCardIdList(field)))
    .catch(handleError);
}

export function getSessionPaymentStatusById(sessionPaymentId) {
  return api
    .structure('sessionPayment')
    .getData('getSessionPaymentStatusById', { sessionPaymentId })
    .then((resp) => get(resp, 'payload[0].status', ''))
    .catch(handleError);
}

// Орагнизации -----------------------------------------------------------------

function parsePromoCode(object) {
  return {
    id: get(object, 'id', null),
    value: get(object, 'value', ''),
    sum: parseFloat(get(object, 'sum', 0)),
    isUsed: get(object, 'isUsed', false),
    sessionId: get(object, 'session_id.id', null),
    sessionClientPhone: get(object, 'session_id.client_id.phone', ''),
    sessionClientNickName: get(object, 'session_id.client_id.nickName', ''),
    sessionClientNamePhone: `${get(object, 'session_id.client_id.nickName', '')} +${get(object, 'session_id.client_id.phone', '')}`,
    sessionCost: parseFloat(get(object, 'session_id.cost', 0)) / 100,
    sessionSpecialistFullName: `${get(object, 'session_id.specialist_id.lastName', '')} ${get(object, 'session_id.specialist_id.firstName', '')} ${get(object, 'session_id.specialist_id.middleName', '')}`,
    sessionDateAndTime: get(object, 'session_id.sessionDateAndTime', null) ? moment(get(object, 'session_id.sessionDateAndTime', null)) : null,
  };
}

function parseCompany(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'name', ''),
    deposit: parseFloat(get(object, 'deposit', 0)),
    promoCodes: get(object, 'promoCodes_ids', []).map((code) => parsePromoCode(code)),
  };
}

export function getCompanyInfoById(id) {
  return api
    .structure('company')
    .getData('getCompanyInfoById', { id })
    .then((resp) => parseCompany(get(resp, 'payload[0]', {})))
    .catch(handleError);
}
