/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import { notification } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import { routes } from '../../utils/routes';
import * as roles from '../../utils/roles';
import { setLocalStorage } from '../../utils/localeStorage/storage';
import {
  getSMS, checkSMS, getUserAfterLogin, editEmailVerificationRequests, checkEmailCode,
} from '../../utils/http';
import LoginMain from './LoginMain/LoginMain';
import LoginSpecialist from './LoginSpecialist/LoginSpecialist';
import { adminPhones } from '../../utils/admins';


class LoginModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      smsID: null,
      webUser: null,
      secs: 60,
      nickName: null,
      email: null,
      user: {},
    };
    this.ping = null;
    this.formRef = React.createRef();
    this.emailInputFormRef = React.createRef();
  }

  componentDidMount() {
    const { history } = this.props;
    this.setState({ user: { role: get(history, 'location.state') === 'specialist' ? 'specialist' : 'client' } });
  }

  componentWillUnmount() {
    clearInterval(this.ping);
    this.ping = null;
  }

  onRedirectAfterLogin = () => {
    const { history, callBack, isHideFields } = this.props;
    const { user } = this.state;

    setLocalStorage({ ...user, isEmailVerified: user.role === roles.CLIENT ? true : user.isEmailVerified });
    if (callBack && !isHideFields) {
      callBack();
      return;
    }
    let routeToPush = '';
    if (user.role === roles.ADMIN) {
      routeToPush = routes.adminSpecialists;
    } else if (user.role === roles.CLIENT && user.isValidClient) {
      routeToPush = routes.meetings;
    } else if (user.role === roles.ORG) {
      routeToPush = routes.promoCodes;
    } else {
      routeToPush = routes.profile;
    }
    if (callBack && isHideFields) {
      callBack(routeToPush);
    }
    history.push(routeToPush);
  }

  onLogin = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        const {
          smsID, webUser, nickName, email, user,
        } = this.state;
        const { callBack, isHideFields } = this.props;
        this.setState({ loading: true });
        if (smsID && this.state.secs !== 0) {
          if (!values.code) {
            notification.error({ message: 'Пожалуйста, введите код из СМС' });
            this.setState({ loading: false });
          } else {
            const offset = new Date().getTimezoneOffset();
            const offsetMajor = -1 * parseInt((offset / 60), 10);
            const offsetMinor = offset % 60;
            let inputTimeZone = `(UTC${offsetMajor > 0 ? '+' : ''}${offsetMajor}`;
            if (offsetMinor !== 0) {
              inputTimeZone += `:${offsetMinor})`;
            } else {
              inputTimeZone += ')';
            }

            checkSMS({ inputCode: values.code, id: smsID, inputTimeZone })
              .then((rsp) => {
                if (rsp.code === 'success') {
                  getUserAfterLogin(rsp.webUserId, rsp.token)
                    .then(async (userResp) => {
                      const timeDifference = new Date().getTimezoneOffset();
                      const payload = {
                        token: rsp.token,
                        ...userResp,
                        isValidSpecialist: userResp.isValidSpecialist && !!userResp.paymentType,
                        role: userResp.isOrg ? 'org' : userResp.role,
                        timeZoneTimeDifference: userResp.timeZoneTimeDifference || timeDifference,
                      };
                      setLocalStorage({ token: rsp.token });
                      if (callBack && !isHideFields) {
                        payload.nickName = nickName;
                        payload.email = email;
                      }
                      clearInterval(this.ping);
                      this.ping = null;
                      this.setState({
                        loading: false, smsID: null, webUser: null, secs: 60, nickName: null, email: null, user: payload,
                      });
                      if (payload.role === roles.ADMIN || payload.role === roles.SPECIALIST || payload.isEmailVerified) {
                        this.onRedirectAfterLogin();
                      }
                      if (payload.email && !payload.isEmailVerified) {
                        this.onGetEmailCode(payload.email);
                      }
                    });
                }
                if (rsp.code === 'error') {
                  this.setState({ loading: false });
                  notification.error({ message: rsp.message });
                }
              });
          }
        } else {
          if (!values.phone && !webUser) {
            notification.error({ message: 'Пожалуйста, введите номер телефона' });
            this.setState({ loading: false });
            return;
          }
          if (callBack && !isHideFields && !values.nickName && !webUser) {
            notification.error({ message: 'Пожалуйста, введите имя' });
            this.setState({ loading: false });
            return;
          }
          if (callBack && !isHideFields && !values.email && !webUser) {
            notification.error({ message: 'Пожалуйста, введите email' });
            this.setState({ loading: false });
            return;
          }
          let phone = null;
          if (webUser && !values.phone) {
            clearInterval(this.ping);
            this.ping = null;
            this.setState({ secs: 60 });
            phone = webUser;
          } else {
            phone = values.phone.replaceAll('+', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '')
              .replaceAll('-', '');
          }
          const userRole = adminPhones.includes(phone) ? roles.ADMIN : user.role;
          getSMS({ phone, role: userRole })
            .then((id) => {
              this.setState((prevState) => ({
                smsID: id,
                webUser: phone,
                loading: false,
                nickName: values.nickName,
                email: values.email,
                user: { ...prevState.user, role: userRole },
              }));
              this.ping = setInterval(() => {
                const { secs } = this.state;
                if (secs !== 0) {
                  this.setState((prevState) => ({ secs: prevState.secs - 1 }));
                }
              }, 1000);
            });
        }
      });
  }

  onGetEmailCode = (email) => {
    let emailInUse = email;
    if (!email) {
      emailInUse = this.emailInputFormRef.current.getFieldsValue()?.email;
    }
    const { user } = this.state;
    const EmailMask = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (EmailMask.test(emailInUse)) {
      editEmailVerificationRequests({ user_id: user.id, date: moment().toISOString(), email: emailInUse })
        .then(() => {
          this.setState({ user: { ...user, email: emailInUse } });
        });
    } else {
      notification.error({ message: 'Введите корректный email!' });
    }
  }

  onVerifyEmail = (userInputEmailVerificationCode) => {
    const { user } = this.state;
    checkEmailCode({ id: user.id, userInputEmailVerificationCode, emailInVerification: user.email })
      .then((isEmailVerified) => {
        if (isEmailVerified) {
          this.onRedirectAfterLogin();
        } else {
          notification.error({ message: 'Неправильный код!' });
        }
      });
  }

  render() {
    const {
      smsID, loading, secs, webUser, user,
    } = this.state;
    const { callBack, isHideFields, isSelectSpecialist } = this.props;
    if (isSelectSpecialist) {
      return (
        <LoginSpecialist
          smsID={smsID}
          loading={loading}
          secs={secs}
          webUser={webUser}
          user={user}
          callBack={callBack}
          isHideFields={isHideFields}
          onVerifyEmail={this.onVerifyEmail}
          formRef={this.formRef}
          onGetEmailCode={this.onGetEmailCode}
          onLogin={this.onLogin}
          emailInputFormRef={this.emailInputFormRef}
          setState={this.setState}
        />
      );
    }
    return (
      <LoginMain
        smsID={smsID}
        loading={loading}
        secs={secs}
        webUser={webUser}
        user={user}
        onVerifyEmail={this.onVerifyEmail}
        formRef={this.formRef}
        onGetEmailCode={this.onGetEmailCode}
        onLogin={this.onLogin}
        emailInputFormRef={this.emailInputFormRef}
        setState={this.setState}
      />
    );
  }
}

LoginModule.propTypes = {
  history: PropTypes.shape().isRequired,
  callBack: PropTypes.func,
  isHideFields: PropTypes.bool,
  isSelectSpecialist: PropTypes.bool,
};

LoginModule.defaultProps = {
  callBack: null,
  isHideFields: null,
  isSelectSpecialist: false,
};

export default withRouter(LoginModule);
