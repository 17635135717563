import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { config } from '../../utils/config';
import getUserRole from '../../utils/localeStorage/getUserRole';
import SettingsPageDesktop from './Desktop/SettingsPageDesktop';
import { setLocalStorage } from '../../utils/localeStorage/storage';
import { totalLogout } from '../../utils/http';
import logout from '../../utils/logout';
import getUserId from '../../utils/localeStorage/getUserId';


class SettingsModule extends Component {
  state = {
    isMobile: window.innerWidth <= config.mobileWidth,
    role: getUserRole(),
  };

  onRoleChange = (e) => {
    this.setState({ role: e.target.value });
    setLocalStorage({ role: e.target.value });
    window.location.reload(false);
  }

  onTotalLogout = () => {
    const { history } = this.props;
    totalLogout({ id: getUserId() })
      .then(() => logout(history));
  }

  render() {
    const {
      isMobile, role,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <SettingsPageDesktop
        role={role}
        onRoleChange={this.onRoleChange}
        onTotalLogout={this.onTotalLogout}
      />
    );
  }
}


SettingsModule.propTypes = {
  history: PropTypes.shape(),
};

SettingsModule.defaultProps = {
  history: {},
};

export default withRouter(SettingsModule);
