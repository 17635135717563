import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Table, Col, Button, Modal, Form, Input, Row, InputNumber, DatePicker, TimePicker, Divider, Select,
} from 'antd';
import getGroupSessionsColumns from '../utils/getGroupSessionsColumns';
import getClientColumns from '../utils/getClientColumns';
import './AdminGroupSessionsPageDesktop.css';


const { TextArea } = Input;
const { Option } = Select;
class AdminGroupSessionsPageDesktop extends Component {
  render() {
    const {
      groupSessions, onSelectSession, selectedSession, onCloseSelectSession, onDecide, formRef, onConfirm, onCancel, groupTypeCatalogue,
    } = this.props;
    return (
      <Col span={24} style={{ padding: '40px' }}>
        <Row style={{ width: '100%' }}>
          <Table
            columns={getGroupSessionsColumns(onSelectSession)}
            rowKey="id"
            dataSource={groupSessions}
            pagination={false}
            style={{ width: '100%' }}
            expandable={{
              rowExpandable: (record) => record.statusId === 'approved',
              expandedRowRender: (record) => {
                const totalClients = record.groupSessionEnroll;
                const approvedClients = record.groupSessionEnroll.filter((e) => e.statusId === 'approved');
                const paidClients = record.groupSessionEnroll.filter((e) => e.statusId === 'paid');
                const canceledClients = record.groupSessionEnroll.filter((e) => e.statusId === 'canceled');
                return (
                  <Col style={{ margin: 0 }} span={24}>
                    <Row style={{ color: '#212121', fontSize: '20px' }}>{`Зарегистрировано: ${totalClients.length}`}</Row>
                    {totalClients.length > 0 && (
                    <Table
                      columns={getClientColumns(true, onConfirm, onCancel)}
                      rowKey="id"
                      dataSource={totalClients}
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                    )}
                    <Row style={{ color: '#212121', fontSize: '20px', marginTop: '20px' }}>{`Подтверждено: ${approvedClients.length + paidClients.length}`}</Row>
                    {approvedClients.length + paidClients.length > 0 && (
                    <Table
                      columns={getClientColumns(false)}
                      rowKey="id"
                      dataSource={[...paidClients, ...approvedClients]}
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                    )}
                    <Row style={{ color: '#212121', fontSize: '20px', marginTop: '20px' }}>{`Оплатило: ${paidClients.length}`}</Row>
                    {paidClients.length > 0 && (
                    <Table
                      columns={getClientColumns(false)}
                      rowKey="id"
                      dataSource={paidClients}
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                    )}
                    <Row style={{ color: '#212121', fontSize: '20px', marginTop: '20px' }}>{`Отменило: ${canceledClients.length}`}</Row>
                    {canceledClients.length > 0 && (
                    <Table
                      columns={getClientColumns(false)}
                      rowKey="id"
                      dataSource={canceledClients}
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                    )}
                  </Col>
                );
              },
            }}
          />
        </Row>
        {selectedSession
         && (
         <Modal
           visible
           title=""
           onCancel={onCloseSelectSession}
           maskClosable
           footer={selectedSession.statusId === 'onApproval' ? [
             <Button key="1" onClick={() => onDecide('declined')}>Отклонить</Button>,
             <Button type="primary" key="2" onClick={() => onDecide('approved')}>Согласовать</Button>,
           ] : [
             <Button key="1" onClick={() => onDecide('hide')} type="danger">Удалить</Button>,
           ]}
         >
           <Form
             layout="vertical"
             ref={formRef}
           >
             <Col span={24}>
               <Row>
                 <Col span={24}>
                   <Form.Item
                     rules={[{ required: true, message: '' }]}
                     name="name"
                     label="Название"
                     initialValue={selectedSession.name}
                   >
                     <Input disabled style={{ width: '100%' }} />
                   </Form.Item>
                 </Col>
               </Row>
               <Row>
                 <Col span={24}>
                   <Form.Item
                     rules={[{ required: true, message: '' }]}
                     name="description"
                     label="Описание"
                     initialValue={selectedSession.description}
                   >
                     <TextArea disabled={selectedSession.status !== 'На модерации'} rows={4} style={{ width: '100%' }} />
                   </Form.Item>
                 </Col>
               </Row>
               <Row>
                 <Col span={24}>
                   <Form.Item
                     rules={[{ required: true, message: '' }]}
                     name="maxNumberOfClients"
                     label="Максимальное кол-во клиентов"
                     initialValue={selectedSession.maxNumberOfClients}
                   >
                     <InputNumber min={1} disabled style={{ width: '100%' }} />
                   </Form.Item>
                 </Col>
               </Row>
               <Row>
                 <Col span={24}>
                   <Form.Item
                     rules={[{ required: true, message: '' }]}
                     name="groupTypeId"
                     label="Вид группы"
                     initialValue={selectedSession.groupTypeId}
                   >
                     <Select style={{ width: '100%' }} disabled>
                       {groupTypeCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.name}</Option>)}
                     </Select>
                   </Form.Item>
                 </Col>
               </Row>
               <Row>
                 <Col span={24}>
                   <Form.Item
                     rules={[{ required: true, message: '' }]}
                     name="date"
                     label="Дата проведения"
                     initialValue={selectedSession.startDateAndTime}
                   >
                     <DatePicker style={{ width: '100%' }} disabled />
                   </Form.Item>
                 </Col>
               </Row>
               <Row>
                 <Col span={24}>
                   <Form.Item
                     rules={[{ required: true, message: '' }]}
                     name="timeStart"
                     label="Время начала"
                     initialValue={selectedSession.startDateAndTime}
                   >
                     <TimePicker
                       style={{ width: '100%' }}
                       format="HH:mm"
                       disabled
                     />
                   </Form.Item>
                 </Col>
               </Row>
               <Row>
                 <Col span={24}>
                   <Form.Item
                     rules={[{ required: true, message: '' }]}
                     name="timeEnd"
                     label="Время окончания"
                     initialValue={selectedSession.endDateAndTime}
                   >
                     <TimePicker
                       style={{ width: '100%' }}
                       format="HH:mm"
                       disabled
                     />
                   </Form.Item>
                 </Col>
               </Row>
               <Divider />
               <Row>
                 <Col span={24}>
                   <Form.Item
                     name="moderatorComment"
                     label="Комментарий модератора"
                     initialValue={selectedSession.moderatorComment}
                   >
                     <TextArea rows={4} disabled={selectedSession.statusId !== 'onApproval'} style={{ width: '100%' }} />
                   </Form.Item>
                 </Col>
               </Row>
             </Col>
           </Form>
         </Modal>
         )}
      </Col>
    );
  }
}

AdminGroupSessionsPageDesktop.propTypes = {
  groupSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelectSession: PropTypes.func.isRequired,
  selectedSession: PropTypes.shape(),
  onCloseSelectSession: PropTypes.func.isRequired,
  onDecide: PropTypes.func.isRequired,
  formRef: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  groupTypeCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

AdminGroupSessionsPageDesktop.defaultProps = {
  selectedSession: null,
};

export default AdminGroupSessionsPageDesktop;
