/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import { orderBy } from 'lodash';
import {
  Spin, Table, Col, Form, Drawer, Input, List, Comment, Button, Row, Card, Popconfirm,
} from 'antd';
import getHelpRequestsColumns from '../utils/getHelpRequestsColumns';
import getUserRole from '../../../utils/localeStorage/getUserRole';
import './HelpPageDesktop.css';


const { TextArea } = Input;
class HelpPageDesktop extends Component {
  render() {
    const {
      loading, helpRequests, formRef, fieldLoading, selectedHelpRequest, onCloseDrawer, onSelectHelpRequest, onAddComment, commentValue,
      handleChangeCommentValue, editHelpRequestStatus, onAddHelpRequest, addHelpRequest,
    } = this.props;
    if (loading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
        </div>
      );
    }
    return (
      <Col span={24} className="helpPageDesktop-wrapper">
        <Card style={{ width: '100%', borderRadius: '25px', height: '100%' }}>
          <Col span={24}>
            <Row style={{ fontSize: '2vh', color: '#212121', marginBottom: '20px' }} justify="space-between">
              <Col>Обращения в поддержку</Col>
              {getUserRole() !== 'as2f2fc34' && <Col><Button type="primary" onClick={onAddHelpRequest}>Новое обращение</Button></Col>}
            </Row>
            <Table
              columns={getHelpRequestsColumns(onSelectHelpRequest)}
              rowKey="id"
              dataSource={orderBy(helpRequests, 'createDate', 'desc')}
              pagination={false}
              scroll={{ y: '60vh' }}
            />
            {selectedHelpRequest && (
            <Drawer
              title="Запрос в поддержку"
              placement="right"
              onClose={onCloseDrawer}
              visible
              width="30vw"
            >
              <Form
                ref={formRef}
                style={{ height: '100%' }}
              >
                <Col
                  style={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%',
                  }}
                  span={24}
                >
                  <Row>
                    <Col span={24}>
                      <div className="helpPageDesktop-block-wrapper">
                        <div style={{ color: '#212121' }}>Тема:</div>
                        <Form.Item
                          name="theme"
                          initialValue={selectedHelpRequest.theme}
                          rules={[{ required: true, message: 'Пожалуйста, заполните поле' }]}
                        >
                          <Input style={{ width: '100%' }} disabled={fieldLoading || !!selectedHelpRequest.id} placeholder="тема вашего обращения" />
                        </Form.Item>
                      </div>
                      <div className="helpPageDesktop-block-wrapper">
                        <div style={{ color: '#212121' }}>Описание:</div>
                        <Form.Item
                          name="description"
                          initialValue={selectedHelpRequest.description}
                          rules={[{ required: true, message: 'Пожалуйста, заполните поле' }]}
                        >
                          <TextArea rows={4} disabled={fieldLoading || !!selectedHelpRequest.id} style={{ width: '100%' }} placeholder="описание" />
                        </Form.Item>
                      </div>
                      {!!selectedHelpRequest.id && selectedHelpRequest.status !== 'Закрыто' && (
                      <Popconfirm
                        title="Вы уверены, что хотите закрыть обращение? Вы больше не сможете оставлять комментарии"
                        onConfirm={editHelpRequestStatus}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button className="btn-default btn_red" type="primary">Закрыть обращение</Button>
                      </Popconfirm>
                      )}
                      {!selectedHelpRequest.id && (
                      <Popconfirm
                        title="Вы уверены, что хотите создать обращение?"
                        onConfirm={addHelpRequest}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button className="btn-default btn_green" type="primary">Создать обращение</Button>
                      </Popconfirm>
                      )}
                    </Col>
                  </Row>
                  {selectedHelpRequest.id && (
                  <Row>
                    <Col span={24}>
                      <span style={{ color: '#212121' }}>Комментарии</span>
                      <List
                        dataSource={orderBy(selectedHelpRequest.helpRequestsComments, 'date', 'desc')}
                        itemLayout="horizontal"
                        renderItem={(props) => <Comment {...props} />}
                        style={{ height: '40vh', overflow: 'scroll' }}
                      />
                      {selectedHelpRequest.status !== 'Закрыто' && <TextArea rows={2} onChange={handleChangeCommentValue} value={commentValue} disabled={fieldLoading} />}
                      {selectedHelpRequest.status !== 'Закрыто' && (
                      <Button htmlType="submit" type="primary" onClick={() => onAddComment(null)} className="btn-default btn_green" style={{ marginTop: '10px' }} disabled={fieldLoading}>
                        Оставить комментарий
                      </Button>
                      )}
                    </Col>
                  </Row>
                  )}
                </Col>
              </Form>
            </Drawer>
            )}
          </Col>
        </Card>
      </Col>
    );
  }
}


HelpPageDesktop.propTypes = {
  loading: PropTypes.bool.isRequired,
  helpRequests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formRef: PropTypes.shape().isRequired,
  fieldLoading: PropTypes.bool.isRequired,
  selectedHelpRequest: PropTypes.shape(),
  onCloseDrawer: PropTypes.func.isRequired,
  onSelectHelpRequest: PropTypes.func.isRequired,
  onAddComment: PropTypes.func.isRequired,
  handleChangeCommentValue: PropTypes.func.isRequired,
  commentValue: PropTypes.string,
  editHelpRequestStatus: PropTypes.func.isRequired,
  onAddHelpRequest: PropTypes.func.isRequired,
  addHelpRequest: PropTypes.func.isRequired,
};

HelpPageDesktop.defaultProps = {
  selectedHelpRequest: null,
  commentValue: '',
};

export default HelpPageDesktop;
