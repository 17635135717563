/* eslint-disable no-lonely-if */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import { orderBy, get } from 'lodash';
import { notification } from 'antd';
import ClientSessionsPageDesktop from './Desktop/ClientSessionsPageDesktop';
import ClientSessionsPageMobile from './Mobile/ClientSessionsPageMobile';
import { config } from '../../utils/config';
import {
  getClientCurrentSpecialistAndAllSessions, getPaymentURL, editSession, getSpecialistSlots, getSessionURL, editChangeSpecialistRequest,
  clientGroupSessions, editGroupSessionEnroll, getSessionPaymentStatusById, sessionEnroll,
} from '../../utils/http';
import { routes } from '../../utils/routes';
import getIsValidClient from '../../utils/localeStorage/getIsValidClient';
import getUserId from '../../utils/localeStorage/getUserId';
import getTimeZoneDifference from '../../utils/localeStorage/getTimeZoneDifference';


const timeDifference = new Date().getTimezoneOffset();
class ClientSessionsModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
      currentSpecialist: null,
      clientSessions: [],
      isShowSelectCancelOptionModal: false,
      selectCancelOptionModalStep: 0,
      selectedDate: null,
      selectedTime: null,
      slots: [],
      declineComment: '',
      loading: true,
      isStartSession: false,
      joinURL: null,
      isShowPaySessionModal: false,
      currentSession: null,
      isShowNewEnrollModal: false,
      changeSpecialistRequestID: null,
      groupSesions: [],
      selectedGroupSession: null,
      selectCancelOptionModalStepGroup: 0,
      isShowPaySessionModalGroup: false,
      paymentURL: null,
      sessionPaymentStatus: '',
      isShowPromoCodeModal: null,
    };
    this.ping = null;
    this.payPing = null;
  }

  componentDidMount() {
    const { history } = this.props;
    const search = get(history, 'location.search', null);
    if (search) {
      const sessionPaymentId = search.split('&').find((el) => el.split('=')[0] === 'PaymentId')?.split('=')[1];
      this.onCheckIfPayed(sessionPaymentId);
    } else {
      this.getData();
    }
  }

  componentWillUnmount() {
    clearInterval(this.ping);
    this.ping = null;
    clearInterval(this.payPing);
    this.payPing = null;
  }

  getData = () => {
    Promise.all([getClientCurrentSpecialistAndAllSessions(getUserId()), clientGroupSessions(getUserId())])
      .then((response) => this.setState({
        currentSpecialist: response[0].currentSpecialist,
        clientSessions: response[0].sessions,
        loading: false,
        currentSession: response[0].sessions.find((session) => session.statusID === 'paid' || session.statusID === 'toBePaid'),
        changeSpecialistRequestID: response[0].changeSpecialistRequestID,
        groupSesions: response[1].filter((g) => g.statusID !== 'done'),
      }));
  }


  onCheckIfPayed = (sessionPaymentId) => {
    this.payPing = setInterval(() => {
      const { sessionPaymentStatus } = this.state;
      if (sessionPaymentStatus === '') {
        getSessionPaymentStatusById(sessionPaymentId)
          .then((status) => {
            if (status !== '') {
              this.getData();
              this.setState({ sessionPaymentStatus: status });
              clearInterval(this.payPing);
            }
          });
      } else {
        clearInterval(this.payPing);
      }
    }, 3000);
  }

  onPaySessionModal = () => {
    const { currentSession } = this.state;
    if (getIsValidClient() !== 'true') {
      const { history } = this.props;
      history.push(routes.profile);
    } else {
      this.onPaySession(currentSession.cost / 100, '');
      // this.setState({ isShowPromoCodeModal: { isGroup: false, session: currentSession } });
    }
  }

  onPaySession = (cost, promoCode) => {
    const { currentSession } = this.state;
    getPaymentURL({
      session_id: currentSession.id,
      date: moment().toISOString(),
      cost,
      promoCodeInput: promoCode,
    })
      .then((result) => {
        if (cost > 0 && !result.paymentURL && !promoCode) {
          notification.error({ message: 'Ошибка проведения оплаты. Пожалуйста, попробуйте позже' });
        }
        if (cost === 0 || (promoCode && result.getPaymentLinkStatus === 'OK')) {
          window.location.reload();
        } else if (cost > 0 && result.paymentURL) {
          window.open(result.paymentURL, '_self');
        } else if (promoCode && result.getPaymentLinkStatus === 'ERROR') {
          notification.error({ message: 'Ошибка использования промокода' });
        }
      });
  }

  onCancelSession = () => {
    const { currentSession } = this.state;
    if (moment().add(24, 'h').isAfter(currentSession.sessionDateAndTime)) {
      const message = 'Отмена с возвратом возможна за 24 часа.';
      notification.error({ message });
      return;
    }
    this.setState({ isShowSelectCancelOptionModal: true });
  }

  onCloseSelectCancelOptionModal = () => {
    const { selectCancelOptionModalStep } = this.state;
    if (selectCancelOptionModalStep === 2) {
      const { history } = this.props;
      history.push(routes.list);
    } else {
      this.setState({ isShowSelectCancelOptionModal: false, selectCancelOptionModalStep: 0 });
    }
  }

  onCancelSelectOption = (variant) => {
    const { currentSession, currentSpecialist, clientSessions } = this.state;
    const currentSpecialistSessionsNumber = clientSessions.filter((session) => session.statusID === 'done' && session.specialist_id === currentSpecialist.specialist_id).length;
    if (variant === 'old') {
      if (moment().add(24, 'h').isAfter(currentSession.sessionDateAndTime)) {
        const message = 'Перенос встречи возможен не раньше, чем за 24ч до ее начала';
        notification.error({ message });
        return;
      }
      getSpecialistSlots(currentSession.specialist_id)
        .then((slots) => this.setState({
          slots: this.parseSlots(slots.slots, currentSession.beforeEnrollTime),
          selectCancelOptionModalStep: 1,
          isShowSelectCancelOptionModal: true,
        }));
    }
    if (variant === 'new') {
      this.setState({ selectCancelOptionModalStep: 3 });
      // editSession({ id: currentSession.id, declineReason: 'Хочу поменять психолога', status_id: 'canceled' })
      //   .then(() => history.push(routes.cleverList));
    }
    if (variant === 'other') {
      if (currentSession.statusID === 'paid') {
        editSession({ id: currentSession.id, declineReason: 'Хочу полностью отменить консультацию. Уже оплатил', status_id: 'canceled' })
          .then(() => {
            this.setState((prevState) => ({
              selectCancelOptionModalStep: 0,
              isShowSelectCancelOptionModal: false,
              currentSpecialist: currentSpecialistSessionsNumber > 0 ? prevState.currentSpecialist : null,
              clientSessions: prevState.clientSessions.map((s) => (s.id === currentSession.id ? { ...currentSession, status_id: 'canceled' } : s)),
            }));
            notification.success({ message: 'Ваша консультация уже была оплачена, для возврата денег с вами свяжется наш сотрудник' });
          });
      }
      if (currentSession.statusID === 'toBePaid') {
        editSession({ id: currentSession.id, declineReason: 'Хочу полностью отменить консультацию. Еще не оплатил', status_id: 'canceled' })
          .then(() => this.setState((prevState) => ({
            selectCancelOptionModalStep: 2,
            currentSpecialist: currentSpecialistSessionsNumber > 0 ? prevState.currentSpecialist : null,
            clientSessions: prevState.clientSessions.map((s) => (s.id === currentSession.id ? { ...currentSession, status_id: 'canceled' } : s)),
          })));
      }
    }
  }

  parseSlots = (slots, beforeEnrollTime) => {
    const startAvailableDate = moment().add(beforeEnrollTime + 1, 'h').startOf('day');
    const availablePychoSlots = [];
    const filteredSlots = slots.filter((slot) => moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isAfter(startAvailableDate) || moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isSame(startAvailableDate));
    filteredSlots.forEach((slot) => {
      let timesArray = [];
      if (moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isSame(startAvailableDate)) {
        const startAvailableTime = moment().add(beforeEnrollTime + 1, 'h').format('HH:00');
        slot.timesArray.forEach((t) => t >= startAvailableTime && timesArray.push(t));
      } else if (slot.timesArray.length > 0 && slot.timesArray[0] !== '') {
        timesArray = slot.timesArray.sort();
      }
      if (timesArray.length > 0) {
        availablePychoSlots.push({ id: slot.id, date: slot.date, timesArray: timesArray.sort() });
      }
    });
    return orderBy(availablePychoSlots.slice(0, 6), 'date', 'asc');
  }

  onSelect = (selectedTime, selectedDate) => {
    this.setState({ selectedTime, selectedDate });
  }

  onEnroll = () => {
    const { selectedTime, selectedDate, currentSession } = this.state;
    const cleanDate = moment(`${selectedDate.startOf('day').format('YYYY-MM-DD')}T${selectedTime}`, 'YYYY-MM-DDTHH:00').add(getTimeZoneDifference() || timeDifference, 'm').format('YYYY-MM-DDTHH:00');
    editSession({
      id: currentSession.id,
      newSessionDate: cleanDate.split('T')[0],
      newSessionTime: cleanDate.split('T')[1],
      sessionDateAndTime: cleanDate,
    })
      .then(() => {
        notification.success({ message: 'Мы перенесли вашу сессию' });
        this.setState((prevState) => ({
          selectCancelOptionModalStep: 0,
          isShowSelectCancelOptionModal: false,
          selectedDate: null,
          selectedTime: null,
          currentSession: { ...prevState.currentSession, sessionDateAndTime: moment(`${selectedDate.startOf('day').format('DD.MM.YYYY')} ${selectedTime}`, 'DD.MM.YYYY HH:00') },
        }));
      });
  }

  onLeaveDeclineComment = () => {
    const { declineComment, currentSession } = this.state;
    const { history } = this.props;
    editSession({
      id: currentSession.id,
      declineComment,
    })
      .then(() => history.push(routes.list));
  }

  onChangeDeclineComment = (e) => {
    this.setState({ declineComment: e.target.value });
  }

  onSelectOtherOption = (option) => {
    const { currentSession } = this.state;
    const { history } = this.props;
    if (option === 1) {
      editSession({ id: currentSession.id, declineReason: 'Хочу другого', status_id: 'canceled' })
        .then(() => history.push(routes.list));
    }
    if (option === 2) {
      editSession({ id: currentSession.id, declineReason: 'Выберите мне другого', status_id: 'canceled' })
        .then(() => history.push(routes.profile));
    }
  }

  onStartSession = () => {
    this.setState({ isStartSession: true });
    this.ping = setInterval(() => {
      const { currentSession, joinURL } = this.state;
      if (!joinURL) {
        getSessionURL(currentSession.id)
          .then((url) => {
            if (url) {
              window.open(url, '_self');
              this.setState({ joinURL: url });
            }
          });
      } else {
        clearInterval(this.ping);
      }
    }, 5000);
  }

  onFindSpecialist = () => {
    const { history } = this.props;
    history.push(routes.list);
  }

  onFindGroup= () => {
    const { history } = this.props;
    history.push(routes.groupSessions);
  }

  onNewEnroll = () => {
    const { currentSpecialist } = this.state;
    getSpecialistSlots(currentSpecialist.id)
      .then((slots) => this.setState({
        slots: this.parseSlots(slots.slots, currentSpecialist.beforeEnrollTime),
        isShowNewEnrollModal: true,
      }));
  }

  onCloseNewEnrollModal = () => {
    this.setState({ isShowNewEnrollModal: false });
  }

  onConfirmNewEnroll = () => {
    this.setState({ loading: true });
    const { selectedTime, selectedDate, currentSpecialist } = this.state;
    const cleanDate = moment(`${selectedDate.startOf('day').format('YYYY-MM-DD')}T${selectedTime}`, 'YYYY-MM-DDTHH:00').add(getTimeZoneDifference() || timeDifference, 'm').format('YYYY-MM-DDTHH:00');
    sessionEnroll({
      sessionDate: cleanDate.split('T')[0],
      sessionTime: cleanDate.split('T')[1],
      client_id: getUserId(),
      specialist_id: currentSpecialist.id,
      sessionDateAndTime: cleanDate,
    })
      .then((resp) => {
        if (resp.code === '200') {
          window.location.reload();
        } else {
          notification.error({ message: 'Что-то пошло не так, попробуйте позже' });
        }
      });
  }

  onChageSpecialistRequest = () => {
    this.setState({ loading: true });
    const { currentSpecialist } = this.state;
    editChangeSpecialistRequest({ client_id: getUserId(), specialist_id: currentSpecialist.id })
      .then((changeSpecialistRequestID) => this.setState({ changeSpecialistRequestID, loading: false }));
  }

  onShowCancelSessionModalGroup = (selectedGroupSession) => {
    this.setState({ selectCancelOptionModalStepGroup: 1, selectedGroupSession });
  }

  onCloseSelectCancelOptionModalGroup = () => {
    this.setState({ selectCancelOptionModalStepGroup: 0, selectedGroupSession: null, declineComment: null });
  }

  onPaySessionModalGroup = (selectedGroupSession) => {
    if (!selectedGroupSession || selectedGroupSession.cost === 0) {
      this.setState((prevState) => ({
        isShowPaySessionModalGroup: !prevState.isShowPaySessionModalGroup,
        selectedGroupSession,
      }));
    } else {
      // this.setState({ isShowPromoCodeModal: { isGroup: true, session: selectedGroupSession } });
      this.onPaySessionGroup(selectedGroupSession.cost, selectedGroupSession);
    }
  }

  onChangeDeclineCommentGroup = (e) => {
    this.setState({ declineCommentGroup: e.target.value });
  }

  onDeclineSessionGroup = () => {
    const { selectedGroupSession, declineCommentGroup } = this.state;
    editGroupSessionEnroll({ id: selectedGroupSession.id, declineComment: declineCommentGroup, status_id: 'canceled' })
      .then(() => window.location.reload());
  }

  onPaySessionGroup = (cost, groupSession, promoCode) => {
    const { selectedGroupSession } = this.state;
    getPaymentURL({
      groupSessionEnroll_id: selectedGroupSession?.id || groupSession.id,
      date: moment().toISOString(),
      cost,
      promoCodeInput: promoCode,
    })
      .then((result) => {
        if (cost > 0 && !result.paymentURL && !promoCode) {
          notification.error({ message: 'Ошибка проведения оплаты. Пожалуйста, попробуйте позже' });
        } if (cost === 0 || (promoCode && result.getPaymentLinkStatus === 'OK')) {
          window.location.reload();
        } else if (cost > 0 && result.paymentURL) {
          window.open(result.paymentURL, '_self');
        } else if (promoCode && result.getPaymentLinkStatus === 'ERROR') {
          notification.error({ message: 'Ошибка использования промокода' });
        }
      });
  }

  onClosePaymentUrl = () => {
    this.setState({ paymentURL: null });
  }

  onClosePromoCodeModal = () => {
    this.setState({ isShowPromoCodeModal: null });
  }

  render() {
    const { history } = this.props;
    const {
      isMobile, currentSpecialist, isShowSelectCancelOptionModal, selectCancelOptionModalStep, selectedDate, selectedTime, slots, loading,
      isStartSession, joinURL, isShowPaySessionModal, clientSessions, currentSession, isShowNewEnrollModal, changeSpecialistRequestID,
      groupSesions, isShowPaySessionModalGroup, selectCancelOptionModalStepGroup, paymentURL, isShowPromoCodeModal,
    } = this.state;
    if (isMobile) {
      return (
        <ClientSessionsPageMobile
          currentSession={currentSession}
          currentSpecialist={currentSpecialist}
          clientSessions={clientSessions}
          onPaySession={this.onPaySession}
          onCancelSession={this.onCancelSession}
          isShowSelectCancelOptionModal={isShowSelectCancelOptionModal}
          onCloseSelectCancelOptionModal={this.onCloseSelectCancelOptionModal}
          onCancelSelectOption={this.onCancelSelectOption}
          selectCancelOptionModalStep={selectCancelOptionModalStep}
          onSelect={this.onSelect}
          onEnroll={this.onEnroll}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          slots={slots}
          onLeaveDeclineComment={this.onLeaveDeclineComment}
          onChangeDeclineComment={this.onChangeDeclineComment}
          onSelectOtherOption={this.onSelectOtherOption}
          loading={loading}
          onStartSession={this.onStartSession}
          isStartSession={isStartSession}
          joinURL={joinURL}
          isShowPaySessionModal={isShowPaySessionModal}
          onPaySessionModal={this.onPaySessionModal}
          onFindSpecialist={this.onFindSpecialist}
          onNewEnroll={this.onNewEnroll}
          onChageSpecialistRequest={this.onChageSpecialistRequest}
          isShowNewEnrollModal={isShowNewEnrollModal}
          onCloseNewEnrollModal={this.onCloseNewEnrollModal}
          onConfirmNewEnroll={this.onConfirmNewEnroll}
          changeSpecialistRequestID={changeSpecialistRequestID}
          history={history}
          paymentURL={paymentURL}
          onClosePaymentUrl={this.onClosePaymentUrl}
          isShowPromoCodeModal={isShowPromoCodeModal}
          onClosePromoCodeModal={this.onClosePromoCodeModal}
        />
      );
    }
    return (
      <ClientSessionsPageDesktop
        currentSession={currentSession}
        currentSpecialist={currentSpecialist}
        clientSessions={clientSessions}
        onPaySession={this.onPaySession}
        onCancelSession={this.onCancelSession}
        isShowSelectCancelOptionModal={isShowSelectCancelOptionModal}
        onCloseSelectCancelOptionModal={this.onCloseSelectCancelOptionModal}
        onCancelSelectOption={this.onCancelSelectOption}
        selectCancelOptionModalStep={selectCancelOptionModalStep}
        onSelect={this.onSelect}
        onEnroll={this.onEnroll}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        slots={slots}
        onLeaveDeclineComment={this.onLeaveDeclineComment}
        onChangeDeclineComment={this.onChangeDeclineComment}
        onSelectOtherOption={this.onSelectOtherOption}
        loading={loading}
        onStartSession={this.onStartSession}
        isStartSession={isStartSession}
        joinURL={joinURL}
        isShowPaySessionModal={isShowPaySessionModal}
        onPaySessionModal={this.onPaySessionModal}
        onFindSpecialist={this.onFindSpecialist}
        onNewEnroll={this.onNewEnroll}
        onChageSpecialistRequest={this.onChageSpecialistRequest}
        isShowNewEnrollModal={isShowNewEnrollModal}
        onCloseNewEnrollModal={this.onCloseNewEnrollModal}
        onConfirmNewEnroll={this.onConfirmNewEnroll}
        changeSpecialistRequestID={changeSpecialistRequestID}
        groupSesions={groupSesions}
        isShowPaySessionModalGroup={isShowPaySessionModalGroup}
        onPaySessionModalGroup={this.onPaySessionModalGroup}
        onPaySessionGroup={this.onPaySessionGroup}
        selectCancelOptionModalStepGroup={selectCancelOptionModalStepGroup}
        onCloseSelectCancelOptionModalGroup={this.onCloseSelectCancelOptionModalGroup}
        onShowCancelSessionModalGroup={this.onShowCancelSessionModalGroup}
        onChangeDeclineCommentGroup={this.onChangeDeclineCommentGroup}
        onDeclineSessionGroup={this.onDeclineSessionGroup}
        onFindGroup={this.onFindGroup}
        isShowPromoCodeModal={isShowPromoCodeModal}
        onClosePromoCodeModal={this.onClosePromoCodeModal}
      />
    );
  }
}

ClientSessionsModule.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(ClientSessionsModule);
