/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';


const getManualUsersColumns = (onClientClick) => [
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    render: (text, object) => <a onClick={() => onClientClick(object)}>{text}</a>,
  },
  {
    title: 'Клиент',
    dataIndex: 'nickName',
    key: 'nickName',
  },
  {
    title: 'Почта',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'createDate',
    key: 'createDate',
    render: (text) => (text ? text.format('DD.MM.YYYY') : ''),
  },
  {
    title: 'Специалист',
    dataIndex: 'currentSpecialistName',
    key: 'currentSpecialistName',
  },
  {
    title: 'Всего сессий',
    dataIndex: 'numberOfSessions',
    key: 'numberOfSessions',
  },
  {
    title: 'Ручной?',
    dataIndex: 'isManual',
    key: 'isManual',
    render: (text) => (text ? 'Да' : 'Нет'),
  },
];

export default getManualUsersColumns;

