/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { notification } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';
import { get } from 'lodash';
import { config } from '../../utils/config';
import ProfilePageDesktop from './Desktop/ProfilePageDesktop';
import ProfilePageMobile from './Mobile/ProfilePageMobile';
import {
  personalDataChangeRequest, getSpecialistProfile, getLockedFields, getClientProfile, editClientProfile,
  editSpecialistProfile, editUserLogo, editEmailVerificationRequests, checkEmailCode, getTimeZoneCatalogue,
} from '../../utils/http';
import getUserId from '../../utils/localeStorage/getUserId';
import getUserRole from '../../utils/localeStorage/getUserRole';
import getIsValidSpecialist from '../../utils/localeStorage/getIsValidSpecialist';
import * as roles from '../../utils/roles';
import { setLocalStorage as setStorage } from '../../utils/localeStorage/storage';
import { routes } from '../../utils/routes';
import getIsValidClient from '../../utils/localeStorage/getIsValidClient';


class ProfileModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
      user: {},
      loading: true,
      fieldLoading: false,
      lockedFields: [],
      isShowApproveEmailInput: false,
      isLoadingEmailInput: false,
      isValidEmailInput: true,
      gender: '',
      timeZoneCatalogue: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { history } = this.props;
    const search = get(history, 'location.search', null);
    if (search === '?meetings' && getUserRole() === roles.CLIENT && getIsValidClient() === 'true') {
      history.push(routes.meetings);
    }
    Promise.all([getUserRole() === roles.CLIENT ? getClientProfile(getUserId()) : getSpecialistProfile(getUserId()),
      getLockedFields(), getTimeZoneCatalogue()])
      .then((res) => {
        this.setState({
          loading: false,
          user: res[0],
          lockedFields: res[1],
          gender: res[0]?.gender,
          fieldLoading: res[0]?.status === 'onApproval' || res[0]?.status === 'blocked',
          timeZoneCatalogue: res[2],
        });
        if (getIsValidSpecialist() !== 'true' && res[0]?.isValidSpecialist && !!res[0]?.paymentType) {
          setStorage({ isValidSpecialist: true });
          window.location.reload(false);
        }
      });
  }

  onPersonalDataChangeRequest = (values) => {
    personalDataChangeRequest({ ...values, webUser_id: getUserId() });
  }

  onLogoChanges = (info) => {
    this.setState({ fieldLoading: true });
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const form = new FormData();
    form.append('id', getUserId());
    form.append('logo_id', info.file.originFileObj);
    editUserLogo(form, headers)
      .then((logoURL) => {
        setStorage({ logoURL });
        this.setState({ fieldLoading: false });
      })
      .catch(() => {
        this.setState({ fieldLoading: false });
      });
  }

  onSaveForm = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        const { timeZoneCatalogue } = this.state;
        const timeZoneTimeDifference = timeZoneCatalogue.find((t) => t.id === values.timeZone).timeDifference;
        this.setState({ fieldLoading: true });
        // eslint-disable-next-line prefer-destructuring
        const email = this.state.user.email;
        // eslint-disable-next-line prefer-destructuring
        let isEmailVerified = this.state.user.isEmailVerified;
        if (email !== values.email) {
          isEmailVerified = false;
        }
        const isValidClient = values.nickName !== 'Аноним' && isEmailVerified;
        editClientProfile({
          id: getUserId(), ...values, isValidClient, isEmailVerified,
        })
          .then((user) => {
            notification.success({ message: 'Успешно сохранено' });
            this.setState({
              fieldLoading: false,
              user,
            });
            setStorage({ isValidClient, nickName: values.nickName, timeZoneTimeDifference });
            window.location.reload(false);
          });
      })
      .catch(() => notification.error({ message: 'Не сохранено. Заполните обязательные поля' }));
  }

  onRedirect = (route) => {
    this.formRef.current.validateFields()
      .then((values) => {
        this.setState({ fieldLoading: true });
        // eslint-disable-next-line prefer-destructuring
        const email = this.state.user.email;
        // eslint-disable-next-line prefer-destructuring
        let isEmailVerified = this.state.user.isEmailVerified;
        if (email !== values.email) {
          isEmailVerified = false;
        }
        editClientProfile({
          id: getUserId(), ...values, isValidClient: true, isEmailVerified,
        })
          .then((user) => {
            this.setState({
              fieldLoading: false,
              user,
            });
            setStorage({ isValidClient: true, nickName: values.nickName });
            const { history } = this.props;
            history.push(route);
          });
      })
      .catch(() => notification.error({ message: 'Не сохранено. Заполните обязательные поля' }));
  }

  onRedirectMobile = (route) => {
    const { history } = this.props;
    history.push(route);
  }

  onSendEmailVerificationCode = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        const EmailMask = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (EmailMask.test(values.email)) {
          editEmailVerificationRequests({ user_id: getUserId(), date: moment().toISOString(), email: values.email })
            .then(() => {
              this.setState({ isShowApproveEmailInput: true });
              notification.success({ message: 'Код отправлен на указанную почту' });
            });
        } else {
          notification.error({ message: 'Введите корректный email!' });
        }
      });
  }

  onCloseShowApproveEmailInput = () => {
    this.setState({ isShowApproveEmailInput: false });
  }

  onFinishApproveEmailInput = (userInputEmailVerificationCode) => {
    const { user } = this.state;
    this.setState({ isLoadingEmailInput: true });
    checkEmailCode({ id: user.id, userInputEmailVerificationCode })
      .then((isEmailVerified) => {
        if (isEmailVerified) {
          notification.success({ message: 'Спасибо!' });
          this.formRef.current.validateFields()
            .then((values) => {
              if (getUserRole() === roles.CLIENT) {
                editClientProfile({
                  id: getUserId(), isEmailVerified, email: values.email,
                })
                  .then(() => {
                    this.setState({
                      isLoadingEmailInput: false,
                      isShowApproveEmailInput: false,
                      user: { ...user, isEmailVerified: true, email: values.email },
                    });
                    this.onSaveForm();
                  });
              } else {
                editSpecialistProfile({
                  id: getUserId(), isEmailVerified, email: values.email,
                })
                  .then(() => {
                    this.setState({
                      isLoadingEmailInput: false,
                      isShowApproveEmailInput: false,
                      user: { ...user, isEmailVerified: true, email: values.email },
                    });
                    this.onSaveForm();
                  });
              }
            });
        } else {
          notification.error({ message: 'Неправильный код!' });
          this.setState({ isLoadingEmailInput: false });
        }
      });
  }

  onChangeEmailInput = () => {
    this.setState({ isValidEmailInput: true });
  }

  onValueChange = (field, value) => {
    if (field === 'gender') {
      this.setState({ gender: value });
    }
  }

  render() {
    const {
      isMobile, loading, user, lockedFields, fieldLoading, isShowApproveEmailInput, isLoadingEmailInput, isValidEmailInput,
      gender, timeZoneCatalogue,
    } = this.state;
    const { history } = this.props;
    if (isMobile) {
      return (
        <ProfilePageMobile
          loading={loading}
          user={user}
          onSaveForm={this.onSaveForm}
          onRedirect={this.onRedirectMobile}
          history={history}
          timeZoneCatalogue={timeZoneCatalogue}
        />
      );
    }
    return (
      <ProfilePageDesktop
        loading={loading}
        onPersonalDataChangeRequest={this.onPersonalDataChangeRequest}
        user={user}
        lockedFieldsClient={(lockedFields || []).filter((f) => f.isClient)}
        fieldLoading={fieldLoading}
        onSaveForm={this.onSaveForm}
        formRef={this.formRef}
        onRedirect={this.onRedirect}
        onSendEmailVerificationCode={this.onSendEmailVerificationCode}
        isShowApproveEmailInput={isShowApproveEmailInput}
        onCloseShowApproveEmailInput={this.onCloseShowApproveEmailInput}
        onFinishApproveEmailInput={this.onFinishApproveEmailInput}
        isLoadingEmailInput={isLoadingEmailInput}
        isValidEmailInput={isValidEmailInput}
        onChangeEmailInput={this.onChangeEmailInput}
        gender={gender}
        onValueChange={this.onValueChange}
        timeZoneCatalogue={timeZoneCatalogue}
      />
    );
  }
}

ProfileModule.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(ProfileModule);
