/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import './ProfileSpecialist.css';
import { editSpecialistProfile, addPaymentInfo, checkCard } from '../../../../utils/http';
import getUserId from '../../../../utils/localeStorage/getUserId';
import PaymentField from './PaymentField';
import editSvg from '../../../../assets/icons/edit.svg';
import { setLocalStorage as setStorage } from '../../../../utils/localeStorage/storage';


class ProfileSpecialist extends Component {
  state = {
    fieldInEdit: null,
    fieldInEditValue: null,
    user: this.props.user,
    view: 'common',
    paymentType: this.props.user.paymentType,
    paymentTypeView: 'legal',
  }


  onSaveClick = () => {
    const { fieldInEdit, fieldInEditValue } = this.state;
    const { timeZoneCatalogue } = this.props;
    let timeZoneTimeDifference = null;
    if (fieldInEdit === 'timeZone') {
      timeZoneTimeDifference = timeZoneCatalogue.find((t) => t.id === fieldInEditValue).timeDifference;
    }
    editSpecialistProfile({ id: getUserId(), [fieldInEdit]: fieldInEditValue })
      .then(() => {
        notification.success({ message: 'Успешно сохранено' });
        if (fieldInEdit === 'timeZone') {
          setStorage({ timeZoneTimeDifference });
          this.setState((prevState) => ({
            fieldInEdit: false,
            fieldInEditValue: false,
            user: {
              ...prevState.user,
              [fieldInEdit]: fieldInEditValue,
              timeZoneValue: timeZoneCatalogue.find((t) => t.id === fieldInEditValue).value,
            },
          }));
        } else {
          this.setState((prevState) => ({
            fieldInEdit: false,
            fieldInEditValue: false,
            user: {
              ...prevState.user,
              [fieldInEdit]: fieldInEditValue,
            },
          }));
        }
      });
  }

  onAddPaymentInfo = (paymentType) => {
    addPaymentInfo({ id: getUserId(), paymentType })
      .then((result) => {
        if (paymentType === 'physical' && result.addCardUrl) {
          window.open(result.addCardUrl);
          editSpecialistProfile({ id: getUserId(), paymentType: '' });
        } if (paymentType === 'legal' && result.shopCode) {
          notification.success({ message: 'Точка зарегистрирована' });
          this.setState((prevState) => ({ user: { ...prevState.user, isLegalPaymentValid: true } }));
          this.onChangePaymentType('legal');
        } else {
          editSpecialistProfile({ id: getUserId(), paymentType: '' });
          notification.error({ message: 'Что-то пошло нет. Попробуйте позже' });
        }
      });
  }

  onChangePaymentType = (paymentType) => {
    this.setState({ paymentType });
    editSpecialistProfile({ id: getUserId(), paymentType })
      .then(() => this.setState((prevState) => ({ paymentType, user: { ...prevState.user, paymentType } })));
  }

  onCheckCard = () => {
    checkCard({ id: getUserId() })
      .then((cardPan) => {
        if (cardPan) {
          notification.success({ message: 'Карта привязана' });
          this.setState((prevState) => ({ user: { ...prevState.user, isPhysicalPaymentValid: true, cardPan } }));
          this.onChangePaymentType('physical');
        } else {
          notification.error({ message: 'Мы не смогли найти вашу карту' });
        }
      });
  }

  onEditFieldClick = (fieldInEdit, fieldInEditValue) => {
    this.setState({ fieldInEdit, fieldInEditValue });
  }

  onCancelFieldClick = () => {
    this.setState({ fieldInEdit: null, fieldInEditValue: null });
  }

  onChangeFieldValue = (fieldInEditValue) => {
    this.setState({ fieldInEditValue });
  }

  render() {
    const {
      fieldInEdit, user, view, paymentType, paymentTypeView,
    } = this.state;
    const { timeZoneCatalogue } = this.props;
    const addressesTypeCatalogue = [
      { id: 'legal', value: 'юридический' },
      { id: 'actual', value: 'фактический' },
      { id: 'post', value: 'почтовый' },
      { id: 'other', value: 'прочий' }];
    const addressesCountryCatalogue = [{ id: 'RUS', value: 'Россия' }];
    if (!user) {
      return null;
    }
    return (
      <div className="profileSpecialist-mobile-wrapper">
        <div className="profileSpecialist-mobile-header-wrapper">
          <div className="profileSpecialist-mobile-header-row">
            <div className="profileSpecialist-mobile-header-logo" />
            <div className="profileSpecialist-mobile-header-name">{user.firstName ? `${user.lastName} ${user.firstName} ${user.middleName}` : 'Фамилия Имя Отчество'}</div>
          </div>
          <div className="clientSessionsPageMobile-header-row" style={{ padding: '0px 30px 30px', justifyContent: 'space-between' }}>
            <div onClick={() => this.setState({ view: 'common' })} className={view === 'common' ? 'profileSpecialist-mobile-body-button-primary' : 'profileSpecialist-mobile-body-button-default'}>Информация</div>
            <div onClick={() => this.setState({ view: 'anketa' })} className={view === 'anketa' ? 'profileSpecialist-mobile-body-button-primary' : 'profileSpecialist-mobile-body-button-default'}>Анкета</div>
            <div onClick={() => this.setState({ view: 'payment' })} className={view === 'payment' ? 'profileSpecialist-mobile-body-button-primary' : 'profileSpecialist-mobile-body-button-default'}>Реквизиты</div>
          </div>
        </div>
        {view === 'common' && (
        <div className="profileSpecialist-mobile-body-wrapper">
          <div className="profileSpecialist-mobile-body-row">
            <div className="profileSpecialist-mobile-body-row-label">Дата рождения:</div>
            <div className="profileSpecialist-mobile-body-row-value-wrapper">
              <div className="profileSpecialist-mobile-body-row-value">{user.birthdayDate ? user.birthdayDate.format('DD MMMM YYYY') : ''}</div>
            </div>
          </div>
          <div className="profileSpecialist-mobile-body-row">
            <div className="profileSpecialist-mobile-body-row-label">Пол:</div>
            <div className="profileSpecialist-mobile-body-row-value-wrapper">
              <div className="profileSpecialist-mobile-body-row-value">{user.gender}</div>
            </div>
          </div>
          <div className="profileSpecialist-mobile-body-col">
            <div className="profileSpecialist-mobile-body-col-label">
              <span>Клиентам о себе:</span>
              {/* {!fieldInEdit && <img src={editSvg} className="profileSpecialist-mobile-header-edit" alt="edit" onClick={() => this.setState({ fieldInEdit: 'descriptionClients', fieldInEditValue: user.descriptionClients })} />} */}
            </div>
            <div className="profileSpecialist-mobile-textarea profileSpecialist-mobile-body-row-value-wrapper">
              {fieldInEdit !== 'descriptionClients' && <div dangerouslySetInnerHTML={{ __html: user.descriptionClients }} />}
              {fieldInEdit === 'descriptionClients'
                && (
                  <textarea rows={4} style={{ width: '100%', backgroundColor: '#fff' }} defaultValue={user.descriptionClients} onChange={(e) => this.setState({ fieldInEditValue: e.target.value })} />
                )}
            </div>
          </div>
          <div className="profileSpecialist-mobile-body-row">
            <div className="profileSpecialist-mobile-body-row-label">Часовой пояс:</div>
            <div className="profileSpecialist-mobile-body-row-value-wrapper">
              {fieldInEdit !== 'timeZone' && <div className="profileSpecialist-mobile-body-row-value">{user.timeZoneValue}</div>}
              {!fieldInEdit && <img src={editSvg} className="profileSpecialist-mobile-header-edit" alt="edit" onClick={() => this.setState({ fieldInEdit: 'timeZone', fieldInEditValue: user.timeZone })} />}
              {fieldInEdit === 'timeZone'
                && (
                  <select defaultValue={user.timeZone} className="profileSpecialist-mobile-select" onChange={(e) => this.setState({ fieldInEditValue: e.target.value })}>
                    {timeZoneCatalogue.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.value}
                      </option>
                    ))}
                  </select>
                )}
            </div>
          </div>
          <h2 className="profileSpecialist-mobile-body-h2">Контактная информация</h2>
          <div className="profileSpecialist-mobile-body-row">
            <div className="profileSpecialist-mobile-body-row-label">Почта:</div>
            <div className="profileSpecialist-mobile-body-row-value-wrapper">
              <div className="profileSpecialist-mobile-body-row-value">{user.email}</div>
            </div>
          </div>
          <div className="profileSpecialist-mobile-body-row">
            <div className="profileSpecialist-mobile-body-row-label">Телефон:</div>
            <div className="profileSpecialist-mobile-body-row-value-wrapper">
              <div className="profileSpecialist-mobile-body-row-value">{user.phone}</div>
            </div>
          </div>
          {fieldInEdit && (
          <div className="profileSpecialist-mobile-body-row" style={{ marginTop: '30px' }}>
            <div className="btn-default btn_red" onClick={this.onSaveClick}>Сохранить</div>
            <div className="btn-default btn_white" onClick={() => this.setState({ fieldInEdit: null, fieldInEditValue: null })}>Отменить</div>
          </div>
          )}
        </div>
        )}
        {view === 'anketa' && (
          <div style={{ display: 'flex', flexDirection: 'column' }} className="profileSpecialist-mobile-body-wrapper">

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="profilePageDesktop-title">Образование</div>
              <div className="profile-input-block">
                <div className="profileLabel">Ваше основное образование</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.mainEducation || 'Не заполнено'}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Ваше дополнительное образование</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.secondaryEducation || 'Не заполнено'}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Фотографии развернутых дипломов и сертификатов</div>
                <div>
                  {user.educationFiles.map((f) => (
                    <div key={f.id}>
                      <div><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></div>
                      <div>{f.originalFileName}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="profilePageDesktop-title">Опыт работы</div>
              <div className="profile-input-block">
                <div className="profileLabel">Опыт работы с LGBTQ+ людьми</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.isLGBTQ ? 'Да' : 'Нет'}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Сколько полных лет вы консультируете?</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.experience || 'Не заполнено'}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Есть ли опыт работы онлайн? Если да, то сколько лет?</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.experienceOnline || 'Не заполнено'}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="profilePageDesktop-title profilePageDesktop-title_mini">Супервизия и Терапия</div>
              <div className="profilePageDesktop-subtitle">Супервизия</div>
              <div className="profile-input-block">
                <div className="profileLabel">Проходите ли вы супервизию</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.supervision}
                </div>
              </div>
              {user.supervision !== 'Нет' && (
                <div className="profile-input-block">
                  <div className="profileLabel">Начало прохождения супервизии</div>
                  <div className="profileInput" style={{ width: 'initial' }}>
                    {user.supervisionStartDate ? user.supervisionStartDate.format('MMMM YYYY') : ''}
                  </div>
                </div>
              )}
              {user.supervision !== 'Нет' && (
                <div className="profile-input-block">
                  <div className="profileLabel">Сколько полных лет супервизии пройдено</div>
                  <div className="profileInput" style={{ width: 'initial' }}>
                    {user.supervisionTime}
                  </div>
                </div>
              )}
              {user.supervision !== 'Нет' && (
                <div className="profile-input-block">
                  <div className="profileLabel">Периодичность</div>
                  <div className="profileInput" style={{ width: 'initial' }}>
                    {user.supervisionPeriod}
                  </div>
                </div>
              )}
              {user.supervision !== 'Нет' && (
                <div className="profile-input-block">
                  <div className="profileLabel">Формат</div>
                  <div className="profileInput" style={{ width: 'initial' }}>
                    {user.supervisionFormat}
                  </div>
                </div>
              )}
              {user.supervision !== 'Нет' && (
                <div className="profile-input-block">
                  <div className="profileLabel">Супервизор (последний)</div>
                  <div className="profileInput" style={{ width: 'initial' }}>
                    {user.supervisionSpecialist}
                  </div>
                </div>
              )}
              <div className="profilePageDesktop-subtitle">Терапия</div>
              <div className="profile-input-block">
                <div className="profileLabel">Проходите ли вы терапию</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.therapy}
                </div>
              </div>
              {user.therapy !== 'Нет' && (
                <div className="profile-input-block">
                  <div className="profileLabel">Объем пройденной терапии (в часах)</div>
                  <div className="profileInput" style={{ width: 'initial' }}>
                    {user.therapyTime}
                  </div>
                </div>
              )}
              {user.therapy !== 'Нет' && (
                <div className="profile-input-block">
                  <div className="profileLabel">Периодичность</div>
                  <div className="profileInput" style={{ width: 'initial' }}>
                    {user.therapyPeriod}
                  </div>
                </div>
              )}
              {user.therapy !== 'Нет' && (
                <div className="profile-input-block">
                  <div className="profileLabel">Формат</div>
                  <div className="profileInput" style={{ width: 'initial' }}>
                    {user.therapyFormat}
                  </div>
                </div>
              )}
              {user.therapy !== 'Нет' && (
                <div className="profile-input-block">
                  <div className="profileLabel">В каком подходе вы проходите терапию?</div>
                  <div className="profileInput" style={{ width: 'initial' }}>
                    {user.therapyApproach}
                  </div>
                </div>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="profilePageDesktop-title">Консультации</div>
              <div className="profile-input-block">
                <div className="profileLabel">Можно записаться за (часы)</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.beforeEnrollTime}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">На каких языках можете консультировать?</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.languagesCatalogueValues.join(', ')}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Желаемая стоимость приема</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.sessionPrice}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Работаете ли вы с парами?</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.isGroupSession ? 'Да' : 'Нет'}
                </div>
              </div>
              {user.isGroupSession && (
                <div className="profile-input-block">
                  <div className="profileLabel">Желаемая стоимость приема за работу с парами</div>
                  <div className="profileInput" style={{ width: 'initial' }}>
                    {user.groupSessionPrice}
                  </div>
                </div>
              )}
              <div className="profile-input-block">
                <div className="profileLabel">Работаю с детьми и подростками</div>
                <div className="profileInput" style={{ width: 'initial' }}>
                  {user.isWorkWithChildren ? 'Да' : 'Нет'}
                </div>
              </div>
            </div>
          </div>
        )}
        {view === 'payment'
        && (
        <div className="profileSpecialist-mobile-body-wrapper">
          <div className="profileSpecialist-mobile-body-row" style={{ justifyContent: 'flex-start' }}>
            <div
              onClick={() => this.setState({ paymentTypeView: 'physical' })}
              className={paymentTypeView === 'physical' ? 'profileSpecialist-mobile-paymentType-radio-green' : 'profileSpecialist-mobile-paymentType-radio-transparent'}
              style={{ borderRadius: '20px 0px 0px 20px' }}
            >
              Физ. лицо
            </div>
            <div
              onClick={() => this.setState({ paymentTypeView: 'legal' })}
              className={paymentTypeView === 'legal' ? 'profileSpecialist-mobile-paymentType-radio-green' : 'profileSpecialist-mobile-paymentType-radio-transparent'}
              style={{ borderRadius: '00px 20px 20px 0px' }}
            >
              Инд. пред
            </div>
          </div>
          {paymentTypeView === 'physical' && (
          <div>
            <div className="profileSpecialist-mobile-body-row" style={{ justifyContent: 'flex-start' }}>
              <span>Активировать</span>
              <label className="switch">
                <input type="checkbox" checked={paymentType === 'physical'} onChange={(e) => this.onChangePaymentType(e.target.checked ? 'physical' : '')} />
                <span className="slider round" />
              </label>
            </div>
            {!user.isPhysicalPaymentValid && (
            <div className="payment__link-block">
              <div className="btn-default btn_green" type="primary" onClick={() => this.onAddPaymentInfo('physical')}>Привязать карту</div>
              <div className="btn-default btn_transparent" onClick={this.onCheckCard}>Карта привязана</div>
            </div>
            )}
            {!user.isPhysicalPaymentValid && (
            <div className="payment__help-text">
              <p>После ввода данных карты, нажмите кнопку «Карта привязана» для проверки введенной информации.</p>
              <p>Если ошибок нет, то карта активируется и профиль будет разблокирован. Теперь вы можете заполнять свой календарь.</p>
            </div>
            )}
            {user.isPhysicalPaymentValid && (
            <div gutter={20} style={{ marginTop: '20px' }}>
              <div span={8} style={{ fontSize: '1.5vh' }}>Привязанная карта</div>
              <div span={16}>
                <div style={{ fontSize: '1.7vh', color: '#212121' }} gutter={8} wrap={false}>
                  <div style={{ whiteSpace: 'break-spaces' }} flex="auto">
                    {user.cardPan}
                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
          )}
          {paymentTypeView === 'legal' && (
            <div>
              <div className="payment-block__title">Общая информация</div>
              {[
                {
                  fieldLabel: 'Название организации на иностранном языке:',
                  fieldName: 'billingDescriptor',
                  inputType: 'input',
                },
                {
                  fieldLabel: 'Полное наименование организации:',
                  fieldName: 'fullName',
                  inputType: 'input',
                },
                {
                  fieldLabel: 'Сокращенное наименование:',
                  fieldName: 'name',
                  inputType: 'input',
                },
                {
                  fieldLabel: 'ИНН:',
                  fieldName: 'inn',
                  inputType: 'input',
                },
                {
                  fieldLabel: 'КПП:',
                  fieldName: 'kpp',
                  inputType: 'input',
                },
                {
                  fieldLabel: 'ОГРН:',
                  fieldName: 'ogrn',
                  inputType: 'input',
                },
                {
                  fieldLabel: 'E-mail:',
                  fieldName: 'paymentEmail',
                  inputType: 'input',
                },
              ].map((f) => (
                <PaymentField
                  key={f.fieldName}
                  fieldLabel={f.fieldLabel}
                  fieldName={f.fieldName}
                  fieldValue={user[f.fieldName]}
                  fieldInEdit={fieldInEdit}
                  onEditFieldClick={this.onEditFieldClick}
                  onSaveFieldClick={this.onSaveClick}
                  onCancelFieldClick={this.onCancelFieldClick}
                  onChangeFieldValue={this.onChangeFieldValue}
                  inputType={f.inputType}
                />
              ))}

              <div className="payment-block__title">Адрес регистрации юр. лица</div>
              {[
                {
                  fieldLabel: 'Тип адреса:',
                  fieldName: 'addressesType',
                  inputType: 'select',
                  catalogue: addressesTypeCatalogue,
                },
                {
                  fieldLabel: 'Индекс:',
                  fieldName: 'addressesZip',
                  inputType: 'input',
                },
                {
                  fieldLabel: 'Страна:',
                  fieldName: 'addressesCountry',
                  inputType: 'select',
                  catalogue: addressesCountryCatalogue,
                },
                {
                  fieldLabel: 'Город:',
                  fieldName: 'addressesCity',
                  inputType: 'input',
                },
                {
                  fieldLabel: 'Улица и дом:',
                  fieldName: 'addressesStreet',
                  inputType: 'input',
                },
              ].map((f) => (
                <PaymentField
                  key={f.fieldName}
                  fieldLabel={f.fieldLabel}
                  fieldName={f.fieldName}
                  fieldValue={user[f.fieldName]}
                  fieldInEdit={fieldInEdit}
                  onEditFieldClick={this.onEditFieldClick}
                  onSaveFieldClick={this.onSaveClick}
                  onCancelFieldClick={this.onCancelFieldClick}
                  onChangeFieldValue={this.onChangeFieldValue}
                  inputType={f.inputType}
                  catalogue={f.catalogue}
                />
              ))}

              <div className="payment-block__title">Сведения о руководителе</div>
              {[{
                fieldLabel: 'Фамилия:',
                fieldName: 'ceoLastName',
                inputType: 'input',
              },
              {
                fieldLabel: 'Имя:',
                fieldName: 'ceoFirstName',
                inputType: 'input',
              },
              {
                fieldLabel: 'Отчество:',
                fieldName: 'ceoMiddleName',
                inputType: 'input',
              },
              {
                fieldLabel: 'Дата рождения:',
                fieldName: 'ceoBirthDate',
                inputType: 'datePicker',
              },
              {
                fieldLabel: 'Телефон:',
                fieldName: 'ceoPhone',
                inputType: 'input',
              },
              {
                fieldLabel: 'Страна:',
                fieldName: 'ceoCountry',
                inputType: 'select',
                catalogue: addressesCountryCatalogue,
              },
              ].map((f) => (
                <PaymentField
                  key={f.fieldName}
                  fieldLabel={f.fieldLabel}
                  fieldName={f.fieldName}
                  fieldValue={user[f.fieldName]}
                  fieldInEdit={fieldInEdit}
                  onEditFieldClick={this.onEditFieldClick}
                  onSaveFieldClick={this.onSaveClick}
                  onCancelFieldClick={this.onCancelFieldClick}
                  onChangeFieldValue={this.onChangeFieldValue}
                  inputType={f.inputType}
                  catalogue={f.catalogue}
                />
              ))}

              <div className="payment-block__title">Реквизиты счета</div>
              {[{
                fieldLabel: 'Расчетный счет:',
                fieldName: 'bankAccountAccount',
                inputType: 'input',
              },
              {
                fieldLabel: 'Наименование банка:',
                fieldName: 'bankAccountBankName',
                inputType: 'input',
              },
              {
                fieldLabel: 'БИК банка:',
                fieldName: 'bankAccountBik',
                inputType: 'input',
              },
              {
                fieldLabel: 'Назначение платежа:',
                fieldName: 'bankAccountDetails',
                inputType: 'input',
              },
              ].map((f) => (
                <PaymentField
                  key={f.fieldName}
                  fieldLabel={f.fieldLabel}
                  fieldName={f.fieldName}
                  fieldValue={user[f.fieldName]}
                  fieldInEdit={fieldInEdit}
                  onEditFieldClick={this.onEditFieldClick}
                  onSaveFieldClick={this.onSaveClick}
                  onCancelFieldClick={this.onCancelFieldClick}
                  onChangeFieldValue={this.onChangeFieldValue}
                  inputType={f.inputType}
                  catalogue={f.catalogue}
                />
              ))}

            </div>
          )}
        </div>
        )}
      </div>
    );
  }
}

ProfileSpecialist.propTypes = {
  user: PropTypes.shape().isRequired,
  timeZoneCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};


export default ProfileSpecialist;
