import React from 'react';
import { Button, Popconfirm } from 'antd';


const getChangeSpecialistRequestColumns = (onChange) => [
  {
    title: 'Дата заявки',
    dataIndex: 'date',
    key: 'date',
    render: (text) => (text ? text.format('DD MMMM YYYY') : ''),
  },
  {
    title: 'Номер клиента',
    dataIndex: 'clientPhone',
    key: 'clientPhone',
  },
  {
    title: 'Ник клиента',
    dataIndex: 'clientName',
    key: 'clientName',
  },
  {
    title: 'Номер психолога',
    dataIndex: 'specialistPhone',
    key: 'specialistPhone',
  },
  {
    title: 'ФИО психолога',
    dataIndex: 'specialistName',
    key: 'specialistName',
  },
  {
    title: 'Причина отмены',
    dataIndex: 'comment',
    key: 'comment',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    render: (text, object) => (object.status === 'Новый' ? (
      <Popconfirm
        title="Вы уверены, что хотите отвязать специалиста?"
        onConfirm={() => onChange(object.id)}
        okText="Да"
        cancelText="Нет"
      >
        <Button type="link">Отвязать</Button>
      </Popconfirm>
    ) : ''),
  },
];

export default getChangeSpecialistRequestColumns;

