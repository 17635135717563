import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as roles from '../../../utils/roles';
import getUserRole from '../../../utils/localeStorage/getUserRole';
import ProfileClient from './components/ProfileClient';
import ProfileSpecialist from './components/ProfileSpecialist';
import Anketa from '../../../components/Anketa/Anketa';
import './ProfilePageDesktop.css';


class ProfilePageDesktop extends Component {
  render() {
    const {
      onPersonalDataChangeRequest, user, lockedFieldsClient, isShowApproveEmailInput,
      loading, fieldLoading, onSaveForm, formRef, onCloseShowApproveEmailInput, onChangeEmailInput,
      onRedirect, isLoadingEmailInput, onSendEmailVerificationCode, onFinishApproveEmailInput, isValidEmailInput,
      gender, onValueChange, timeZoneCatalogue,
    } = this.props;
    if (loading) {
      return (
        <div className="profilePageDesktop-wrapper" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
        </div>
      );
    }
    return (
      <div className="profilePageDesktop_center">
        {getUserRole() === roles.CLIENT
        && (
        <ProfileClient
          onPersonalDataChangeRequest={onPersonalDataChangeRequest}
          user={user}
          lockedFields={lockedFieldsClient}
          fieldLoading={fieldLoading}
          onSaveForm={onSaveForm}
          formRef={formRef}
          onRedirect={onRedirect}
          onSendEmailVerificationCode={onSendEmailVerificationCode}
          isShowApproveEmailInput={isShowApproveEmailInput}
          onCloseShowApproveEmailInput={onCloseShowApproveEmailInput}
          onFinishApproveEmailInput={onFinishApproveEmailInput}
          isLoadingEmailInput={isLoadingEmailInput}
          isValidEmailInput={isValidEmailInput}
          onChangeEmailInput={onChangeEmailInput}
          gender={gender}
          onValueChange={onValueChange}
        />
        )}
        {getUserRole() === roles.SPECIALIST && user.status === 'approved' && (
        <ProfileSpecialist timeZoneCatalogue={timeZoneCatalogue} />
        )}
        {getUserRole() === roles.SPECIALIST && user.status !== 'approved' && (
        <Anketa />
        )}
      </div>
    );
  }
}


ProfilePageDesktop.propTypes = {
  onPersonalDataChangeRequest: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  lockedFieldsClient: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fieldLoading: PropTypes.bool.isRequired,
  onSaveForm: PropTypes.func.isRequired,
  formRef: PropTypes.shape().isRequired,
  onRedirect: PropTypes.func.isRequired,
  onSendEmailVerificationCode: PropTypes.func.isRequired,
  isShowApproveEmailInput: PropTypes.bool.isRequired,
  onCloseShowApproveEmailInput: PropTypes.func.isRequired,
  onFinishApproveEmailInput: PropTypes.func.isRequired,
  isLoadingEmailInput: PropTypes.bool.isRequired,
  isValidEmailInput: PropTypes.bool.isRequired,
  onChangeEmailInput: PropTypes.func.isRequired,
  gender: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  timeZoneCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ProfilePageDesktop;
