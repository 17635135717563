/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import {
  Col, Row, Card, Tabs, Badge, List, Button, Skeleton, Avatar, Modal, InputNumber, Popconfirm, Input,
} from 'antd';
import './ClientGroupSessionsPageDesktop.css';
import getSessionId from '../../../utils/localeStorage/getSessionId';


const { TabPane } = Tabs;
const { TextArea } = Input;
class ClientGroupSessionsPageDesktop extends Component {
  state = {
    cost: 0,
  }

  render() {
    const {
      initLoading, loading, list, onLoadMore, isShowLoadMore, onEnroll, clientSessions, isShowPaySessionModal, onChangeDeclineComment,
      onPaySessionModal, onPaySession, selectCancelOptionModalStep, onCloseSelectCancelOptionModal, onShowCancelSessionModal,
      onDeclineSession, currentTab, changeTab, doneSessions,
    } = this.props;
    const { cost } = this.state;
    return (
      <Col className="profilePageDesktop_center wrapper__clientsession">
        <Card>
          <Tabs activeKey={currentTab} onChange={changeTab}>
            <TabPane tab="Доступные" key="1">
              <List
                className="demo-loadmore-list group-session__list"
                loading={initLoading || loading}
                itemLayout="horizontal"
                loadMore={isShowLoadMore && !initLoading && !loading ? (
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: 12,
                      height: 32,
                      lineHeight: '32px',
                    }}
                  >
                    <Button className="btn-default btn_link btn_link_dotted" onClick={onLoadMore}>Загрузить еще</Button>
                  </div>
                ) : null}
                dataSource={orderBy(list, 'startDateAndTime', 'asc')}
                renderItem={(item) => (
                  <List.Item
                    className="group-session__item"
                    actions={[
                      <div className="group-session__item__footer">
                        {item.id && (
                        <div className="group-session__item__foot-left">
                          <div className="group-session__item__count-title">Записалось:</div>
                          <div className="group-session__item__count">{`${item.groupSessionEnroll.length}/${item.maxNumberOfClients}`}</div>
                        </div>
                        )}
                        {!getSessionId() && item.id && (
                          <a className="btn-default btn_red" key="list-loadmore-edit" onClick={() => onEnroll(item.id)}>Присоединиться к группе</a>
                        )}
                        {getSessionId() && item.id && (
                        <Popconfirm
                          className="group-session__item__foot-right"
                          title="Вы уверены, что хотите записаться?"
                          onConfirm={() => onEnroll(item.id)}
                          okText="Да"
                          cancelText="Нет"
                        >
                          <a className="btn-default btn_red" key="list-loadmore-edit">Присоединиться к группе</a>
                        </Popconfirm>
                        )}
                      </div>,
                    ]}
                  >
                    <Skeleton avatar title={false} loading={item.loading} active>
                      <List.Item.Meta
                        avatar={(
                          <div className="group-session__item__header">
                            <Avatar src={item.specialist.logoURL} shape="square" />
                            <div className="group-session__item__trainer">
                              <div className="group-session__item__trainer-name">
                                <span>{`${item.specialist.lastName} ${item.specialist.firstName}`}</span>
                                {` ${item.specialist.middleName}`}
                              </div>
                              <div className="group-session__item__trainer-title">Ведущий</div>
                            </div>
                            <div className="group-session__item__date">
                              <div className="group-session__item__day">{item.startDateAndTime && item.startDateAndTime.format('DD.MM.YY')}</div>
                              <div className="group-session__item__hours">{item.startDateAndTime && item.startDateAndTime.format('HH:mm')}</div>
                            </div>
                          </div>
                        )}
                        title={(
                          <div className="group-session__item__description">
                            <div className="group-session__item__title">{item.name}</div>
                            <div className="group-session__item__text">{item.description}</div>
                          </div>
                        )}
                        description={(
                          <div className="group-session__item__param">
                            <div className="group-session__item__param_1 group-session__item__param_col">
                              <span>Время</span>
                              <p>1.5 часа</p>
                            </div>
                            <div className="group-session__item__param_2 group-session__item__param_col">
                              <span>Формат</span>
                              <p>1 раз в неделю</p>
                            </div>
                            <div className="group-session__item__param_3 group-session__item__param_col">
                              <span>Тип группы</span>
                              <p>{item.groupTypeName}</p>
                            </div>
                          </div>
                        )}
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </TabPane>
            <TabPane
              tab={(
                <Badge className="group-session__tabpane-my-group" count={clientSessions.length} size="small">
                  Мои текущие записи
                </Badge>
)}
              key="2"
            >
              <List
                className="demo-loadmore-list"
                loading={initLoading}
                itemLayout="horizontal"
                dataSource={orderBy(clientSessions, 'startDateAndTime', 'asc')}
                renderItem={(item) => (
                  <List.Item
                    className="group-session__one-block"
                    actions={item.statusID === 'toBePaid' || item.statusID === 'approved'
                      ? [<a className="btn-default btn_link btn_link_dotted" key="list-cancel" onClick={() => onShowCancelSessionModal(item)}><span>Отменить</span></a>,
                        <a className="btn-default btn_red" key="list-pay" onClick={() => onPaySessionModal(item)}><span>Оплатить</span></a>]
                      : item.statusID === 'paid'
                        ? [<a className="btn-default btn_link btn_link_dotted" key="list-cancel" onClick={() => onShowCancelSessionModal(item)}><span>Отменить</span></a>,
                          <a className="btn-default btn_green go-to-meet" key="list-ощшт" onClick={() => window.open(item.VOXClientURL, '_self')}><span>Начать</span></a>]
                        : []}
                  >
                    <Skeleton avatar title={false} loading={item.loading} active>
                      <List.Item.Meta
                        className="client__group-session__left"
                        avatar={(
                          <Avatar className="client__group-session__avatar" src={item.specialist.logoURL} />
                        )}
                        title={(
                          <div className="client__group-session__about">
                            <div className="client__group-session__param">
                              <div className="client__group-session__title">{item.name}</div>
                            </div>
                            <div className="client__group-session__person">{`Тренер - ${item.specialist.lastName} ${item.specialist.firstName} ${item.specialist.middleName}`}</div>
                            <div className="client__group-session__date">{`${item.startDateAndTime && item.startDateAndTime.format('DD MMMM')} ${item.startDateAndTime && item.startDateAndTime.format('HH:mm')} - ${item.endDateAndTime && item.endDateAndTime.format('HH:mm')}, до ${item.maxNumberOfClients} человек`}</div>
                          </div>
                        )}
                        description={(
                          <div className="client__group-session__desc">{item.description}</div>
                        )}
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </TabPane>
            <TabPane
              tab={(
                <Badge className="group-session__tabpane-my-group" count={doneSessions.length} size="small">
                  Завершенные
                </Badge>
)}
              key="3"
            >
              <List
                className="demo-loadmore-list"
                loading={initLoading}
                itemLayout="horizontal"
                dataSource={orderBy(doneSessions, 'startDateAndTime', 'asc')}
                renderItem={(item) => (
                  <List.Item
                    className="group-session__one-block"
                    actions={[<a className="btn-default btn_link btn_link_dotted" key="list-pay" onClick={() => onPaySessionModal(item)}><span>Внести оплату</span></a>]}
                  >
                    <Skeleton avatar title={false} loading={item.loading} active>
                      <List.Item.Meta
                        className="client__group-session__left"
                        avatar={<Avatar src={item.specialist.logoURL} className="client__group-session__avatar" />}
                        title={(
                          <div className="client__group-session__about">
                            <div className="client__group-session__param">
                              <div className="client__group-session__title">{item.name}</div>
                              {/* <div className="client__group-session__status">{getStatusTag(item.statusID, item.statusValue)}</div> */}
                            </div>
                            <div className="client__group-session__person">{`Тренер - ${item.specialist.lastName} ${item.specialist.firstName} ${item.specialist.middleName}`}</div>
                            <div className="client__group-session__date">{`${item.startDateAndTime && item.startDateAndTime.format('DD MMMM')} ${item.startDateAndTime && item.startDateAndTime.format('HH:mm')} - ${item.endDateAndTime && item.endDateAndTime.format('HH:mm')}, до ${item.maxNumberOfClients} человек`}</div>
                          </div>
                        )}
                        description={(
                          <div className="client__group-session__desc">{item.description}</div>
                        )}
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </TabPane>
          </Tabs>
        </Card>
        {isShowPaySessionModal && (
          <Modal
            visible
            title="Подтверждение сессии"
            maskClosable
            onCancel={() => onPaySessionModal()}
            footer={false}
          >
            <Col span={24}>
              <Row>
                Пожалуйста, подтвердите проведение сессии со специалистом. Вы также можете оплатить
                услуги специлиста в том размере, в котором считаете нужным.
              </Row>
              <Row style={{ margin: '10px 0px' }}><InputNumber style={{ width: '100%' }} min={0} value={cost} onChange={(newCost) => this.setState({ cost: newCost })} /></Row>
              <Row><Button className="btn-default btn_red" onClick={() => onPaySession(cost)}>Подтвердить</Button></Row>
            </Col>
          </Modal>
        )}
        {selectCancelOptionModalStep !== 0 && (
          <Modal
            visible
            title="Укажите причину отмены"
            maskClosable
            onCancel={onCloseSelectCancelOptionModal}
            footer={false}
          >
            <Col span={24}>
              <Row>Можете ли вы рассказать нам, что пошло не так?</Row>
              <TextArea style={{ margin: '10px 0px' }} onChange={onChangeDeclineComment} />
              <Button className="btn-default btn_green" type="primary" onClick={onDeclineSession}>Отменить</Button>
            </Col>
          </Modal>
        )}
      </Col>
    );
  }
}

ClientGroupSessionsPageDesktop.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  initLoading: PropTypes.bool.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  isShowLoadMore: PropTypes.bool.isRequired,
  onEnroll: PropTypes.func.isRequired,
  clientSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isShowPaySessionModal: PropTypes.bool.isRequired,
  onPaySessionModal: PropTypes.func.isRequired,
  onPaySession: PropTypes.func.isRequired,
  selectCancelOptionModalStep: PropTypes.number.isRequired,
  onCloseSelectCancelOptionModal: PropTypes.func.isRequired,
  onShowCancelSessionModal: PropTypes.func.isRequired,
  onChangeDeclineComment: PropTypes.func.isRequired,
  onDeclineSession: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  doneSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ClientGroupSessionsPageDesktop;
