/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './SideBarButton.css';


const sideBarButton = ({
  icon, onClick, route, history, name, isDisabled,
}) => {
  function getStyle() {
    const isActive = route.includes(history.location.pathname.split('/')[1]);
    const cursor = isDisabled ? 'not-allowed' : 'pointer';
    let color = '#333333';
    if (isDisabled) {
      color = '#666666';
    } else if (isActive) {
      color = '#E79F8B';
    }
    return {
      cursor,
      color,
    };
  }

  function getUserClass() {
    const isActive = route.includes(history.location.pathname.split('/')[1]);
    let classusername = 'sideBarButton-wrapper';
    if (isActive) {
      classusername = 'sideBarButton-wrapper active';
    }
    return classusername;
  }

  return (
    <div
      className={getUserClass()}
      onClick={isDisabled ? () => {} : () => onClick(route)}
      style={getStyle()}
    >
      <div className="sideBarButton__img">{icon}</div>
      <div className="sideBarButton__text">{name}</div>
    </div>
  );
};

sideBarButton.propTypes = {
  icon: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  history: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default sideBarButton;
