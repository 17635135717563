import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Table, Tabs,
} from 'antd';
import getCancelSessionRequestColumns from '../utils/getCancelSessionRequestColumns';
import getChangeSpecialistRequestColumns from '../utils/getChangeSpecialistRequestColumns';
import './AdminCancelRequestsDesktop.css';


const { TabPane } = Tabs;
class AdminCancelRequestsDesktop extends Component {
  render() {
    const { cancelSessionRequest, changeSpecialistRequest, onChange } = this.props;
    return (
      <Col className="adminCancelRequestsDesktop-wrapper" span={24}>
        <Row style={{ marginBottom: '20px', fontSize: '20px' }}>Заявки на отмену</Row>
        <Tabs>
          <TabPane tab="Отмены сессий" key="sessions">
            <Table
              columns={getCancelSessionRequestColumns()}
              rowKey="id"
              dataSource={cancelSessionRequest}
              pagination={false}
            />
          </TabPane>
          <TabPane tab="Смены специалиста" key="specialists">
            <Table
              columns={getChangeSpecialistRequestColumns(onChange)}
              rowKey="id"
              dataSource={changeSpecialistRequest}
              pagination={false}
            />
          </TabPane>
        </Tabs>
      </Col>
    );
  }
}

AdminCancelRequestsDesktop.propTypes = {
  cancelSessionRequest: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  changeSpecialistRequest: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AdminCancelRequestsDesktop;
