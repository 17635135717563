/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import './SideBar.css';
import SideBarButton from './components/SideBarButton/SideBarButton';
import adminButtonsArray from './utils/adminButtonsArray';
import specialistButtonsArray from './utils/specialistButtonsArray';
import clientButtonsArray from './utils/clientButtonsArray';
import orgButtonsArray from './utils/orgButtonsArray';
import * as roles from '../../utils/roles';
import getUserRole from '../../utils/localeStorage/getUserRole';
import getIsValidClient from '../../utils/localeStorage/getIsValidClient';
import getIsValidSpecialist from '../../utils/localeStorage/getIsValidSpecialist';
import getUserId from '../../utils/localeStorage/getUserId';
import { routes } from '../../utils/routes';


class SideBar extends Component {
  handleButtonClick = (route) => {
    const { history } = this.props;
    history.push(route);
  }

  render() {
    const { history } = this.props;
    const pathname = get(history, 'location.pathname', '');
    const routesKeys = Object.keys(routes);
    const routesValues = [];
    routesKeys.forEach((k) => routesValues.push(routes[k]));
    routesValues.push('/specialist');
    const isNotVisible = [routes.home, routes.login, routes.homePage, routes.register, routes.specialists].includes(`/${pathname.split('/')[1]}`) || !routesValues.includes(`/${pathname.split('/')[1]}`);
    if (isNotVisible || !getUserId()) {
      return null;
    }
    let buttonsArray = [];
    switch (getUserRole().trim()) {
      case roles.ADMIN:
        buttonsArray = adminButtonsArray;
        break;
      case roles.CLIENT:
        buttonsArray = clientButtonsArray;
        break;
      case roles.SPECIALIST:
        buttonsArray = specialistButtonsArray;
        break;
      case roles.ORG:
        buttonsArray = orgButtonsArray;
        break;
      default: break;
    }
    let isDisabled = true;
    if (getUserRole() === roles.CLIENT && getIsValidClient() === 'true') {
      isDisabled = false;
    }
    if (getUserRole().trim() === roles.ADMIN) {
      isDisabled = false;
    }
    if (getUserRole() === roles.SPECIALIST && getIsValidSpecialist() === 'true') {
      isDisabled = false;
    }
    if (getUserRole() === roles.ORG) {
      isDisabled = false;
    }
    return (
      <div className="side-bar__col">
        {buttonsArray
          .map((button) => (
            <SideBarButton
              key={button.id}
              icon={button.icon}
              name={button.name}
              onClick={this.handleButtonClick}
              route={button.route}
              history={history}
              isDisabled={isDisabled && button.id !== 'profile'}
            />
          ))}
      </div>
    );
  }
}

SideBar.propTypes = {
  history: PropTypes.shape(),
};

SideBar.defaultProps = {
  history: {},
};

export default withRouter(SideBar);
