/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Spin, Dropdown, Menu,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import MobiMenu from '../../../assets/icons/mobi-menu.svg';
import MobiLogo from '../../../assets/icons/mobi-logo.svg';
import SpecialistSlots from '../components/SpecialistSlots';
import getUserRole from '../../../utils/localeStorage/getUserRole';
import adminButtonsArray from '../../../components/SideBar/utils/adminButtonsArray';
import specialistButtonsArray from '../../../components/SideBar/utils/specialistButtonsArray';
import clientButtonsArray from '../../../components/SideBar/utils/clientButtonsArray';
import getIsValidClient from '../../../utils/localeStorage/getIsValidClient';
import getIsValidSpecialist from '../../../utils/localeStorage/getIsValidSpecialist';
import * as roles from '../../../utils/roles';
import logout from '../../../utils/logout';
import './ClientSessionsPageMobile.css';
import getSessionId from '../../../utils/localeStorage/getSessionId';
import { routes } from '../../../utils/routes';
import { editUser, getUserAfterLogin } from '../../../utils/http';
import { setLocalStorage } from '../../../utils/localeStorage/storage';
import getUserId from '../../../utils/localeStorage/getUserId';
import timeZoneCatalogue from '../../../utils/timeZoneCatalogue';
import getTimeZoneDifference from '../../../utils/localeStorage/getTimeZoneDifference';


class ClientSessionsPageMobile extends Component {
    state = {
      cost: 0,
      code: '',
    }

    handleMenuButtonClick = (route) => {
      const { history } = this.props;
      history.push(route);
    }

    changeRole = () => {
      const { history } = this.props;
      editUser({ id: getUserId(), role: getUserRole() === roles.CLIENT ? roles.SPECIALIST : roles.CLIENT })
        .then(() => getUserAfterLogin(getUserId(), getSessionId())
          .then((user) => {
            const payload = {
              ...user,
              isValidSpecialist: user.isValidSpecialist && !!user.paymentType,
            };
            setLocalStorage(payload);
            // window.location.reload();
            history.push(routes.profile);
          }));
    }

    onPaySession = () => {
      const { code } = this.state;
      const { isShowPromoCodeModal, onPaySession } = this.props;
      if (!isShowPromoCodeModal.isGroup) {
        onPaySession(isShowPromoCodeModal.session.cost / 100, code);
      }
    }

    render() {
      const {
        currentSpecialist, onPaySession, onCancelSession, onCloseSelectCancelOptionModal, isShowSelectCancelOptionModal, onCancelSelectOption,
        selectCancelOptionModalStep, onEnroll, onSelect, selectedTime, selectedDate, slots, onChangeDeclineComment, onLeaveDeclineComment,
        onSelectOtherOption, loading, onStartSession, isStartSession, joinURL, isShowPaySessionModal, onPaySessionModal, onConfirmNewEnroll,
        clientSessions, currentSession, onNewEnroll, onChageSpecialistRequest, isShowNewEnrollModal, onCloseNewEnrollModal,
        changeSpecialistRequestID, history, paymentURL, onClosePaymentUrl, isShowPromoCodeModal, onClosePromoCodeModal,
      } = this.props;
      let buttonsArray = [];
      switch (getUserRole()) {
        case roles.ADMIN:
          buttonsArray = adminButtonsArray;
          break;
        case roles.CLIENT:
          buttonsArray = clientButtonsArray;
          break;
        case roles.SPECIALIST:
          buttonsArray = specialistButtonsArray;
          break;
        default: break;
      }
      let isDisabled = true;
      if (getUserRole() === roles.CLIENT && getIsValidClient() === 'true') {
        isDisabled = false;
      }
      if (getUserRole() === roles.ADMIN) {
        isDisabled = false;
      }
      if (getUserRole() === roles.SPECIALIST && getIsValidSpecialist() === 'true') {
        isDisabled = false;
      }
      const menu = (
        <Menu>
          {buttonsArray
            .filter((b) => b.id !== 'help')
            .map((b) => (
              <Menu.Item
                key={b.id}
                disabled={isDisabled}
                onClick={() => this.handleMenuButtonClick(b.route)}
              >
                {b.name}
              </Menu.Item>
            ))}
          <Menu.Divider />
          {getUserId() && <Menu.Item key="2" onClick={this.changeRole}>Сменить роль</Menu.Item>}
          {getUserId() && <Menu.Divider />}
          <Menu.Item key="3" onClick={() => logout(history)} style={{ cursor: 'pointer' }}>Выйти</Menu.Item>
        </Menu>
      );
      const { cost, code } = this.state;
      const currentSpecialistSessionsNumber = currentSpecialist && clientSessions.filter((session) => session.statusID === 'done' && session.specialist_id === currentSpecialist.id).length;
      return (
        <div className="clientSessionsPageMobile-wrapper">
          <div className="profile-wrapper__header mobile-header__wrapper">
            <div className="mobi-logo"><a href="https://gestalt.online"><img src={MobiLogo} alt="Вернуться на главную" /></a></div>
            <div className="profile-header-menu">
              <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                <img src={MobiMenu} alt="Мобильное меню" />
              </Dropdown>
            </div>
          </div>
          {isStartSession && (
          <div>
            {!joinURL && (
            <div>
              <div>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
              </div>
              <div>Ожидаем подготовки комнаты для начала сессии</div>
            </div>
            )}
            {joinURL && (
              <div style={{ height: '100%' }}>
                <span>Ваша комната готова. Если страница не открылась автоматически, перейдите</span>
                <a href={joinURL}> по этой ссылке </a>
              </div>
            )}
          </div>
          )}
          {loading && !isStartSession && (
          <div>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
          </div>
          )}
          {!loading && !isStartSession && currentSpecialist && (
          <div>
            <div className="clientSessionsPageMobile-header-wrapper">
              <div className="clientSessionsPageMobile-header-row">
                <div className="clientSessionsPageMobile-header-img">
                  <img src={currentSpecialist.logoURL} className="clientSessionsPageMobile-header-logo" alt="Фотография специалиста" />
                </div>
                <div className="clientSessionsPageMobile-header-column">
                  <div className="clientSessionsPageMobile-header-name">{`${currentSpecialist.lastName} ${currentSpecialist.firstName} ${currentSpecialist.middleName}`}</div>
                  <div className="clientSessionsPageMobile-header-count">
                    {`Количество проведенных с вами сессий: ${currentSpecialistSessionsNumber}`}
                  </div>
                  <div className="clientSessionsPageMobile-header-title-session">Ближайшая сессия</div>
                  <div style={{ fontSize: '10px' }}>{timeZoneCatalogue.find((t) => t.timeDifference === `${getTimeZoneDifference()}`).value}</div>
                  <div className="clientSessionsPageMobile-header-title-date">{currentSession && currentSession.sessionDateAndTime.format('DD MMMM, HH:mm, dd')}</div>
                </div>
              </div>
              {currentSession && (
              <div className="clientSessionsPageMobile-header-btn">
                {currentSession.statusID === 'toBePaid' && <div onClick={onPaySessionModal} className="btn-default btn_transparent">Оплатить</div>}
                {currentSession.statusID === 'paid' && <div className="btn-default btn_transparent" onClick={() => onCancelSelectOption('old')}>Перенести встречу</div>}
                {(currentSession.statusID === 'toBePaid' || currentSession.statusID === 'paid') && <div onClick={onCancelSession} className="btn-default btn_transparent">Отменить</div>}
                {currentSession.statusID === 'paid' && <div className="btn-default btn_red btn_startsession" onClick={onStartSession}>Начать сессию</div>}
              </div>
              )}
              {!currentSession && (
                <div className="clientSessionsPageMobile-header-btn">
                  <div className="btn-default btn_transparent" style={{ marginRight: '10px' }} onClick={onNewEnroll}>Записаться</div>
                  {!changeSpecialistRequestID && (
                    <div className="btn-default btn_transparent" onClick={onChageSpecialistRequest}>Поменять специалиста</div>
                  )}
                </div>
              )}
            </div>
            <div className="clientSessionsPageMobile-body-wrapper">
              <div className="clientSessionsPageMobile-body-title">О специалисте</div>
              <div className="clientSessionsPageMobile-body-row">
                <div className="clientSessionsPageMobile-body-row-value" dangerouslySetInnerHTML={{ __html: currentSpecialist.descriptionClients }} />
              </div>
              <div className="clientSessionsPageMobile-body-row">
                <div className="clientSessionsPageMobile-body-row-label">Основное образование:</div>
                <div className="clientSessionsPageMobile-body-row-value-wrapper">
                  <div className="clientSessionsPageMobile-body-row-value">{currentSpecialist.mainEducation}</div>
                </div>
              </div>
              <div className="clientSessionsPageMobile-body-row">
                <div className="clientSessionsPageMobile-body-row-label">Дополнительное образование:</div>
                <div className="clientSessionsPageMobile-body-row-value-wrapper">
                  <div className="clientSessionsPageMobile-body-row-value">{currentSpecialist.secondaryEducation}</div>
                </div>
              </div>
            </div>
          </div>
          )}

          {isShowSelectCancelOptionModal && (
          <Modal
            visible
            title={selectCancelOptionModalStep === 0 || selectCancelOptionModalStep === 2
              ? 'Укажите причину отмены'
              : selectCancelOptionModalStep === 3 ? 'Выберите вариант' : 'Выберите время'}
            maskClosable
            onCancel={onCloseSelectCancelOptionModal}
            footer={false}
          >
            {selectCancelOptionModalStep === 0 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="btn-default btn_red" onClick={() => onCancelSelectOption('new')}>Хочу поменять психолога</div>
              <div className="btn-default btn_transparent" onClick={() => onCancelSelectOption('old')} style={{ margin: '10px 0px' }}>Хочу поменять время встречи</div>
              <div className="btn-default btn_transparent" onClick={() => onCancelSelectOption('other')}>Хочу полностью отменить консультацию</div>
            </div>
            )}
            {selectCancelOptionModalStep === 1 && (
            <SpecialistSlots
              slots={slots}
              onEnroll={onEnroll}
              onSelect={onSelect}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
            />
            )}
            {selectCancelOptionModalStep === 2 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>Спасибо мы отменили вашу встречу, можете ли вы рассказать нам, что пошло не так?</div>
              <input style={{ margin: '10px 0px' }} onChange={onChangeDeclineComment} />
              <div className="btn-default btn_transparent" onClick={onLeaveDeclineComment}>Поделиться</div>
            </div>
            )}
            {selectCancelOptionModalStep === 3 && (
            <div>
              <div className="btn-default btn_transparent" style={{ marginRight: '10px' }} onClick={() => onSelectOtherOption(1)}>Хочу сам выбрать другого</div>
              <div className="btn-default btn_transparent" onClick={() => onSelectOtherOption(2)}>Подберите мне другого</div>
            </div>
            )}
          </Modal>
          )}
          {isShowPaySessionModal && (
          <Modal
            visible
            title="Подтверждение сессии"
            maskClosable
            onCancel={onPaySessionModal}
            footer={false}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                Пожалуйста, подтвердите проведение сессии со специалистом. Вы также можете оплатить
                услуги специлиста в том размере, в котором считаете нужным.
              </div>
              <input style={{ width: '100%', margin: '10px 0px' }} value={cost} onChange={(e) => this.setState({ cost: e.target.value })} />
              <div className="btn-default btn_transparent" onClick={() => onPaySession(cost)}>Подтвердить</div>
            </div>
          </Modal>
          )}
          {isShowPromoCodeModal && (
          <Modal
            visible
            title="Оплата сессии"
            maskClosable
            onCancel={onClosePromoCodeModal}
            footer={false}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                Пожалуйста, введите промокод
              </div>
              <input style={{ width: '100%', margin: '10px 0px' }} value={code} onChange={(e) => this.setState({ code: e.target.value })} />
              <div className="btn-default btn_transparent" onClick={this.onPaySession}>Подтвердить</div>
            </div>
          </Modal>
          )}
          {isShowNewEnrollModal && (
          <Modal
            visible
            title="Новая сессия"
            maskClosable
            onCancel={onCloseNewEnrollModal}
            footer={false}
          >
            <SpecialistSlots
              slots={slots}
              onEnroll={onConfirmNewEnroll}
              onSelect={onSelect}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              isNew
            />
          </Modal>
          )}
          {paymentURL && (
          <Modal
            visible
            maskClosable
            onCancel={onClosePaymentUrl}
            footer={false}
            bodyStyle={{ height: '60vh' }}
          >
            <iframe src={paymentURL} height="100%" width="100%" />
          </Modal>
          )}

        </div>
      );
    }
}


ClientSessionsPageMobile.propTypes = {
  currentSpecialist: PropTypes.shape(),
  clientSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onPaySession: PropTypes.func.isRequired,
  onCancelSession: PropTypes.func.isRequired,
  isShowSelectCancelOptionModal: PropTypes.bool.isRequired,
  onCloseSelectCancelOptionModal: PropTypes.func.isRequired,
  onCancelSelectOption: PropTypes.func.isRequired,
  selectCancelOptionModalStep: PropTypes.number.isRequired,
  onEnroll: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedTime: PropTypes.string,
  selectedDate: PropTypes.shape(),
  slots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeDeclineComment: PropTypes.func.isRequired,
  onSelectOtherOption: PropTypes.func.isRequired,
  onLeaveDeclineComment: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onStartSession: PropTypes.func.isRequired,
  isStartSession: PropTypes.bool.isRequired,
  joinURL: PropTypes.string,
  onPaySessionModal: PropTypes.func.isRequired,
  isShowPaySessionModal: PropTypes.bool.isRequired,
  currentSession: PropTypes.shape(),
  onNewEnroll: PropTypes.func.isRequired,
  onChageSpecialistRequest: PropTypes.func.isRequired,
  changeSpecialistRequestID: PropTypes.string,
  history: PropTypes.shape().isRequired,
  isShowNewEnrollModal: PropTypes.bool.isRequired,
  onCloseNewEnrollModal: PropTypes.func.isRequired,
  onConfirmNewEnroll: PropTypes.func.isRequired,
  paymentURL: PropTypes.string,
  onClosePaymentUrl: PropTypes.func.isRequired,
  isShowPromoCodeModal: PropTypes.shape(),
  onClosePromoCodeModal: PropTypes.func.isRequired,
};

ClientSessionsPageMobile.defaultProps = {
  currentSession: null,
  currentSpecialist: null,
  selectedTime: null,
  selectedDate: null,
  joinURL: null,
  changeSpecialistRequestID: null,
  paymentURL: null,
  isShowPromoCodeModal: null,
};


export default ClientSessionsPageMobile;
