/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import React from 'react';


const getPersonalDataChangeRequestsColumns = (lockedFields) => [
  {
    title: 'ФИО',
    dataIndex: 'userName',
    key: 'userName',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Поле',
    dataIndex: 'fieldName',
    key: 'fieldName',
    render: (text) => lockedFields.find((f) => f.value === text)?.text,
  },
  {
    title: 'Новое значение',
    dataIndex: 'fieldValue',
    key: 'fieldValue',
  },
  {
    title: 'Старое значение',
    dataIndex: 'oldValue',
    key: 'oldValue',
    render: (text, object) => object.user[object.fieldName],
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
    key: 'comment',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
  },
];

export default getPersonalDataChangeRequestsColumns;
