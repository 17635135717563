/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import getStatusTag from './getStatusTag';


const getClientSessionsColumns = (onSelectSession) => [
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    render: (text, object) => <a onClick={() => onSelectSession(object)}>{text}</a>,
  },
  {
    title: 'Максимум',
    dataIndex: 'maxNumberOfClients',
    key: 'maxNumberOfClients',
  },
  {
    title: 'Зарегистрировано',
    dataIndex: 'groupSessionEnroll',
    key: 'groupSessionEnroll',
    render: (text) => text.length,
  },
  {
    title: 'Подтверждено',
    dataIndex: 'groupSessionEnroll',
    key: 'groupSessionEnroll',
    render: (text) => text.filter((e) => e.statusId === 'paid').length + text.filter((e) => e.statusId === 'approved').length,
  },
  {
    title: 'Дата и время проведения',
    dataIndex: 'dateAndTime',
    key: 'dateAndTime',
    render: (text, object) => `${object.startDateAndTime.format('DD MMMM YYYY')} c ${object.startDateAndTime.format('HH:mm')} до ${object.endDateAndTime.format('HH:mm')}`,
  },
  {
    title: 'Специалист',
    dataIndex: 'specialist',
    key: 'specialist',
    render: (text) => <div>{`${text.lastName} ${text.firstName} ${text.middleName}`}</div>,
  },
  {
    title: 'Статус',
    dataIndex: 'statusId',
    key: 'statusId',
    render: (text, object) => getStatusTag(object.isHidden ? 'isHidden' : text),
  },
];

export default getClientSessionsColumns;

