import React, { Component } from 'react';
import CalendarModule from '../Calendar/CalendarModule';


class SpecialistTimetable extends Component {
  render() {
    return (
      <CalendarModule />
    );
  }
}

export default SpecialistTimetable;

