/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Col, Row, Avatar, Tag, Button, Popconfirm, Card, Anchor, Alert,
} from 'antd';
import calendar from '../../../assets/icons/calendar.svg';
import getSessionId from '../../../utils/localeStorage/getSessionId';
import './PsychoSelectPageDesktop.css';
import ageEnding from '../../../utils/ageEnding';
import timeZoneCatalogue from '../../../utils/timeZoneCatalogue';
import getTimeZoneDifference from '../../../utils/localeStorage/getTimeZoneDifference';


const { CheckableTag } = Tag;
const { Meta } = Card;
const { Link } = Anchor;
class PsychoSelectPageDesktop extends Component {
  render() {
    const {
      specialist, slots, selectedTime, selectedDate, onSelect, onEnroll, onRedirectBack, onShowMore, isShowMoreAvailable, isSessionExist,
    } = this.props;
    return (
      <Col className="psychoSelectPageDesktop profilePageDesktop_center">
        {isSessionExist && (
        <Alert
          message="Внимание! Есть неоплаченные сессии или уже выбран терапевт"
          description="Чтобы записаться к новому специалисту, необходимо сначала открепиться от старого или отменить запись"
          type="warning"
          showIcon
        />
        )}
        {!isSessionExist && (
        <Col>
          <div className="block-btn-back"><Button className="btn-default btn_transparent" onClick={onRedirectBack}>Назад</Button></div>
          <div className="psychoSelectPageDesktop__prev">
            <Card
              className="psychoSelectPageDesktop__wrapper"
              hoverable={false}
            >
              <Meta
                description={(
                  <Row justify="space-between">
                    <Avatar src={specialist.logoURL} className="active-psycho__avatar" />
                    <div className="active-psycho__param-1">
                      <div className="active-psycho__name">{`${specialist.firstName} ${specialist.lastName}`}</div>
                      {slots.length > 0 && (
                      <div className="active-psycho__prev">
                        <div className="active-psycho__prev-left">
                          <img className="block-icon active-psycho__icon-calendar" src={calendar} alt="calendar" />
                        </div>
                        <div className="active-psycho__prev-center">
                          <div className="active-psycho__title-time">Ближайшее доступное время</div>
                          <div className="active-psycho__date">{`${slots[0].date.format('DD MMMM, dd')}, ${slots[0].timesArray[0]}`}</div>
                        </div>
                      </div>
                      )}
                      <Anchor affix={false}><Link href="#TIME" title={<Button className="btn-default btn_red" type="primary">Выбрать время</Button>} /></Anchor>
                    </div>
                    <div className="active-psycho__param-2">
                      <div className="active-psycho__param__price">{`${specialist.sessionPrice} P`}</div>
                      <div className="active-psycho__param__line active-psycho__param__line-1">
                        <div className="active-psycho__param__line-title">Возраст:</div>
                        <div className="active-psycho__param__line-text">{specialist.birthdayDate ? ageEnding(moment().diff(specialist.birthdayDate, 'years')) : ''}</div>
                      </div>
                      <div className="active-psycho__param__line active-psycho__param__line-2">
                        <div className="active-psycho__param__line-title">Опыт работы:</div>
                        <div className="active-psycho__param__line-text">{ageEnding(specialist.experience)}</div>
                      </div>
                      <div className="active-psycho__param__line active-psycho__param__line-3">
                        <div className="active-psycho__param__line-title">Язык:</div>
                        <div className="active-psycho__param__line-text">{specialist.languagesCatalogue_ids.map((l) => l.value).join(', ')}</div>
                      </div>
                    </div>
                  </Row>
                    )}
              />
            </Card>
          </div>

          <Row style={{ marginTop: '40px' }}>
            <Card
              hoverable={false}
              style={{ borderColor: '#bfbdbb', width: '100%' }}
            >
              <Meta
                description={(
                  <div className="content-text">
                    <h2 className="content-text__h2">О специалисте</h2>
                    <div className="content-text__p" dangerouslySetInnerHTML={{ __html: specialist.descriptionClients }} />
                  </div>
                    )}
              />
            </Card>
          </Row>

          {slots.length > 0 && (
          <div className="content-text time-to-go">
            <Card
              hoverable={false}
              style={{ borderColor: '#bfbdbb', width: '100%' }}
              id="TIME"
            >
              <Meta
                description={(
                  <Col span={24}>
                    <h2 className="">Ближайшее время</h2>
                    <h3>Записаться к специалисту</h3>
                    <span>{getTimeZoneDifference() ? timeZoneCatalogue.find((t) => t.timeDifference === `${getTimeZoneDifference()}`).value : ''}</span>
                    {slots.map((slot) => (
                      <Row style={{ margin: '10px' }} key={slot.id}>
                        <Col span={24} key={slot.id}>
                          <Row style={{ color: '#333333' }}>{slot.date.format('DD MMMM, dddd')}</Row>
                          <Row gutter={12}>
                            {slot.timesArray.map((time) => (
                              <CheckableTag
                                key={time + slot.date}
                                checked={selectedTime === time && slot.date.isSame(selectedDate)}
                                onChange={() => onSelect(time, slot.date)}
                              >
                                {time}
                              </CheckableTag>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    ))}
                    {isShowMoreAvailable && <Row><Button className="btn-default btn_link btn_link_dotted" onClick={onShowMore}>Показать еще</Button></Row>}
                    {selectedDate && selectedTime && getSessionId() && (
                    <Row style={{ marginTop: '40px' }}>
                      <Popconfirm
                        title="Вы уверены, что хотите записаться к этому специалисту?"
                        onConfirm={onEnroll}
                        okText="Да"
                        cancelText="Нет"
                      >
                        <Button className="btn-default btn_red" type="primary">Записаться</Button>
                      </Popconfirm>
                    </Row>
                    )}
                    {selectedDate && selectedTime && !getSessionId() && (
                    <Row style={{ marginTop: '40px' }}>
                      <Button className="btn-default btn_red" type="primary" onClick={onEnroll}>Записаться</Button>
                    </Row>
                    )}
                  </Col>
                    )}
              />
            </Card>
          </div>
          )}
        </Col>
        )}
      </Col>
    );
  }
}

PsychoSelectPageDesktop.propTypes = {
  specialist: PropTypes.shape().isRequired,
  slots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedTime: PropTypes.string,
  selectedDate: PropTypes.shape(),
  onSelect: PropTypes.func.isRequired,
  onEnroll: PropTypes.func.isRequired,
  onRedirectBack: PropTypes.func.isRequired,
  onShowMore: PropTypes.func.isRequired,
  isShowMoreAvailable: PropTypes.bool.isRequired,
  isSessionExist: PropTypes.bool.isRequired,
};

PsychoSelectPageDesktop.defaultProps = {
  selectedTime: null,
  selectedDate: null,
};

export default PsychoSelectPageDesktop;
