import formatSumWithSymbol from '../../../utils/formatSumWithSymbol';
import getStatusTag from './getStatusTag';


const getColumns = () => [
  {
    title: 'Промокод',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'Сумма',
    dataIndex: 'sum',
    key: 'sum',
    render: (sum) => formatSumWithSymbol(sum),
  },
  {
    title: 'Статус',
    dataIndex: 'isUsed',
    key: 'isUsed',
    render: (isUsed) => getStatusTag(isUsed),
  },
  {
    title: 'Клиент',
    dataIndex: 'sessionClientNickName',
    key: 'sessionClientNickName',
    render: (_, object) => (object.sessionId ? `${object.sessionClientNickName} +${object.sessionClientPhone}` : ''),
  },
  {
    title: 'Специалист',
    dataIndex: 'sessionSpecialistFullName',
    key: 'sessionSpecialistFullName',
  },
  {
    title: 'Дата сессии',
    dataIndex: 'sessionDateAndTime',
    key: 'sessionDateAndTime',
    render: (sessionDateAndTime) => (sessionDateAndTime ? sessionDateAndTime.format('DD MMMM YYYY') : ''),
  },
  {
    title: 'Стоимость сессии',
    dataIndex: 'sessionCost',
    key: 'sessionCost',
    render: (sessionCost, object) => (object.sessionId ? formatSumWithSymbol(sessionCost) : ''),
  },
];

export default getColumns;

