const specialistStatusCatalogue = [
  {
    id: 'onApproval',
    value: 'На верификации',
  },
  {
    id: 'declined',
    value: 'Отказано',
  },
  {
    id: 'approved',
    value: 'Доступ предоставлен',
  },
  {
    id: 'new',
    value: 'Новый',
  },
];

export default specialistStatusCatalogue;
