import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Radio, Popconfirm } from 'antd';
import './SettingsPageDesktop.css';


class SettingsPageDesktop extends Component {
  render() {
    const { role, onRoleChange, onTotalLogout } = this.props;
    return (
      <div className="settingsPageDesktop-wrapper">
        <Radio.Group defaultValue={role} buttonStyle="solid" onChange={onRoleChange} disabled>
          <Radio.Button value="client">Клиент</Radio.Button>
          <Radio.Button value="specialist">Специалист</Radio.Button>
        </Radio.Group>
        <div>Выйти со всех устройств</div>
        <Popconfirm
          onConfirm={onTotalLogout}
          title="Вы уверены, что хотите выйти?"
        >
          <Button type="primary">Выйти</Button>
        </Popconfirm>
      </div>
    );
  }
}

SettingsPageDesktop.propTypes = {
  role: PropTypes.string.isRequired,
  onRoleChange: PropTypes.func.isRequired,
  onTotalLogout: PropTypes.func.isRequired,
};

export default SettingsPageDesktop;
