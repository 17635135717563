import React, { Component } from 'react';
import moment from 'moment';
import ru from 'moment/locale/ru';
import {
  ConfigProvider, Row,
} from 'antd';
import { get } from 'lodash';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import ru_RU from 'antd/es/locale/ru_RU';
import SideBar from './components/SideBar/SideBar';
import Header from './components/Header/Header';
import PrivateRoute from './utils/privateRoute';
import HomeModule from './pages/Home/HomeModule';
import Footer from './components/Footer/Footer';
import RegisterPsychoModule from './pages/RegisterPsycho/RegisterPsychoModule';
import PsychoListModule from './pages/PsychoList/PsychoListModule';
import CleverPsychoListModule from './pages/CleverPsychoList/CleverPsychoListModule';
import ProfileModule from './pages/Profile/ProfileModule';
import CalendarModule from './pages/Calendar/CalendarModule';
import Settings from './pages/Settings/SettingsModule';
import ClientSessionsModule from './pages/ClientSessions/ClientSessionsModule';
import AdminFieldsModule from './pages/AdminFields/AdminFieldsModule';
import AdminCancelRequestsModule from './pages/AdminCancelRequests/AdminCancelRequestsModules';
import AdminSpecialistsModule from './pages/AdminSpecialists/AdminSpecialistsModule';
import AdminDataChangeRequests from './pages/AdminDataChangeRequests/AdminDataChangeRequestsModule';
import AdminManualUsersModule from './pages/AdminManualUsers/AdminManualUsersModule';
import AdminGroupSessionsModule from './pages/AdminGroupSessions/AdminGroupSessionsModule';
import ClientGroupSessionsModule from './pages/ClientGroupSessions/ClientGroupSessionsModule';
import SpecialistGroups from './pages/SpecialistGroups/SpecialistGroups';
import SpecialistSessions from './pages/SpecialistSessions/SpecialistSessions';
import SpecialistTimetable from './pages/SpecialistTimetable/SpecialistTimetable';
import AdminLogin from './pages/AdminLogin/AdminLoginModule';
import PsychoSelectModule from './pages/PsychoSelect/PsychoSelectModule';
import EmailVerificationModule from './pages/EmailVerification/EmailVerificationModule';
import HelpModule from './pages/Help/HelpModule';
import { routes } from './utils/routes';
import BottomMobile from './components/BottomMobile/BottomMobile';
import LoginModule from './pages/Login/LoginModule';
import OrgPromoCodesModule from './pages/OrgPromoCodes/OrgPromoCodesModule';


moment.updateLocale('ru', ru);
export default class App extends Component {
  state = {
    width: window.innerWidth,
    isShowBottomMobile: true,
    path: get(window, 'location.pathname'),
  };

  render() {
    const {
      width, isShowBottomMobile, path,
    } = this.state;
    const isMobile = width <= 800;
    const isBottomVisible = isShowBottomMobile && isMobile;
    const isLogin = [routes.login, routes.home].includes(path);
    return (
      <ConfigProvider locale={ru_RU}>
        <Router>
          <div className="body-wrapper">
            {!isBottomVisible && <Row><Header /></Row>}
            <div className={`content-zone ${isLogin ? 'content-zone__lite' : ''}`}>
              {!isBottomVisible && <div className="side-bar__wrapper"><SideBar /></div>}
              <div className="right-zone__wrapper">
                <div className="right-zone__content">
                  <Switch>
                    <Route exact path={routes.home}>
                      <LoginModule callBack={(p) => this.setState({ path: p })} isHideFields />
                    </Route>
                    <Route exact path={routes.login}>
                      <LoginModule callBack={(p) => this.setState({ path: p })} isHideFields />
                    </Route>
                    <Route path={routes.specialists}>
                      <RegisterPsychoModule />
                    </Route>
                    <PrivateRoute path={routes.meetings}>
                      <ClientSessionsModule />
                    </PrivateRoute>
                    <PrivateRoute path={routes.profile}>
                      <ProfileModule />
                    </PrivateRoute>
                    <Route path={routes.list}>
                      <PsychoListModule />
                    </Route>
                    <PrivateRoute path={routes.cleverList}>
                      <CleverPsychoListModule />
                    </PrivateRoute>
                    <PrivateRoute path={routes.calendar}>
                      <CalendarModule />
                    </PrivateRoute>
                    <PrivateRoute path={routes.specialistGroups}>
                      <SpecialistGroups />
                    </PrivateRoute>
                    <PrivateRoute path={routes.specialistSessions}>
                      <SpecialistSessions />
                    </PrivateRoute>
                    <PrivateRoute path={routes.specialistTimetable}>
                      <SpecialistTimetable />
                    </PrivateRoute>
                    <Route path={routes.groupSessions}>
                      <ClientGroupSessionsModule />
                    </Route>
                    <PrivateRoute path={routes.settings}>
                      <Settings />
                    </PrivateRoute>
                    <PrivateRoute path={routes.adminFields}>
                      <AdminFieldsModule />
                    </PrivateRoute>
                    <PrivateRoute path={routes.adminCancelRequests}>
                      <AdminCancelRequestsModule />
                    </PrivateRoute>
                    <PrivateRoute path={routes.adminSpecialists}>
                      <AdminSpecialistsModule />
                    </PrivateRoute>
                    <PrivateRoute path={routes.adminDataChangeRequests}>
                      <AdminDataChangeRequests />
                    </PrivateRoute>
                    <PrivateRoute path={routes.adminLogin}>
                      <AdminLogin />
                    </PrivateRoute>
                    <PrivateRoute path={routes.adminManualUsers}>
                      <AdminManualUsersModule />
                    </PrivateRoute>
                    <PrivateRoute path={routes.adminGroupSessions}>
                      <AdminGroupSessionsModule />
                    </PrivateRoute>
                    <Route path={routes.specialist}>
                      <PsychoSelectModule />
                    </Route>
                    <PrivateRoute path={routes.help}>
                      <HelpModule />
                    </PrivateRoute>
                    <PrivateRoute path={routes.emailVerification}>
                      <EmailVerificationModule />
                    </PrivateRoute>
                    <PrivateRoute path={routes.promoCodes}>
                      <OrgPromoCodesModule />
                    </PrivateRoute>
                    <Route component={HomeModule} />
                  </Switch>
                </div>
                <Row><Footer /></Row>
              </div>
            </div>
            {isBottomVisible && <BottomMobile />}
          </div>
        </Router>
      </ConfigProvider>
    );
  }
}
