/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Input, notification, Button, Modal,
} from 'antd';
import PinInput from 'react-pin-input';
import { routes } from '../../../../utils/routes';
import './ProfileClient.css';
import {
  editClientProfile, getTimeZoneCatalogue, editEmailVerificationRequests, checkEmailCode,
} from '../../../../utils/http';
import editSvg from '../../../../assets/icons/edit.svg';
import getUserId from '../../../../utils/localeStorage/getUserId';
import { setLocalStorage as setStorage } from '../../../../utils/localeStorage/storage';


class ProfileClient extends Component {
  state = {
    fieldInEdit: null,
    fieldInEditValue: null,
    user: this.props.user,
    timeZoneCatalogue: [],
    isShowApproveEmailInput: false,
    isLoadingEmailInput: false,
    isValidEmailInput: true,
  }

  componentDidMount() {
    getTimeZoneCatalogue()
      .then((timeZoneCatalogue) => this.setState({
        timeZoneCatalogue,
      }));
  }

  onSaveClick = () => {
    const { fieldInEdit, fieldInEditValue, timeZoneCatalogue } = this.state;
    let timeZoneTimeDifference = null;
    if (fieldInEdit === 'timeZone') {
      timeZoneTimeDifference = timeZoneCatalogue.find((t) => t.id === fieldInEditValue).timeDifference;
    }
    editClientProfile({ id: getUserId(), [fieldInEdit]: fieldInEditValue })
      .then(() => {
        notification.success({ message: 'Успешно сохранено' });
        this.setState({
          fieldInEdit: false,
          fieldInEditValue: false,
        });
        if (fieldInEdit === 'nickName') {
          setStorage({ nickName: fieldInEditValue });
          this.setState((prevState) => ({ user: { ...prevState.user, [fieldInEdit]: fieldInEditValue } }));
        }
        if (fieldInEdit === 'email') {
          this.setState((prevState) => ({ user: { ...prevState.user, [fieldInEdit]: fieldInEditValue } }));
        }
        if (fieldInEdit === 'timeZone') {
          setStorage({ timeZoneTimeDifference });
          this.setState((prevState) => ({
            user: {
              ...prevState.user,
              [fieldInEdit]: fieldInEditValue,
              timeZoneValue: timeZoneCatalogue.find((t) => t.id === fieldInEditValue).value,
            },
          }));
        }
      });
  }


  onSendEmailVerificationCode = () => {
    const { user } = this.state;

    editEmailVerificationRequests({ user_id: getUserId(), date: moment().toISOString(), email: user.email })
      .then(() => {
        this.setState({ isShowApproveEmailInput: true });
        notification.success({ message: 'Код отправлен на указанную почту' });
      });
  }

  onCloseShowApproveEmailInput = () => {
    this.setState({ isShowApproveEmailInput: false });
  }

  onFinishApproveEmailInput = (userInputEmailVerificationCode) => {
    const { user } = this.state;
    this.setState({ isLoadingEmailInput: true });
    checkEmailCode({ id: user.id, userInputEmailVerificationCode })
      .then((isEmailVerified) => {
        if (isEmailVerified) {
          notification.success({ message: 'Спасибо!' });
          const isValidClient = user.nickName !== 'Аноним' && isEmailVerified;
          editClientProfile({
            id: getUserId(), isEmailVerified, isValidClient,
          })
            .then(() => {
              this.setState({
                isLoadingEmailInput: false,
                isShowApproveEmailInput: false,
                user: { ...user, isEmailVerified: true },
              });
              setStorage({ isValidClient });
            });
        } else {
          notification.error({ message: 'Неправильный код!' });
          this.setState({ isLoadingEmailInput: false });
        }
      });
  }

  onChangeEmailInput = () => {
    this.setState({ isValidEmailInput: true });
  }

  render() {
    const {
      fieldInEdit, user, isShowApproveEmailInput, isValidEmailInput, isLoadingEmailInput, timeZoneCatalogue,
    } = this.state;
    const { onRedirect } = this.props;
    if (!user) {
      return null;
    }
    return (
      <div className="profileClient-wrapper">
        <div className="profileClient-header-wrapper">
          <div className="profileClient-header-row">
            <div className="profileClient-header-logo" />
            <div className="profileClient-header-name">{user.nickName}</div>
          </div>
        </div>
        <div className="profileClient-body-wrapper">
          <div className="profileClient-body-row">
            <div className="profileClient-body-row-label">Ваше имя или псевдоним:</div>
            <div className="profileClient-body-row-value-wrapper">
              {fieldInEdit !== 'nickName' && <div className="profileClient-body-row-value">{user.nickName}</div>}
              {fieldInEdit === 'nickName' && <Input onChange={(e) => this.setState({ fieldInEditValue: e.target.value })} defaultValue={user.nickName} bordered={false} style={{ borderBottom: '1px solid', borderRadius: '0px' }} />}
              {!fieldInEdit && <img src={editSvg} className="profileClient-header-edit" alt="edit" onClick={() => this.setState({ fieldInEdit: 'nickName', fieldInEditValue: user.nickName })} />}
            </div>
          </div>
          <div className="profileClient-body-row">
            <div className="profileClient-body-row-label">Почта:</div>
            <div className="profileClient-body-row-value-wrapper">
              {fieldInEdit !== 'email' && <div className="profileClient-body-row-value">{user.email}</div>}
              {fieldInEdit === 'email' && <Input onChange={(e) => this.setState({ fieldInEditValue: e.target.value })} defaultValue={user.email} bordered={false} style={{ borderBottom: '1px solid', borderRadius: '0px' }} />}
              {!fieldInEdit && <img src={editSvg} className="profileClient-header-edit" alt="edit" onClick={() => this.setState({ fieldInEdit: 'email', fieldInEditValue: user.email })} />}
            </div>
          </div>
          {!fieldInEdit && !user.isEmailVerified && (
          <div style={{
            fontSize: '10px', color: 'red', fontWeight: '100', textAlign: 'end', marginTop: '-20px',
          }}
          >
            Внимание! Вы должны подтвердить свою почту
          </div>
          )}
          {!fieldInEdit && !user.isEmailVerified && <Button type="link" onClick={this.onSendEmailVerificationCode} className="btn-default btn_link btn_link_dotted" style={{ alignSelf: 'end', marginBottom: '20px' }}>Подтвердить</Button>}
          <div className="profileClient-body-row">
            <div className="profileClient-body-row-label">Телефон:</div>
            <div className="profileClient-body-row-value-wrapper">
              <div className="profileClient-body-row-value">{user.phone}</div>
            </div>
          </div>
          <div className="profileClient-body-row">
            <div className="profileClient-body-row-label">Часовой пояс:</div>
            <div className="profileClient-body-row-value-wrapper">
              {fieldInEdit !== 'timeZone' && <div className="profileClient-body-row-value">{user.timeZoneValue}</div>}
              {!fieldInEdit && <img src={editSvg} className="profileClient-header-edit" alt="edit" onClick={() => this.setState({ fieldInEdit: 'timeZone', fieldInEditValue: user.timeZone })} />}
              {fieldInEdit === 'timeZone'
                && (
                  <select defaultValue={user.timeZone} className="profileClient-select" onChange={(e) => this.setState({ fieldInEditValue: e.target.value })}>
                    {timeZoneCatalogue.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.value}
                      </option>
                    ))}
                  </select>
                )}
            </div>
          </div>
          {!fieldInEdit && (
          <div className="profileClient-body-row profileClient-body-row__padding" style={{ marginTop: '30px' }}>
            <div className="btn-default btn_red" onClick={() => onRedirect(routes.list)}>Найти специалиста</div>
            <div className="btn-default btn_green" onClick={() => onRedirect(routes.groupSessions)}>Найти группу</div>
          </div>
          )}
          {fieldInEdit && (
          <div className="profileClient-body-row" style={{ marginTop: '30px' }}>
            <div className="btn-default btn_red" onClick={this.onSaveClick}>Сохранить</div>
            <div className="btn-default btn_transparent" onClick={() => this.setState({ fieldInEdit: null, fieldInEditValue: null })}>Отменить</div>
          </div>
          )}
        </div>
        {isShowApproveEmailInput
      && (
      <Modal
        title=""
        onCancel={this.onCloseShowApproveEmailInput}
        visible
        footer={null}
      >
        <div>Введите, пожалуйста, код, полученный на указанный email</div>
        <PinInput
          length={6}
          initialValue=""
          secret={false}
          type="numeric"
          inputMode="number"
          onChange={this.onChangeEmailInput}
          inputStyle={{
            color: 'black',
            backgroundColor: isLoadingEmailInput ? '#f0f0f0' : '#ffffff',
            borderColor: isValidEmailInput ? '#f0f0f0' : '#f5222d',
            borderRadius: '6px',
            border: isLoadingEmailInput ? '1px solid rgb(240, 240, 240)' : '1px solid',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
          }}
          inputFocusStyle={{
            borderColor: isValidEmailInput ? '#E79F8B' : '#f5222d',
          }}
          disabled={isLoadingEmailInput}
          onComplete={(value) => this.onFinishApproveEmailInput(value)}
          autoSelect
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </Modal>
      )}
      </div>
    );
  }
}

ProfileClient.propTypes = {
  user: PropTypes.shape().isRequired,
  onRedirect: PropTypes.func.isRequired,
};


export default ProfileClient;
