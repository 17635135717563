/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import ru from 'react-phone-input-2/lang/es.json';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';
import {
  Row, Col, Upload, Card, Avatar, Spin, Popover, Progress, Tabs, Switch, Button, notification, Tag, Modal, Input, DatePicker, Select,
} from 'antd';
import {
  UserOutlined, EditTwoTone, InfoCircleTwoTone, LoadingOutlined, DownloadOutlined,
} from '@ant-design/icons';
import getUserId from '../../../../utils/localeStorage/getUserId';
import getUserLogo from '../../../../utils/localeStorage/getUserLogo';
import {
  getSpecialistProfile, addPaymentInfo, checkCard, editSpecialistProfile, editUserLogo,
} from '../../../../utils/http';
import './ProfileSpecialist.css';
import { setLocalStorage as setStorage } from '../../../../utils/localeStorage/storage';


const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;
class ProfileSpecialist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      user: null,
      paymentType: '',
      selectedFiledValue: null,
      selectedFiled: null,
      selectedTimeZone: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    Promise.all([getSpecialistProfile(getUserId())])
      .then((res) => {
        this.setState({
          user: res[0],
          files: res[0].educationFiles,
          paymentType: res[0].paymentType,
          selectedTimeZone: res[0].timeZone,
        });
      });
  }

  onFieldSelect = (selectedFiled) => {
    this.setState((prevState) => ({ selectedFiled, selectedFiledValue: prevState.user[selectedFiled.name] }));
  }

  onCloseModal = () => {
    this.setState({ selectedFiled: null, selectedFiledValue: null });
  }

  onSaveModal = () => {
    const { selectedFiledValue, selectedFiled } = this.state;
    if (selectedFiled.name === 'billingDescriptor' && (selectedFiledValue.length > 14 || !RegExp(/^[a-zA-Z]+$/).test(selectedFiledValue))) {
      notification.error({ message: 'Проверьте корректность заполнения данных' });
      return;
    }
    let value = selectedFiledValue;
    if (selectedFiled.name === 'addressesStreet') {
      value = value.replaceAll(/\\/g, '/');
    }

    if (selectedFiled.name !== 'ceoBirthDate') {
      value = value.replaceAll(/"/g, '\'');
    }

    editSpecialistProfile({ id: getUserId(), [selectedFiled.name]: value })
      .then(() => {
        this.setState((prevState) => ({
          selectedFiled: null,
          selectedFiledValue: null,
          user: { ...prevState.user, [selectedFiled.name]: selectedFiledValue },
        }));
        notification.success({ message: 'Сохранено успешно!', duration: 2 });
      });
  }

  onchangeFieldValue = (selectedFiledValue) => {
    this.setState({ selectedFiledValue });
  }

  onAddPaymentInfo = (paymentType) => {
    addPaymentInfo({ id: getUserId(), paymentType })
      .then((result) => {
        if (paymentType === 'physical' && result.addCardUrl) {
          window.open(result.addCardUrl);
          editSpecialistProfile({ id: getUserId(), paymentType: '' });
        } if (paymentType === 'legal' && result.shopCode) {
          notification.success({ message: 'Точка зарегистрирована' });
          editSpecialistProfile({ id: getUserId(), isLegalPaymentValid: true, isValidSpecialist: true });
          this.setState((prevState) => ({ user: { ...prevState.user, isLegalPaymentValid: true, isValidSpecialist: true } }));
          setStorage({ isValidSpecialist: true });
          this.onChangePaymentType('legal');
        } else {
          editSpecialistProfile({ id: getUserId(), paymentType: '' });
          notification.error({ message: 'Что-то пошло нет. Попробуйте позже' });
        }
      });
  }

  onCheckCard = () => {
    checkCard({ id: getUserId() })
      .then((cardPan) => {
        if (cardPan) {
          notification.success({ message: 'Карта привязана' });
          editSpecialistProfile({ id: getUserId(), isPhysicalPaymentValid: true, isValidSpecialist: true });
          this.setState((prevState) => ({ user: { ...prevState.user, isPhysicalPaymentValid: true, cardPan } }));
          setStorage({ isValidSpecialist: true });
          this.onChangePaymentType('physical');
          window.location.reload(false);
        } else {
          notification.error({ message: 'Мы не смогли найти вашу карту' });
        }
      });
  }

  onChangePaymentType = (paymentType) => {
    this.setState({ paymentType });
    editSpecialistProfile({ id: getUserId(), paymentType })
      .then(() => this.setState((prevState) => ({ paymentType, user: { ...prevState.user, paymentType } })));
  }

  onLogoChanges = (info) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const form = new FormData();
    const { user } = this.state;
    form.append('id', user.id);
    form.append('logo_id', info.file.originFileObj);
    editUserLogo(form, headers)
      .then((logoURL) => {
        window.location.reload();
        setStorage({ logoURL });
      });
  }

  onSaveTimeZone = () => {
    const { selectedTimeZone } = this.state;
    const { timeZoneCatalogue } = this.props;
    editSpecialistProfile({ id: getUserId(), timeZone: selectedTimeZone })
      .then(() => {
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            timeZone: selectedTimeZone,
            timeZoneValue: timeZoneCatalogue.find((e) => e.id === selectedTimeZone).value,
          },
        }));
        notification.success({ message: 'Сохранено успешно!', duration: 2 });
        setStorage({ timeZoneTimeDifference: timeZoneCatalogue.find((e) => e.id === selectedTimeZone).timeDifference });
        window.location.reload(false);
      });
  }

  onChangeTimeZone = (e) => {
    this.setState({ selectedTimeZone: e });
  }

  render() {
    const {
      user, files, paymentType, selectedFiled, selectedTimeZone,
    } = this.state;
    const { timeZoneCatalogue } = this.props;
    const addressesTypeCatalogue = [
      { id: 'legal', value: 'юридический' },
      { id: 'actual', value: 'фактический' },
      { id: 'post', value: 'почтовый' },
      { id: 'other', value: 'прочий' }];
    const addressesCountryCatalogue = [{ id: 'RUS', value: 'Россия' }];

    if (!user) {
      return (
        <div className="adminDataChangeRequestsPageDesktop-wrapper">
          <Spin indicator={<LoadingOutlined spin />} />
        </div>
      );
    }

    return (
      <div className="just-duplicate-block">
        <h1 className="profileSpecPageDesktop__title">Персональные данные</h1>
        <div className="profileSpecPageDesktop__prev">
          <div className="profilePageDesktop__prev__wrapper">
            <div>
              <div>
                <Upload
                  action={null}
                  showUploadList={false}
                  onChange={this.onLogoChanges}
                >
                  {getUserLogo() || user.logo
                    ? <Avatar src={getUserLogo() || user.logo} shape="square" size={100} className="profilePageDesktop-avatar" />
                    : <Avatar shape="square" icon={<UserOutlined />} className="profilePageDesktop-avatar" />}
                </Upload>
              </div>
            </div>
            <div>
              <div className="profilePageDesktop__prev__name">{user.firstName ? `${user.lastName} ${user.firstName} ${user.middleName}` : 'Фамилия Имя Отчество'}</div>
              <Popover className="profilePageDesktop__approved" content="Профиль верифицирован" title={null}>
                <Progress type="circle" percent={100} width={30} />
                <div className="profilePageDesktop__approved_text">Одобрен</div>
              </Popover>
            </div>
          </div>
        </div>
        <Tabs defaultActiveKey="1" className="TabPane__user TabPane__profile">
          <TabPane tab="Общая информация" key="1">
            <div className="profilePageDesktop__block-date">
              <div className="profile-input-block">
                <div className="profileLabel">Фамилия</div>
                <div flex="auto" className="profileInput">
                  {user.lastName || 'Не заполнено'}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Имя</div>
                <div flex="auto" className="profileInput">
                  {user.firstName || 'Не заполнено'}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Отчество</div>
                <div flex="auto" className="profileInput">
                  {user.middleName || 'Не заполнено'}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Дата рождения</div>
                <div flex="auto" className="profileInput">
                  {user.birthdayDate ? user.birthdayDate.format('DD MMMM YYYY') : 'Не заполнено'}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Пол</div>
                <div flex="auto" className="profileInput">
                  {user.gender || 'Не заполнено'}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Часовой пояс</div>
                <div flex="auto" className="profileInput">
                  <Select
                    className="profile-spec-timeZone"
                    style={{ width: '410px' }}
                    value={selectedTimeZone}
                    showSearch
                    onChange={this.onChangeTimeZone}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {timeZoneCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                  </Select>
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Клиентам о себе</div>
                <div flex="auto" className="profileTextArea">
                  <div dangerouslySetInnerHTML={{ __html: user.descriptionClients }} />
                </div>
              </div>
            </div>
            <div className="profilePageDesktop__block-date">
              <div className="profile-input-block">
                <div className="profileLabel">Почта</div>
                <div className="profileInput">
                  {user.email}
                </div>
              </div>
              <div className="profile-input-block">
                <div className="profileLabel">Телефон</div>
                <div className="profilePageDesktop-phone-input-disabled profileInput">
                  <PhoneInput localization={ru} value={user.phone} disabled />
                </div>
              </div>
            </div>
            <Button
              onClick={this.onSaveTimeZone}
              style={{
                backgroundColor: '#2D4D4C', color: '#fff', border: '1px #2D4D4C solid', padding: '12px 24px',
              }}
            >
              Сохранить
            </Button>
          </TabPane>
          <TabPane tab="Профессиональная информация" key="2">
            <Col>
              <Row>
                <Col>
                  <Card>
                    <Col>
                      <Row className="profilePageDesktop-title">Образование</Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Ваше основное образование</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.mainEducation || 'Не заполнено'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Ваше дополнительное образование</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.secondaryEducation || 'Не заполнено'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Фотографии развернутых дипломов и сертификатов</Col>
                        <Col>
                          {files.map((f) => (
                            <Row key={f.id} gutter={12}>
                              <Col><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></Col>
                              <Col>{f.originalFileName}</Col>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                  </Card>
                  <Card>
                    <Col>
                      <Row className="profilePageDesktop-title">Опыт работы</Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Опыт работы с LGBTQ+ людьми</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.isLGBTQ ? 'Да' : 'Нет'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Сколько полных лет вы консультируете?</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.experience || 'Не заполнено'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Есть ли опыт работы онлайн? Если да, то сколько лет?</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.experienceOnline || 'Не заполнено'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Card>
                  <Card>
                    <Col>
                      <Row className="profilePageDesktop-title profilePageDesktop-title_mini">Супервизия и Терапия</Row>
                      <Row className="profilePageDesktop-subtitle">Супервизия</Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Проходите ли вы супервизию</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.supervision}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {user.supervision !== 'Нет' && (
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Начало прохождения супервизии</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.supervisionStartDate ? user.supervisionStartDate.format('MMMM YYYY') : ''}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      )}
                      {user.supervision !== 'Нет' && (
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Сколько полных лет супервизии пройдено</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.supervisionTime}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      )}
                      {user.supervision !== 'Нет' && (
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Периодичность</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.supervisionPeriod}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      )}
                      {user.supervision !== 'Нет' && (
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Формат</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.supervisionFormat}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      )}
                      {user.supervision !== 'Нет' && (
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Супервизор (последний)</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.supervisionSpecialist}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      )}
                      <Row className="profilePageDesktop-subtitle">Терапия</Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Проходите ли вы терапию</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.therapy}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {user.therapy !== 'Нет' && (
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Объем пройденной терапии (в часах)</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.therapyTime}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      )}
                      {user.therapy !== 'Нет' && (
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Периодичность</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.therapyPeriod}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      )}
                      {user.therapy !== 'Нет' && (
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Формат</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.therapyFormat}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      )}
                      {user.therapy !== 'Нет' && (
                      <Row className="profile-input-block">
                        <Col className="profileLabel">В каком подходе вы проходите терапию?</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.therapyApproach}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      )}
                    </Col>
                  </Card>
                  <Card>
                    <Col>
                      <Row className="profilePageDesktop-title">Консультации</Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Можно записаться за (часы)</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.beforeEnrollTime}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">На каких языках можете консультировать?</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.languagesCatalogueValues.join(', ')}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Желаемая стоимость приема</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.sessionPrice}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Работаете ли вы с парами?</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.isGroupSession ? 'Да' : 'Нет'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {user.isGroupSession && (
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Желаемая стоимость приема за работу с парами</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.groupSessionPrice}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      )}
                      <Row className="profile-input-block">
                        <Col className="profileLabel">Работаю с детьми и подростками</Col>
                        <Col>
                          <Row wrap={false}>
                            <Col className="profileInput">
                              {user.isWorkWithChildren ? 'Да' : 'Нет'}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Card>
                </Col>
              </Row>
            </Col>
          </TabPane>
          <TabPane
            tab={(
              <span>
                Платежная информация
                {user.paymentType === '' && <InfoCircleTwoTone style={{ marginLeft: '5px' }} twoToneColor="#ff4d4f" />}
              </span>
            )}
            key="3"
          >
            <Col>
              <Row>
                <Col className="payment-block payment-block__left">
                  <Card>
                    <Col>
                      <Row className="payment-block__profile-title">
                        <Col>
                          <Row className="payment-block__urlico">Если вы Самозанятый</Row>
                          {user.isPhysicalPaymentValid && <Row><Tag color="green">Подтверждено</Tag></Row>}
                          {!user.isPhysicalPaymentValid && <Row><Tag color="volcano">Не подтверждено</Tag></Row>}
                        </Col>
                        <Col>
                          <Switch
                            disabled={!user.isPhysicalPaymentValid || (user.isPhysicalPaymentValid && paymentType === 'physical' && !user.isLegalPaymentValid)}
                            checked={paymentType === 'physical'}
                            onChange={(checked) => this.onChangePaymentType(checked ? 'physical' : '')}
                          />
                        </Col>
                      </Row>
                      {!user.isPhysicalPaymentValid && (
                      <Row className="payment__link-block">
                        <Button className="btn-default btn_green" type="primary" onClick={() => this.onAddPaymentInfo('physical')}>Привязать карту</Button>
                        <Button className="btn-default btn_transparent" onClick={this.onCheckCard}>Карта привязана</Button>
                      </Row>
                      )}
                      {!user.isPhysicalPaymentValid && (
                      <div className="payment__help-text">
                        <p>После ввода данных карты, нажмите кнопку «Карта привязана» для проверки введенной информации.</p>
                        <p>Если ошибок нет, то карта активируется и профиль будет разблокирован. Теперь вы можете заполнять свой календарь.</p>
                      </div>
                      )}
                      {user.isPhysicalPaymentValid && (
                      <Row gutter={20} style={{ marginTop: '20px' }}>
                        <Col span={8} style={{ fontSize: '1.5vh' }}>Привязанная карта</Col>
                        <Col span={16}>
                          <Row style={{ fontSize: '1.7vh', color: '#212121' }} gutter={8} wrap={false}>
                            <Col style={{ whiteSpace: 'break-spaces' }} flex="auto">
                              {user.cardPan}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      )}
                    </Col>
                  </Card>
                </Col>
                <Col className="payment-block payment-block__right">
                  <Card>
                    <Col>
                      <Row className="payment-block__profile-title">
                        <Col>
                          <Row className="payment-block__urlico">Если у вас ИП</Row>
                          {user.isLegalPaymentValid && <Row><Tag color="green">Подтверждено</Tag></Row>}
                          {!user.isLegalPaymentValid && <Row><Tag color="volcano">Не подтверждено</Tag></Row>}
                        </Col>
                        <Col>
                          <Switch
                            disabled={!user.isLegalPaymentValid || (user.isLegalPaymentValid && paymentType === 'legal' && !user.isPhysicalPaymentValid)}
                            checked={paymentType === 'legal'}
                            onChange={(checked) => this.onChangePaymentType(checked ? 'legal' : '')}
                          />
                        </Col>
                      </Row>
                      <Row className="payment-block__title">Общая информация</Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name payment-block__line_name_long">Название организации на иностранном языке</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.billingDescriptor || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'billingDescriptor', label: 'Название организации на иностранном языке', value: user.billingDescriptor, hint: 'Только латиница. От 1 до 14 знаков', fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name payment-block__line_name_long">Полное наименование организации</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.fullName || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'fullName', label: 'Полное наименование организации', value: user.fullName, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name payment-block__line_name_long">Сокращенное наименование</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.name || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'name', label: 'Сокращенное наименование', value: user.name, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">ИНН</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.inn || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'inn', label: 'ИНН', value: user.inn, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">КПП</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.kpp || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'kpp', label: 'КПП', value: user.kpp, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">ОГРН</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.ogrn || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'ogrn', label: 'ОГРН', value: user.ogrn, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">E-mail</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.paymentEmail || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'paymentEmail', label: 'E-mail', value: user.paymentEmail, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__title">Адрес регистрации юр. лица</Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Тип адреса</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.addressesType ? addressesTypeCatalogue.find((c) => c.id === user.addressesType).value : 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'addressesType', label: 'Тип адреса', value: user.addressesType, fieldType: 'select', catalogue: addressesTypeCatalogue,
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Индекс</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.addressesZip || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'addressesZip', label: 'Индекс', value: user.addressesZip, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Страна</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.addressesCountry || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'addressesCountry', label: 'Страна', value: user.addressesCountry, fieldType: 'select', catalogue: addressesCountryCatalogue,
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Город</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.addressesCity || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'addressesCity', label: 'Город', value: user.addressesCity, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Улица и дом</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.addressesStreet || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'addressesStreet', label: 'Улица и дом', value: user.addressesStreet, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__title">Сведения о руководителе</Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Фамилия</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.ceoLastName || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'ceoLastName', label: 'Фамилия', value: user.ceoLastName, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Имя</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.ceoFirstName || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'ceoFirstName', label: 'Имя', value: user.ceoFirstName, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Отчество</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.ceoMiddleName || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'ceoMiddleName', label: 'Отчество', value: user.ceoMiddleName, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Дата рождения</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.ceoBirthDate ? user.ceoBirthDate.format('DD MMMM YYYY') : 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'ceoBirthDate', label: 'Дата рождения', value: user.ceoBirthDate, fieldType: 'datePicker',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Телефон</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.ceoPhone || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'ceoPhone', label: 'Телефон', value: user.ceoPhone, fieldType: 'input', hint: 'Пример: 89101234567',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Страна</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.ceoCountry || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'ceoCountry', label: 'Страна', value: user.ceoCountry, fieldType: 'select', catalogue: addressesCountryCatalogue,
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__title">Реквизиты счета</Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Расчетный счет</Col>
                        <Col span={12}>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.bankAccountAccount || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'bankAccountAccount', label: 'Расчетный счет', value: user.bankAccountAccount, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Наименование банка</Col>
                        <Col>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.bankAccountBankName || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'bankAccountBankName', label: 'Наименование банка', value: user.bankAccountBankName, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">БИК банка</Col>
                        <Col>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.bankAccountBik || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'bankAccountBik', label: 'БИК банка', value: user.bankAccountBik, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="payment-block__line">
                        <Col className="payment-block__line_name">Назначение платежа</Col>
                        <Col>
                          <Row className="payment-block__line_input">
                            <Col className="payment-block__line_input_date">
                              {user.bankAccountDetails || 'Не заполнено'}
                            </Col>
                            <Col className="payment-block__line_edit">
                              <EditTwoTone
                                color="#1890ff"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.onFieldSelect({
                                  name: 'bankAccountDetails', label: 'Назначение платежа', value: user.bankAccountDetails, fieldType: 'input',
                                })}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {!user.isLegalPaymentValid && (
                      <Row style={{ marginTop: '20px' }}>
                        <Button className="btn-default btn_green" type="primary" onClick={() => this.onAddPaymentInfo('legal')}>Зарегистрироваться</Button>
                      </Row>
                      )}
                    </Col>
                  </Card>
                </Col>
              </Row>
            </Col>
          </TabPane>
        </Tabs>
        {selectedFiled
      && (
      <Modal
        title={selectedFiled.label}
        onCancel={this.onCloseModal}
        visible
        footer={[
          <Button key="back" onClick={this.onCloseModal}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" onClick={this.onSaveModal}>
            Сохранить
          </Button>,
        ]}
      >
        <div className="modalBlock__fields-noname">
          {selectedFiled.hint && <Row style={{ fontSize: '1.3vh', marginBottom: '5px' }}>{selectedFiled.hint}</Row>}
          <div>
            {selectedFiled.fieldType === 'input' && <Input onChange={(e) => this.onchangeFieldValue(e.target.value)} defaultValue={selectedFiled.value} style={{ width: '100%' }} />}
            {selectedFiled.fieldType === 'datePicker' && <DatePicker defaultValue={selectedFiled.value} style={{ width: '100%' }} onChange={this.onchangeFieldValue} />}
            {selectedFiled.fieldType === 'textArea' && <TextArea defaultValue={selectedFiled.value} style={{ width: '100%' }} rows={4} onChange={(e) => this.onchangeFieldValue(e.target.value)} />}
            {selectedFiled.fieldType === 'select' && (
            <Select onChange={(value) => this.onchangeFieldValue(value)} defaultValue={selectedFiled.value} style={{ width: '100%' }}>
              {selectedFiled.catalogue.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
            </Select>
            )}
          </div>
        </div>
      </Modal>
      )}
      </div>
    );
  }
}

ProfileSpecialist.propTypes = {
  timeZoneCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};


export default ProfileSpecialist;
