import React from 'react';
import { Tag } from 'antd';


const getStatusTag = (statusId) => {
  let tag = '';
  switch (statusId) {
    case 'onApproval':
      tag = (
        <Tag
          color="gold"
        >
          На согласовании
        </Tag>
      );
      break;
    case 'approved':
      tag = (
        <Tag
          color="green"
        >
          Согласовано
        </Tag>
      );
      break;
    case 'declined':
      tag = (
        <Tag
          color="red"
        >
          Отклонено
        </Tag>
      );
      break;
    case 'isHidden':
      tag = (
        <Tag
          color="black"
        >
          Удалено
        </Tag>
      );
      break;
    default: break;
  }
  return tag;
};

export default getStatusTag;
