import React from 'react';
import {
  // SettingOutlined,
  StopOutlined,
  CustomerServiceOutlined,
  TeamOutlined,
  FormOutlined,
  // LoginOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { routes } from '../../../utils/routes';


const adminButtonsArray = [
  {
    name: 'Наши специалисты',
    id: 'adminSpecialists',
    icon: <TeamOutlined />,
    route: routes.adminSpecialists,
  },
  {
    name: 'Групповые сессии',
    id: 'adminGroupSessions',
    icon: <CustomerServiceOutlined />,
    route: routes.adminGroupSessions,
  },
  {
    name: 'Управление пользователями',
    id: 'adminManualUsers',
    icon: <PlusCircleOutlined />,
    route: routes.adminManualUsers,
  },
  {
    name: 'Запросы на изменение данных',
    id: 'adminDataChangeRequests',
    icon: <FormOutlined />,
    route: routes.adminDataChangeRequests,
  },
  {
    name: 'Заявки на отмену',
    id: 'adminCancelRequests',
    icon: <StopOutlined />,
    route: routes.adminCancelRequests,
  },
  {
    name: 'Поддержка',
    id: 'help',
    icon: <QuestionCircleOutlined />,
    route: routes.help,
  },
  // {
  //   name: 'Входы в систему',
  //   id: 'adminLogin',
  //   icon: <LoginOutlined />,
  //   route: routes.adminLogin,
  // },
  // {
  //   name: 'Управление полями',
  //   id: 'adminFields',
  //   icon: <SettingOutlined />,
  //   route: routes.adminFields,
  // },
];

export default adminButtonsArray;
