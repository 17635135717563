import React from 'react';
import { Space, Button, Popconfirm } from 'antd';


const getClientColumns = (isShowActions, onConfirm, onCancel) => [
  {
    title: 'Имя',
    dataIndex: 'clientName',
    key: 'clientName',
  },
  {
    title: 'Телефон',
    dataIndex: 'clientPhone',
    key: 'clientPhone',
  },
  {
    title: 'E-mail',
    dataIndex: 'clientEmail',
    key: 'clientEmail',
  },
  {
    title: '',
    key: 'action',
    render: (text, record) => isShowActions && record.statusID === 'toBePaid' && (
      <Space size="middle">
        <Popconfirm
          title="Вы уверены, что хотите подтвердить?"
          onConfirm={() => onConfirm(record)}
          okText="Да"
          cancelText="Нет"
        >
          <Button type="link">Подтвердить</Button>
        </Popconfirm>
        <Popconfirm
          title="Вы уверены, что хотите отменить?"
          onConfirm={() => onCancel(record)}
          okText="Да"
          cancelText="Нет"
        >
          <Button type="link">Отменить</Button>
        </Popconfirm>
      </Space>
    ),
    align: 'right',
  },
];

export default getClientColumns;

