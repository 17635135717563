import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card, Avatar, Alert, Col, Spin, Row, Slider, Select,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import iconexperience from '../../../assets/icons/spec-experience.svg';
import iconprice from '../../../assets/icons/spec-price.svg';
import iconeage from '../../../assets/icons/spec-age.svg';
import './PsychoListPageDesktop.css';
import ageEnding from '../../../utils/ageEnding';


const { Option } = Select;
const { Meta } = Card;
class PsychoListPageDesktop extends Component {
  render() {
    const {
      specList, isSessionExist, onPsychoClick, languagesCatalogue, setFilter, filters,
      specialistAgeCatalogue, sessionPriceCatalogue, isInitLoading,
    } = this.props;
    if (isInitLoading) {
      return (
        <Col className="profilePageDesktop_center">
          <div className="psychoListPageDesktop-loading">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
          </div>
        </Col>
      );
    }
    return (
      <div className="profilePageDesktop_center">
        {isSessionExist && (
        <Alert
          message="Внимание! Есть неоплаченные сессии или уже выбран терапевт"
          description="Чтобы записаться к новому специалисту, необходимо сначала открепиться от старого или отменить запись"
          type="warning"
          showIcon
        />
        )}
        {!isSessionExist && (
        <div className="psycho-list">
          <h1 className="profilePageDesktop-form-column-title">Каталог психологов</h1>
          <Row className="psychoListPageDesktop-filter">
            <div className="psychoListPageDesktop-filter-wrapper">
              <div className="psychoListPageDesktop-filter__lang">Какой возраст должен быть у специалиста?</div>
              <Slider
                range
                tooltipVisible
                min={specialistAgeCatalogue.min}
                max={specialistAgeCatalogue.max}
                value={filters.find((f) => f.id === 'age').value}
                onChange={(e) => setFilter(e, 'age')}
                marks={{
                  [specialistAgeCatalogue.min]: specialistAgeCatalogue.min,
                  [specialistAgeCatalogue.max]: specialistAgeCatalogue.max,
                }}
              />
            </div>
            <div className="psychoListPageDesktop-filter-wrapper psychoListPageDesktop-filter-wrapper_margin-bottom">
              <div className="psychoListPageDesktop-filter__lang">Стоимость услуг</div>
              <Slider
                range
                tooltipVisible
                min={sessionPriceCatalogue.min}
                max={sessionPriceCatalogue.max}
                value={filters.find((f) => f.id === 'sessionPrice').value}
                onChange={(e) => setFilter(e, 'sessionPrice')}
                marks={{
                  [sessionPriceCatalogue.min]: sessionPriceCatalogue.min,
                  [sessionPriceCatalogue.max]: sessionPriceCatalogue.max,
                }}
              />
            </div>
            <div className="psychoListPageDesktop-filter-wrapper psychoListPageDesktop-filter-wrapper_margin-bottom">
              <div className="psychoListPageDesktop-filter__lang">Язык консультации</div>
              <Select
                onChange={(e) => setFilter(e, 'language')}
                mode="multiple"
                value={filters.find((f) => f.id === 'language').value}
              >
                {languagesCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
              </Select>
            </div>
            <div className="psychoListPageDesktop-filter-wrapper psychoListPageDesktop-filter-wrapper_margin-bottom">
              <div className="psychoListPageDesktop-filter__lang">Пол специалиста</div>
              <Select
                onChange={(e) => setFilter(e, 'gender')}
                mode="multiple"
                value={filters.find((f) => f.id === 'gender').value}
              >
                {[{ id: 'Мужской', value: 'Мужской' }, { id: 'Женский', value: 'Женский' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
              </Select>
            </div>
            <div className="psychoListPageDesktop-filter-wrapper psychoListPageDesktop-filter-wrapper_margin-bottom">
              <div className="psychoListPageDesktop-filter__lang">Желаемое время консультации</div>
              <Select
                onChange={(e) => setFilter(e, 'time')}
                mode="multiple"
                value={filters.find((f) => f.id === 'time').value}
              >
                {[{ id: 'Утро', value: 'Утро' }, { id: 'День', value: 'День' }, { id: 'Вечер', value: 'Вечер' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
              </Select>
            </div>
          </Row>

          <div className="psycho-list__wrapper">
            <div className="psychoListPageDesktop-row">
              {specList.map((sp) => (
                <Card
                  className="ant-card-meta-description__item"
                  key={sp.id}
                  hoverable={!isSessionExist}
                  onClick={isSessionExist ? () => {} : () => onPsychoClick(sp)}
                >
                  <Meta
                    description={(
                      <div className="specialist-item">
                        <div className="specialist-item__img"><Avatar src={sp.logoURL} /></div>
                        <h2 className="specialist-item__name">{`${sp.lastName} ${sp.firstName}`}</h2>
                        <div className="specialist-item__subname">Гештальт-терапевт</div>
                        <div className="specialist-item__param">
                          <div className="specialist-item__exp specialist-item__param_col">
                            <img src={iconexperience} alt="Опыт работы" />
                            <h3>Опыт</h3>
                            <div className="specialist-item__sub">{ageEnding(sp.experience)}</div>
                          </div>
                          <div className="specialist-item__price specialist-item__param_col">
                            <img src={iconprice} alt="Стоимость приема" />
                            <h3>Цена</h3>
                            <div className="specialist-item__sub">{`${sp.sessionPrice} руб`}</div>
                          </div>
                          <div className="specialist-item__years specialist-item__param_col">
                            <img src={iconeage} alt="Возраст терапевта" />
                            <h3>Возраст</h3>
                            <div className="specialist-item__sub">{sp.birthdayDate ? ageEnding(moment().diff(sp.birthdayDate, 'years')) : ''}</div>
                          </div>
                        </div>
                        <div className="specialist-item__btn-row">
                          <button type="submit" className="btn-default btn_transparent btn_mini" onClick={() => onPsychoClick(sp)}>Подробнее</button>
                          <button type="submit" className="btn-default btn_red btn_mini" onClick={() => onPsychoClick(sp)}>Записаться</button>
                        </div>
                      </div>
                    )}
                  />
                </Card>
              ))}
            </div>
          </div>
        </div>
        )}
      </div>
    );
  }
}

PsychoListPageDesktop.propTypes = {
  specList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isSessionExist: PropTypes.bool.isRequired,
  onPsychoClick: PropTypes.func.isRequired,
  languagesCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setFilter: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  specialistAgeCatalogue: PropTypes.shape().isRequired,
  sessionPriceCatalogue: PropTypes.shape().isRequired,
  isInitLoading: PropTypes.bool.isRequired,
};

export default PsychoListPageDesktop;
