import React, { Component } from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import Clients from './Components/Clients';
import GroupSessions from './Components/GroupSessions';
import CalendarTimeSlots from './Components/CalendarTimeSlots';
import CalendarSessions from './Components/CalendarSessions';
import './CalendarPageDesktop.css';


const { TabPane } = Tabs;
class CalendarPageDesktop extends Component {
  render() {
    const {
      onWeekSelect, firstDayOfWeek, slots, onCheckBoxChange, sessions, onSave, onQuikSelectClick, onShowCloseTherapyModal,
      isShowCloseTherapyModal, onSaveCloseTherapyModal, onCloseCloseTherapyModal, formRef, onRowChange, onChangeSessionStatus,
      onMonthSelect, selectedMonth, selectedDay, onDaySelect, selectedYear, onYearSelect, onStartSession, selectedProfile,
      onCloseModal, onShowProfile, onColumnChange, onAddSession, isShowAddSession, selectedDate, selectedTime, onEnroll,
      onCloseAddSession, onSelectTimeAndDate, groupSessions, onEditClientNameClick, onSaveEditClientName, onCancelEditClientName,
      editClientId, onChangeEditClientName, editClientName, defaultActiveView,
    } = this.props;
    return (
      <div className="profilePageDesktop_center">
        <h1 className="profileSpecPageDesktop__title">Расписание</h1>
        <Tabs defaultActiveKey={defaultActiveView} className="TabPane__user TabPane__calendar">
          <TabPane tab="Текущие записи" key="1">
            <CalendarSessions
              sessions={sessions}
              onMonthSelect={onMonthSelect}
              selectedMonth={selectedMonth}
              selectedDay={selectedDay}
              onDaySelect={onDaySelect}
              selectedYear={selectedYear}
              onYearSelect={onYearSelect}
              onChangeSessionStatus={onChangeSessionStatus}
              onStartSession={onStartSession}
              selectedProfile={selectedProfile}
              onCloseModal={onCloseModal}
              onShowProfile={onShowProfile}
              onEditClientNameClick={onEditClientNameClick}
              onSaveEditClientName={onSaveEditClientName}
              onCancelEditClientName={onCancelEditClientName}
              editClientId={editClientId}
              onChangeEditClientName={onChangeEditClientName}
              editClientName={editClientName}
            />
          </TabPane>
          <TabPane tab="Мои часы работы" key="2">
            <CalendarTimeSlots
              onWeekSelect={onWeekSelect}
              firstDayOfWeek={firstDayOfWeek}
              slots={slots}
              onCheckBoxChange={onCheckBoxChange}
              sessions={sessions}
              onSave={onSave}
              onQuikSelectClick={onQuikSelectClick}
              onShowCloseTherapyModal={onShowCloseTherapyModal}
              isShowCloseTherapyModal={isShowCloseTherapyModal}
              onCloseCloseTherapyModal={onCloseCloseTherapyModal}
              onSaveCloseTherapyModal={onSaveCloseTherapyModal}
              formRef={formRef}
              onRowChange={onRowChange}
              onColumnChange={onColumnChange}
              groupSessions={groupSessions}
            />
          </TabPane>
          <TabPane tab="Мои клиенты" key="3">
            <Clients
              sessions={sessions}
              onAddSession={onAddSession}
              isShowAddSession={isShowAddSession}
              onCloseAddSession={onCloseAddSession}
              timeSlots={slots}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              onEnroll={onEnroll}
              onSelect={onSelectTimeAndDate}
            />
          </TabPane>
          <TabPane tab="Групповые сессии" key="4">
            <GroupSessions
              sessions={sessions}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

CalendarPageDesktop.propTypes = {
  onWeekSelect: PropTypes.func.isRequired,
  firstDayOfWeek: PropTypes.shape().isRequired,
  slots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onCheckBoxChange: PropTypes.func.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSave: PropTypes.func.isRequired,
  onQuikSelectClick: PropTypes.func.isRequired,
  onShowCloseTherapyModal: PropTypes.func.isRequired,
  isShowCloseTherapyModal: PropTypes.bool.isRequired,
  onSaveCloseTherapyModal: PropTypes.func.isRequired,
  onCloseCloseTherapyModal: PropTypes.func.isRequired,
  formRef: PropTypes.shape().isRequired,
  onRowChange: PropTypes.func.isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  selectedMonth: PropTypes.shape().isRequired,
  onDaySelect: PropTypes.func.isRequired,
  selectedDay: PropTypes.shape().isRequired,
  selectedYear: PropTypes.number.isRequired,
  onYearSelect: PropTypes.func.isRequired,
  onStartSession: PropTypes.func.isRequired,
  onChangeSessionStatus: PropTypes.func.isRequired,
  onShowProfile: PropTypes.func.isRequired,
  selectedProfile: PropTypes.shape(),
  onCloseModal: PropTypes.func.isRequired,
  onColumnChange: PropTypes.func.isRequired,
  onAddSession: PropTypes.func.isRequired,
  isShowAddSession: PropTypes.bool.isRequired,
  selectedDate: PropTypes.shape(),
  selectedTime: PropTypes.string,
  onEnroll: PropTypes.func.isRequired,
  onCloseAddSession: PropTypes.func.isRequired,
  onSelectTimeAndDate: PropTypes.func.isRequired,
  groupSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onEditClientNameClick: PropTypes.func.isRequired,
  onSaveEditClientName: PropTypes.func.isRequired,
  onCancelEditClientName: PropTypes.func.isRequired,
  editClientId: PropTypes.string,
  editClientName: PropTypes.string,
  onChangeEditClientName: PropTypes.func.isRequired,
  defaultActiveView: PropTypes.string.isRequired,
};

CalendarPageDesktop.defaultProps = {
  selectedProfile: null,
  selectedDate: null,
  selectedTime: null,
  editClientId: null,
  editClientName: '',
};

export default CalendarPageDesktop;
