import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin, Dropdown, Menu } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './ProfilePageMobile.css';
import MobiMenu from '../../../assets/icons/mobi-menu.svg';
import MobiLogo from '../../../assets/icons/mobi-logo.svg';
import ProfileSpecialist from './components/ProfileSpecialist';
import ProfileClient from './components/ProfileClient';
import * as roles from '../../../utils/roles';
import Anketa from '../../../components/Anketa/Anketa';
import getUserRole from '../../../utils/localeStorage/getUserRole';
import adminButtonsArray from '../../../components/SideBar/utils/adminButtonsArray';
import specialistButtonsArray from '../../../components/SideBar/utils/specialistButtonsArray';
import clientButtonsArray from '../../../components/SideBar/utils/clientButtonsArray';
import getIsValidClient from '../../../utils/localeStorage/getIsValidClient';
import getIsValidSpecialist from '../../../utils/localeStorage/getIsValidSpecialist';
import logout from '../../../utils/logout';
import getSessionId from '../../../utils/localeStorage/getSessionId';
import { routes } from '../../../utils/routes';
import { editUser, getUserAfterLogin } from '../../../utils/http';
import { setLocalStorage } from '../../../utils/localeStorage/storage';
import getUserId from '../../../utils/localeStorage/getUserId';


class ProfilePageMobile extends Component {
  handleMenuButtonClick = (route) => {
    const { history } = this.props;
    history.push(route);
  }

  changeRole = () => {
    const { history } = this.props;
    editUser({ id: getUserId(), role: getUserRole() === roles.CLIENT ? roles.SPECIALIST : roles.CLIENT })
      .then(() => getUserAfterLogin(getUserId(), getSessionId())
        .then((user) => {
          const payload = {
            ...user,
            isValidSpecialist: user.isValidSpecialist && !!user.paymentType,
          };
          setLocalStorage(payload);
          // window.location.reload();
          history.push(routes.profile);
        }));
  }

  render() {
    const {
      user, onSaveForm, onRedirect, history, timeZoneCatalogue,
    } = this.props;
    if (!user.id) {
      return (
        <div className="adminDataChangeRequestsPageDesktop-wrapper" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
        </div>
      );
    }

    let buttonsArray = [];
    switch (getUserRole()) {
      case roles.ADMIN:
        buttonsArray = adminButtonsArray;
        break;
      case roles.CLIENT:
        buttonsArray = clientButtonsArray;
        break;
      case roles.SPECIALIST:
        buttonsArray = specialistButtonsArray;
        break;
      default: break;
    }
    let isDisabled = true;
    if (getUserRole() === roles.CLIENT && getIsValidClient() === 'true') {
      isDisabled = false;
    }
    if (getUserRole() === roles.ADMIN) {
      isDisabled = false;
    }
    if (getUserRole() === roles.SPECIALIST && getIsValidSpecialist() === 'true') {
      isDisabled = false;
    }
    const menu = (
      <Menu>
        {buttonsArray
          .filter((b) => b.id !== 'help')
          .map((b) => (
            <Menu.Item
              key={b.id}
              disabled={isDisabled}
              onClick={() => this.handleMenuButtonClick(b.route)}
            >
              {b.name}
            </Menu.Item>
          ))}
        <Menu.Divider />
        {getUserId() && <Menu.Item key="2" onClick={this.changeRole}>Сменить роль</Menu.Item>}
        {getUserId() && <Menu.Divider />}
        <Menu.Item key="3" onClick={() => logout(history)} style={{ cursor: 'pointer' }}>Выйти</Menu.Item>
      </Menu>
    );

    return (
      <div className="profile-wrapper">
        <div className="profile-wrapper__header mobile-header__wrapper">
          <div className="mobi-logo"><a href="https://gestalt.online"><img src={MobiLogo} alt="Вернуться на главную" /></a></div>
          <span className="profile-title mobile-header__title">Профиль</span>
          <div className="profile-header-menu">
            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
              <img src={MobiMenu} alt="Мобильное меню" />
            </Dropdown>

          </div>
        </div>
        {getUserRole() === roles.SPECIALIST && user.status === 'approved' && (
        <ProfileSpecialist
          user={user}
          onSaveForm={onSaveForm}
          onRedirect={onRedirect}
          timeZoneCatalogue={timeZoneCatalogue}
        />
        )}
        {getUserRole() === roles.CLIENT && (
        <ProfileClient
          user={user}
          onRedirect={onRedirect}
          timeZoneCatalogue={timeZoneCatalogue}
        />
        )}
        {getUserRole() === roles.SPECIALIST && user.status !== 'approved' && (
        <Anketa />
        )}
      </div>
    );
  }
}

ProfilePageMobile.propTypes = {
  user: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  onSaveForm: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  timeZoneCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};


export default ProfilePageMobile;
