import React, { Component } from 'react';
import { withRouter } from 'react-router';
// import { get } from 'lodash';
// import PropTypes from 'prop-types';
import {
  Spin,
} from 'antd';
import {
  LoadingOutlined,
} from '@ant-design/icons';
// import { checkEmailCode } from '../../utils/http';


class EmailVerificationModule extends Component {
  // componentDidMount() {
  //   const { history } = this.props;
  //   const code = get(history, 'location.pathname', '').split('/')[2];
  // }

  render() {
    return (
      <div style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#e5e3dc', height: '100vh', width: '100vw',
      }}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
      </div>
    );
  }
}


// EmailVerificationModule.propTypes = {
//   history: PropTypes.shape(),
// };

// EmailVerificationModule.defaultProps = {
//   history: {},
// };

export default withRouter(EmailVerificationModule);
