const getStatColumns = () => [
  {
    title: 'Клиент',
    dataIndex: 'clientName',
    key: 'clientName',
  },
  {
    title: 'Кол-во сессий',
    dataIndex: 'numberOfSessions',
    key: 'numberOfSessions',
  },
];

export default getStatColumns;

