import React, { Component } from 'react';
import { notification } from 'antd';
import moment from 'moment';
import { config } from '../../utils/config';
import HelpPageDesktop from './Desktop/HelpPageDesktop';
import {
  getUserHelpRequests, getAdminHelpRequests, editHelpRequests, editHelpRequestsComments,
} from '../../utils/http';
import getUserRole from '../../utils/localeStorage/getUserRole';
import getUserId from '../../utils/localeStorage/getUserId';


class HelpModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
      loading: true,
      helpRequests: [],
      selectedHelpRequest: null,
      fieldLoading: false,
      commentValue: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (getUserRole() === 'as2f2fc34') {
      getAdminHelpRequests()
        .then((helpRequests) => this.setState({
          loading: false,
          helpRequests,
        }));
    } else {
      getUserHelpRequests(getUserId())
        .then((helpRequests) => this.setState({
          loading: false,
          helpRequests,
        }));
    }
  }

  addHelpRequest = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        this.setState({ fieldLoading: true });
        editHelpRequests({
          ...values, displayID: moment().format('DDMMYY-HHss'), createDate: moment().toISOString(), author_id: getUserId(), status: 'Открыто',
        })
          .then((helpRequest) => {
            notification.success({ message: 'Успешно сохранено' });
            this.setState((prevState) => ({
              fieldLoading: false,
              helpRequests: [...prevState.helpRequests, helpRequest],
              selectedHelpRequest: null,
            }));
          });
      })
      .catch(() => notification.error({ message: 'Не сохранено. Заполните обязательные поля' }));
  }

  editHelpRequestStatus = () => {
    const { selectedHelpRequest } = this.state;
    this.setState({ fieldLoading: true });
    editHelpRequests({
      id: selectedHelpRequest.id, status: 'Закрыто',
    })
      .then(() => {
        notification.success({ message: 'Успешно сохранено' });
        this.setState((prevState) => ({
          fieldLoading: false,
          selectedHelpRequest: null,
          helpRequests: prevState.helpRequests.map((h) => (h.id === selectedHelpRequest.id ? { ...h, status: 'Закрыто' } : h)),
        }));
      });
  }

  onCloseDrawer = () => {
    this.setState({ selectedHelpRequest: null });
  }

  onSelectHelpRequest = (selectedHelpRequest) => {
    if (getUserRole() === 'as2f2fc34' && selectedHelpRequest.adminUnreadCount > 0) {
      this.setState((prevState) => ({
        selectedHelpRequest,
        helpRequests: prevState.helpRequests.map((h) => (h.id === selectedHelpRequest.id ? { ...h, adminUnreadCount: 0 } : h)),
      }));
      editHelpRequests({ id: selectedHelpRequest.id, adminUnreadCount: 0 });
    } else if (getUserRole() !== 'as2f2fc34' && selectedHelpRequest.authorUnreadCount > 0) {
      this.setState((prevState) => ({
        selectedHelpRequest,
        helpRequests: prevState.helpRequests.map((h) => (h.id === selectedHelpRequest.id ? { ...h, authorUnreadCount: 0 } : h)),
      }));
      editHelpRequests({ id: selectedHelpRequest.id, authorUnreadCount: 0 });
    } else {
      this.setState({ selectedHelpRequest });
    }
  }

  onAddComment = () => {
    const { commentValue, selectedHelpRequest } = this.state;
    if (!commentValue) {
      notification.error({ message: 'Введите комментарий' });
      return;
    }
    this.setState({ fieldLoading: true });
    editHelpRequestsComments({
      comment: commentValue, author_id: getUserId(), helpRequests_id: selectedHelpRequest.id, date: moment().toISOString(),
    })
      .then((helpRequestsComment) => {
        notification.success({ message: 'Комментарий добавлен' });
        this.setState((prevState) => ({
          selectedHelpRequest: {
            ...prevState.selectedHelpRequest,
            helpRequestsComments: [...prevState.selectedHelpRequest.helpRequestsComments, helpRequestsComment],
          },
          helpRequests: prevState.helpRequests
            .map((h) => (h.id === selectedHelpRequest.id
              ? { ...h, helpRequestsComments: [...h.helpRequestsComments, helpRequestsComment] }
              : h)),
          commentValue: null,
          fieldLoading: false,
        }));
      });
  }

  handleChangeCommentValue = (e) => {
    this.setState({ commentValue: e.target.value });
  };

  onAddHelpRequest = () => {
    this.setState({ selectedHelpRequest: {} });
  }

  render() {
    const {
      isMobile, loading, helpRequests, selectedHelpRequest, fieldLoading, commentValue,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <HelpPageDesktop
        loading={loading}
        helpRequests={helpRequests}
        formRef={this.formRef}
        fieldLoading={fieldLoading}
        selectedHelpRequest={selectedHelpRequest}
        onCloseDrawer={this.onCloseDrawer}
        onSelectHelpRequest={this.onSelectHelpRequest}
        onAddComment={this.onAddComment}
        commentValue={commentValue}
        handleChangeCommentValue={this.handleChangeCommentValue}
        editHelpRequestStatus={this.editHelpRequestStatus}
        onAddHelpRequest={this.onAddHelpRequest}
        addHelpRequest={this.addHelpRequest}
      />
    );
  }
}

export default HelpModule;
