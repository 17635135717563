import React from 'react';
import { routes } from '../../../utils/routes';


const specialistButtonsArray = [
  {
    name: 'Профиль',
    id: 'profile',
    icon: <div className="sideBarButton__icon sideBarButton__icon_profile" />,
    route: routes.profile,
  },
  {
    name: 'Календарь',
    id: 'calendar',
    icon: <div className="sideBarButton__icon sideBarButton__icon_calendar" />,
    route: routes.calendar,
  },
  {
    name: 'Группы',
    id: 'specialistGroups',
    icon: <div className="sideBarButton__icon sideBarButton__icon_group" />,
    route: routes.specialistGroups,
  },
  {
    name: 'Поддержка',
    id: 'help',
    icon: <div className="sideBarButton__icon sideBarButton__icon_help" />,
    route: routes.help,
  },
];

export default specialistButtonsArray;
