/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import logo from '../../../assets/images/logo.svg';
import readmore from '../../../assets/icons/arrow-long-right.svg';
import enter from '../../../assets/icons/enter.svg';
import specAge from '../../../assets/icons/spec-age.svg';
import specExp from '../../../assets/icons/spec-experience.svg';
import specPrice from '../../../assets/icons/spec-price.svg';
import homeBigImage from '../../../assets/images/nadezda-lubanickaja.jpg';
import ImagesOne from '../../../assets/images/one.jpg';
import ImagesGroup from '../../../assets/images/group.jpg';
import ImagesPers1 from '../../../assets/images/anna-sergeeva.jpg';
import ImagesPers2 from '../../../assets/images/petr-krotov.jpg';
import ImagesPers3 from '../../../assets/images/valentina-popova.jpg';
import reviewsFoto from '../../../assets/images/reviews.png';
import getNickName from '../../../utils/localeStorage/getNickName';
import getUserFirstName from '../../../utils/localeStorage/getUserFirstName';
import './HomePageDesktop.css';


class HomePageDesktop extends Component {
  render() {
    const {
      onRegisterClick, onRegisterPsychoClick, articles,
    } = this.props;
    return (
      <div className="homePageDesktop-wrapper">
        <div className="homePageDesktop-header-wrapper">
          <div className="center-1440 header-flex">
            <img src={logo} className="homePageDesktop-header-logo header-logo" alt="Гештальт онлайн" />
            <div className="homePageDesktop-header-wrapper-second">
              {/* <a href="#0" className="homePageDesktop-header-buttons-title">О нас</a> */}
              {/* <a href="#0" className="homePageDesktop-header-buttons-title">Антикризисное предложение</a> */}
              <a href="#0" className="homePageDesktop-header-buttons-title">Блог</a>
              <a href="#0" className="homePageDesktop-header-buttons-title" onClick={onRegisterPsychoClick}>Психологам</a>
            </div>
            <div className="header-buttons">
              <Button
                type="button"
                className="btn-default btn_green"
                onClick={onRegisterClick}
              >
                Подобрать группу
              </Button>
              {!getNickName() && !getUserFirstName()
              && (
              <Button
                type="primary"
                className="btn-default btn_red"
                onClick={onRegisterClick}
              >
                Выбрать психолога
              </Button>
              )}
              <div className="button-enter">
                <img src={enter} className="icon-enter" alt="Вход для зарегистрированных пользователей" />
                <Button
                  className="btn_link"
                  onClick={onRegisterClick}
                  type={getNickName() || getUserFirstName() ? 'primary' : 'default'}
                >
                  {getNickName() || getUserFirstName() ? `Войти, ${getNickName() || getUserFirstName()}` : 'Войти'}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="body-wrapper">
          <div className="homePageDesktop-homeFirstScreen center-1200">
            <div className="homePageDesktop-homeFirstScreen-left">
              <h1 className="mp__h1">
                В трудный момент&nbsp;
                <span>мы рядом</span>
              </h1>
              <div className="mp__h1_sub">
                Сервис, созданный профессиональными психологами
              </div>
              <div className="mp__h1_text">
                Групповая и индивидуальная работа
              </div>
              <Button
                type="primary"
                onClick={onRegisterClick}
                className="btn-default btn_transparent"
              >
                Выбрать терапевта
              </Button>
            </div>
            <div className="homePageDesktop-homeBigImage-wrapper">
              <div className="homePageDesktop-homeBigImage-text">Надежда Лубяницкая, гештальт-терапевт, ректор Московского института гештальта и психодрамы, руководитель Центра Надежды Лубяницкой</div>
              <img src={homeBigImage} className="homePageDesktop-homeBigImage" alt="Онлайн консультации с психологом" />
            </div>
          </div>

          <div className="homePageDesktop-numWrapper center-1200">
            <div className="homePageDesktop-kindWrapper">
              <div className="homePageDesktop-kindWrapper-1 homePageDesktop-kindWrapper-col">
                <div className="homePageDesktop-kindWrapper-title">Личная терапия</div>
                <div className="homePageDesktop-kindWrapper-text">
                  <p>Личная терапия позволяет разобраться с конкретными ситуациями, которые кажутся неразрешимыми: от приступов тревоги и любовных переживаний до поиска смысла жизни. В индивидуальной работе хорошо видны повторяющиеся паттерны характера и возможна их проработка в конакте с поддерживающим терапевтом.</p>
                </div>
                <img src={ImagesOne} alt="Личная терапия" />
                <Button
                  type="primary"
                  className="btn-default btn_red"
                  onClick={onRegisterClick}
                >
                  Выбрать психолога
                </Button>
              </div>
              <div className="homePageDesktop-kindWrapper-2 homePageDesktop-kindWrapper-col">
                <div className="homePageDesktop-kindWrapper-title">Терапевтические группы</div>
                <div className="homePageDesktop-kindWrapper-text">
                  <p>Групповая терапия — особенно эффективное направление в Гештальте. В диалоге с другими вы лучше осознаете свои ценности, границы и опоры. Групповая терапия полезна тем, кто нуждается в поддержке и ищет близких людей для проживания непростых моментов и лучшего понимания себя во взаимодействии с другими.</p>
                </div>
                <img src={ImagesGroup} alt="Групповая терапия" />
                <Button
                  type="button"
                  className="btn-default btn_green"
                  onClick={onRegisterClick}
                >
                  Подобрать группу
                </Button>
              </div>
            </div>
          </div>
          <div className="homePageDesktop-gestalt center-1440">
            <h2>Почему гештальт-терапия</h2>
            <p>Гештальт-терапия — одно из самых распространенных направлений психологии, которое получило мировое признание своей эффективности. Основной акцент в работе делается на распознание истинных потребностей клиента и поиске лучшего способа их удовлетворения. В краткосрочной терапии уделяется внимание поиску решений конкретных проблем, а долгосрочная посвящена более подробной прицельной работе с характером.</p>
            <div className="homePageDesktop-gestalt-btn">
              <Button
                type="primary"
                className="btn-default btn_red btn_big"
                onClick={onRegisterClick}
              >
                Выбрать психолога
              </Button>
            </div>
          </div>
          <div className="homePageDesktop-choosePsy center-1200">
            <h2 className="homePageDesktop-h2 homePageDesktop-noMargin">Выбрать психолога</h2>
            <p className="homePageDesktop-subText">Здесь может быть небольшой текст про выбор психолога две строки, не более 100 символов</p>
            <div className="homePageDesktop-choosePsy-wrapper">
              <div className="choosePsy-item choosePsy-item_1">
                <img src={ImagesPers1} className="homePageDesktop-choosePsy__img" alt="Имя специалиста" />
                <h3 className="choosePsy-item__h3">Анна Сергеева</h3>
                <div className="choosePsy-item__prof">Гештальт-терапевт, клинический психолог</div>
                <div className="choosePsy-item__param">
                  <div className="choosePsy-item__param_col choosePsy-item__param_1">
                    <img src={specExp} className="homePageDesktop-choosePsy-item__param__icon" alt="Опыт" />
                    <h4>Опыт</h4>
                    <p>8 лет</p>
                  </div>
                  <div className="choosePsy-item__param_col choosePsy-item__param_2">
                    <img src={specPrice} className="homePageDesktop-choosePsy-item__param__icon" alt="Стоимость" />
                    <h4>Стоимость</h4>
                    <p>4000 руб</p>
                  </div>
                  <div className="choosePsy-item__param_col choosePsy-item__param_3">
                    <img src={specAge} className="homePageDesktop-choosePsy-item__param__icon" alt="Возраст" />
                    <h4>Возраст</h4>
                    <p>30 лет</p>
                  </div>
                </div>
                <div className="choosePsy-item__btn-list">
                  <Button
                    type="button"
                    className="btn-default btn_transparent"
                    onClick={onRegisterClick}
                  >
                    Подробнее
                  </Button>
                  <Button
                    type="button"
                    className="btn-default btn_red"
                    onClick={onRegisterClick}
                  >
                    Записаться
                  </Button>
                </div>
              </div>
              <div className="choosePsy-item choosePsy-item_2">
                <img src={ImagesPers2} className="homePageDesktop-choosePsy__img" alt="Имя специалиста" />
                <h3 className="choosePsy-item__h3">Петр Кротов</h3>
                <div className="choosePsy-item__prof">Гештальт-терапевт</div>
                <div className="choosePsy-item__param">
                  <div className="choosePsy-item__param_col choosePsy-item__param_1">
                    <img src={specExp} className="homePageDesktop-choosePsy-item__param__icon" alt="Опыт" />
                    <h4>Опыт</h4>
                    <p>10 лет</p>
                  </div>
                  <div className="choosePsy-item__param_col choosePsy-item__param_2">
                    <img src={specPrice} className="homePageDesktop-choosePsy-item__param__icon" alt="Стоимость" />
                    <h4>Стоимость</h4>
                    <p>5000 руб</p>
                  </div>
                  <div className="choosePsy-item__param_col choosePsy-item__param_3">
                    <img src={specAge} className="homePageDesktop-choosePsy-item__param__icon" alt="Возраст" />
                    <h4>Возраст</h4>
                    <p>35 лет</p>
                  </div>
                </div>
                <div className="choosePsy-item__btn-list">
                  <Button
                    type="button"
                    className="btn-default btn_transparent"
                    onClick={onRegisterClick}
                  >
                    Подробнее
                  </Button>
                  <Button
                    type="button"
                    className="btn-default btn_red"
                    onClick={onRegisterClick}
                  >
                    Записаться
                  </Button>
                </div>
              </div>
              <div className="choosePsy-item choosePsy-item_3">
                <img src={ImagesPers3} className="homePageDesktop-choosePsy__img" alt="Имя специалиста" />
                <h3 className="choosePsy-item__h3">Валентина Попова</h3>
                <div className="choosePsy-item__prof">Гештальт-терапевт, клинический психолог</div>
                <div className="choosePsy-item__param">
                  <div className="choosePsy-item__param_col choosePsy-item__param_1">
                    <img src={specExp} className="homePageDesktop-choosePsy-item__param__icon" alt="Опыт" />
                    <h4>Опыт</h4>
                    <p>3 лет</p>
                  </div>
                  <div className="choosePsy-item__param_col choosePsy-item__param_2">
                    <img src={specPrice} className="homePageDesktop-choosePsy-item__param__icon" alt="Стоимость" />
                    <h4>Стоимость</h4>
                    <p>2500 руб</p>
                  </div>
                  <div className="choosePsy-item__param_col choosePsy-item__param_3">
                    <img src={specAge} className="homePageDesktop-choosePsy-item__param__icon" alt="Возраст" />
                    <h4>Возраст</h4>
                    <p>25 лет</p>
                  </div>
                </div>
                <div className="choosePsy-item__btn-list">
                  <Button
                    type="button"
                    className="btn-default btn_transparent"
                    onClick={onRegisterClick}
                  >
                    Подробнее
                  </Button>
                  <Button
                    type="button"
                    className="btn-default btn_red"
                    onClick={onRegisterClick}
                  >
                    Записаться
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="homePageDesktop-help">
            <div className="center-1200">
              <h2 className="homePageDesktop-h2 title-margin_88">В чем могут помочь консультации с психологом</h2>
              <div className="homePageDesktop-help-wrapper">
                <div className="homePageDesktop-3-col">
                  <div className="homePageDesktop-cirlce-number">1</div>
                  <div className="homePageDesktop-help-text">Найти внутренние опоры в сложной ситуации</div>
                </div>
                <div className="homePageDesktop-3-col">
                  <div className="homePageDesktop-cirlce-number">2</div>
                  <div className="homePageDesktop-help-text">Научиться справляться с тревогой и страхом</div>
                </div>
                <div className="homePageDesktop-3-col">
                  <div className="homePageDesktop-cirlce-number">3</div>
                  <div className="homePageDesktop-help-text">Разобраться в отношениях в семье, с друзьями, на работе</div>
                </div>
                <div className="homePageDesktop-3-col">
                  <div className="homePageDesktop-cirlce-number">4</div>
                  <div className="homePageDesktop-help-text">Пережить потери и вернуть новые смыслы</div>
                </div>
                <div className="homePageDesktop-3-col">
                  <div className="homePageDesktop-cirlce-number">5</div>
                  <div className="homePageDesktop-help-text">Осознать свои настоящие желания и цели</div>
                </div>
                <div className="homePageDesktop-3-col">
                  <div className="homePageDesktop-cirlce-number">6</div>
                  <div className="homePageDesktop-help-text">Реализовать себя на новых этапах жизни</div>
                </div>
              </div>
              <div className="homePageDesktop-help-btn">
                <Button
                  type="primary"
                  className="btn-default btn_red btn_big"
                  onClick={onRegisterClick}
                >
                  Выбрать психолога
                </Button>
              </div>
            </div>
          </div>
          <div className="homePageDesktop-selection">
            <div className="center-1200">
              <h2 className="homePageDesktop-h2 title-margin_32">Почему нам можно доверять</h2>
              <div className="homePageDesktop-selection-prev">
                <p>Наш подход к выбору специалистов — это не просто проведение «отборочного» интервью с кандидатами. Создатель сервиса Надежда Лубяницкая лично знает каждого терапевта и его подходы к работе не один год</p>
              </div>
              <div className="homePageDesktop-selection-wrapper">
                <div className="homePageDesktop-4-col selection-1">
                  <div className="homePageDesktop-cirlce-number">1</div>
                  <div className="homePageDesktop-selection-lable">Соответсвие мировым стандартам</div>
                  <div className="homePageDesktop-selection-text">Дипломы о высшем образовании в области психологии или медицины. Обучение по стандартам Европейской ассоциации гештальт-терапии. Работают по этическим нормам EAGT</div>
                </div>
                <div className="homePageDesktop-4-col selection-2">
                  <div className="homePageDesktop-cirlce-number">2</div>
                  <div className="homePageDesktop-selection-lable">Опыт и зрелость</div>
                  <div className="homePageDesktop-selection-text">Критерии отбора — не только несколько лет работы, но и личностная и эмоциональная зрелость, а также обязательная супервизия</div>
                </div>
                <div className="homePageDesktop-4-col selection-3">
                  <div className="homePageDesktop-cirlce-number">3</div>
                  <div className="homePageDesktop-selection-lable">Экспертность</div>
                  <div className="homePageDesktop-selection-text">Мы лично присутствовали на открытых сессиях наших терапевтов и гарантируем их бережную и аккуратную работу</div>
                </div>
                <div className="homePageDesktop-4-col selection-4">
                  <div className="homePageDesktop-cirlce-number">4</div>
                  <div className="homePageDesktop-selection-lable">Постоянное развитие</div>
                  <div className="homePageDesktop-selection-text">Обучение не заканчивается — психологи постоянно учатся на семинарах, вебинарах и интенсивах</div>
                </div>
              </div>
              <div className="homePageDesktop-selection-btn">
                <Button
                  type="primary"
                  className="btn-default btn_red btn_big"
                  onClick={onRegisterClick}
                >
                  Выбрать психолога
                </Button>
              </div>
            </div>
          </div>
          <div className="homePageDesktop-reviews">
            <div className="center-1200">
              <h2 className="homePageDesktop-reviews__title">Отзывы о сервисе</h2>
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
              >
                <SwiperSlide>
                  <div className="homePageDesktop-reviews__slider">
                    <img src={reviewsFoto} className="homePageDesktop-reviews__foto" alt="Отзыв о сервисе" />
                    <div className="homePageDesktop-reviews__content">
                      <p className="homePageDesktop-reviews__text">И записаться к любому из них. Если вы не доверяете «умному подбору», то всегда можете самостоятельно изучить наших специалистов. И записаться к любому из них. Если вы не доверяете «умному подбору», то всегда можете самостоятельно изучить наших специалистов.</p>
                      <p className="homePageDesktop-reviews__name"><strong>Екатерина Иванова</strong></p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="homePageDesktop-reviews__slider">
                    <img src={reviewsFoto} className="homePageDesktop-reviews__foto" alt="Отзыв о сервисе" />
                    <div className="homePageDesktop-reviews__content">
                      <p className="homePageDesktop-reviews__text">И записаться к любому из них. Если вы не доверяете «умному подбору», то всегда можете самостоятельно изучить наших специалистов. И записаться к любому из них. Если вы не доверяете «умному подбору», то всегда можете самостоятельно изучить наших специалистов.</p>
                      <p className="homePageDesktop-reviews__name"><strong>Екатерина Иванова</strong></p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="homePageDesktop-blog">
            <div className="homePageDesktop-blog__title">
              <h4 className="h4-undTitle">Что почитать</h4>
              <h2 className="homePageDesktop-h2 title-margin_72">Статьи экспертов и специалистов сервиса</h2>
            </div>
            <div className="center-1200">
              <div className="homePageDesktop-blog-wrapper">
                {articles.map((a) => (
                  <div
                    className="blog-item"
                    key={a.id}
                  >
                    <div className="blog-param">
                      <a href="/">Читать</a>
                      <img src={readmore} className="blog-item__readmore" alt="Читать" />
                      <h3>{a.title}</h3>
                      <p>{a.date ? a.date.format('DD MMMM YYYY') : ''}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomePageDesktop.propTypes = {
  onRegisterClick: PropTypes.func.isRequired,
  onRegisterPsychoClick: PropTypes.func.isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default HomePageDesktop;
