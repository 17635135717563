/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import {
  Button, Col, Row, Select, Modal, Input,
} from 'antd';
import { orderBy, groupBy } from 'lodash';
import PropTypes from 'prop-types';
import {
  FormOutlined,
} from '@ant-design/icons';
import moment from 'moment';


const { Option } = Select;
const years = [
  2020,
  2021,
  2022,
];
class CalendarSessions extends Component {
  getDaysArray = (month, year) => {
    month.set('year', year);
    let daysInMonth = month.daysInMonth();
    const arrDays = [];

    while (daysInMonth) {
      const current = moment().set('year', year).set('month', month.month()).date(daysInMonth)
        .startOf('D');
      arrDays.push(current);
      daysInMonth -= 1;
    }

    return arrDays.reverse();
  }

  render() {
    const {
      sessions, onMonthSelect, selectedMonth, selectedDay, onDaySelect, selectedYear, onYearSelect, onStartSession, onChangeSessionStatus,
      onShowProfile, selectedProfile, onCloseModal, onEditClientNameClick, onSaveEditClientName, onCancelEditClientName, editClientId,
      onChangeEditClientName, editClientName,
    } = this.props;
    const monthDays = this.getDaysArray(selectedMonth, selectedYear);
    const thisDaySession = orderBy(sessions.filter((s) => moment(s.sessionDateAndTime).startOf('D').isSame(selectedDay)), ['sessionDateAndTime'], ['asc']);
    const groupedByClientDoneSessions = groupBy(sessions.filter((s) => s.statusID === 'done'), 'clientID');
    return (
      <Col span={24}>
        <Row justify="space-between">
          <Col
            span={2}
            className="calendarPageDesktop-years"
            style={{ background: 'inherit', color: 'inherit' }}
          >
            <Select style={{ width: '100%' }} onSelect={(e) => onYearSelect(e)} value={selectedYear}>
              {years.map((y) => ({ id: y, value: y })).map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
            </Select>
          </Col>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((e) => {
            const month = moment().set('year', selectedYear).startOf('year').add(e, 'M');
            return (
              <Col
                key={e}
                flex="auto"
                onClick={() => onMonthSelect(month)}
                className="calendarPageDesktop-month"
                style={{ background: month.isSame(selectedMonth) ? '#B5C1C0' : 'inherit', color: month.isSame(selectedMonth) ? '#2D4D4C' : 'inherit' }}
              >
                {month.format('MMMM')}
              </Col>
            );
          })}
        </Row>
        <div className="calendarPageDesktop-current__search-day">
          {monthDays.map((d) => (
            <Col
              className={`calendarPageDesktop-week ${d.isSame(selectedDay) ? 'calendarPageDesktop-week_active' : ''}`}
              onClick={() => onDaySelect(d)}
              key={d}
            >
              {d.format('D')}
            </Col>
          ))}
        </div>
        <h2 className="calendarPageDesktop-current__day">
          {selectedDay.format('dddd').charAt(0).toUpperCase() + selectedDay.format('dddd').slice(1)}
          <span>{selectedDay.format('DD MMMM')}</span>
        </h2>
        <div className="calendarPageDesktop-current-result">
          {thisDaySession.map((s) => (
            <div
              key={s.id}
              className="calendarPageDesktop-current-result__wrapper"
            >
              <div className="calendarSession-time">{s.sessionDateAndTime.format('HH:00')}</div>
              <div className="calendarSession__wrapper">
                <div className="calendarSession__param">
                  <div className="PageDesktop__calendarSession__block">
                    <div className="calendarSession-count">{groupedByClientDoneSessions[s.clientID] && groupedByClientDoneSessions[s.clientID].length > 0 ? groupedByClientDoneSessions[s.clientID].length : 'NEW' }</div>
                    <div className="PageDesktop__calendarSession__block__param">
                      <div className="PageDesktop__calendarSession__block__name">
                        <div className="client-nickname">{`${s.clientName} ${s.clientDisplayName ? `(${s.clientDisplayName})` : ''}`}</div>
                        <div className="client-status"><FormOutlined onClick={() => onEditClientNameClick(s.clientDisplayName, s.clientID)} /></div>
                      </div>
                      <div className="PageDesktop__calendarSession__block__pay">
                        <div className="PageDesktop__calendarSession__block__pay__title">Статус:</div>
                        <div className="PageDesktop__calendarSession__block__pay__value">{s.statusValue}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="calendarSession__action">
                  <div className="calendarSession__sub-block">
                    <div className="calendarSession-anketa" onClick={() => onShowProfile(s)}>Анкета клиента</div>
                    {s.hostURL && s.statusID === 'paid' && <div><Button onClick={() => onChangeSessionStatus(s.id, 'canceled')}>Сессия не состоялась</Button></div>}
                    {s.hostURL && s.statusID === 'paid' && <div><Button onClick={() => onChangeSessionStatus(s.id, 'done')}>Сессия завершена</Button></div>}
                    {s.statusID === 'paid' && (
                    <div onClick={() => onStartSession(s.id)} className="start-session btn-default btn_green">Начать</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {selectedProfile && (
        <Modal
          visible
          title={`Клиент: ${selectedProfile.clientName}`}
          onCancel={onCloseModal}
          maskClosable
          footer={null}
        >
          <Col span={24}>
            <Row>
              <Col span={12} style={{ color: '#212121' }}>Кто будет работать с психологом?</Col>
              <Col>{selectedProfile.whoWillUse}</Col>
            </Row>
            <Row style={{ margin: '2vh 0vw' }}>
              <Col span={12} style={{ color: '#212121' }}>Пол (как обращаться)</Col>
              <Col>{selectedProfile.gender}</Col>
            </Row>
            <Row>
              <Col span={12} style={{ color: '#212121' }}>Что бы вы хотели обсудить в первую очередь?</Col>
              <Col span={12}>{selectedProfile.specializationArray.join('. ')}</Col>
            </Row>
          </Col>
        </Modal>
        )}
        {editClientId
      && (
      <Modal
        title="Никнейм клиента"
        onCancel={onCancelEditClientName}
        visible
        footer={[
          <Button key="back" onClick={onCancelEditClientName}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" onClick={onSaveEditClientName}>
            Сохранить
          </Button>,
        ]}
      >
        <Col span={24}>
          <Row style={{ marginBottom: '5px' }}>Это имя будет отображаться только у Вас. Клиент его не увидит</Row>
          <Row>
            <Input onChange={(e) => onChangeEditClientName(e.target.value)} defaultValue={editClientName} style={{ width: '100%', marginBottom: '15px' }} />
          </Row>
        </Col>
      </Modal>
      )}
      </Col>
    );
  }
}

CalendarSessions.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  selectedMonth: PropTypes.shape().isRequired,
  onDaySelect: PropTypes.func.isRequired,
  selectedDay: PropTypes.shape().isRequired,
  selectedYear: PropTypes.number.isRequired,
  onYearSelect: PropTypes.func.isRequired,
  onStartSession: PropTypes.func.isRequired,
  onChangeSessionStatus: PropTypes.func.isRequired,
  onShowProfile: PropTypes.func.isRequired,
  selectedProfile: PropTypes.shape(),
  onCloseModal: PropTypes.func.isRequired,
  onEditClientNameClick: PropTypes.func.isRequired,
  onSaveEditClientName: PropTypes.func.isRequired,
  onCancelEditClientName: PropTypes.func.isRequired,
  editClientId: PropTypes.string,
  editClientName: PropTypes.string,
  onChangeEditClientName: PropTypes.func.isRequired,
};

CalendarSessions.defaultProps = {
  selectedProfile: null,
  editClientId: null,
  editClientName: '',
};

export default CalendarSessions;
