import React from 'react';

import { routes } from '../../../utils/routes';


const clientButtonsArray = [
  {
    name: 'Профиль',
    id: 'profile',
    icon: <div className="sideBarButton__icon sideBarButton__icon_profile" />,
    route: routes.profile,
  },
  {
    name: 'Наши специалисты',
    id: 'cleverList',
    icon: <div className="sideBarButton__icon sideBarButton__icon_specialists" />,
    route: routes.list,
  },
  {
    name: 'Консультации',
    id: 'meetings',
    icon: <div className="sideBarButton__icon sideBarButton__icon_meetings" />,
    route: routes.meetings,
  },
  {
    name: 'Группы',
    id: 'groupSessions',
    icon: <div className="sideBarButton__icon sideBarButton__icon_group" />,
    route: routes.groupSessions,
  },
  {
    name: 'Поддержка',
    id: 'help',
    icon: <div className="sideBarButton__icon sideBarButton__icon_help" />,
    route: routes.help,
  },
];

export default clientButtonsArray;
