import React, { Component } from 'react';
// import moment from 'moment';
import {
  Button, Col, Row, Collapse, Table, Modal, Card, Popconfirm, Tag,
} from 'antd';
import { groupBy, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import getClientSessionsColumns from '../../utils/getClientSessionsColumns';


const { Panel } = Collapse;
const { CheckableTag } = Tag;
const { Meta } = Card;
class Clients extends Component {
  render() {
    const {
      sessions, onAddSession, isShowAddSession, timeSlots, selectedDate, selectedTime, onEnroll, onCloseAddSession, onSelect,
    } = this.props;
    const groupedClients = groupBy(sessions, 'clientID');
    const clientsIDS = Object.keys(groupedClients);
    return (
      <Col>
        <Collapse className="my-clients" expandIconPosition="right">
          {clientsIDS.map((clientID) => {
            const session = sessions.find((s) => s.clientID === clientID);
            return (
              <Panel
                className="my-clients__wrapper"
                header={(
                  <div className="my-clients__one-block">
                    <div className="my-clients__one-block__name">{session.clientDisplayName || session.clientName}</div>
                    <div className="my-clients__one-block__edit"><Button className="btn-default btn_link btn_link_dotted" type="primary" onClick={() => onAddSession(clientID)}>Записать клиента</Button></div>
                  </div>
            )}
                key={clientID}
              >
                <Col className="my-clients__1">
                  <Table
                    className="my-clients__list-date"
                    columns={getClientSessionsColumns()}
                    rowKey="id"
                    dataSource={sessions.filter((s) => s.clientID === clientID)}
                    pagination={false}
                    style={{ width: '100%' }}
                  />
                </Col>
              </Panel>
            );
          })}
        </Collapse>
        {isShowAddSession && (
        <Modal
          visible
          title=""
          onCancel={onCloseAddSession}
          maskClosable
          footer={null}
          width="80vw"
          bodyStyle={{ height: '70vh' }}
        >
          <Col>
            <Row style={{ height: '60vh', overflow: 'scroll' }}>
              <Card
                hoverable={false}
                style={{ borderColor: '#bfbdbb', width: '100%' }}
                id="TIME"
              >
                <Meta
                  description={(
                    <Col span={24}>
                      {orderBy(timeSlots, 'date')
                        // .filter((slot) => slot.date.isAfter(moment()))
                        .map((slot) => (
                          <Row style={{ margin: '10px' }} key={slot.id}>
                            <Col span={24} key={slot.id}>
                              <Row style={{ color: '#333333' }}>{slot.date.format('DD MMMM, dddd')}</Row>
                              <Row gutter={12}>
                                {slot.timesArray.map((time) => (
                                  <CheckableTag
                                    key={time + slot.date}
                                    checked={selectedTime === time && slot.date.isSame(selectedDate)}
                                    onChange={() => onSelect(time, slot.date)}
                                  >
                                    {time}
                                  </CheckableTag>
                                ))}
                              </Row>
                            </Col>
                          </Row>
                        ))}
                    </Col>
              )}
                />
              </Card>
            </Row>
            {selectedDate && selectedTime && (
            <Row style={{ marginTop: '20px' }}>
              <Popconfirm
                title="Вы уверены, что хотите записать клиента на эту дату?"
                onConfirm={onEnroll}
                okText="Да"
                cancelText="Нет"
              >
                <Button className="btn-default btn_green" type="primary">Записать</Button>
              </Popconfirm>
            </Row>
            )}
          </Col>
        </Modal>
        )}
      </Col>
    );
  }
}

Clients.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onAddSession: PropTypes.func.isRequired,
  isShowAddSession: PropTypes.bool.isRequired,
  timeSlots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedDate: PropTypes.shape(),
  selectedTime: PropTypes.string,
  onEnroll: PropTypes.func.isRequired,
  onCloseAddSession: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

Clients.defaultProps = {
  selectedDate: null,
  selectedTime: null,
};

export default Clients;
