/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Spin, Col, Row, Form, Button, Select, Radio, Slider, InputNumber, Checkbox, Input, Progress, Card, Avatar, Empty, Alert,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import age from '../../../assets/icons/age.svg';
import experience from '../../../assets/icons/experience.svg';
import price from '../../../assets/icons/price.svg';
import './CleverPsychoListPageDesktop.css';


const { Option } = Select;
const { Meta } = Card;
class CleverPsychoListPageDesktop extends Component {
  render() {
    const {
      loading, specializationCatalogue, formRef, languagesCatalogue, userSpecializations, onSpecializaionCheckbox, otherSpecialisation,
      specList, onGenderChange, gender, step, onNextStep, onPreviousStep, values, onPsychoClick, specialistAgeCatalogue,
      sessionPriceCatalogue, onRedirectPsychoList, isSessionExist, onShowMore, allSpecListLength, onChangeOtherSpecialisation,
    } = this.props;
    const specGroups = [];
    specializationCatalogue.forEach((s) => {
      const group = specGroups.find((g) => g === s.group);
      if (!group) {
        specGroups.push(s.group);
      }
    });
    if (loading) {
      return (
        <Col className="cleverPsychoListPageDesktop-wrapper" span={24}>
          <div className="cleverPsychoListPageDesktop-loading">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
          </div>
        </Col>
      );
    }
    return (
      <Col className="cleverPsychoListPageDesktop-wrapper" span={24}>
        {isSessionExist && (
        <Alert
          message="Внимание! Есть неоплаченные сессии или уже выбран терапевт"
          description="Чтобы записаться к новому специалисту, необходимо сначала открепиться от старого или отменить запись"
          type="warning"
          showIcon
        />
        )}
        {!isSessionExist && (
        <Form ref={formRef}>
          <div style={{ marginBottom: '20px', fontSize: '20px' }}>Умный подбор</div>
          <div>
            Наша система позволяет осуществить “Умный подбор” специалиста, основываясь на ваших
            пожеланиях и чувствах. Вы можете заполнять не все поля, однако, точность заполнения напрямую
            повлияет на качество подбора.
          </div>
          <Progress steps={5} style={{ width: '100%' }} percent={((step + 1) / 5) * 100} />
          <div style={{ fontSize: '12px', margin: '10px 0px 5px' }}>{`Шаг ${step + 1} из 5`}</div>
          <Col span={16}>
            {step === 0 && (
            <Col span={16}>
              <Row>
                <div style={{ marginBottom: '20px', fontSize: '20px' }}>Заполните информацию о себе</div>
              </Row>
              <Row style={{ alignItems: 'center', marginBottom: '15px' }} gutter={20}>
                <Col span={8} style={{ fontWeight: 'bold' }}>Сколько вам лет?</Col>
                <Col span={12}>
                  <Form.Item
                    name="age"
                    style={{ marginBottom: '0px' }}
                    initialValue={values.age}
                    rules={[{ required: true, message: '' }]}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ alignItems: 'center', marginBottom: '15px' }} gutter={20}>
                <Col span={8} style={{ fontWeight: 'bold' }}>Ваш пол</Col>
                <Col span={12}>
                  <Form.Item
                    name="gender"
                    style={{ marginBottom: '0px' }}
                    rules={[{ required: true, message: '' }]}
                    initialValue={values.gender}
                  >
                    <Select style={{ width: '100%' }} onSelect={(e) => onGenderChange(e)}>
                      {[{ id: 'Мужской', value: 'Мужской' }, { id: 'Женский', value: 'Женский' }, { id: 'Иное', value: 'Иное' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {gender === 'Иное'
              && (
              <Row style={{ alignItems: 'center', marginBottom: '15px' }} gutter={20}>
                <Col span={8} style={{ fontWeight: 'bold' }}>Как к вам обращаться?</Col>
                <Col span={12}>
                  <Form.Item
                    name="genderOther"
                    style={{ marginBottom: '0px' }}
                    rules={[{ required: true, message: '' }]}
                    initialValue={values.genderOther}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              )}
              <Row><Button onClick={onNextStep}>Продолжить</Button></Row>
            </Col>
            )}
          </Col>

          {step === 1 && (
          <Col span={16}>
            <Row style={{ alignItems: 'center', marginBottom: '15px' }} gutter={20}>
              <Col span={8} style={{ fontWeight: 'bold' }}>Кто будет работать с психологом?</Col>
              <Col span={12}>
                <Form.Item
                  name="whoWillUse"
                  style={{ marginBottom: '0px' }}
                  initialValue={values.whoWillUse}
                  rules={[{ required: true, message: '' }]}
                >
                  <Radio.Group
                    options={[
                      { label: 'Только я', value: 'onlyMe' },
                      { label: 'Ребенок', value: 'child' },
                      { label: 'Я и мой партнер', value: 'meAndPartner' },
                      { label: 'Другое', value: 'other' },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ alignItems: 'center', marginBottom: '15px' }} gutter={20}>
              <Col span={8} style={{ fontWeight: 'bold' }}>Был ли у вас опыт работы с психологом ранее?</Col>
              <Col span={12}>
                <Form.Item
                  name="isHavePreviousExperince"
                  style={{ marginBottom: '0px' }}
                  initialValue={values.isHavePreviousExperince}
                  rules={[{ required: true, message: '' }]}
                >
                  <Radio.Group
                    options={[
                      { label: 'Да', value: true },
                      { label: 'Нет', value: false },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col>
                <Button onClick={onPreviousStep}>Назад</Button>
              </Col>
              <Col>
                <Button onClick={onNextStep}>Продолжить</Button>
              </Col>
            </Row>
          </Col>
          )}

          {step === 2 && (
          <Col span={16}>
            <Row style={{ alignItems: 'center', marginBottom: '15px' }} gutter={20}>
              <Col span={8} style={{ fontWeight: 'bold' }}>С кем вам было бы комфортнее работать?</Col>
              <Col span={12}>
                <Form.Item
                  name="specialistGender"
                  style={{ marginBottom: '0px' }}
                  initialValue={values.specialistGender}
                  rules={[{ required: true, message: '' }]}
                >
                  <Radio.Group
                    options={[
                      { label: 'Мужчина', value: 'Мужской' },
                      { label: 'Женщина', value: 'Женский' },
                      { label: 'Не важно', value: 'Иное' },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ alignItems: 'center', marginBottom: '15px' }} gutter={20}>
              <Col span={8} style={{ fontWeight: 'bold' }}>Какой возраст должен быть у специалиста?</Col>
              <Col span={12}>
                <Form.Item
                  name="specialistAge"
                  style={{ marginBottom: '0px' }}
                  initialValue={values.specialistAge}
                  rules={[{ required: true, message: '' }]}
                >
                  <Slider range min={specialistAgeCatalogue.min} max={specialistAgeCatalogue.max} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ alignItems: 'center', marginBottom: '15px' }} gutter={20}>
              <Col span={8} style={{ fontWeight: 'bold' }}>Стоимость услуг</Col>
              <Col span={12}>
                <Form.Item
                  name="sessionPrice"
                  style={{ marginBottom: '0px' }}
                  initialValue={values.sessionPrice}
                  rules={[{ required: true, message: '' }]}
                >
                  <Slider range min={sessionPriceCatalogue.min} max={sessionPriceCatalogue.max} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ alignItems: 'center', marginBottom: '15px' }} gutter={20}>
              <Col span={8} style={{ fontWeight: 'bold' }}>Язык консультаций</Col>
              <Col span={12}>
                <Form.Item
                  name="languages"
                  style={{ marginBottom: '0px' }}
                  initialValue={values.languages}
                  rules={[{ required: true, message: '' }]}
                >
                  <Select
                    mode="multiple"
                  >
                    {languagesCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col>
                <Button onClick={onPreviousStep}>Назад</Button>
              </Col>
              <Col>
                <Button onClick={onNextStep}>Продолжить</Button>
              </Col>
            </Row>
          </Col>
          )}


          {step === 3 && (
          <Col span={16}>
            <Row style={{ margin: '40px 0px 0px' }}>
              <div style={{ fontWeight: 'bold' }}>Что бы вы хотели обсудить в первую очередь?</div>
            </Row>
            <Row style={{ alignItems: 'center', marginBottom: '15px' }} gutter={20}>
              <Col>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {specGroups.map((g) => (
                    <div key={g} style={{ display: 'flex', flexDirection: 'column', margin: '10px 0px' }}>
                      <div style={{ fontWeight: 'bold' }}>{g}</div>
                      <Row gutter={16}>
                        {specializationCatalogue.filter((sp) => sp.group === g).map((s) => (
                          <Col key={s.id} span={12}>
                            <Checkbox
                              checked={userSpecializations.includes(s.id)}
                              onChange={(e) => onSpecializaionCheckbox(s.id, e.target.checked, s.value)}
                            >
                              {s.value}
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ))}
                  <div style={{ fontWeight: 'bold' }}>Другое</div>
                  <Input value={otherSpecialisation} onChange={onChangeOtherSpecialisation} />
                </div>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col>
                <Button onClick={onPreviousStep}>Назад</Button>
              </Col>
              <Col>
                <Button onClick={onNextStep}>Продолжить</Button>
              </Col>
            </Row>
          </Col>
          )}

          {step === 4 && !loading && (
          <Col span={24}>
            <Row style={{ fontSize: '24px', fontWeight: 'bold' }}>Найденные специалисты</Row>
            <Row gutter={12} style={{ marginBottom: '5vh' }}>
              <Col>
                <Button onClick={onPreviousStep} style={{ backgroundColor: 'inherit', borderColor: '#bfbdbb' }}>Назад</Button>
              </Col>
            </Row>
            {specList.length === 0 && (
            <Row justify="center">
              <Empty
                description={(
                  <Col>
                    <Row justify="center">Мы не смогли подобрать специалиста по указанным Вами параметрам.</Row>
                    <Row justify="center">Пожалуйста, попробуйте изменить условия или перейдите на полный список специалистов по кнопке ниже.</Row>
                  </Col>
    )}
              >
                <Button type="primary" onClick={onRedirectPsychoList}>Специалисты</Button>
              </Empty>
            </Row>
            )}
            {specList.length > 0 && (
              <Col span={24}>
                <Row gutter={[100, 100]}>
                  {specList.map((sp) => (
                    <Col span={8} key={sp.id}>
                      <Card
                        key={sp.specialistID}
                        hoverable={false}
                        style={{ borderColor: '#bfbdbb' }}
                      >
                        <Meta
                          description={(
                            <Col span={24} style={{ padding: 0 }}>
                              <Row justify="center"><Avatar src={sp.logoURL} size={100} /></Row>
                              <Row justify="center" style={{ color: '#333333', fontWeight: 'bold', fontSize: '18px' }}>{`${sp.lastName} ${sp.firstName}`}</Row>
                              <Row justify="center" style={{ color: '#333333', marginBottom: '3vh' }}>{`Опыт ${sp.experience} лет`}</Row>
                              <Row gutter={12}>
                                <Col span={8}>
                                  <Row justify="center"><img className="block-icon" src={experience} alt="age" /></Row>
                                  <Row justify="center" style={{ color: '#333333' }}>Опыт</Row>
                                  <Row justify="center">{`${sp.experience} лет`}</Row>
                                </Col>
                                <Col span={8}>
                                  <Row justify="center"><img className="block-icon" src={price} alt="age" /></Row>
                                  <Row justify="center" style={{ color: '#333333' }}>Стоимость</Row>
                                  <Row justify="center">{`${sp.sessionPrice} руб`}</Row>
                                </Col>
                                <Col span={8}>
                                  <Row justify="center"><img className="block-icon" src={age} alt="age" /></Row>
                                  <Row justify="center" style={{ color: '#333333' }}>Возраст</Row>
                                  <Row justify="center">{moment().diff(sp.birthdayDate, 'years')}</Row>
                                </Col>
                              </Row>
                              <Row justify="space-between" style={{ marginTop: '3vh' }}>
                                <Col><Button style={{ backgroundColor: 'inherit', borderColor: '#bfbdbb' }} onClick={() => onPsychoClick(sp)}>Подробнее</Button></Col>
                                <Col><Button type="primary" onClick={() => onPsychoClick(sp)}>Записаться</Button></Col>
                              </Row>
                            </Col>
              )}
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Row style={{ marginTop: '5vh' }}>
                  {allSpecListLength > 6 && <Col><Button type="primary" onClick={onShowMore}>Показать еще варианты</Button></Col>}
                  <Col><Button style={{ backgroundColor: 'inherit', borderColor: '#bfbdbb', marginLeft: '20px' }} onClick={onRedirectPsychoList}>Посмотреть каталог</Button></Col>
                </Row>
              </Col>
            )}
          </Col>
          )}
        </Form>
        )}
      </Col>
    );
  }
}

CleverPsychoListPageDesktop.propTypes = {
  specializationCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  specList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  languagesCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  formRef: PropTypes.shape().isRequired,
  onSpecializaionCheckbox: PropTypes.func.isRequired,
  userSpecializations: PropTypes.arrayOf(PropTypes.string).isRequired,
  onGenderChange: PropTypes.func.isRequired,
  gender: PropTypes.string.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPreviousStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  values: PropTypes.shape().isRequired,
  onPsychoClick: PropTypes.func.isRequired,
  specialistAgeCatalogue: PropTypes.shape().isRequired,
  sessionPriceCatalogue: PropTypes.shape().isRequired,
  onRedirectPsychoList: PropTypes.func.isRequired,
  isSessionExist: PropTypes.bool.isRequired,
  onShowMore: PropTypes.func.isRequired,
  allSpecListLength: PropTypes.number.isRequired,
  onChangeOtherSpecialisation: PropTypes.func.isRequired,
  otherSpecialisation: PropTypes.string.isRequired,
};

export default CleverPsychoListPageDesktop;
