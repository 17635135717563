export const routes = {
  home: '/',
  login: '/login',
  homePage: '/homePage',
  specialists: '/specialists',
  help: '/help',
  emailVerification: '/emailVerification/:id',

  list: '/list',
  cleverList: '/cleverList',
  settings: '/settings',
  profile: '/profile',
  meetings: '/meetings',
  calendar: '/calendar',
  groupSessions: '/groupSessions',
  specialist: '/specialist/:id',
  specialistGroups: '/specialistGroups',
  specialistSessions: '/specialistSessions',
  specialistTimetable: '/specialistTimetable',

  adminFields: '/adminFields',
  adminCancelRequests: '/adminCancelRequests',
  adminSpecialists: '/adminSpecialists',
  adminDataChangeRequests: '/adminDataChangeRequests',
  adminLogin: '/adminLogin',
  adminManualUsers: '/adminManualUsers',
  adminGroupSessions: '/adminGroupSessions',

  promoCodes: '/promoCodes',
};

export const routeGenerator = {
  specialist: (id) => `${routes.specialist.replace(':id', id)}`,
};
