/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import ru from 'react-phone-input-2/lang/es.json';
import PhoneInput from 'react-phone-input-2';
import PinInput from 'react-pin-input';
import { Form, Button, Input } from 'antd';
import goHome from '../../../assets/icons/arrow-left-long.svg';
import './LoginMain.css';


function LoginMain({
  user, smsID, loading, onVerifyEmail, formRef, onGetEmailCode, onLogin, emailInputFormRef, webUser, secs,
  setState,
}) {
  return (
    <div className="loginPage">
      {!user.token && (
        <div className="loginModule">
          <div className="loginModule__navigation">
            <a href="https://gestalt.online" className="navigation__gohome">
              <img className="navigation__arrow" src={goHome} alt="На главную страницу" />
              На главную
            </a>
            <div className="navigation__close" />
          </div>
          {!smsID && (
          <div className="loginModule__top loginModule__beforesms">
            <div className="loginModule__title">Авторизация или создание аккаунта</div>
            <div className="loginModule__text"><p>Введите ваш номер телефона. А мы отправим на него SMS с кодом.</p></div>
          </div>
          )}
          {smsID && (
          <div className="loginModule__top loginModule__aftersms">
            <div className="loginModule__title loginModule__title_nomargin">Введите код из SMS</div>
            <div className="loginModule__text_aftersms"><p>{`Код был отправлен на номер: +${webUser}`}</p></div>
          </div>
          )}
          <Form ref={formRef}>
            <Form.Item
              className="loginModule__input loginModule__input_minimargin"
              name={smsID ? 'code' : 'phone'}
            >
              {smsID
                ? (
                  <PinInput
                    length={4}
                    initialValue=""
                    secret={false}
                    type="numeric"
                    inputMode="number"
                    disabled={loading}
                    onComplete={(value) => onLogin(value)}
                    autoSelect
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                )
                : (
                  <PhoneInput
                    localization={ru}
                    placeholder=""
                    country="ru"
                  />
                )}
            </Form.Item>
            {smsID && (
            <div className="loginModule__enter">
              {secs !== 0 && <div className="loginModule__confirm"><p>{`Получить новый код можно через 00:${secs}`}</p></div>}
              {secs === 0 && <a className="link link_dotted" onClick={onLogin}>Получить новый код</a>}
            </div>
            )}
          </Form>
          {smsID && (
          <div
            className="loginModule__cancel link link_dotted"
            onClick={() => setState({
              smsID: null, webUser: null, nickName: null, secs: 60,
            })}
          >
            Использовать другой номер
          </div>
          )}
          {!smsID && (
          <Form.Item>
            <Button type="primary" shape="round" htmlType="submit" onClick={onLogin} disabled={loading} className="btn btn_green btn_full">
              <span>Получить код</span>
            </Button>
          </Form.Item>
          )}
          {!smsID && (
          <div className="loginModule__confirm">
            <p>
              Нажимая «Получить код», вы подтверждаете, что ознакомлены и принимаете
              {' '}
              <a className="link" onClick={() => window.open('https://gestalt.online/files/soglashenie-client.pdf', '_blank')}>«Условия пользования сайтом»</a>
              , даёте свое согласие на получение сообщений с использованием указанных при регистрации данных согласно
              {' '}
              <a className="link" onClick={() => window.open('https://gestalt.online/files/soglashenie-client.pdf', '_blank')}>«Условиям пользования сайтом»</a>
              {' '}
              и даёте свое согласие на обработку персональных данных согласно
              {' '}
              <a className="link" onClick={() => window.open('https://gestalt.online/files/policy.pdf', '_blank')}>«Политике обработки персональных данных»</a>
            </p>
          </div>
          )}
        </div>
      )}

      {user.token && !user.email && (
        <div className="loginModule">
          <div className="loginModule__navigation">
            <a href="https://gestalt.online" className="navigation__gohome">
              <img className="navigation__arrow" src={goHome} alt="На главную страницу" />
              На главную
            </a>
            <div className="navigation__close" />
          </div>
          <div className="loginModule__top loginModule__aftersms">
            <div className="loginModule__title loginModule__title_nomargin">Укажите свою почту</div>
            <div className="loginModule__text"><p>Почта необходима для оповещения вас о сессиях. И получения иных информационных и сервисых сообщений.</p></div>
          </div>
          <Form ref={emailInputFormRef}>
            <Form.Item
              className="loginModule__input loginModule__input_email"
              name="email"
            >
              <Input placeholder="Ваш email" />
            </Form.Item>
          </Form>
          <Button type="primary" shape="round" onClick={() => onGetEmailCode()} disabled={loading} className="btn btn_green btn_full">
            <span>Подтвердить почту</span>
          </Button>
        </div>
      )}

      {user.token && user.email && !user.isEmailVerified && (
        <div className="loginModule">
          <div className="loginModule__navigation">
            <a href="https://gestalt.online" className="navigation__gohome">
              <img className="navigation__arrow" src={goHome} alt="На главную страницу" />
              На главную
            </a>
            <div className="navigation__close" />
          </div>
          <div className="loginModule__top loginModule__beforesms">
            <div className="loginModule__title loginModule__title_nomargin">Введите код из письма</div>
            <div className="loginModule__text"><p>На вашу почту отправлено письмо с кодом</p></div>
          </div>
          <div className="loginModule__pincode">
            <PinInput
              length={6}
              initialValue=""
              secret={false}
              type="numeric"
              inputMode="number"
              disabled={loading}
              onComplete={(value) => onVerifyEmail(value)}
              autoSelect
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <div className="loginModule__confirm">
            <p>{`Письмо отправлено на почту: ${user.email}`}</p>
            <p><span className="link link_lite">Отправить письмо повторно</span></p>
            <div onClick={() => setState({ user: { ...user, email: null } })}><p><span className="link link_lite">Указать другую почту</span></p></div>
          </div>
        </div>
      )}
    </div>
  );
}

LoginMain.propTypes = {
  user: PropTypes.shape().isRequired,
  smsID: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onVerifyEmail: PropTypes.func.isRequired,
  formRef: PropTypes.shape().isRequired,
  onGetEmailCode: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  emailInputFormRef: PropTypes.shape().isRequired,
  webUser: PropTypes.string,
  secs: PropTypes.number.isRequired,
  setState: PropTypes.func.isRequired,
};

LoginMain.defaultProps = {
  smsID: null,
  webUser: null,
};

export default LoginMain;
