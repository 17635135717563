/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ru from 'react-phone-input-2/lang/es.json';
import {
  Form, Input, Select, InputNumber, DatePicker, Card, Button, Alert,
} from 'antd';
import './ProfileField.css';


const { Option } = Select;
const { TextArea } = Input;
class ProfileField extends Component {
    getField = (disabled, loading, catalogue, fieldType, showSearch, setNewValue, needHelp, width, onValueChange, fieldName, initialValue) => {
      switch (fieldType) {
        case 'input':
          return (
            <Input
              disabled={!needHelp && (disabled || loading)}
              onChange={(e) => onValueChange(e.target.value)}
              style={{ borderColor: fieldName === 'nickName' && !initialValue ? 'red' : 'inherit' }}
            />
          );
        case 'select':
          if (showSearch) {
            return (
              <Select
                disabled={!needHelp && (disabled || loading)}
                showSearch
                onSelect={(e) => onValueChange(e)}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {catalogue.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
              </Select>
            );
          }
          return (
            <Select
              disabled={!needHelp && (disabled || loading)}
              onSelect={(e) => onValueChange(e)}
            >
              {catalogue.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
            </Select>
          );
        case 'inputNumber':
          return (
            <InputNumber
              disabled={!needHelp && (disabled || loading)}
              onChange={(e) => onValueChange(e)}
            />
          );
        case 'phone':
          return (
            <PhoneInput
              localization={ru}
              placeholder=""
              disabled={!needHelp && (disabled || loading)}
              country="ru"
            />
          );
        case 'datePicker':
          return (
            <DatePicker
              disabled={!needHelp && (disabled || loading)}
              format="DD MMMM YYYY"
            />
          );
        default: return <div />;
      }
    }

    render() {
      const {
        fieldVisibleName, initialValue, fieldName, disabled, fieldType, catalogue, required, onApproveClick,
        loading, fieldInEdit, onCancelChange, onSaveClick, setNewValue, setComment, showSearch, placeholder, onValueChange, isEmailVerified,
      } = this.props;
      return (
        <div className="profileField-form-column-block">
          <div className="profileField-form-column-block-row">
            <div className="profileField-form-column-block-column">
              {fieldName === 'email' && (
              <Form.Item
                name={fieldName}
                initialValue={initialValue}
                rules={[{ required, message: '', type: 'email' }]}
                label={fieldVisibleName}
                validateStatus={fieldName === 'email' && !isEmailVerified && 'error'}
              >
                {this.getField(disabled, loading, catalogue, fieldType, showSearch, () => {}, false, '20vw', (v) => onValueChange(fieldName, v))}
              </Form.Item>
              )}
              {fieldName !== 'email' && (
              <Form.Item
                name={fieldName}
                initialValue={initialValue}
                rules={[{ required, message: '' }]}
                label={fieldVisibleName}
              >
                {this.getField(disabled, loading, catalogue, fieldType, showSearch, () => {}, false, '20vw', (v) => onValueChange(fieldName, v), fieldName, initialValue)}
              </Form.Item>
              )}
              <div className="profileField-form-column-block-placeholder">{placeholder}</div>
            </div>
            {/* {!fieldInEdit && disabled && <Button type="link" onClick={() => onChangeClick(fieldName, disabled)}>Изменить</Button>} */}
            {!fieldInEdit && fieldName === 'email' && !isEmailVerified && <div className="profileField-form-column-block-row-change" onClick={() => onApproveClick(fieldName)}>Подтвердить</div>}
          </div>
          {fieldInEdit === fieldName && disabled && (
          <div className="profileField-card">
            <Card bordered>
              <Alert message="Данное поле меняется только через запрос к поддержке" type="warning" style={{ marginBottom: '16px' }} />
              <div>Новое значение</div>
              {this.getField(disabled, loading, catalogue, fieldType, showSearch, setNewValue, true, '100%')}
              <div>Причина изменения</div>
              <TextArea onChange={(e) => setComment(e.target.value)} />
              <div className="btn-block__inside">
                <Button type="primary" className="btn-default btn_green" onClick={onSaveClick}>Сохранить</Button>
                <Button onClick={onCancelChange} className="btn-default btn_transparent">Отмена</Button>
              </div>
            </Card>
          </div>
          )}
        </div>
      );
    }
}

ProfileField.propTypes = {
  initialValue: PropTypes.any,
  fieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  fieldVisibleName: PropTypes.string.isRequired,
  fieldInEdit: PropTypes.string,
  onSaveClick: PropTypes.func.isRequired,
  onCancelChange: PropTypes.func.isRequired,
  setNewValue: PropTypes.func.isRequired,
  setComment: PropTypes.func.isRequired,
  fieldType: PropTypes.string.isRequired,
  catalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showSearch: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onValueChange: PropTypes.func,
  onApproveClick: PropTypes.func,
  isEmailVerified: PropTypes.bool,
};

ProfileField.defaultProps = {
  fieldInEdit: null,
  initialValue: null,
  placeholder: '',
  required: false,
  onValueChange: () => {},
  disabled: true,
  onApproveClick: () => {},
  isEmailVerified: true,
};

export default ProfileField;
