/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import {
  Avatar, Button, Col, Row, Modal, Popconfirm, Input, Spin, InputNumber, List, Skeleton,
} from 'antd';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import calendar from '../../../assets/icons/calendar.svg';
import moreInfo from '../../../assets/icons/more-info.png';
import SpecialistSlots from '../components/SpecialistSlots';
import './ClientSessionsPageDesktop.css';
import '../../ClientGroupSessions/Desktop/ClientGroupSessionsPageDesktop.css';
import timeZoneCatalogue from '../../../utils/timeZoneCatalogue';
import getTimeZoneDifference from '../../../utils/localeStorage/getTimeZoneDifference';


const { TextArea } = Input;
class ClientSessionsPageDesktop extends Component {
  state = {
    cost: 0,
    isShowDetailedInfo: false,
    code: '',
  }

  onPaySession = () => {
    const { code } = this.state;
    const { isShowPromoCodeModal, onPaySession, onPaySessionGroup } = this.props;
    if (isShowPromoCodeModal.isGroup) {
      onPaySessionGroup(isShowPromoCodeModal.session.cost, isShowPromoCodeModal.session, code);
    } else {
      onPaySession(isShowPromoCodeModal.session.cost / 100, code);
    }
  }

  render() {
    const {
      currentSpecialist, onPaySession, onCancelSession, onCloseSelectCancelOptionModal, isShowSelectCancelOptionModal, onCancelSelectOption,
      selectCancelOptionModalStep, onEnroll, onSelect, selectedTime, selectedDate, slots, onChangeDeclineComment, onLeaveDeclineComment,
      onSelectOtherOption, loading, onStartSession, isStartSession, joinURL, isShowPaySessionModal, onPaySessionModal, onFindSpecialist,
      clientSessions, currentSession, onNewEnroll, onChageSpecialistRequest, isShowNewEnrollModal, onCloseNewEnrollModal, onConfirmNewEnroll,
      changeSpecialistRequestID, groupSesions, isShowPaySessionModalGroup, onPaySessionModalGroup, onPaySessionGroup,
      selectCancelOptionModalStepGroup, onCloseSelectCancelOptionModalGroup, onShowCancelSessionModalGroup, onChangeDeclineCommentGroup,
      onDeclineSessionGroup, onFindGroup, isShowPromoCodeModal, onClosePromoCodeModal,
    } = this.props;
    const { cost, isShowDetailedInfo, code } = this.state;
    const currentSpecialistSessionsNumber = currentSpecialist && clientSessions.filter((session) => session.statusID === 'done' && session.specialist_id === currentSpecialist.id).length;
    return (
      <Col className="profilePageDesktop_center wrapper__clientsession">
        {isStartSession && (
          <Row className="clientSessionsPageDesktop clientSessionsPageDesktop__no-padding">
            {!joinURL && (
            <Col>
              <Row justify="center">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
              </Row>
              <Row>Ожидаем подготовки комнаты для начала сессии</Row>
            </Col>
            )}
            {joinURL && (
              <Col style={{ height: '100%' }}>
                <span>Ваша комната готова. Если страница не открылась автоматически, перейдите</span>
                <a href={joinURL}> по этой ссылке </a>
              </Col>
            )}
          </Row>
        )}
        {loading && !isStartSession && (
        <Row className="clientSessionsPageDesktop clientSessionsPageDesktop__no-padding">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
        </Row>
        )}
        <div className="profilePageDesktop-form-column-title">Ближайшие события</div>
        <div style={{ fontSize: '10px', marginTop: '-40px', marginBottom: '40px' }}>{timeZoneCatalogue.find((t) => t.timeDifference === `${getTimeZoneDifference()}`).value}</div>
        <div className="h3-title">Индивидуальная консультация</div>
        {!loading && !currentSpecialist && !isStartSession && (
          <div>
            <div className="message__no-events">Вы еще не записались к специалисту</div>
            <div className="btn-default btn_red" onClick={onFindSpecialist}>Выбрать психолога</div>
          </div>
        )}
        {!loading && !isStartSession && currentSpecialist && (
        <Row className="clientSessionsPageDesktop clientSessionsPageDesktop__no-padding">
          <div className="client-session__wrapper">
            <div className="client-session__prev">
              <Avatar src={currentSpecialist.logoURL} className="client-session__avatar" />
              <Col span={16}>
                <Row className="client-session__name" gutter={10}>
                  <Col>
                    {`${currentSpecialist.firstName} ${currentSpecialist.lastName}`}
                  </Col>
                  <Col onClick={() => this.setState({ isShowDetailedInfo: true })} className="info-block__link">
                    <img className="icon__more-info" src={moreInfo} alt="Информация о специалисте" title="Информация о специалисте" />
                  </Col>
                </Row>
                <Row className="client-session__count">{`Количество проведенных с вами сессий: ${currentSpecialistSessionsNumber}`}</Row>
                {!currentSession && (
                <Row>
                  <Col>
                    <Button type="primary" className="btn-default btn_green btn_right-margin" onClick={onNewEnroll}>Записаться</Button>
                  </Col>
                  {!changeSpecialistRequestID && (
                  <Col>
                    <Button className="btn-default btn_transparent" onClick={onChageSpecialistRequest}>Поменять специалиста</Button>
                  </Col>
                  )}
                </Row>
                )}

                {currentSession && (
                <Row className="client-session__param">
                  <Col className="client-session__param_1">
                    <Row justify="center"><img className="block-icon" src={calendar} alt="calendar" /></Row>
                  </Col>
                  <Col className="client-session__param_2">
                    <h3>Ближайшая консультация</h3>
                    <h4>{currentSession.sessionDateAndTime.format('DD MMMM, HH:mm, dd')}</h4>
                    {currentSession.statusID === 'toBePaid' && (
                    <p className="client-session__redline">
                      {`Консультация должна быть оплачена до ${moment.max([moment(currentSession.sessionDateAndTime).subtract(24, 'h')]).format('DD MMMM YYYY, HH:mm')}`}
                    </p>
                    )}
                  </Col>
                  <Col className="client-session__param_3">
                    <Row gutter={20}>
                      {currentSession.statusID === 'toBePaid' && <Col><Button type="primary" className="btn-default btn_red" onClick={onPaySessionModal}>Оплатить встречу</Button></Col>}
                      {currentSession.statusID === 'paid' && <Col><Popconfirm title="Вы уверены, что хотите перенести?" onConfirm={() => onCancelSelectOption('old')} okText="Да" cancelText="Нет"><Button className="btn-default btn_transparent" type="primary">Перенести встречу</Button></Popconfirm></Col>}
                      {(currentSession.statusID === 'toBePaid' || currentSession.statusID === 'paid') && <Col><Button className="btn-default btn_transparent" onClick={onCancelSession}>Отменить</Button></Col>}
                      {currentSession.statusID === 'paid' && <Col><Button type="primary" className="btn-default btn_red" onClick={onStartSession}>Начать сессию</Button></Col>}
                    </Row>
                  </Col>
                </Row>
                )}
              </Col>
            </div>
          </div>
          {isShowSelectCancelOptionModal && (
          <Modal
            visible
            title={selectCancelOptionModalStep === 0 || selectCancelOptionModalStep === 2
              ? 'Укажите причину отмены'
              : selectCancelOptionModalStep === 3 ? 'Выберите вариант' : 'Выберите время'}
            maskClosable
            onCancel={onCloseSelectCancelOptionModal}
            footer={false}
          >
            {selectCancelOptionModalStep === 0 && (
            <Col span={24}>
              <Row><Button className="btn-default btn_red" onClick={() => onCancelSelectOption('new')}>Хочу поменять психолога</Button></Row>
              <Row><Button className="btn-default btn_transparent" onClick={() => onCancelSelectOption('old')} style={{ margin: '10px 0px' }}>Хочу поменять время встречи</Button></Row>
              <Row><Button className="btn-default btn_transparent" onClick={() => onCancelSelectOption('other')}>Хочу полностью отменить консультацию</Button></Row>
            </Col>
            )}
            {selectCancelOptionModalStep === 1 && (
            <SpecialistSlots
              slots={slots}
              onEnroll={onEnroll}
              onSelect={onSelect}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
            />
            )}
            {selectCancelOptionModalStep === 2 && (
            <Col>
              <Row>Спасибо мы отменили вашу встречу, можете ли вы рассказать нам, что пошло не так?</Row>
              <TextArea style={{ margin: '10px 0px' }} onChange={onChangeDeclineComment} />
              <Button type="primary" onClick={onLeaveDeclineComment}>Поделиться</Button>
            </Col>
            )}
            {selectCancelOptionModalStep === 3 && (
            <Col>
              <Row gutter={12}>
                <Col><Button className="btn-default btn_transparent" onClick={() => onSelectOtherOption(1)}>Хочу сам выбрать другого</Button></Col>
                <Col><Button className="btn-default btn_transparent" onClick={() => onSelectOtherOption(2)}>Подберите мне другого</Button></Col>
              </Row>
            </Col>
            )}
          </Modal>
          )}
          {isShowPaySessionModal && (
          <Modal
            visible
            title="Подтверждение сессии"
            maskClosable
            onCancel={onPaySessionModal}
            footer={false}
          >
            <Col span={24}>
              <Row>
                Пожалуйста, подтвердите проведение сессии со специалистом. Вы также можете оплатить
                услуги специлиста в том размере, в котором считаете нужным.
              </Row>
              <Row style={{ margin: '10px 0px' }}><InputNumber style={{ width: '100%' }} min={0} value={cost} onChange={(newCost) => this.setState({ cost: newCost })} /></Row>
              <Row><Button onClick={() => onPaySession(cost)}>Подтвердить</Button></Row>
            </Col>
          </Modal>
          )}
          {isShowNewEnrollModal && (
          <Modal
            visible
            title="Новая сессия"
            maskClosable
            onCancel={onCloseNewEnrollModal}
            footer={false}
          >
            <Col span={24}>
              <SpecialistSlots
                slots={slots}
                onEnroll={onConfirmNewEnroll}
                onSelect={onSelect}
                selectedDate={selectedDate}
                selectedTime={selectedTime}
                isNew
              />
            </Col>
          </Modal>
          )}
          {isShowDetailedInfo && (
          <Modal
            visible
            maskClosable
            width="80vw"
            onCancel={() => this.setState({ isShowDetailedInfo: false })}
            footer={false}
          >
            <div className="content-text">
              <h2 className="content-text__h2">О специалисте</h2>
              <div className="content-text__p" dangerouslySetInnerHTML={{ __html: currentSpecialist.descriptionClients }} />
            </div>
          </Modal>
          )}
        </Row>
        )}
        <div className="h3-title h3-title__topmargin">Групповые консультации</div>
        {!loading && groupSesions.length === 0 && (
          <div>
            <div className="message__no-events">У вас нет ближайших групп</div>
            <div className="btn-default btn_green" onClick={onFindGroup}>Подобрать группу</div>
          </div>
        )}
        {!loading && groupSesions.length > 0
        && (
          <div className="wrapper__clientsession">
            <List
              className="demo-loadmore-list"
              itemLayout="horizontal"
              dataSource={orderBy(groupSesions, 'startDateAndTime', 'asc')}
              renderItem={(item) => (
                <List.Item
                  className="group-session__one-block"
                  actions={item.statusID === 'toBePaid' || item.statusID === 'approved'
                    ? [<a className="btn-default btn_link btn_link_dotted" key="list-cancel" onClick={() => onShowCancelSessionModalGroup(item)}><span>Отменить</span></a>,
                      <a className="btn-default btn_red" key="list-pay" onClick={() => onPaySessionModalGroup(item)}><span>Оплатить</span></a>]
                    : item.statusID === 'paid'
                      ? [<a className="btn-default btn_link btn_link_dotted" key="list-cancel" onClick={() => onShowCancelSessionModalGroup(item)}><span>Отменить</span></a>,
                        <a className="btn-default btn_green go-to-meet" key="list-ощшт" onClick={() => window.open(item.VOXClientURL, '_self')}><span>Начать</span></a>]
                      : []}
                >
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      className="client__group-session__left"
                      avatar={(
                        <Avatar className="client__group-session__avatar" src={item.specialist.logoURL} />
                )}
                      title={(
                        <div className="client__group-session__about">
                          <div className="client__group-session__param">
                            <div className="client__group-session__title">{item.name}</div>
                          </div>
                          <div className="client__group-session__person">{`Тренер - ${item.specialist.lastName} ${item.specialist.firstName} ${item.specialist.middleName}`}</div>
                          <div className="client__group-session__date">{`${item.startDateAndTime && item.startDateAndTime.format('DD MMMM')} ${item.startDateAndTime && item.startDateAndTime.format('HH:mm')} - ${item.endDateAndTime && item.endDateAndTime.format('HH:mm')}, до ${item.maxNumberOfClients} человек`}</div>
                        </div>
                )}
                      description={(
                        <div className="client__group-session__desc">{item.description}</div>
                )}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </div>
        )}
        {isShowPaySessionModalGroup && (
          <Modal
            visible
            title="Подтверждение сессии"
            maskClosable
            onCancel={() => onPaySessionModalGroup()}
            footer={false}
          >
            <Col span={24}>
              <Row>
                Пожалуйста, подтвердите проведение сессии со специалистом. Вы также можете оплатить
                услуги специлиста в том размере, в котором считаете нужным.
              </Row>
              <Row style={{ margin: '10px 0px' }}><InputNumber style={{ width: '100%' }} min={0} value={cost} onChange={(newCost) => this.setState({ cost: newCost })} /></Row>
              <Row><Button className="btn-default btn_red" onClick={() => onPaySessionGroup(cost)}>Подтвердить</Button></Row>
            </Col>
          </Modal>
        )}
        {selectCancelOptionModalStepGroup !== 0 && (
          <Modal
            visible
            title="Укажите причину отмены"
            maskClosable
            onCancel={onCloseSelectCancelOptionModalGroup}
            footer={false}
          >
            <Col span={24}>
              <Row>Можете ли вы рассказать нам, что пошло не так?</Row>
              <TextArea style={{ margin: '10px 0px' }} onChange={onChangeDeclineCommentGroup} />
              <Button className="btn-default btn_green" type="primary" onClick={onDeclineSessionGroup}>Отменить</Button>
            </Col>
          </Modal>
        )}
        {isShowPromoCodeModal && (
          <Modal
            visible
            title="Оплата сессии"
            maskClosable
            onCancel={onClosePromoCodeModal}
            footer={false}
          >
            <Col span={24}>
              <Row>
                Пожалуйста, введите промокод
              </Row>
              <Row style={{ margin: '10px 0px' }}><Input style={{ width: '100%' }} value={code} onChange={(e) => this.setState({ code: e.target.value })} /></Row>
              <Row><Button className="btn-default btn_red" onClick={this.onPaySession}>Оплатить</Button></Row>
            </Col>
          </Modal>
        )}
      </Col>
    );
  }
}

ClientSessionsPageDesktop.propTypes = {
  currentSpecialist: PropTypes.shape(),
  clientSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onPaySession: PropTypes.func.isRequired,
  onCancelSession: PropTypes.func.isRequired,
  isShowSelectCancelOptionModal: PropTypes.bool.isRequired,
  onCloseSelectCancelOptionModal: PropTypes.func.isRequired,
  onCancelSelectOption: PropTypes.func.isRequired,
  selectCancelOptionModalStep: PropTypes.number.isRequired,
  onEnroll: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedTime: PropTypes.string,
  selectedDate: PropTypes.shape(),
  slots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeDeclineComment: PropTypes.func.isRequired,
  onSelectOtherOption: PropTypes.func.isRequired,
  onLeaveDeclineComment: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onStartSession: PropTypes.func.isRequired,
  isStartSession: PropTypes.bool.isRequired,
  joinURL: PropTypes.string,
  onPaySessionModal: PropTypes.func.isRequired,
  isShowPaySessionModal: PropTypes.bool.isRequired,
  onFindSpecialist: PropTypes.func.isRequired,
  currentSession: PropTypes.shape(),
  onNewEnroll: PropTypes.func.isRequired,
  onChageSpecialistRequest: PropTypes.func.isRequired,
  isShowNewEnrollModal: PropTypes.bool.isRequired,
  onCloseNewEnrollModal: PropTypes.func.isRequired,
  onConfirmNewEnroll: PropTypes.func.isRequired,
  changeSpecialistRequestID: PropTypes.string,
  groupSesions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isShowPaySessionModalGroup: PropTypes.bool.isRequired,
  onPaySessionModalGroup: PropTypes.func.isRequired,
  onPaySessionGroup: PropTypes.func.isRequired,
  selectCancelOptionModalStepGroup: PropTypes.number.isRequired,
  onCloseSelectCancelOptionModalGroup: PropTypes.func.isRequired,
  onShowCancelSessionModalGroup: PropTypes.func.isRequired,
  onChangeDeclineCommentGroup: PropTypes.func.isRequired,
  onDeclineSessionGroup: PropTypes.func.isRequired,
  onFindGroup: PropTypes.func.isRequired,
  isShowPromoCodeModal: PropTypes.shape(),
  onClosePromoCodeModal: PropTypes.func.isRequired,
};

ClientSessionsPageDesktop.defaultProps = {
  currentSession: null,
  currentSpecialist: null,
  selectedTime: null,
  selectedDate: null,
  joinURL: null,
  changeSpecialistRequestID: null,
  isShowPromoCodeModal: null,
};

export default ClientSessionsPageDesktop;
