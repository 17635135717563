import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import { ru } from 'convert-layout';
import { orderBy, groupBy, uniqBy } from 'lodash';
import OrgPromoCodesPageDesktop from './Desktop/OrgPromoCodesPageDesktop';
import { getCompanyInfoById } from '../../utils/http';
import getUserId from '../../utils/localeStorage/getUserId';


function OrgPromoCodesModule() {
  const [loading, setLoading] = useState(true);
  const [isMobile] = useState(window.innerWidth <= 800);
  const [orgInfo, serOrgInfo] = useState(null);
  const [detailedDataSourse, setDetailedDataSourse] = useState([]);
  const [statDataSourse, setStatDataSourse] = useState([]);
  const [originalDetailedDataSourse, setOriginalDetailedDataSourse] = useState([]);
  const [originalStatDataSourse, setOriginalStatDataSourse] = useState([]);


  const prepareStatDataSourse = (initArray) => {
    const grouped = groupBy(initArray.filter((s) => s.sessionId), 'sessionClientNamePhone');
    const result = [];
    Object.keys(grouped).forEach((key) => result.push({
      id: key,
      clientName: key,
      numberOfSessions: grouped[key].length,
    }));
    return orderBy(result, 'numberOfSessions', 'desc');
  };

  useEffect(() => {
    getCompanyInfoById(getUserId())
      .then((response) => {
        setLoading(false);
        serOrgInfo(response);
        setDetailedDataSourse(response.promoCodes);
        setOriginalDetailedDataSourse(response.promoCodes);
        const statData = prepareStatDataSourse(response.promoCodes);
        setStatDataSourse(statData);
        setOriginalStatDataSourse(statData);
      });
  }, []);

  const onSearchStat = (input) => {
    if (!input) {
      setStatDataSourse(originalStatDataSourse);
      return;
    }

    const fuse = new Fuse(originalStatDataSourse, {
      keys: ['clientName'],
      threshold: 0.3,
    });

    const matches = [
      ...fuse.search(input),
      ...fuse.search(ru.fromEn(input)),
    ].map((result) => result.item);
    setStatDataSourse(orderBy(uniqBy(matches, 'id'), 'numberOfSessions', 'desc'));
  };

  const onSearchDetailed = (input) => {
    if (!input) {
      setDetailedDataSourse(originalDetailedDataSourse);
      return;
    }

    const fuse = new Fuse(originalDetailedDataSourse, {
      keys: ['sessionClientNickName', 'sessionClientPhone'],
      threshold: 0.3,
    });

    const matches = [
      ...fuse.search(input),
      ...fuse.search(ru.fromEn(input)),
    ].map((result) => result.item);
    setDetailedDataSourse(uniqBy(matches, 'id'));
  };


  if (!isMobile) {
    return (
      <OrgPromoCodesPageDesktop
        loading={loading}
        orgInfo={orgInfo}
        detailedDataSourse={detailedDataSourse}
        statDataSourse={statDataSourse}
        onSearchStat={onSearchStat}
        onSearchDetailed={onSearchDetailed}
      />
    );
  }
  return (
    <div />
  );
}


export default OrgPromoCodesModule;
