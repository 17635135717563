import React from 'react';
import { Button, Tag, Badge } from 'antd';
import getUserRole from '../../../utils/localeStorage/getUserRole';


const getHelpRequestsColumns = (onSelectHelpRequest) => {
  const res = [
    {
      title: 'Номер',
      dataIndex: 'displayID',
      key: 'displayID',
      width: '10%',
      render: (text, object) => (
        <Badge count={getUserRole() === 'as2f2fc34' ? object.adminUnreadCount : object.authorUnreadCount} offset={[-10, 5]}>
          <Button type="link" onClick={() => onSelectHelpRequest(object)}>{text}</Button>
        </Badge>
      ),
    },
    {
      title: 'Дата обращения',
      dataIndex: 'createDate',
      key: 'createDate',
      width: '15%',
      render: (text) => (text ? text.format('DD MMMM YYYY') : ''),
    },
    {
      title: 'Тема',
      dataIndex: 'theme',
      key: 'theme',
      width: '30%',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (text) => (text === 'Открыто' ? <Tag color="gold">{text}</Tag> : <Tag color="green">{text}</Tag>),
    },
  ];
  if (getUserRole() === 'as2f2fc34') {
    res.push({
      title: 'Автор',
      dataIndex: 'author',
      key: 'author',
      render: (text, object) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{text}</div>
          <div>{object.authorPhone}</div>
        </div>
      ),
    });
  }
  return res;
};

export default getHelpRequestsColumns;
