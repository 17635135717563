import React, { Component } from 'react';
import { config } from '../../utils/config';
import { getAllCancelSessionRequest, getAllChangeSpecialistRequest, editChangeSpecialistRequest } from '../../utils/http';
import AdminCancelRequestsDesktop from './Desktop/AdminCancelRequestsDesktop';


class AdminCancelRequestsModule extends Component {
  state = {
    isMobile: window.innerWidth <= config.mobileWidth,
    loading: false,
    cancelSessionRequest: [],
    changeSpecialistRequest: [],
  }

  componentDidMount() {
    Promise.all([getAllCancelSessionRequest(), getAllChangeSpecialistRequest()])
      .then((res) => this.setState({ cancelSessionRequest: res[0], changeSpecialistRequest: res[1] }));
  }

  onChange = (id) => {
    this.setState({ loading: true });
    editChangeSpecialistRequest({ id, status: 'Выполнен' })
      .then(() => this.setState((prevState) => ({
        loading: false,
        changeSpecialistRequest: prevState.changeSpecialistRequest.map((s) => (s.id === id ? { ...s, status: 'Выполнен' } : s)),
      })));
  }

  render() {
    const {
      isMobile, loading, cancelSessionRequest, changeSpecialistRequest,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <AdminCancelRequestsDesktop
        loading={loading}
        cancelSessionRequest={cancelSessionRequest}
        changeSpecialistRequest={changeSpecialistRequest}
        onChange={this.onChange}
      />
    );
  }
}

export default AdminCancelRequestsModule;
