/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Spin, Tabs, Table, Modal, Button, Form, Input, Col, Popconfirm,
} from 'antd';
import adminSpecialistsColumns from '../utils/adminSpecialistsColumns';
// import adminClientColumns from '../utils/adminClientColumns';
import './AdminSpecialistsPageDesktop.css';
import specialistStatusCatalogue from '../../../utils/specialistStatusCatalogue';
import Anketa from '../../../components/Anketa/Anketa';


const { TextArea } = Input;
const { TabPane } = Tabs;
const { Search } = Input;
class AdminSpecialistsPageDesktop extends Component {
  render() {
    const {
      loading, specialists, onChangeView, view, onSelect, selectedSpecialist, onCloseModal, onSearch,
      onApprove, onDecline, isShowDeclineModal, formRef, onConfirmDecline, onCloseDeclineModal,
      selectedClient, onBlockClient, declineStatus,
    } = this.props;
    if (loading) {
      return (
        <div className="adminSpecialistsPageDesktop-wrapper" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
        </div>
      );
    }
    return (
      <Col span={22} className="adminSpecialistsPageDesktop-wrapper">
        <Tabs>
          <TabPane tab="Специалисты" key="specialists">
            <Search
              placeholder="Имя или телефон"
              onChange={(e) => onSearch(e, 'specialists')}
            />
            <Tabs defaultActiveKey={view} onChange={onChangeView}>
              {specialistStatusCatalogue.map((status) => (
                <TabPane tab={status.value} key={status.id}>
                  <Table
                    columns={adminSpecialistsColumns(onSelect)}
                    rowKey="id"
                    dataSource={specialists.filter((s) => s.status === status.id)}
                    pagination={false}
                    scroll={{ y: '60vh' }}
                  />
                </TabPane>
              ))}
            </Tabs>
          </TabPane>
          {/* <TabPane tab="Клиенты" key="clients">
            <Search
              placeholder="Имя или телефон"
              onChange={(e) => onSearch(e, 'clients')}
            />
            <Table
              columns={adminClientColumns(onSelect)}
              rowKey="id"
              dataSource={clients}
              pagination={false}
              scroll={{ y: '60vh' }}
            />
          </TabPane> */}
        </Tabs>
        {selectedClient && (
          <Modal
            visible
            title={`Клиент: ${selectedClient.nickName || 'Без имени'} (+${selectedClient.phone})`}
            onCancel={onCloseModal}
            maskClosable
            footer={
              <Button style={{ backgroundColor: '#ff4d4f', color: '#fff' }} onClick={onBlockClient}>Заблокировать</Button>
}
          >
            <Form ref={formRef} layout="vertical">
              <Form.Item
                style={{ marginTop: '10px' }}
                name="moderatorComment"
                label="Комментарий"
                rules={[{ required: true }]}
              >
                <TextArea style={{ width: '100%' }} />
              </Form.Item>
            </Form>
          </Modal>

        )}
        {selectedSpecialist && (
        <Modal
          visible
          title="Анкета"
          onCancel={onCloseModal}
          maskClosable
          width="90%"
          bodyStyle={{ height: '75vh' }}
          style={{ top: '5vh' }}
          footer={view === 'onApproval' ? [
            <Popconfirm
              title="Вы нажали кнопку 'Сохранить'? Перед одобрением, сохраните, пожалуйста, изменения"
              onConfirm={onApprove}
              okText="Да"
              cancelText="Нет"
            >
              <Button key="1" style={{ backgroundColor: '#52c41a', color: '#fff' }}>Одобрить</Button>
            </Popconfirm>,
            <Button key="2" type="primary" onClick={() => onDecline('declined')}>На доработку</Button>,
            <Button key="3" style={{ backgroundColor: '#ff4d4f', color: '#fff' }} onClick={() => onDecline('blocked')}>Отказать</Button>,
          ] : null}
        >
          <div style={{ height: '70vh', overflowY: 'scroll' }}>
            <Anketa user={selectedSpecialist} />
          </div>
        </Modal>
        )}
        {isShowDeclineModal && (
        <Modal
          visible
          title="Причина отклонения"
          onCancel={onCloseDeclineModal}
          maskClosable
          footer={[
            <Button key="3" type="primary" onClick={onConfirmDecline}>{declineStatus === 'declined' ? 'На доработку' : 'Отказать'}</Button>,
          ]}
        >
          <Form ref={formRef}>
            <div className="anketa-wrapper-block">
              <div style={{ fontWeight: 'bold' }}>Комментарий</div>
              <Form.Item
                style={{ marginTop: '10px' }}
                name="moderatorComment"
              >
                <TextArea style={{ width: '100%' }} />
              </Form.Item>
            </div>
          </Form>
        </Modal>
        )}
      </Col>
    );
  }
}


AdminSpecialistsPageDesktop.propTypes = {
  loading: PropTypes.bool.isRequired,
  specialists: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedSpecialist: PropTypes.shape(),
  onCloseModal: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  isShowDeclineModal: PropTypes.bool.isRequired,
  onConfirmDecline: PropTypes.func.isRequired,
  formRef: PropTypes.shape().isRequired,
  onCloseDeclineModal: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  // clients: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedClient: PropTypes.shape(),
  onBlockClient: PropTypes.func.isRequired,
  declineStatus: PropTypes.string,
};

AdminSpecialistsPageDesktop.defaultProps = {
  selectedSpecialist: null,
  selectedClient: null,
  declineStatus: null,
};

export default AdminSpecialistsPageDesktop;
