/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';


const adminSpecialistsColumns = (onSelect) => [
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    render: (text, object) => <a onClick={() => onSelect(object.id, 'specialists')}>{text}</a>,
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'ФИО',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'createDate',
    key: 'createDate',
    render: (text) => (text ? text.format('DD MMMM YYYY') : ''),
    sorter: (a, b) => a.createDate - b.createDate,
  },
];

export default adminSpecialistsColumns;
