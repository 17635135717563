/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import { orderBy } from 'lodash';
import {
  Spin, Table, Col,
} from 'antd';
import getAdminLoginColumns from '../utils/getAdminLoginColumns';
import './AdminLoginPageDesktop.css';


class AdminLoginPageDesktop extends Component {
  render() {
    const { loading, loginAttempts } = this.props;
    if (loading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
        </div>
      );
    }
    return (
      <Col span={22} className="adminLoginPageDesktop-wrapper">
        <div style={{ marginBottom: '20px', fontSize: '20px' }}>Все входы в систему</div>
        <Table
          columns={getAdminLoginColumns()}
          rowKey="id"
          dataSource={orderBy(loginAttempts, 'date', 'desc')}
          pagination={false}
          scroll={{ y: '60vh' }}
        />
      </Col>
    );
  }
}


AdminLoginPageDesktop.propTypes = {
  loading: PropTypes.bool.isRequired,
  loginAttempts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default AdminLoginPageDesktop;
