/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './HomePageMobile.css';
import logo from '../../../assets/icons/logo.svg';


class HomePageMobile extends Component {
  render() {
    const { onRegisterClick, onRegisterPsychoClick } = this.props;
    return (
      <div className="homePageMobile-wrapper" style={{ padding: '0px' }}>
        <div className="homePageMobile-wrapper">
          <img src={logo} alt="Гештальт онлайн" />
          <div style={{ fontSize: '30px', margin: '20px 0px' }}>Найди своего терапевта онлайн</div>
          <div style={{ fontSize: '20px' }}>Наш сервис поможет решить ваши проблемы</div>
          <div
            onClick={onRegisterClick}
            style={{
              backgroundColor: 'red', borderRadius: '15px', width: '50px', height: '50px', color: '#fff', lineHeight: '50px', margin: '50px 0px',
            }}
          >
            {'->'}
          </div>
        </div>
        <div style={{ backgroundColor: 'green', width: '100%', padding: '30px 40px' }}>
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px 0px 10px', color: '#fff', borderBottom: '1px solid #fff',
          }}
          >
            <div>1</div>
            <div>Создать аккаунт</div>
          </div>
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '30px 0px 10px', color: '#fff', borderBottom: '1px solid #fff',
          }}
          >
            <div>1</div>
            <div>Как это работает?</div>
          </div>
          <div
            onClick={onRegisterPsychoClick}
            style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '30px 0px 10px', color: '#fff', borderBottom: '1px solid #fff',
            }}
          >
            <div>1</div>
            <div>Терапевтам</div>
          </div>
        </div>
      </div>
    );
  }
}


HomePageMobile.propTypes = {
  onRegisterClick: PropTypes.func.isRequired,
  onRegisterPsychoClick: PropTypes.func.isRequired,
};

export default HomePageMobile;
