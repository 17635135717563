import { routes } from './routes';


const logout = (history) => {
  window.localStorage.clear();
  history.push(routes.home);
  window.location.reload();
};

export default logout;
