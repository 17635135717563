/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import { orderBy } from 'lodash';
import {
  Spin, Table, Col, Button, Modal, Form, Input, Select, Row, Card, Popconfirm, Tag, Divider, Tabs,
} from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ru from 'react-phone-input-2/lang/es.json';
import getManualUsersColumns from '../utils/getManualUsersColumns';
import getClientSessionsColumns from '../utils/getClientSessionsColumns';
import './AdminManualUsersPageDesktop.css';


const { Option } = Select;
const { Meta } = Card;
const { CheckableTag } = Tag;
const { TextArea } = Input;
const { Search } = Input;
const { TabPane } = Tabs;
class AdminManualUsersPageDesktop extends Component {
  render() {
    const {
      loading, manualUsers, onClientClick, onCloseClient, selectedClient, formRef, timeZoneCatalogue, onNextPage, page, specialistList,
      onPsychoClick, selectedPsychoSlots, onBackPage, selectedDate, selectedTime, onSelect, onEnroll, clientSessions, onSaveProfile,
      onCancelSession, onCancelSelectOption, isShowSelectCancelOptionModal, onCloseSelectCancelOptionModal, selectCancelOptionModalStep,
      onLeaveDeclineComment, onChangeDeclineComment, onSearch,
    } = this.props;
    if (loading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
        </div>
      );
    }
    return (
      <Col span={24} className="adminManualUsersPageDesktop-wrapper">
        <div style={{ fontSize: '20px', color: '#212121', marginBottom: '40px' }}>Управление пользователями</div>
        <Button type="primary" onClick={() => onClientClick({})}>Добавить нового пользователя</Button>
        <Search
          placeholder="Имя или телефон"
          onChange={onSearch}
        />
        <Tabs>
          <TabPane tab="Активные" key="active">
            <Table
              columns={getManualUsersColumns(onClientClick)}
              rowKey="id"
              dataSource={orderBy(manualUsers.filter((c) => c.isValidClient), 'date', 'desc')}
              pagination={false}
              scroll={{ y: '60vh' }}
              style={{ marginTop: '20px' }}
            />
          </TabPane>
          <TabPane tab="Неактивные" key="unactive">
            <Table
              columns={getManualUsersColumns(onClientClick)}
              rowKey="id"
              dataSource={orderBy(manualUsers.filter((c) => !c.isValidClient), 'date', 'desc')}
              pagination={false}
              scroll={{ y: '60vh' }}
              style={{ marginTop: '20px' }}
            />
          </TabPane>
          <TabPane tab="Заблокированные" key="blocked">
            <Table
              columns={getManualUsersColumns(onClientClick)}
              rowKey="id"
              dataSource={orderBy(manualUsers.filter((c) => c.isBlocked), 'date', 'desc')}
              pagination={false}
              scroll={{ y: '60vh' }}
              style={{ marginTop: '20px' }}
            />
          </TabPane>
          <TabPane tab="Удаленные" key="deleted">
            <Table
              columns={getManualUsersColumns(onClientClick)}
              rowKey="id"
              dataSource={orderBy(manualUsers.filter((c) => c.isDeleted), 'date', 'desc')}
              pagination={false}
              scroll={{ y: '60vh' }}
              style={{ marginTop: '20px' }}
            />
          </TabPane>
        </Tabs>
        {selectedClient && (
        <Modal
          visible
          title={`Клиент: ${selectedClient.nickName || 'Без имени'} ${selectedClient.phone ? `(+${selectedClient.phone})` : ''}`}
          onCancel={onCloseClient}
          maskClosable
          footer={page === 0 ? [
            <Button onClick={onNextPage}>Далее</Button>,
          ]
            : page === 2 || page === 3
              ? [
                <Button onClick={onBackPage}>Назад</Button>,
              ]
              : []}
          width={page === 0 ? '30vw' : '80vw'}
          bodyStyle={{ height: page === 0 ? '50vh' : '70vh' }}
        >
          {page === 0 && (
          <Form ref={formRef} layout="vertical" className="adminManualUsersPageDesktop-form">
            <Form.Item
              style={{ marginTop: '10px' }}
              name="nickName"
              label="Как зовут клиента?"
              initialValue={selectedClient.nickName}
              rules={[{ required: true }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: '10px' }}
              name="phone"
              initialValue={selectedClient.phone}
              label="Телефон"
              rules={[{ required: true }]}
            >
              <PhoneInput
                localization={ru}
                placeholder=""
                country="ru"
                disabled={!!selectedClient.id}
              />
            </Form.Item>
            <Form.Item
              style={{ marginTop: '10px' }}
              name="email"
              initialValue={selectedClient.email}
              label="Почта"
              rules={[{ required: true }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item hidden name="id" initialValue={selectedClient.id}>
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item hidden name="isManual" initialValue>
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              style={{ marginTop: '10px' }}
              name="timeZone"
              initialValue={selectedClient.timeZoneId}
              label="Часовой пояс?"
            >
              <Select
                style={{ width: '100%' }}
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {timeZoneCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
              </Select>
            </Form.Item>
          </Form>
          )}
          {page === 1 && (
            <Row style={{ height: '100%' }}>
              <Col span={6}>
                <Row style={{ color: '#212121', marginBottom: '40px', fontSize: '18px' }}>Анкета клиента</Row>
                <Form ref={formRef} layout="vertical" className="adminManualUsersPageDesktop-form">
                  <Form.Item
                    name="nickName"
                    label="Как зовут клиента?"
                    initialValue={selectedClient.nickName}
                    rules={[{ required: true }]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: '10px' }}
                    name="phone"
                    initialValue={selectedClient.phone}
                    label="Телефон"
                    rules={[{ required: true }]}
                  >
                    <PhoneInput
                      localization={ru}
                      placeholder=""
                      country="ru"
                      disabled={!!selectedClient.id}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: '10px' }}
                    name="email"
                    initialValue={selectedClient.email}
                    label="Почта"
                    rules={[{ required: true }]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item hidden name="id" initialValue={selectedClient.id}>
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item hidden name="isManual" initialValue={selectedClient.isManual}>
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: '10px' }}
                    name="timeZone"
                    initialValue={selectedClient.timeZoneId}
                    label="Часовой пояс?"
                  >
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {timeZoneCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: '10px' }}
                    name="currentSpecialist_id"
                    initialValue={selectedClient.currentSpecialistId}
                    label="Специалист"
                  >
                    <Select
                      style={{ width: '100%' }}
                      allowClear
                      showSearch
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {specialistList.map((u) => <Option key={u.id} value={u.id}>{`${u.lastName} ${u.firstName}`}</Option>)}
                    </Select>
                  </Form.Item>
                </Form>
                <Row><Button type="primary" onClick={onSaveProfile}>Сохранить</Button></Row>
              </Col>
              <Col span={1} style={{ textAlign: 'center' }}><Divider type="vertical" style={{ height: '100%' }} /></Col>
              <Col span={17}>
                <Row style={{ color: '#212121', marginBottom: '40px', fontSize: '18px' }} justify="space-between">
                  <Col>Все записи клиента</Col>
                  <Col><Button type="primary" onClick={onNextPage}>Добавить</Button></Col>
                </Row>
                <Row style={{ width: '100%' }}>
                  <Table
                    columns={getClientSessionsColumns(onCancelSession, onCancelSelectOption)}
                    rowKey="id"
                    dataSource={clientSessions}
                    pagination={false}
                    style={{ width: '100%' }}
                  />
                </Row>
              </Col>
            </Row>
          )}
          {page === 2 && (
            <div className="psychoListPageDesktop-column" style={{ height: '65vh', overflow: 'scroll' }}>
              {specialistList.map((sp) => (
                <div onClick={() => onPsychoClick(sp)} style={{ cursor: 'pointer', color: '#1890ff' }}>
                  {`${sp.lastName} ${sp.firstName}`}
                </div>
              ))}
            </div>
          )}
          {page === 3 && (
            <Col>
              <Row style={{ height: '60vh', overflow: 'scroll' }}>
                <Card
                  hoverable={false}
                  style={{ borderColor: '#bfbdbb', width: '100%' }}
                  id="TIME"
                >
                  <Meta
                    description={(
                      <Col span={24}>
                        <Row style={{ color: '#333333', margin: '0px 0px 10px 0px', fontSize: '20px' }}>Записаться к специалисту</Row>
                        <span>{selectedClient?.timeZone}</span>
                        {selectedPsychoSlots.map((slot) => (
                          <Row style={{ margin: '10px' }} key={slot.id}>
                            <Col span={24} key={slot.id}>
                              <Row style={{ color: '#333333' }}>{slot.date.format('DD MMMM, dddd')}</Row>
                              <Row gutter={12}>
                                {slot.timesArray.map((time) => (
                                  <CheckableTag
                                    key={time + slot.date}
                                    checked={selectedTime === time && slot.date.isSame(selectedDate)}
                                    onChange={() => onSelect(time, slot.date)}
                                  >
                                    {time}
                                  </CheckableTag>
                                ))}
                              </Row>
                            </Col>
                          </Row>
                        ))}

                      </Col>
              )}
                  />
                </Card>
              </Row>
              {selectedDate && selectedTime && (
              <Row style={{ marginTop: '20px' }}>
                <Popconfirm
                  title="Вы уверены, что хотите записаться к этому специалисту?"
                  onConfirm={onEnroll}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button type="primary">Записаться</Button>
                </Popconfirm>
              </Row>
              )}
            </Col>
          )}
        </Modal>
        )}
        {isShowSelectCancelOptionModal && (
          <Modal
            visible
            title={selectCancelOptionModalStep === 0 || selectCancelOptionModalStep === 2
              ? 'Укажите причину отмены'
              : selectCancelOptionModalStep === 3 ? 'Выберите вариант' : 'Выберите время'}
            maskClosable
            onCancel={onCloseSelectCancelOptionModal}
            footer={false}
          >
            {selectCancelOptionModalStep === 0 && (
            <Col span={24}>
              <Row><Button style={{ marginRight: '10px' }} onClick={() => onCancelSelectOption('new')}>Хочу поменять психолога</Button></Row>
              <Row><Button onClick={() => onCancelSelectOption('old')} style={{ margin: '10px 0px' }}>Хочу поменять время встречи</Button></Row>
              <Row><Button onClick={() => onCancelSelectOption('other')}>Хочу полностью отменить консультацию</Button></Row>
            </Col>
            )}
            {selectCancelOptionModalStep === 2 && (
            <Col>
              <Row>Спасибо мы отменили вашу встречу, можете ли вы рассказать нам, что пошло не так?</Row>
              <TextArea style={{ margin: '10px 0px' }} onChange={onChangeDeclineComment} />
              <Button type="primary" onClick={onLeaveDeclineComment}>Поделиться</Button>
            </Col>
            )}
          </Modal>
        )}
      </Col>
    );
  }
}


AdminManualUsersPageDesktop.propTypes = {
  loading: PropTypes.bool.isRequired,
  manualUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClientClick: PropTypes.func.isRequired,
  onCloseClient: PropTypes.func.isRequired,
  selectedClient: PropTypes.shape(),
  formRef: PropTypes.shape().isRequired,
  timeZoneCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onNextPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  specialistList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onPsychoClick: PropTypes.func.isRequired,
  selectedPsychoSlots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onBackPage: PropTypes.func.isRequired,
  selectedTime: PropTypes.string,
  selectedDate: PropTypes.shape(),
  onSelect: PropTypes.func.isRequired,
  onEnroll: PropTypes.func.isRequired,
  clientSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSaveProfile: PropTypes.func.isRequired,
  onCancelSession: PropTypes.func.isRequired,
  onCancelSelectOption: PropTypes.func.isRequired,
  isShowSelectCancelOptionModal: PropTypes.bool.isRequired,
  onCloseSelectCancelOptionModal: PropTypes.func.isRequired,
  selectCancelOptionModalStep: PropTypes.number.isRequired,
  onLeaveDeclineComment: PropTypes.func.isRequired,
  onChangeDeclineComment: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

AdminManualUsersPageDesktop.defaultProps = {
  selectedClient: null,
  selectedTime: null,
  selectedDate: null,
};

export default AdminManualUsersPageDesktop;
