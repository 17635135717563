
const getCancelSessionRequestColumns = () => [
  {
    title: 'Дата заявки',
    dataIndex: 'date',
    key: 'date',
    render: (text) => (text ? text.format('DD MMMM YYYY') : ''),
  },
  {
    title: 'Дата сессии',
    dataIndex: 'sessionDate',
    key: 'sessionDate',
    render: (text) => (text ? text.format('DD MMMM YYYY, HH:mm') : ''),
  },
  {
    title: 'Номер клиента',
    dataIndex: 'clientPhone',
    key: 'clientPhone',
  },
  {
    title: 'Ник клиента',
    dataIndex: 'clientName',
    key: 'clientName',
  },
  {
    title: 'Номер психолога',
    dataIndex: 'specialistPhone',
    key: 'specialistPhone',
  },
  {
    title: 'ФИО психолога',
    dataIndex: 'specialistName',
    key: 'specialistName',
  },
  {
    title: 'Причина отмены',
    dataIndex: 'declineReason',
    key: 'declineReason',
  },

];

export default getCancelSessionRequestColumns;
