const timeZoneCatalogue = [
  {
    id: 'dfabaee3-c01c-4765-b2bf-7210029a121f',
    value: '(UTC-12) Линия перемены дат',
    utc: '(UTC-12)',
    timeDifference: '720',
  },
  {
    id: '26c4296a-fbf4-452c-926a-5f5c725f1120',
    value: '(UTC-11) Самоа',
    utc: '(UTC-11)',
    timeDifference: '660',
  },
  {
    id: 'd1150487-b660-492e-8346-75e0f9b41f9f',
    value: '(UTC-10) Гавайи',
    utc: '(UTC-10)',
    timeDifference: '600',
  },
  {
    id: 'a10cb06d-111c-4159-869b-370990c4430c',
    value: '(UTC-9) Аляска',
    utc: '(UTC-9)',
    timeDifference: '540',
  },
  {
    id: '21f239f2-7685-4b9a-aafe-afb46eec4113',
    value: '(UTC-8) Североамериканское тихоокеанское время (США и Канада)',
    utc: '(UTC-8)',
    timeDifference: '480',
  },
  {
    id: '35b3db89-fae4-4814-85ed-dade5a9ae6ea',
    value: '(UTC-7) Горное время (США и Канада), Мексика (Чиуауа, Ла-Пас, Мацатлан)',
    utc: '(UTC-7)',
    timeDifference: '420',
  },
  {
    id: '8f8aa312-7165-4fca-b500-e4cf5ee2d083',
    value: '(UTC-6) Центральное время (США и Канада), Центральноамериканское время, Мексика (Гвадалахара, Мехико, Монтеррей)',
    utc: '(UTC-6)',
    timeDifference: '360',
  },
  {
    id: 'cd2834eb-809d-4d64-bec8-247316363078',
    value: '(UTC-5) Североамериканское восточное время (США и Канада), Южноамериканское тихоокеанское время (Богота, Лима, Кито)',
    utc: '(UTC-5)',
    timeDifference: '300',
  },
  {
    id: '3aff9af1-d31c-4395-a7f0-b65f480a1f4b',
    value: '(UTC-4:30) Каракас',
    utc: '(UTC-4:30)',
    timeDifference: '270',
  },
  {
    id: '0d5ae37b-542d-472f-bc17-fd109bfe9a28',
    value: '(UTC-4) Атлантическое время (Канада), Южноамериканское тихоокеанское время (Ла-Пас, Сантьяго)',
    utc: '(UTC-4)',
    timeDifference: '240',
  },
  {
    id: 'a9379a7b-8ef3-4961-8a44-853289e3e49c',
    value: '(UTC-3:30) Ньюфаундленд',
    utc: '(UTC-3:30)',
    timeDifference: '210',
  },
  {
    id: 'd9ea9e76-a9ec-405c-9163-546b6aa4401c',
    value: '(UTC-3) Южноамериканское восточное время (Бразилия, Буэнос-Айрес, Джорджтаун), Гренландия',
    utc: '(UTC-3)',
    timeDifference: '180',
  },
  {
    id: '65a7e847-737a-419b-a702-41163d116652',
    value: '(UTC-2) Среднеатлантическое время',
    utc: '(UTC-2)',
    timeDifference: '120',
  },
  {
    id: '25e4318c-0178-4d86-bd98-dfee61e69b60',
    value: '(UTC-1) Азорские острова, Кабо Верде',
    utc: '(UTC-1)',
    timeDifference: '60',
  },
  {
    id: '5ec08ea4-e33b-4102-8c1f-ffa9d05bea4b',
    value: '(UTC+0) Западноевропейское время (Дублин, Эдинбург, Лиссабон, Лондон), Касабланка, Монровия',
    utc: '(UTC+0)',
    timeDifference: '0',
  },
  {
    id: 'd42f53a6-cf98-40fc-8d80-8b01180b3f9e',
    value: '(UTC+1) Центральноевропейское время (Амстердам, Берлин, Берн, Брюссель, Вена, Копенгаген, Мадрид, Париж, Рим, Стокгольм, Белград, Братислава, Будапешт, Варшава, Любляна, Прага, Сараево, Скопье, Загреб), Западное центральноафриканское время',
    utc: '(UTC+1)',
    timeDifference: '-60',
  },
  {
    id: 'ce9efbb5-d2b7-4f24-bdfd-a3b8c9f48d3b',
    value: '(UTC+2) Восточноевропейское время (Афины, Бухарест, Вильнюс, Киев, Кишинев, Минск, Рига, София, Таллин, Хельсинки, Калининград), Египет, Израиль, Ливан, Турция, ЮАР',
    utc: '(UTC+2)',
    timeDifference: '-120',
  },
  {
    id: '75374088-0839-4e1f-a3bf-30518f9ece2b',
    value: '(UTC+3) Московское время, Восточноафриканское время (Найроби, Аддис-Абеба), Ирак, Кувейт, Саудовская Аравия',
    utc: '(UTC+3)',
    timeDifference: '-180',
  },
  {
    id: '421f64fa-467c-42f6-a11c-d013f2da9dee',
    value: '(UTC+3:30) Тегеранское время',
    utc: '(UTC+3:30)',
    timeDifference: '-210',
  },
  {
    id: 'b1f131f9-ad04-4282-8647-cff50d3a1918',
    value: '(UTC+4) Самарское время, Объединённые Арабские Эмираты, Оман, Азербайджан, Армения, Грузия',
    utc: '(UTC+4)',
    timeDifference: '-240',
  },
  {
    id: 'a0816007-ccfd-4056-974c-33167b631069',
    value: '(UTC+4:30) Афганистан',
    utc: '(UTC+4:30)',
    timeDifference: '-270',
  },
  {
    id: '6ef38db9-eb13-4b77-918b-396e18b98830',
    value: '(UTC+5) Екатеринбургское время, Западноазиатское время (Исламабад, Карачи, Ташкент)',
    utc: '(UTC+5)',
    timeDifference: '-300',
  },
  {
    id: 'ecca255e-dccb-476c-8515-840a7c0d82f8',
    value: '(UTC+5:30) Индия, Шри-Ланка',
    utc: '(UTC+5:30)',
    timeDifference: '-330',
  },
  {
    id: '98308f3f-d0f2-47c3-8829-f0cbafcba35a',
    value: '(UTC+5:45) Непал',
    utc: '(UTC+5:45)',
    timeDifference: '-345',
  },
  {
    id: 'e8610a2f-e57a-454b-98a9-4ebf271831b0',
    value: '(UTC+6) Новосибирск, Омское время, Центральноазиатское время (Бангладеш, Казахстан)',
    utc: '(UTC+6)',
    timeDifference: '-360',
  },
  {
    id: '1d931abc-e457-454b-afdb-79162384e430',
    value: '(UTC+6:30) Мьянма',
    utc: '(UTC+6:30)',
    timeDifference: '-390',
  },
  {
    id: '2395875b-3cde-426d-820d-585bf446e143',
    value: '(UTC+7) Красноярское время, Юго-Восточная Азия (Бангкок, Джакарта, Ханой)',
    utc: '(UTC+7)',
    timeDifference: '-420',
  },
  {
    id: '441b3cdc-1b32-42d6-892d-5c0f9e105f2c',
    value: '(UTC+8) Иркутское время, Улан-Батор, Куала-Лумпур, Гонконг, Китай, Сингапур, Тайвань, западноавстралийское время (Перт)',
    utc: '(UTC+8)',
    timeDifference: '-480',
  },
  {
    id: 'fd90ee78-45b8-482a-be99-38616a892a0b',
    value: '(UTC+9) Якутское время, Корея, Япония',
    utc: '(UTC+9)',
    timeDifference: '-540',
  },
  {
    id: 'c10479e7-db8f-4d66-a47b-40015e7e3809',
    value: '(UTC+9:30) Центральноавстралийское время (Аделаида, Дарвин)',
    utc: '(UTC+9:30)',
    timeDifference: '-570',
  },
  {
    id: '007abeec-11aa-4fcd-8fa7-bc300933ef11',
    value: '(UTC+10) Владивостокское время, Восточноавстралийское время (Брисбен, Канберра, Мельбурн, Сидней), Тасмания, Западно-тихоокеанское время (Гуам, Порт-Морсби)',
    utc: '(UTC+10)',
    timeDifference: '-600',
  },
  {
    id: 'b4768710-e140-497a-b76b-fb8ae837e2e6',
    value: '(UTC+11) Магаданское время, Центрально-тихоокеанское время (Соломоновы острова, Новая Каледония)',
    utc: '(UTC+11)',
    timeDifference: '-660',
  },
  {
    id: '53fcaad9-65a7-4fdf-ac28-7eb53330ee13',
    value: '(UTC+12) Камчатское время, Маршалловы острова, Фиджи, Новая Зеландия',
    utc: '(UTC+12)',
    timeDifference: '-720',
  },
  {
    id: '24868b70-88b4-47e6-b4f8-15d8da544c34',
    value: '(UTC+13) Тонга',
    utc: '(UTC+13)',
    timeDifference: '-780',
  },
  {
    id: 'd748bf74-088f-40a7-8e9a-2b2d4c9a1fe0',
    value: '(UTC+14) Острова Лайн (Кирибати)',
    utc: '(UTC+14)',
    timeDifference: '-840',
  },
];


export default timeZoneCatalogue;
