/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Alert, Menu, Dropdown } from 'antd';
import age from '../../../assets/icons/spec-age-red.svg';
import experience from '../../../assets/icons/spec-experience-red.svg';
import price from '../../../assets/icons/spec-price-red.svg';
import MobiLogo from '../../../assets/icons/mobi-logo.svg';
import MobiMenu from '../../../assets/icons/mobi-menu.svg';
import './PsychoListPageMobile.css';
import getUserRole from '../../../utils/localeStorage/getUserRole';
import adminButtonsArray from '../../../components/SideBar/utils/adminButtonsArray';
import specialistButtonsArray from '../../../components/SideBar/utils/specialistButtonsArray';
import clientButtonsArray from '../../../components/SideBar/utils/clientButtonsArray';
import getIsValidClient from '../../../utils/localeStorage/getIsValidClient';
import getIsValidSpecialist from '../../../utils/localeStorage/getIsValidSpecialist';
import logout from '../../../utils/logout';
import * as roles from '../../../utils/roles';
import ageEnding from '../../../utils/ageEnding';
import getSessionId from '../../../utils/localeStorage/getSessionId';
import { routes } from '../../../utils/routes';
import { editUser, getUserAfterLogin } from '../../../utils/http';
import { setLocalStorage } from '../../../utils/localeStorage/storage';
import getUserId from '../../../utils/localeStorage/getUserId';


class PsychoListPageMobile extends Component {
  handleMenuButtonClick = (route) => {
    const { history } = this.props;
    history.push(route);
  }

  changeRole = () => {
    const { history } = this.props;
    editUser({ id: getUserId(), role: getUserRole() === roles.CLIENT ? roles.SPECIALIST : roles.CLIENT })
      .then(() => getUserAfterLogin(getUserId(), getSessionId())
        .then((user) => {
          const payload = {
            ...user,
            isValidSpecialist: user.isValidSpecialist && !!user.paymentType,
          };
          setLocalStorage(payload);
          // window.location.reload();
          history.push(routes.profile);
        }));
  }

  render() {
    const {
      specList, onPsychoClick, isSessionExist, history,
    } = this.props;
    let buttonsArray = [];
    switch (getUserRole()) {
      case roles.ADMIN:
        buttonsArray = adminButtonsArray;
        break;
      case roles.CLIENT:
        buttonsArray = clientButtonsArray;
        break;
      case roles.SPECIALIST:
        buttonsArray = specialistButtonsArray;
        break;
      default: break;
    }
    let isDisabled = true;
    if (getUserRole() === roles.CLIENT && getIsValidClient() === 'true') {
      isDisabled = false;
    }
    if (getUserRole() === roles.ADMIN) {
      isDisabled = false;
    }
    if (getUserRole() === roles.SPECIALIST && getIsValidSpecialist() === 'true') {
      isDisabled = false;
    }
    const menu = (
      <Menu>
        {buttonsArray
          .filter((b) => b.id !== 'help')
          .map((b) => (
            <Menu.Item
              key={b.id}
              disabled={isDisabled}
              onClick={() => this.handleMenuButtonClick(b.route)}
            >
              {b.name}
            </Menu.Item>
          ))}
        <Menu.Divider />
        {getUserId() && <Menu.Item key="2" onClick={this.changeRole}>Сменить роль</Menu.Item>}
        {getUserId() && <Menu.Divider />}
        <Menu.Item key="3" onClick={() => logout(history)} style={{ cursor: 'pointer' }}>Выйти</Menu.Item>
      </Menu>
    );

    return (
      <div className="psychoListPageMobile-wrapper">
        <div className="profile-wrapper__header">
          <div className="mobi-logo"><a href="https://gestalt.online"><img src={MobiLogo} alt="Вернуться на главную" /></a></div>
          <span className="psychoListPageMobile-title mobile-header__title">Специалисты</span>
          <div className="profile-header-menu">
            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
              <img src={MobiMenu} alt="Мобильное меню" />
            </Dropdown>
          </div>
        </div>
        {isSessionExist && (
        <Alert
          message="Внимание! Есть неоплаченные сессии или уже выбран терапевт"
          description="Чтобы записаться к новому специалисту, необходимо сначала открепиться от старого или отменить запись"
          type="warning"
          showIcon
        />
        )}
        {!isSessionExist && (
        <div className="psychoListPageMobile-subheader">
          <span className="psychoListPageMobile-list-title">{`Найдено ${specList.length} терапевтов`}</span>
        </div>
        )}
        {!isSessionExist && (
        <div className="psychoListPageMobile-body">
          {specList.map((s) => (
            <div
              className="psychoListPageMobile-card-wrapper"
              key={s.id}
            >
              {s.logoURL ? <div className="psychoListPageMobile-card-img"><img className="psychoListPageMobile-card-logo" src={s.logoURL} alt="logo" /></div> : <div className="psychoListPageMobile-card-logo" />}
              <div className="psychoListPageMobile-card-title">{`${s.lastName} ${s.firstName}`}</div>
              <div className="psychoListPageMobile-card-gestalt">Гештальт-терапевт</div>
              <div className="psychoListPageMobile-card-row">
                <div className="psychoListPageMobile-card-column">
                  <img className="psychoListPageMobile-card-column-icon" src={experience} alt="experience" />
                  <div className="psychoListPageMobile-card-column-label">Опыт</div>
                  <div className="psychoListPageMobile-card-column-value">{ageEnding(s.experience)}</div>
                </div>
                <div className="psychoListPageMobile-card-column">
                  <img className="psychoListPageMobile-card-column-icon" src={price} alt="price" />
                  <div className="psychoListPageMobile-card-column-label">Стоимость</div>
                  <div className="psychoListPageMobile-card-column-value">{`${s.sessionPrice} руб`}</div>
                </div>
                <div className="psychoListPageMobile-card-column">
                  <img className="psychoListPageMobile-card-column-icon" src={age} alt="age" />
                  <div className="psychoListPageMobile-card-column-label">Возраст</div>
                  <div className="psychoListPageMobile-card-column-value">{s.birthdayDate ? ageEnding(moment().diff(s.birthdayDate, 'years')) : ''}</div>
                </div>
              </div>
              <div className="psychoListPageMobile-card-row btn-row__mobile__padding">
                <div className="btn-default btn_transparent" onClick={() => onPsychoClick(s)}>Подробнее</div>
                <div className="btn-default btn_red" onClick={() => onPsychoClick(s)}>Записаться</div>
              </div>
            </div>
          ))}
        </div>
        )}

        {!isSessionExist && (
        <div className="psychoListPageMobile-body-pagination">
          {specList
            .map((spec, index) => (
              <div
                key={spec.id}
                className={index === 0 ? 'psychoListPageMobile-body-pagination-circle-active' : 'psychoListPageMobile-body-pagination-circle'}
              />
            )) }
        </div>
        )}
      </div>
    );
  }
}

PsychoListPageMobile.propTypes = {
  specList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onPsychoClick: PropTypes.func.isRequired,
  isSessionExist: PropTypes.bool.isRequired,
  history: PropTypes.shape().isRequired,
};

export default PsychoListPageMobile;
