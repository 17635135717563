import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Row, Collapse, Modal, Form, DatePicker, Input, Select, notification, TimePicker, Divider, Alert, Table,
} from 'antd';
import { orderBy } from 'lodash';
import moment from 'moment';
import {
  editGroupSession, getSpecialistGroupSessions, editGroupSessionEnroll, getGroupTypeCatalogue,
} from '../../../../utils/http';
import getUserId from '../../../../utils/localeStorage/getUserId';
import './GroupSessions.css';
import getStatusTag from '../../../AdminGroupSessions/utils/getStatusTag';
import getClientColumns from '../../utils/getClientColumns';


const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;
class GroupSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      groupSessions: [],
      selectedDate: null,
      selectedStartTime: null,
      selectedSession: null,
      selectedGroupType: null,
      groupTypeCatalogue: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    Promise.all([getSpecialistGroupSessions(getUserId()), getGroupTypeCatalogue()])
      .then((result) => this.setState({ groupSessions: orderBy(result[0], ['startDateAndTime'], ['desc']), groupTypeCatalogue: result[1] }));
  }

  onAddGroupSessionModal = (selectedSession) => {
    this.setState({
      selectedGroupType: selectedSession.groupTypeId,
      selectedSession,
      selectedDate: selectedSession.startDateAndTime,
      selectedStartTime: selectedSession.startDateAndTime ? selectedSession.startDateAndTime.format('HH:mm') : null,
    });
  }

  onCloseGroupSessionModal = () => {
    this.setState({
      selectedSession: null,
      selectedDate: null,
      selectedStartTime: null,
    });
  }

  onSaveAddGroupSession = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        const {
          selectedStartTime, selectedDate, selectedSession, selectedGroupType, groupTypeCatalogue,
        } = this.state;
        const groupType = groupTypeCatalogue.find((g) => g.id === selectedGroupType);
        const selectedStartMinutes = Number(selectedStartTime.split(':')[0]) * 60 + Number(selectedStartTime.split(':')[1]);
        const calculatedEndTime = `${(`0${Math.floor((groupType.minutesLength + selectedStartMinutes) / 60)}`).slice(-2)}:${(`0${((groupType.minutesLength + selectedStartMinutes) % 60).toFixed(0)}`).slice(-2)}`;
        const objToSave = {
          id: selectedSession.id,
          description: values.description,
          name: values.name,
          endDateAndTime: moment(`${selectedDate.format('YYYY-MM-DD')} ${calculatedEndTime}`),
          startDateAndTime: moment(`${selectedDate.format('YYYY-MM-DD')} ${selectedStartTime}`),
          specialist_id: getUserId(),
          groupTypeCatalogue_id: selectedGroupType,
          status_id: 'onApproval',
        };
        editGroupSession(objToSave)
          .then((id) => {
            this.setState((prevState) => ({
              groupSessions: selectedSession.id
                ? prevState.groupSessions.map((g) => (g.id === selectedSession.id ? {
                  ...g, ...objToSave, groupTypeId: groupType.id, statusId: 'onApproval',
                } : g))
                : [...prevState.groupSessions, {
                  ...objToSave,
                  statusId: 'onApproval',
                  id,
                  groupSessionEnroll: [],
                  groupTypeName: groupType.name,
                }],
            }));
            notification.success({ message: 'Сохранено успешно' });
            this.onCloseGroupSessionModal();
          });
      });
  }

  getDisabledHoursStart = () => {
    const {
      selectedDate, groupSessions, selectedGroupType, groupTypeCatalogue,
    } = this.state;
    const groupType = groupTypeCatalogue.find((g) => g.id === selectedGroupType);
    const { sessions } = this.props;
    const disabledHours = [];
    const selectedDateSessionsTimes = sessions
      .filter((s) => moment(s.sessionDateAndTime.format('DD/MM/YYYY'), 'DD/MM/YYYY').isSame(selectedDate.startOf('day')))
      .map((s) => Number(s.sessionDateAndTime.format('HH')));
    selectedDateSessionsTimes.forEach((t) => disabledHours.push(t - Math.floor(groupType.minutesLength / 60)));
    disabledHours.push(...selectedDateSessionsTimes);
    const selectedDateGroupSessions = groupSessions
      .filter((s) => moment(s.startDateAndTime.format('DD/MM/YYYY'), 'DD/MM/YYYY').isSame(selectedDate.startOf('day')));
    selectedDateGroupSessions.forEach((s) => {
      for (let i = Number(s.startDateAndTime.format('HH')); i <= Number(s.endDateAndTime.format('HH')); i += 1) {
        if (!disabledHours.includes(i)) {
          disabledHours.push(i);
        }
      }
    });
    return disabledHours;
  }

  onConfirm = (obj) => {
    editGroupSessionEnroll({ id: obj.id, status_id: 'approved' })
      .then(() => this.setState((prevState) => ({
        groupSessions: prevState.groupSessions.map((s) => (s.id === obj.groupSessionID
          ? { ...s, groupSessionEnroll: s.groupSessionEnroll.map((e) => (e.id === obj.id ? { ...e, statusID: 'approved' } : e)) }
          : s)),
      })));
  }

  onCancel = (obj) => {
    editGroupSessionEnroll({ id: obj.id, status_id: 'canceled' })
      .then(() => this.setState((prevState) => ({
        groupSessions: prevState.groupSessions.map((s) => (s.id === obj.groupSessionID
          ? { ...s, groupSessionEnroll: s.groupSessionEnroll.map((e) => (e.id === obj.id ? { ...e, statusID: 'canceled' } : e)) }
          : s)),
      })));
  }


  render() {
    const {
      disabled, groupSessions, selectedDate, selectedSession, selectedGroupType, groupTypeCatalogue,
    } = this.state;
    return (
      <Col span={24} className="groupSessions-wrapper">
        <Button type="primary" onClick={() => this.onAddGroupSessionModal({})} className="btn-default btn_link btn_link_dotted"><span>Добавить групповую сессию</span></Button>
        {groupSessions.map((gs) => {
          const totalClients = gs.groupSessionEnroll;
          const paidClients = gs.groupSessionEnroll.filter((e) => e.statusID === 'paid');
          const canceledClients = gs.groupSessionEnroll.filter((e) => e.statusID === 'canceled');
          const approvedClients = gs.groupSessionEnroll.filter((e) => e.statusID === 'approved');
          return (
            <Collapse className={gs.statusId === 'done' ? 'groupItem old-group' : 'groupItem'} key={gs.id}>
              <Panel
                showArrow={false}
                header={(
                  <div className="groupItem__prev">
                    <div className="groupItem__title">
                      <h3>
                        Терапевтическая группа
                        <br />
                        &laquo;
                        {gs.name}
                        &raquo;
                      </h3>
                      <div>
                        <div className="groupItem__line groupItem__date">
                          <strong>Дата: </strong>
                          {`${gs.startDateAndTime.format('DD MMMM YYYY')} c ${gs.startDateAndTime.format('HH:mm')} до ${gs.endDateAndTime.format('HH:mm')}`}
                        </div>
                        <div className="groupItem__line groupItem__type">
                          <strong>Тип группы: </strong>
                          {gs.groupTypeName}
                        </div>
                      </div>
                    </div>
                    <div className="groupItem__action">
                      <div className="groupItem__btn">
                        {gs.statusId === 'declined' && (
                        <Button className="groupItem__icon-edit" type="primary" onClick={() => this.onAddGroupSessionModal(gs)}><span>Редактировать</span></Button>
                        )}
                        {gs.VOXSpecialistURL && gs.statusId === 'approved' && (
                        <Button className="groupItem__icon-play btn-default btn_green" type="primary" onClick={() => window.open(gs.VOXSpecialistURL, '_self')}><span>Начать</span></Button>
                        )}
                      </div>
                      <div className="groupItem__status">
                        {getStatusTag(gs.statusId)}
                      </div>
                    </div>
                  </div>
)}
                key={gs.id}
              >
                <div className="groupItem__expanded">
                  <div className="groupItem__desc">{gs.description}</div>
                  <div className="groupItem__title-count">{`Зарегистрировано: ${totalClients.length}`}</div>
                  {totalClients.length > 0 && (
                    <Table
                      columns={getClientColumns(true, this.onConfirm, this.onCancel)}
                      rowKey="id"
                      dataSource={totalClients}
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                  )}
                  <div className="groupItem__title-count">{`Подтверждено: ${approvedClients.length + paidClients.length}`}</div>
                  {approvedClients.length + paidClients.length > 0 && (
                    <Table
                      columns={getClientColumns(false)}
                      rowKey="id"
                      dataSource={[...paidClients, ...approvedClients]}
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                  )}
                  <div className="groupItem__title-count">{`Оплатило: ${paidClients.length}`}</div>
                  {paidClients.length > 0 && (
                    <Table
                      columns={getClientColumns(false)}
                      rowKey="id"
                      dataSource={paidClients}
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                  )}
                  <div className="groupItem__title-count">{`Отменило: ${canceledClients.length}`}</div>
                  {canceledClients.length > 0 && (
                    <Table
                      columns={getClientColumns(false)}
                      rowKey="id"
                      dataSource={canceledClients}
                      pagination={false}
                      style={{ width: '100%' }}
                    />
                  )}
                </div>
              </Panel>
            </Collapse>
          );
        })}
        {selectedSession
         && (
         <Modal
           visible
           title=""
           onCancel={this.onCloseGroupSessionModal}
           maskClosable
           footer={[
             <Button className="btn-default btn_green" type="primary" key="1" onClick={this.onSaveAddGroupSession}>Отправить на модерацию</Button>,
           ]}
         >
           <Form
             layout="vertical"
             ref={this.formRef}
           >
             <div className="modal-form__createGroup">
               <div className="modal-form__createGroup__name">
                 <Form.Item
                   className="anketa__input-hor anketa__input_input"
                   rules={[{ required: true, message: '' }]}
                   name="name"
                   label="Название"
                   initialValue={selectedSession.name}
                 >
                   <Input disabled={disabled} />
                 </Form.Item>
               </div>
               <div className="modal-form__createGroup__desc">
                 <Form.Item
                   className="anketa__text"
                   rules={[{ required: true, message: '' }]}
                   name="description"
                   label="Описание"
                   initialValue={selectedSession.description}
                 >
                   <TextArea className="anketa__textarea" rows={4} disabled={disabled} />
                 </Form.Item>
               </div>
               <div>
                 <div>
                   <Form.Item
                     className="anketa__input-hor anketa__input_format"
                     rules={[{ required: true, message: '' }]}
                     name="groupType"
                     label="Вид группы"
                     initialValue={selectedSession.groupTypeId}
                   >
                     <Select
                       onChange={(e) => this.setState({ selectedGroupType: e })}
                     >
                       {groupTypeCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.name}</Option>)}
                     </Select>
                   </Form.Item>
                 </div>
               </div>
               {selectedGroupType && (
               <Row>
                 <Col span={24}>
                   <Form.Item
                     rules={[{ required: true, message: '' }]}
                     name="date"
                     label="Дата проведения"
                     initialValue={selectedSession.startDateAndTime}
                   >
                     <DatePicker style={{ width: '100%' }} onChange={(date) => this.setState({ selectedDate: date })} />
                   </Form.Item>
                 </Col>
               </Row>
               )}
               {selectedDate && selectedGroupType && (
               <Row>
                 <Col span={24}>
                   <Form.Item
                     rules={[{ required: true, message: '' }]}
                     name="timeStart"
                     label="Время начала"
                     initialValue={selectedSession.startDateAndTime}
                   >
                     <TimePicker
                       style={{ width: '100%' }}
                       format="HH:mm"
                       onChange={(date, time) => this.setState({ selectedStartTime: time })}
                       disabledHours={() => this.getDisabledHoursStart()}
                       showNow={false}
                     />
                   </Form.Item>
                 </Col>
               </Row>
               )}
               {selectedSession.statusId === 'declined' && <Divider />}
               {selectedSession.statusId === 'declined' && (
               <Alert
                 message="Требуется доработка"
                 description={(
                   <div>
                     <div style={{ whiteSpace: 'break-spaces' }}>{selectedSession.moderatorComment}</div>
                   </div>
          )}
                 type="error"
               />
               )}
             </div>
           </Form>
         </Modal>
         )}
      </Col>
    );
  }
}

GroupSessions.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default GroupSessions;
