import React, { Component } from 'react';
import { config } from '../../utils/config';
import AdminLoginPageDesktop from './Desktop/AdminLoginPageDesktop';
import { getLoginAttempts } from '../../utils/http';


class AdminLoginPageModule extends Component {
  state = {
    isMobile: window.innerWidth <= config.mobileWidth,
    loading: true,
    loginAttempts: [],
  }

  componentDidMount() {
    getLoginAttempts()
      .then((loginAttempts) => this.setState({
        loading: false,
        loginAttempts,
      }));
  }


  render() {
    const {
      isMobile, loading, loginAttempts,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <AdminLoginPageDesktop
        loading={loading}
        loginAttempts={loginAttempts}
      />
    );
  }
}

export default AdminLoginPageModule;
