import React, { Component } from 'react';
import { config } from '../../utils/config';
import AdminFieldsPageDesktop from './Desktop/AdminFieldsPageDesktop';
import {
  getLockedFields, editLockedField,
} from '../../utils/http';


class AdminFieldsModule extends Component {
  state = {
    isMobile: window.innerWidth <= config.mobileWidth,
    loading: true,
    fieldsLoading: false,
    lockedFields: [],
    view: 'client',
  }

  componentDidMount() {
    getLockedFields()
      .then((lockedFields) => this.setState({
        loading: false,
        lockedFields,
      }));
  }

  onLockedFiledEdit = (value) => {
    this.setState({ fieldsLoading: true });
    editLockedField(value)
      .then((newField) => this.setState((prevState) => ({
        fieldsLoading: false,
        lockedFields: prevState.lockedFields.map((f) => (f.id === newField.id ? newField : f)),
      })));
  }

  onChangeView = (view) => {
    this.setState({ view });
  }

  render() {
    const {
      isMobile, loading, lockedFields, fieldsLoading, view,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <AdminFieldsPageDesktop
        loading={loading}
        lockedFieldsClient={lockedFields.filter((f) => f.isClient)}
        lockedFieldsSpec={lockedFields.filter((f) => f.isSpecialist)}
        onLockedFiledEdit={this.onLockedFiledEdit}
        fieldsLoading={fieldsLoading}
        onChangeView={this.onChangeView}
        view={view}
      />
    );
  }
}

export default AdminFieldsModule;
