/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Menu, Dropdown, Popconfirm, Modal, Skeleton,
} from 'antd';
import { withRouter } from 'react-router';
import MobiMenu from '../../../assets/icons/mobi-menu.svg';
import MobiLogo from '../../../assets/icons/mobi-logo.svg';
import './ClientGroupSessionsPageMobile.css';
import getUserRole from '../../../utils/localeStorage/getUserRole';
import adminButtonsArray from '../../../components/SideBar/utils/adminButtonsArray';
import specialistButtonsArray from '../../../components/SideBar/utils/specialistButtonsArray';
import clientButtonsArray from '../../../components/SideBar/utils/clientButtonsArray';
import getIsValidClient from '../../../utils/localeStorage/getIsValidClient';
import getIsValidSpecialist from '../../../utils/localeStorage/getIsValidSpecialist';
import getSessionId from '../../../utils/localeStorage/getSessionId';
import logout from '../../../utils/logout';
import * as roles from '../../../utils/roles';
import { routes } from '../../../utils/routes';
import { editUser, getUserAfterLogin } from '../../../utils/http';
import { setLocalStorage } from '../../../utils/localeStorage/storage';
import getUserId from '../../../utils/localeStorage/getUserId';


class ClientGroupSessionsPageMobile extends Component {
  state = {
    cost: 0,
  }

  handleMenuButtonClick = (route) => {
    const { history } = this.props;
    history.push(route);
  }

  changeRole = () => {
    const { history } = this.props;
    editUser({ id: getUserId(), role: getUserRole() === roles.CLIENT ? roles.SPECIALIST : roles.CLIENT })
      .then(() => getUserAfterLogin(getUserId(), getSessionId())
        .then((user) => {
          const payload = {
            ...user,
            isValidSpecialist: user.isValidSpecialist && !!user.paymentType,
          };
          setLocalStorage(payload);
          // window.location.reload();
          history.push(routes.profile);
        }));
  }

  render() {
    const {
      list, onLoadMore, isShowLoadMore, onEnroll, clientSessions, isShowPaySessionModal, onChangeDeclineComment,
      onPaySessionModal, onPaySession, selectCancelOptionModalStep, onCloseSelectCancelOptionModal, onShowCancelSessionModal,
      onDeclineSession, currentTab, changeTab, history, doneSessions,
    } = this.props;
    const { cost } = this.state;
    let buttonsArray = [];
    switch (getUserRole()) {
      case roles.ADMIN:
        buttonsArray = adminButtonsArray;
        break;
      case roles.CLIENT:
        buttonsArray = clientButtonsArray;
        break;
      case roles.SPECIALIST:
        buttonsArray = specialistButtonsArray;
        break;
      default: break;
    }
    let isDisabled = true;
    if (getUserRole() === roles.CLIENT && getIsValidClient() === 'true') {
      isDisabled = false;
    }
    if (getUserRole() === roles.ADMIN) {
      isDisabled = false;
    }
    if (getUserRole() === roles.SPECIALIST && getIsValidSpecialist() === 'true') {
      isDisabled = false;
    }
    const menu = (
      <Menu>
        {buttonsArray
          .filter((b) => b.id !== 'help')
          .map((b) => (
            <Menu.Item
              key={b.id}
              disabled={isDisabled}
              onClick={() => this.handleMenuButtonClick(b.route)}
            >
              {b.name}
            </Menu.Item>
          ))}
        <Menu.Divider />
        {getUserId() && <Menu.Item key="2" onClick={this.changeRole}>Сменить роль</Menu.Item>}
        {getUserId() && <Menu.Divider />}
        <Menu.Item key="3" onClick={() => logout(history)} style={{ cursor: 'pointer' }}>Выйти</Menu.Item>
      </Menu>
    );
    return (
      <div className="clientGroupSessionsPageMobile-wrapper">
        <div className="profile-wrapper__header mobile-header__wrapper">
          <div className="mobi-logo"><a href="https://gestalt.online"><img src={MobiLogo} alt="Вернуться на главную" /></a></div>
          <span className="profile-title mobile-header__title">Группы</span>
          <div className="profile-header-menu">
            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
              <img src={MobiMenu} alt="Мобильное меню" />
            </Dropdown>
          </div>
        </div>
        <h1 className="clientGroupSessionsPageMobile-title">Терапевтические группы</h1>
        <a className="clientGroupSessionsPageMobile-helptext" onClick={() => window.open('https://gestalt.online/files/pravila-accii-podderzki.pdf', '_blank')}>Правила проведения акции "Группа поддержки"</a>

        <div className="clientGroupSessionsPageMobile-header-row" style={{ padding: '0px 30px 30px', justifyContent: 'space-between' }}>
          <div onClick={() => changeTab('1')} className={currentTab === '1' ? 'clientGroupSessionsPageMobile-mobile-body-button-primary' : 'clientGroupSessionsPageMobile-mobile-body-button-default'}>Доступные</div>
          <div onClick={() => changeTab('2')} className={currentTab === '2' ? 'clientGroupSessionsPageMobile-mobile-body-button-primary' : 'clientGroupSessionsPageMobile-mobile-body-button-default'}>Текущие</div>
          <div onClick={() => changeTab('3')} className={currentTab === '3' ? 'clientGroupSessionsPageMobile-mobile-body-button-primary' : 'clientGroupSessionsPageMobile-mobile-body-button-default'}>Завершенные</div>
        </div>

        {currentTab === '1' && list.map((s) => {
          if (!s.id) {
            return (
              <div key={s.id} className="clientGroupSessionsPageMobile-card">
                <Skeleton />
              </div>
            );
          }
          return (
            <div key={s.id} className="clientGroupSessionsPageMobile-card">
              <div className="clientGroupSessionsPageMobile-card-row">
                <div className="clientGroupSessionsPageMobile-card-img"><img className="clientGroupSessionsPageMobile-card-logo" src={s.specialist.logoURL} alt="logo" /></div>
                <div className="clientGroupSessionsPageMobile-card-column clientGroupSessionsPageMobile-card-who">
                  <span className="clientGroupSessionsPageMobile-card-specialist-name">{` ${s.specialist.lastName} ${s.specialist.firstName} ${s.specialist.middleName}`}</span>
                  <span className="clientGroupSessionsPageMobile-card-specialist-trainer">ведущий</span>
                </div>
                <div className="clientGroupSessionsPageMobile-card-column clientGroupSessionsPageMobile-card-when">
                  <span className="clientGroupSessionsPageMobile-card-date">{`${s.startDateAndTime && s.startDateAndTime.format('DD.MM.YY')}`}</span>
                  <span className="clientGroupSessionsPageMobile-card-time">{`${s.startDateAndTime && s.startDateAndTime.format('HH:mm')} - ${s.endDateAndTime && s.endDateAndTime.format('HH:mm')}`}</span>
                </div>
              </div>
              <div className="clientGroupSessionsPageMobile-card-name">
                {s.name}
              </div>
              <div className="clientGroupSessionsPageMobile-card-description">
                {s.description}
              </div>
              <div className="clientGroupSessionsPageMobile-card-param">
                <div className="clientGroupSessionsPageMobile-card-param__left">
                  <div className="clientGroupSessionsPageMobile-card-spec">
                    <span className="clientGroupSessionsPageMobile-card-hour">1.5 часа</span>
                    <span className="clientGroupSessionsPageMobile-card-period">1 раз в неделю</span>
                    <span className="clientGroupSessionsPageMobile-card-format">{s.groupTypeName}</span>
                  </div>
                  <div className="clientGroupSessionsPageMobile-card-count">
                    <span>Записалось:</span>
                    {`${s.groupSessionEnroll.length}/${s.maxNumberOfClients}`}
                  </div>
                </div>

                <div className="clientGroupSessionsPageMobile-card-row">
                  {!getSessionId() && (<div className="btn-round" onClick={() => onEnroll(s.id)}><span>Записаться</span></div>)}
                  {getSessionId() && (
                  <Popconfirm
                    className="group-session__item__foot-right"
                    title="Вы уверены, что хотите записаться?"
                    onConfirm={() => onEnroll(s.id)}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <div className="btn-round"><span>Записаться</span></div>
                  </Popconfirm>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        {currentTab === '1' && isShowLoadMore && <div className="btn-default btn_link btn_link_dotted" onClick={onLoadMore}>Загрузить еще</div>}


        {currentTab === '2' && clientSessions.map((s) => (
          <div key={s.id} className="clientGroupSessionsPageMobile-card">
            <div className="clientGroupSessionsPageMobile-card-row">
              <div className="clientGroupSessionsPageMobile-card-img"><img className="clientGroupSessionsPageMobile-card-logo" src={s.specialist.logoURL} alt="logo" /></div>
              <div className="clientGroupSessionsPageMobile-card-column clientGroupSessionsPageMobile-card-who">
                <span className="clientGroupSessionsPageMobile-card-specialist-name">{` ${s.specialist.lastName} ${s.specialist.firstName} ${s.specialist.middleName}`}</span>
                <span className="clientGroupSessionsPageMobile-card-specialist-trainer">ведущий</span>
              </div>
              <div className="clientGroupSessionsPageMobile-card-column clientGroupSessionsPageMobile-card-when">
                <span className="clientGroupSessionsPageMobile-card-date">{`${s.startDateAndTime && s.startDateAndTime.format('DD.MM.YY')}`}</span>
                <span className="clientGroupSessionsPageMobile-card-time">{`${s.startDateAndTime && s.startDateAndTime.format('HH:mm')} - ${s.endDateAndTime && s.endDateAndTime.format('HH:mm')}`}</span>
              </div>
            </div>
            <div className="clientGroupSessionsPageMobile-card-name">
              {s.name}
            </div>
            <div className="clientGroupSessionsPageMobile-card-description">
              {s.description}
            </div>
            <div className="clientGroupSessionsPageMobile-card-param">
              <div className="clientGroupSessionsPageMobile-card-count">
                <span>Записалось:</span>
                {`${s.groupSessionEnroll.filter((g) => g.statusID === 'paid').length}/${s.maxNumberOfClients}`}
              </div>
              <div className="clientGroupSessionsPageMobile-card-row clientGroupSessionsPageMobile-card-row-play">
                {s.statusID === 'toBePaid' || s.statusID === 'approved'
                  ? [<a className="btn-default btn_red" key="list-pay" onClick={() => onPaySessionModal(s)}><span>оплатить</span></a>,
                    <a className="btn-default btn_link btn_link_dotted" key="list-cancel" onClick={() => onShowCancelSessionModal(selectCancelOptionModalStep)}><span>отменить</span></a>]
                  : s.statusID === 'paid'
                    ? [<a className="btn-default btn_link btn_link_dotted" key="list-cancel" onClick={() => onShowCancelSessionModal(s)}><span>отменить</span></a>,
                      <a className="btn-default btn_green go-to-meet" key="list-ощшт" onClick={() => window.open(s.VOXClientURL, '_self')}><span>Начать</span></a>]
                    : []}
              </div>
            </div>
          </div>
        ))}

        {currentTab === '3' && doneSessions.map((s) => (
          <div key={s.id} className="clientGroupSessionsPageMobile-card">
            <div className="clientGroupSessionsPageMobile-card-row">
              <div className="clientGroupSessionsPageMobile-card-img"><img className="clientGroupSessionsPageMobile-card-logo" src={s.specialist.logoURL} alt="logo" /></div>
              <div className="clientGroupSessionsPageMobile-card-column clientGroupSessionsPageMobile-card-who">
                <span className="clientGroupSessionsPageMobile-card-specialist-name">{` ${s.specialist.lastName} ${s.specialist.firstName} ${s.specialist.middleName}`}</span>
                <span className="clientGroupSessionsPageMobile-card-specialist-trainer">ведущий</span>
              </div>
              <div className="clientGroupSessionsPageMobile-card-column clientGroupSessionsPageMobile-card-when">
                <span className="clientGroupSessionsPageMobile-card-date">{`${s.startDateAndTime && s.startDateAndTime.format('DD.MM.YY')}`}</span>
                <span className="clientGroupSessionsPageMobile-card-time">{`${s.startDateAndTime && s.startDateAndTime.format('HH:mm')} - ${s.endDateAndTime && s.endDateAndTime.format('HH:mm')}`}</span>
              </div>
            </div>
            <div className="clientGroupSessionsPageMobile-card-name">
              {s.name}
            </div>
            <div className="clientGroupSessionsPageMobile-card-description">
              {s.description}
            </div>
            <div className="clientGroupSessionsPageMobile-card-param">
              <div className="clientGroupSessionsPageMobile-card-count">
                <span>Записалось:</span>
                {`${s.groupSessionEnroll.filter((g) => g.statusID === 'paid').length}/${s.maxNumberOfClients}`}
              </div>
              <div className="clientGroupSessionsPageMobile-card-row clientGroupSessionsPageMobile-card-row-play">
                <a className="btn-default btn_link btn_link_dotted" key="list-pay" onClick={() => onPaySessionModal(s)}><span>оплатить</span></a>
              </div>
            </div>
          </div>
        ))}

        {isShowPaySessionModal && (
          <Modal
            visible
            title="Подтверждение сессии"
            maskClosable
            onCancel={() => onPaySessionModal()}
            footer={false}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                Пожалуйста, подтвердите проведение сессии со специалистом. Вы также можете оплатить
                услуги специлиста в том размере, в котором считаете нужным.
              </div>
              <div style={{ margin: '10px 0px' }}><input style={{ width: '100%' }} value={cost} onChange={(e) => this.setState({ cost: e.target.value })} /></div>
              <div className="btn-default btn_red" onClick={() => onPaySession(cost)}>Подтвердить</div>
            </div>
          </Modal>
        )}
        {selectCancelOptionModalStep !== 0 && (
          <Modal
            visible
            title="Укажите причину отмены"
            maskClosable
            onCancel={onCloseSelectCancelOptionModal}
            footer={false}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>Можете ли вы рассказать нам, что пошло не так?</div>
              <input style={{ margin: '10px 0px' }} onChange={onChangeDeclineComment} />
              <div className="btn-default btn_green" type="primary" onClick={onDeclineSession}>Отменить</div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

ClientGroupSessionsPageMobile.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  isShowLoadMore: PropTypes.bool.isRequired,
  onEnroll: PropTypes.func.isRequired,
  clientSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isShowPaySessionModal: PropTypes.bool.isRequired,
  onPaySessionModal: PropTypes.func.isRequired,
  onPaySession: PropTypes.func.isRequired,
  selectCancelOptionModalStep: PropTypes.number.isRequired,
  onCloseSelectCancelOptionModal: PropTypes.func.isRequired,
  onShowCancelSessionModal: PropTypes.func.isRequired,
  onChangeDeclineComment: PropTypes.func.isRequired,
  onDeclineSession: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  doneSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default withRouter(ClientGroupSessionsPageMobile);
