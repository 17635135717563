import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, orderBy } from 'lodash';
import {
  Spin, Row, Modal, notification,
} from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router';
import { LoadingOutlined } from '@ant-design/icons';
import { config } from '../../utils/config';
import { routes } from '../../utils/routes';
import PsychoSelectPageDesktop from './Desktop/PsychoSelectPageDesktop';
import PsychoSelectPageMobile from './Mobile/PsychoSelectPageMobile';
import getUserId from '../../utils/localeStorage/getUserId';
import getSessionId from '../../utils/localeStorage/getSessionId';
import getIsValidClient from '../../utils/localeStorage/getIsValidClient';
import LoginModule from '../Login/LoginModule';
import {
  getSpecialist, getSpecialistSlots, sessionEnroll, getClientCurrentSpecialistAndAllSessions,
} from '../../utils/http';
import getTimeZoneDifference from '../../utils/localeStorage/getTimeZoneDifference';


const timeDifference = new Date().getTimezoneOffset();
class PsychoSelectModule extends Component {
  state = {
    isMobile: window.innerWidth <= config.mobileWidth,
    specialist: null,
    loading: true,
    slots: [],
    allSlots: [],
    selectedDate: null,
    selectedTime: null,
    isShowMoreAvailable: false,
    isShowLoginModal: false,
    isSessionExist: false,
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const { history } = this.props;
    const specID = get(history, 'location.pathname', '').split('/')[2];
    let isSessionExist = false;
    if (getSessionId() && getUserId()) {
      const res = await getClientCurrentSpecialistAndAllSessions(getUserId());
      isSessionExist = !!res.currentSpecialist;
    }
    if (isSessionExist) {
      this.setState({
        isSessionExist,
        loading: false,
      });
    } else {
      Promise.all([getSpecialist(specID), getSpecialistSlots(specID)])
        .then((res) => this.setState({
          specialist: res[0],
          loading: false,
          slots: this.parseSlots(res[1].slots, res[0].beforeEnrollTime).slice(0, 7),
          allSlots: this.parseSlots(res[1].slots, res[0].beforeEnrollTime),
          isShowMoreAvailable: this.parseSlots(res[1].slots, res[0].beforeEnrollTime).length > 7,
        }));
    }
  }

  parseSlots = (slots, beforeEnrollTime) => {
    const startAvailableDate = moment().add(beforeEnrollTime + 1, 'h').startOf('day');
    const availablePychoSlots = [];
    const filteredSlots = slots.filter((slot) => moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isAfter(startAvailableDate) || moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isSame(startAvailableDate));
    filteredSlots.forEach((slot) => {
      let timesArray = [];
      if (moment(slot.date.format('DD.MM.YYYY'), 'DD.MM.YYYY').startOf('day').isSame(startAvailableDate)) {
        const startAvailableTime = moment().add(beforeEnrollTime + 1, 'h').format('HH:00');
        slot.timesArray.forEach((t) => t >= startAvailableTime && timesArray.push(t));
      } else if (slot.timesArray.length > 0 && slot.timesArray[0] !== '') {
        timesArray = slot.timesArray.sort();
      }
      if (timesArray.length > 0) {
        availablePychoSlots.push({ id: slot.id, date: slot.date, timesArray: timesArray.sort() });
      }
    });
    return orderBy(availablePychoSlots, 'date', 'asc');
  }

  onSelect = (selectedTime, selectedDate) => {
    this.setState({ selectedTime, selectedDate });
  }

  onEnroll = () => {
    const {
      selectedTime, selectedDate, specialist,
    } = this.state;
    const { history } = this.props;
    if (!getSessionId() || !getUserId()) {
      this.setState({ isShowLoginModal: true });
    } else {
      this.setState({ loading: true, isShowLoginModal: false });
      const cleanDate = moment(`${selectedDate.startOf('day').format('YYYY-MM-DD')}T${selectedTime}`, 'YYYY-MM-DDTHH:00').add(getTimeZoneDifference() || timeDifference, 'm').format('YYYY-MM-DDTHH:00');
      sessionEnroll({
        sessionDate: cleanDate.split('T')[0],
        sessionTime: cleanDate.split('T')[1],
        client_id: getUserId(),
        specialist_id: specialist.id,
        sessionDateAndTime: cleanDate,
      })
        .then((resp) => {
          if (resp.code === '200') {
            if (getIsValidClient() === 'true') {
              history.push(routes.meetings);
            } else {
              history.push(routes.profile);
            }
          } else {
            notification.error({ message: 'Что-то пошло не так, попробуйте позже' });
            window.location.reload();
          }
        });
    }
  }

  onRedirectBack = () => {
    const { history } = this.props;
    history.push({
      pathname: routes.list,
    });
  }

  onShowMore = () => {
    const { slots, allSlots } = this.state;
    const slotsLength = slots.length;
    const allSlotsLength = allSlots.length;
    this.setState({
      slots: allSlots.slice(0, slotsLength + 7),
      isShowMoreAvailable: slotsLength + 7 < allSlotsLength,
    });
  }

  render() {
    const {
      isMobile, specialist, loading, slots, selectedTime, selectedDate, isShowMoreAvailable, isShowLoginModal, isSessionExist,
    } = this.state;
    if (loading) {
      return (
        <Row
          justify="center"
          style={{
            backgroundColor: '#E5E3DC', width: '100%', alignItems: 'center', height: '100vh',
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
        </Row>
      );
    }

    if (isMobile) {
      return (
        <div>
          <PsychoSelectPageMobile
            specialist={specialist}
            slots={slots}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            onSelect={this.onSelect}
            onEnroll={this.onEnroll}
            onRedirectBack={this.onRedirectBack}
            onShowMore={this.onShowMore}
            isShowMoreAvailable={isShowMoreAvailable}
            isSessionExist={isSessionExist}
          />
          <Modal visible={isShowLoginModal} onCancel={() => this.setState({ isShowLoginModal: false })} footer={null}>
            <LoginModule callBack={this.onEnroll} />
          </Modal>
        </div>
      );
    }
    return (
      <div>
        <PsychoSelectPageDesktop
          specialist={specialist}
          slots={slots}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          onSelect={this.onSelect}
          onEnroll={this.onEnroll}
          onRedirectBack={this.onRedirectBack}
          onShowMore={this.onShowMore}
          isShowMoreAvailable={isShowMoreAvailable}
          isSessionExist={isSessionExist}
        />
        <Modal visible={isShowLoginModal} onCancel={() => this.setState({ isShowLoginModal: false })} footer={null}>
          <LoginModule callBack={this.onEnroll} isSelectSpecialist />
        </Modal>
      </div>
    );
  }
}

PsychoSelectModule.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(PsychoSelectModule);
