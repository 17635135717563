import React, { Component } from 'react';
import {
  Button, Checkbox, Col, Row, Modal, Form, DatePicker, Popover,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import timeArray from '../../utils/timeArray';


class CalendarTimeSlots extends Component {
  render() {
    const {
      onWeekSelect, firstDayOfWeek, slots, onCheckBoxChange, sessions, onSave, onQuikSelectClick, onShowCloseTherapyModal,
      isShowCloseTherapyModal, onSaveCloseTherapyModal, onCloseCloseTherapyModal, formRef, onRowChange, onColumnChange,
      groupSessions,
    } = this.props;
    return (
      <div>
        <div className="wrapper__btn-list">
          <div className="btn__session-list" justify="end">
            <div className="btn__session-list__checking">
              <Button className="btn-default btn_link btn_link_dotted" onClick={() => onQuikSelectClick(1)}>Раб часы, без вых(10-20)</Button>
              <Button className="btn-default btn_link btn_link_dotted" onClick={() => onQuikSelectClick(2)}>Раб часы с вых (10-20)</Button>
              <Button className="btn-default btn_link btn_link_dotted" onClick={() => onQuikSelectClick(3)}>Вечер, без вых (16-21)</Button>
              <Button className="btn-default btn_link btn_link_dotted" onClick={() => onQuikSelectClick(4)}>Вечер, с вых (16-21)</Button>
            </div>
            <div className="btn__session-list__action">
              <Button className="btn-default btn_red" type="danger" onClick={onShowCloseTherapyModal}>Закрыть прием</Button>
              <Button className="btn-default btn_green" type="primary" onClick={onSave}>Сохранить</Button>
              <Button className="btn-default btn_transparent" onClick={() => onQuikSelectClick(5)}>Сбросить все</Button>
            </div>
          </div>
        </div>
        <Row>
          {[0, 1, 2, 3].map((i) => {
            const weekStartDay = moment().add(i, 'weeks').startOf('isoWeek');
            return (
              <Col key={i} span={6} className="calendarPageDesktop-week" onClick={() => onWeekSelect(weekStartDay)} style={{ backgroundColor: weekStartDay.isSame(firstDayOfWeek) ? '#B5C1C0' : '', fontWeight: weekStartDay.isSame(firstDayOfWeek) ? '700' : '400' }}>
                {i === 0 ? 'Текущая неделя' : `${moment().add(i, 'weeks').startOf('isoWeek').format('DD')} - ${moment().add(i, 'weeks').endOf('isoWeek').format('DD')} ${moment().add(i, 'weeks').endOf('isoWeek').format('MMMM')}`}
              </Col>
            );
          })}
        </Row>
        <Row style={{ backgroundColor: '#B5C1C0' }}>
          <Col span={3} className="calendarPageDesktop-day" />
          {[0, 1, 2, 3, 4, 5, 6].map((i) => {
            let checkedTimesInColumn = 0;
            const slot = slots.find((s) => s.date.startOf('day').isSame(moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day')));
            if (slot) {
              checkedTimesInColumn = slot.timesArray.length;
            }
            return (
              <Col key={i} span={3} className="calendarPageDesktop-day">
                <Row justify="center">{moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day').format('dd, DD')}</Row>
                <Row justify="center">
                  <Checkbox
                    onChange={(e) => onColumnChange(moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day'), e.target.checked, slot)}
                    checked={checkedTimesInColumn === timeArray.length}
                  />
                </Row>
              </Col>
            );
          })}
        </Row>
        {timeArray.map((t) => {
          let checkedDaysInRow = 0;
          [0, 1, 2, 3, 4, 5, 6].forEach((i) => {
            const slot = slots.find((s) => s.date.startOf('day').isSame(moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day')));
            const isTime = slot && slot.timesArray.includes(t);
            if (isTime) {
              checkedDaysInRow += 1;
            }
          });
          return (
            <Row key={t} style={{ borderBottom: '1px solid #b7b6b2' }}>
              <Col span={3} className="calendarPageDesktop-day">
                <Row justify="space-around">
                  <Checkbox
                    onChange={(e) => onRowChange(moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY'), t, e.target.checked)}
                    checked={checkedDaysInRow === 7}
                  />
                  {t}
                </Row>
              </Col>
              {[0, 1, 2, 3, 4, 5, 6].map((i) => {
                const slot = slots.find((s) => s.date.startOf('day').isSame(moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day')));
                const isTime = slot && slot.timesArray.includes(t);
                const session = sessions.find((s) => moment(s.sessionDateAndTime).startOf('day').isSame(moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day')) && moment(s.sessionDateAndTime).format('HH:00') === t);
                const checkBox = moment(`${moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day').format('YYYY-MM-DD')} ${t}`);
                const groupSession = groupSessions.find((s) => checkBox.isBetween(s.startDateAndTime, s.endDateAndTime, 'minute', '[]'));
                return (
                  <Col span={3} key={i} className="calendarPageDesktop-day">
                    {!!groupSession && (
                    <Popover content={(
                      <Col>
                        <Row>{`${moment(groupSession.startDateAndTime).format('HH:00')}-${moment(groupSession.endDateAndTime).format('HH:00')}`}</Row>
                        <Row>{groupSession.name}</Row>
                      </Col>
)}
                    >
                      <Checkbox
                        checked={false}
                        disabled
                      />
                    </Popover>
                    )}
                    {!!session && (
                      <Popover content={(
                        <Col>
                          <Row>{moment(session.sessionDateAndTime).format('DD MMMM, HH:00')}</Row>
                          <Row>{session.clientName}</Row>
                        </Col>
)}
                      >
                        <Checkbox
                          checked={isTime}
                          disabled
                        />
                      </Popover>
                    )}
                    {!session && !groupSession && (
                    <Checkbox
                      onChange={(e) => onCheckBoxChange(moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day'), t, e.target.checked, slot)}
                      checked={isTime}
                    />
                    )}
                  </Col>
                );
              })}
            </Row>
          );
        })}

        {isShowCloseTherapyModal
        && (
        <Modal
          title="Закрыть прием"
          visible
          onOk={onSaveCloseTherapyModal}
          onCancel={onCloseCloseTherapyModal}
          okText="Закрыть"
          cancelText="Отмена"
        >
          <Form
            ref={formRef}
          >
            <Form.Item
              label="Дата начала"
              name="dateStart"
              rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label="Дата окончания"
              name="dateEnd"
              rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
            >
              <DatePicker />
            </Form.Item>
          </Form>
        </Modal>
        )}
      </div>
    );
  }
}

CalendarTimeSlots.propTypes = {
  onWeekSelect: PropTypes.func.isRequired,
  firstDayOfWeek: PropTypes.shape().isRequired,
  slots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onCheckBoxChange: PropTypes.func.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSave: PropTypes.func.isRequired,
  onQuikSelectClick: PropTypes.func.isRequired,
  onShowCloseTherapyModal: PropTypes.func.isRequired,
  isShowCloseTherapyModal: PropTypes.bool.isRequired,
  onSaveCloseTherapyModal: PropTypes.func.isRequired,
  onCloseCloseTherapyModal: PropTypes.func.isRequired,
  formRef: PropTypes.shape().isRequired,
  onRowChange: PropTypes.func.isRequired,
  onColumnChange: PropTypes.func.isRequired,
  groupSessions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CalendarTimeSlots;
