/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Anchor, Alert } from 'antd';
import PrevPage from '../../../assets/icons/arrow-right.svg';
import MobiMenu from '../../../assets/icons/mobi-menu.svg';
import './PsychoSelectPageMobile.css';
import ageEnding from '../../../utils/ageEnding';
import timeZoneCatalogue from '../../../utils/timeZoneCatalogue';
import getTimeZoneDifference from '../../../utils/localeStorage/getTimeZoneDifference';


const { Link } = Anchor;
class PsychoSelectPageMobile extends Component {
  render() {
    const {
      specialist, slots, selectedTime, selectedDate, onSelect, onEnroll, onRedirectBack, onShowMore, isShowMoreAvailable, isSessionExist,
    } = this.props;
    return (
      <div className="psychoSelect-wrapper">
        {isSessionExist && (
        <Alert
          message="Внимание! Есть неоплаченные сессии или уже выбран терапевт"
          description="Чтобы записаться к новому специалисту, необходимо сначала открепиться от старого или отменить запись"
          type="warning"
          showIcon
        />
        )}
        {!isSessionExist && (
        <div>
          <div className="profile-wrapper__header mobile-header__wrapper">
            <div className="mobi-logo mobi-logo_icon"><a><img src={PrevPage} onClick={onRedirectBack} alt="Назад к списку" /></a></div>
            <span className="profile-title mobile-header__title">Профиль терапевта</span>
            <div className="profile-header-menu">
              <img src={MobiMenu} alt="Мобильное меню" />
            </div>
          </div>
          <div className="psychoSelect-header-wrapper">
            <div className="psychoSelect-header-img"><img src={specialist.logoURL} className="psychoSelect-header-logo" alt="Фото специалиста" /></div>
            <div className="psychoSelect-header-column">
              <div className="psychoSelect-header-name">{`${specialist.lastName} ${specialist.firstName} ${specialist.middleName}`}</div>
              <div className="psychoSelect-header-btn"><Anchor affix={false}><Link href="#TIME" title={<Button className="btn-default btn_red" type="primary">Выбрать время</Button>} /></Anchor></div>
              <div className="psychoSelect-header-row">
                <div className="psychoSelect-header-row-label">Возраст:</div>
                <div className="psychoSelect-header-row-value">{specialist.birthdayDate ? ageEnding(moment().diff(specialist.birthdayDate, 'years')) : ''}</div>
              </div>
              <div className="psychoSelect-header-row">
                <div className="psychoSelect-header-row-label">Опыт работы:</div>
                <div className="psychoSelect-header-row-value">{ageEnding(specialist.experience)}</div>
              </div>
              <div className="psychoSelect-header-row">
                <div className="psychoSelect-header-row-label">Язык:</div>
                <div className="psychoSelect-header-row-value">{specialist.languagesCatalogue_ids.map((l) => l.value).join(', ')}</div>
              </div>
            </div>
          </div>
          <div className="psychoSelect-body-wrapper">
            <div className="psychoSelect-body-title">О специалисте</div>
            <div className="psychoSelect-body-row">
              <div className="psychoSelect-body-row-label">Основное образование:</div>
              <div className="psychoSelect-body-row-value-wrapper">
                <div className="psychoSelect-body-row-value">{specialist.mainEducation}</div>
              </div>
            </div>
            <div className="psychoSelect-body-col">
              <div className="psychoSelect-body-col-label">Дополнительное образование:</div>
              <div className="psychoSelect-body-col-value-wrapper">
                <div className="psychoSelect-body-col-value">{specialist.secondaryEducation}</div>
              </div>
            </div>
            <div className="psychoSelect-body-col">
              <div className="psychoSelect-body-col-value" dangerouslySetInnerHTML={{ __html: specialist.descriptionClients }} />
            </div>
            <div className="psychoSelect-body-col">
              <div className="psychoSelect-body-col-label">Специалист работает с:</div>
              <div className="psychoSelect-body-col-value">{specialist.specializationCatalogue_ids.map((specialisation) => <div key={specialisation.id}>{specialisation.value}</div>)}</div>
            </div>
            <div id="TIME" className="psychoSelect-body-title">Записаться к специалисту</div>
            <span style={{ marginBottom: '10px', fontSize: '10px' }}>{timeZoneCatalogue.find((t) => t.timeDifference === `${getTimeZoneDifference()}`).value}</span>
            {slots.map((slot) => (
              <div className="psychoSelect-mobi-zapis" key={slot.id}>
                <div className="psychoSelect-mobi-zapis__item">
                  <div className="psychoSelect-mobi-zapis__date">{slot.date.format('DD MMMM, dddd')}</div>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {slot.timesArray.map((time) => (
                      <div
                        className={selectedTime === time && slot.date.isSame(selectedDate) ? 'psychoSelect-body-slot-wrapper-selected' : 'psychoSelect-body-slot-wrapper'}
                        key={time + slot.date}
                        onClick={() => onSelect(time, slot.date)}
                      >
                        {time}
                      </div>
                    ))}

                  </div>
                </div>
              </div>
            ))}
            {isShowMoreAvailable && <div onClick={onShowMore} className="psychoSelect-body-show-more-button">Показать еще</div>}
            {selectedDate && selectedTime && (
            <div type="primary" className="psychoSelect-body-show-more-button" onClick={onEnroll}>Записаться</div>
            )}
          </div>
        </div>
        )}
      </div>
    );
  }
}

PsychoSelectPageMobile.propTypes = {
  specialist: PropTypes.shape().isRequired,
  slots: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedTime: PropTypes.string,
  selectedDate: PropTypes.shape(),
  onSelect: PropTypes.func.isRequired,
  onEnroll: PropTypes.func.isRequired,
  onRedirectBack: PropTypes.func.isRequired,
  onShowMore: PropTypes.func.isRequired,
  isShowMoreAvailable: PropTypes.bool.isRequired,
  isSessionExist: PropTypes.bool.isRequired,
};

PsychoSelectPageMobile.defaultProps = {
  selectedTime: null,
  selectedDate: null,
};

export default PsychoSelectPageMobile;
