/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import { notification } from 'antd';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import { config } from '../../utils/config';
import CalendarPageDesktop from './Desktop/CalendarPageDesktop';
import CalendarPageMobile from './Mobile/CalendarPageMobile';
import {
  getSpecialistSlots, editSpecialistSlots, getSpecialistSessions, startSessionZoom, editSession, getSpecialistGroupSessions,
  editClientProfile, sessionEnroll,
} from '../../utils/http';
import getTimeZoneDifference from '../../utils/localeStorage/getTimeZoneDifference';
import getUserId from '../../utils/localeStorage/getUserId';
import timeArray from './utils/timeArray';


const timeDifference = new Date().getTimezoneOffset();
class CalendarModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
      initLoading: true,
      slots: [],
      sessions: [],
      firstDayOfWeek: moment().startOf('isoWeek'),
      isShowCloseTherapyModal: false,
      selectedMonth: moment().startOf('M'),
      selectedDay: moment().startOf('D'),
      selectedYear: Number(moment().format('YYYY')),
      selectedProfile: null,
      isShowAddSession: false,
      selectedDate: null,
      selectedTime: null,
      selectedClientID: null,
      groupSessions: [],
      editClientName: '',
      editClientId: null,
      defaultActiveView: '1',
      slotsIds: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { history } = this.props;
    const isGroup = history.location.pathname === '/specialistGroups';
    const isSessions = history.location.pathname === '/specialistSessions';
    const isTimetable = history.location.pathname === '/specialistTimetable';
    let defaultActiveView = '1';
    if (isSessions) {
      defaultActiveView = '1';
    }
    if (isTimetable) {
      defaultActiveView = '2';
    }
    if (isGroup) {
      defaultActiveView = '4';
    }
    Promise.all([getSpecialistSlots(getUserId()), getSpecialistSessions(getUserId()), getSpecialistGroupSessions(getUserId())])
      .then((res) => this.setState({
        slots: res[0].slots,
        slotsIds: res[0].slotsIds,
        initLoading: false,
        sessions: res[1],
        groupSessions: res[2],
        defaultActiveView,
      }));
  }

  onSave = () => {
    const { slots, slotsIds } = this.state;
    let slotsToSave = [];
    const dates = [];
    slots.forEach((slot) => {
      slot.timesArray.forEach((time) => {
        const newdate = moment(`${slot.date.startOf('day').format('YYYY-MM-DD')}T${time}`, 'YYYY-MM-DDTHH:00').add(getTimeZoneDifference() || timeDifference, 'm').format('YYYY-MM-DDTHH:00');
        dates.push(newdate);
      });
    });
    dates.forEach((date) => {
      const slotToSave = slotsToSave.find((s) => s.date === date.split('T')[0]);
      if (slotToSave) {
        slotsToSave = slotsToSave.map((s) => (s.date === slotToSave.date ? { ...s, timesArray: [...s.timesArray, date.split('T')[1]] } : s));
      } else {
        slotsToSave.push({
          user_id: getUserId(),
          date: date.split('T')[0],
          timesArray: [date.split('T')[1]],
        });
      }
    });
    editSpecialistSlots([...slotsToSave, ...slotsIds.map((s) => ({ id: s, isDeleted: true }))])
      .then((list) => {
        notification.success({ message: 'Успешно сохранено!' });
        this.setState({ slots: list.slots, slotsIds: list.slotsIds });
      });
  }


  onQuikSelectClick = (v) => {
    const { firstDayOfWeek, slots } = this.state;
    const newSlots = [];
    const timesArrayAll = ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'];
    const timesArrayEvening = ['16:00', '17:00', '18:00', '19:00', '20:00', '21:00'];
    let startDate = moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY');
    switch (v) {
      case 1:
        for (let i = 0; i < 5; i += 1) {
          const slot = slots.find((s) => s.date && s.date.startOf('day').isSame(startDate.startOf('day')));
          if (slot) {
            newSlots.push({
              ...slot,
              timesArray: timesArrayAll,
            });
          } else {
            newSlots.push({
              id: null,
              date: moment(startDate.format('DD.MM.YY'), 'DD.MM.YY'),
              timesArray: timesArrayAll,
            });
          }
          startDate = startDate.add(1, 'days');
        }
        break;
      case 2:
        for (let i = 0; i < 7; i += 1) {
          const slot = slots.find((s) => s.date && s.date.startOf('day').isSame(startDate.startOf('day')));
          if (slot) {
            newSlots.push({
              ...slot,
              timesArray: timesArrayAll,
            });
          } else {
            newSlots.push({
              id: null,
              date: moment(startDate.format('DD.MM.YY'), 'DD.MM.YY'),
              timesArray: timesArrayAll,
            });
          }
          startDate = startDate.add(1, 'days');
        }
        break;
      case 3:
        for (let i = 0; i < 5; i += 1) {
          const slot = slots.find((s) => s.date && s.date.startOf('day').isSame(startDate.startOf('day')));
          if (slot) {
            newSlots.push({
              ...slot,
              timesArray: timesArrayEvening,
            });
          } else {
            newSlots.push({
              id: null,
              date: moment(startDate.format('DD.MM.YY'), 'DD.MM.YY'),
              timesArray: timesArrayEvening,
            });
          }
          startDate = startDate.add(1, 'days');
        }
        break;
      case 4:
        for (let i = 0; i < 7; i += 1) {
          const slot = slots.find((s) => s.date && s.date.startOf('day').isSame(startDate.startOf('day')));
          if (slot) {
            newSlots.push({
              ...slot,
              timesArray: timesArrayEvening,
            });
          } else {
            newSlots.push({
              id: null,
              date: moment(startDate.format('DD.MM.YY'), 'DD.MM.YY'),
              timesArray: timesArrayEvening,
            });
          }
          startDate = startDate.add(1, 'days');
        }
        break;
      case 5:
        break;
      default: break;
    }
    this.setState((prevState) => ({
      slots: [
        ...prevState.slots.filter((s) => moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').isAfter(s.date) || s.date.isAfter(moment(firstDayOfWeek.format('DD/MM/YY'), 'DD/MM/YY').add(6, 'd'))),
        ...newSlots],
    }));
  }


  onWeekSelect = (firstDayOfWeek) => {
    this.setState({ firstDayOfWeek });
  }

  onCheckBoxChange = (date, time, value, slot) => {
    this.setState((prevState) => ({
      slots: slot
        ? prevState.slots.map((s) => (s.date && s.date.startOf('day').isSame(date.startOf('day')) ? { ...s, timesArray: value ? s.timesArray.includes(time) ? s.timesArray : [...s.timesArray, time] : s.timesArray.filter((t) => t !== time) } : s))
        : [...prevState.slots, { id: null, date, timesArray: value ? [time] : [] }],
    }));
  }

  onShowCloseTherapyModal = () => {
    this.setState({ isShowCloseTherapyModal: true });
  }

  onCloseCloseTherapyModal = () => {
    this.setState({ isShowCloseTherapyModal: false });
  }

  onSaveCloseTherapyModal = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        const { dateStart, dateEnd } = values;
        const { slots } = this.state;
        const saveArray = [];
        while (dateEnd.isAfter(dateStart)) {
          const slot = slots.find((s) => s.date && s.date.startOf('day').isSame(dateStart.startOf('day')));
          if (slot) {
            saveArray.push({ id: slot.id, timesArray: '' });
          } else {
            saveArray.push({
              id: null, timesArray: '', user_id: getUserId(), date: dateStart.toISOString(),
            });
          }
          dateStart.add(1, 'd');
        }
        editSpecialistSlots(saveArray)
          .then((list) => {
            notification.success({ message: 'Успешно!' });
            this.setState(({ slots: list, isShowCloseTherapyModal: false }));
          });
      });
  }

  onRowChange = (startDate, time, isChecked) => {
    const { slots } = this.state;
    [0, 1, 2, 3, 4, 5, 6].forEach((i) => {
      const slot = slots.find((s) => s.date.startOf('day').isSame(moment(startDate.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day')));
      this.onCheckBoxChange(moment(startDate.format('DD/MM/YY'), 'DD/MM/YY').add(i, 'day'), time, isChecked, slot);
    });
  }

  onColumnChange = (date, isChecked, slot) => {
    this.setState((prevState) => ({
      slots: slot
        ? prevState.slots.map((s) => (s.date && s.date.startOf('day').isSame(date.startOf('day')) ? { ...s, timesArray: isChecked ? [...timeArray] : [] } : s))
        : [...prevState.slots, { id: null, date, timesArray: isChecked ? [...timeArray] : [] }],
    }));
  }

  onMonthSelect = (selectedMonth) => {
    this.setState({ selectedMonth, selectedDay: moment(selectedMonth).startOf('month') });
  }

  onDaySelect = (selectedDay) => {
    this.setState({ selectedDay });
  }

  onYearSelect = (selectedYear) => {
    this.setState({ selectedYear });
  }

  onStartSession = (sessionID) => {
    startSessionZoom(sessionID)
      .then((url) => {
        if (url) {
          window.open(url, '_self');
        } else {
          notification.error({ message: 'Что-то пошло не так, попробуйте позже' });
        }
      });
  }

  onChangeSessionStatus = (sessionID, status) => {
    editSession({ id: sessionID, status_id: status })
      .then(() => {
        this.setState((prevState) => ({
          sessions: prevState.sessions.map((s) => (s.id === sessionID ? { ...s, statusID: status } : s)),
        }));
        notification.success({ message: 'Изменения сохранены успешно!' });
      });
  }

  onCloseModal = () => {
    this.setState({ selectedProfile: null });
  }

  onShowProfile = (selectedProfile) => {
    this.setState({ selectedProfile });
  }

  onAddSession = (selectedClientID) => {
    this.setState({ isShowAddSession: true, selectedClientID });
  }

  onCloseAddSession = () => {
    this.setState({ isShowAddSession: false, selectedClientID: null });
  }

  onEnroll = () => {
    const {
      selectedTime, selectedDate, selectedClientID,
    } = this.state;
    const cleanDate = moment(`${selectedDate.startOf('day').format('YYYY-MM-DD')}T${selectedTime}`, 'YYYY-MM-DDTHH:00').add(getTimeZoneDifference() || timeDifference, 'm').format('YYYY-MM-DDTHH:00');
    sessionEnroll({
      sessionDate: cleanDate.split('T')[0],
      sessionTime: cleanDate.split('T')[1],
      client_id: selectedClientID,
      specialist_id: getUserId(),
      sessionDateAndTime: cleanDate,
    })
      .then((resp) => {
        if (resp.code === '200') {
          this.setState((prevState) => ({
            sessions: [
              ...prevState.sessions,
              {
                clientDisplayName: '',
                clientID: selectedClientID,
                gender: '',
                hostURL: null,
                id: resp.id,
                sessionDateAndTime: moment(`${selectedDate.startOf('day').format('DD.MM.YYYY')} ${selectedTime}`, 'DD.MM.YYYY HH:00'),
                specializationArray: [''],
                statusID: 'toBePaid',
                statusValue: 'Ожидает оплаты',
                whoWillUse: '',
              }],
          }));
          this.onCloseAddSession();
        } else {
          this.onCloseAddSession();
          notification.error({ message: 'Что-то пошло не так, попробуйте позже' });
        }
      });
  }

  onSelectTimeAndDate = (selectedTime, selectedDate) => {
    this.setState({ selectedTime, selectedDate });
  }

  onEditClientNameClick = (editClientName, editClientId) => {
    this.setState({ editClientName, editClientId });
  }

  onSaveEditClientName = () => {
    const { editClientId, editClientName } = this.state;
    editClientProfile({ id: editClientId, clientDisplayName: editClientName })
      .then(() => this.setState((prevState) => ({
        editClientId: null,
        editClientName: '',
        sessions: prevState.sessions.map((s) => (s.clientID === editClientId ? ({ ...s, clientDisplayName: editClientName }) : s)),
      })));
  }

  onCancelEditClientName = () => {
    this.setState({ editClientName: '', editClientId: null });
  }

  onChangeEditClientName = (editClientName) => {
    this.setState({ editClientName });
  }


  render() {
    const {
      isMobile, firstDayOfWeek, initLoading, slots, sessions, isShowCloseTherapyModal, selectedMonth, selectedDay, selectedYear,
      selectedProfile, isShowAddSession, selectedDate, selectedTime, groupSessions, editClientId, editClientName, defaultActiveView,
    } = this.state;
    if (initLoading) {
      return <div />;
    }
    if (isMobile) {
      return (
        <CalendarPageMobile
          onWeekSelect={this.onWeekSelect}
          firstDayOfWeek={firstDayOfWeek}
          slots={slots}
          onCheckBoxChange={this.onCheckBoxChange}
          sessions={sessions}
          onSave={this.onSave}
          onQuikSelectClick={this.onQuikSelectClick}
          onShowCloseTherapyModal={this.onShowCloseTherapyModal}
          isShowCloseTherapyModal={isShowCloseTherapyModal}
          onCloseCloseTherapyModal={this.onCloseCloseTherapyModal}
          onSaveCloseTherapyModal={this.onSaveCloseTherapyModal}
          formRef={this.formRef}
          onRowChange={this.onRowChange}
          onMonthSelect={this.onMonthSelect}
          selectedMonth={selectedMonth}
          selectedDay={selectedDay}
          onDaySelect={this.onDaySelect}
          selectedYear={selectedYear}
          onYearSelect={this.onYearSelect}
          onStartSession={this.onStartSession}
          onChangeSessionStatus={this.onChangeSessionStatus}
          selectedProfile={selectedProfile}
          onCloseModal={this.onCloseModal}
          onShowProfile={this.onShowProfile}
          onColumnChange={this.onColumnChange}
          onAddSession={this.onAddSession}
          isShowAddSession={isShowAddSession}
          onCloseAddSession={this.onCloseAddSession}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          onEnroll={this.onEnroll}
          onSelectTimeAndDate={this.onSelectTimeAndDate}
          groupSessions={groupSessions}
          onEditClientNameClick={this.onEditClientNameClick}
          onSaveEditClientName={this.onSaveEditClientName}
          onCancelEditClientName={this.onCancelEditClientName}
          editClientId={editClientId}
          editClientName={editClientName}
          onChangeEditClientName={this.onChangeEditClientName}
          defaultActiveView={defaultActiveView}
        />
      );
    }
    return (
      <CalendarPageDesktop
        onWeekSelect={this.onWeekSelect}
        firstDayOfWeek={firstDayOfWeek}
        slots={slots}
        onCheckBoxChange={this.onCheckBoxChange}
        sessions={sessions}
        onSave={this.onSave}
        onQuikSelectClick={this.onQuikSelectClick}
        onShowCloseTherapyModal={this.onShowCloseTherapyModal}
        isShowCloseTherapyModal={isShowCloseTherapyModal}
        onCloseCloseTherapyModal={this.onCloseCloseTherapyModal}
        onSaveCloseTherapyModal={this.onSaveCloseTherapyModal}
        formRef={this.formRef}
        onRowChange={this.onRowChange}
        onMonthSelect={this.onMonthSelect}
        selectedMonth={selectedMonth}
        selectedDay={selectedDay}
        onDaySelect={this.onDaySelect}
        selectedYear={selectedYear}
        onYearSelect={this.onYearSelect}
        onStartSession={this.onStartSession}
        onChangeSessionStatus={this.onChangeSessionStatus}
        selectedProfile={selectedProfile}
        onCloseModal={this.onCloseModal}
        onShowProfile={this.onShowProfile}
        onColumnChange={this.onColumnChange}
        onAddSession={this.onAddSession}
        isShowAddSession={isShowAddSession}
        onCloseAddSession={this.onCloseAddSession}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        onEnroll={this.onEnroll}
        onSelectTimeAndDate={this.onSelectTimeAndDate}
        groupSessions={groupSessions}
        onEditClientNameClick={this.onEditClientNameClick}
        onSaveEditClientName={this.onSaveEditClientName}
        onCancelEditClientName={this.onCancelEditClientName}
        editClientId={editClientId}
        editClientName={editClientName}
        onChangeEditClientName={this.onChangeEditClientName}
        defaultActiveView={defaultActiveView}
      />
    );
  }
}

CalendarModule.propTypes = {
  history: PropTypes.shape().isRequired,
};


export default withRouter(CalendarModule);
