import React from 'react';
import { Tag } from 'antd';


const getStatusTag = (isUsed) => {
  if (isUsed) {
    return (
      <Tag
        color="red"
      >
        Использован
      </Tag>
    );
  }
  return (
    <Tag
      color="green"
    >
      Новый
    </Tag>
  );
};

export default getStatusTag;
