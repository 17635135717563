/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import { config } from '../../utils/config';
import './Footer.css';
import { routes } from '../../utils/routes';


const fullFutterRoutes = [routes.home, routes.login, routes.homePage, routes.specialists];
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
    };
  }

  render() {
    const { history } = this.props;
    const pathname = get(history, 'location.pathname', '');
    const routesKeys = Object.keys(routes);
    const routesValues = [];
    routesKeys.forEach((k) => routesValues.push(routes[k]));
    routesValues.push('/specialist');
    const isVisible = ![routes.login, routes.home].includes(`/${pathname.split('/')[1]}`);
    if (!isVisible) {
      return null;
    }
    const isFullFooterVisible = fullFutterRoutes.includes(`/${pathname.split('/')[1]}`) || !routesValues.includes(`/${pathname.split('/')[1]}`);
    const {
      isMobile,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <div className={isFullFooterVisible ? 'footer center-1200' : 'footer center-1200 center-1200_mini'}>
        <div className="othersHelp-wrapper">
          {isFullFooterVisible && <div className="othersHelp-title">Если у вас серьезные, угражающие вашей жизни проблемы, которые требуют немедленного решения, вам лучше обратиться в какую-либо из этих организаций:</div>}
          {isFullFooterVisible && (
          <div className="othersHelp-phones">
            <div className="othersHelp-phonesColumn othersHelp-phonesColumn-1">
              <h3 className="othersHelp-h3">Неотложная психологическая помощь в Москве</h3>
              <p>051 - с городского телефона</p>
              <p>8 (495) 051 - с мобильного телефона</p>
            </div>
            <div className="othersHelp-phonesColumn othersHelp-phonesColumn-2">
              <h3 className="othersHelp-h3">Московская служба психологической помощи населению</h3>
              <p>+7 (495) 173-09-00</p>
            </div>
            <div className="othersHelp-phonesColumn othersHelp-phonesColumn-3">
              <h3 className="othersHelp-h3">Независимый благотворительный центр помощи пережившим сексуальное насилие</h3>
              <p>+7 (499) 901-02-01</p>
            </div>
            <div className="othersHelp-phonesColumn othersHelp-phonesColumn-4">
              <h3 className="othersHelp-h3">Телефон доверия для детей, подростков и их родителей</h3>
              <p>+7 (800) 2000-122</p>
            </div>
          </div>
          )}
          <div className={isFullFooterVisible ? 'footerUnd' : 'footerUnd footerUnd_mini'}>
            <div className="footerUnd-column footerUnd-columnLeft">
              <p><strong>©2022 Гештальт.онлайн</strong></p>
              <p>
                <a href="https://gestalt.online/files/client-consultant.pdf" target="_blank" rel="noreferrer">Договор "Клиент-Консультант"</a>
                <br />
                <a href="https://gestalt.online/files/pravila-accii-podderzki.pdf" target="_blank" rel="noreferrer">Правила проведения акции "Группа поддержки"</a>
                <br />
                <a href="https://gestalt.online/files/soglashenie-client.pdf" target="_blank" rel="noreferrer">Соглашение об использовании сервиса</a>
              </p>
            </div>
            <div className="footerUnd-column footerUnd-columnCenter">
              <p>Регистрация на сайте означает согласие с пользовательским соглашением. Заходя на сайт, вы даете согласие на использование файлов cookie и иных данных в соответствии с политикой в отношении обработки и защиты персональных данных.</p>
              <p>Под термином «психотерапия» на сайте подразумевается психологическое консультирование. Психологи и Психотерапевты не оказывают медицинских услуг на площадке.</p>
            </div>
            <div className="footerUnd-column footerUnd-columnRight">
              <p>
                Все вопросы про запись, оплату, технические проблемы направлять на почту:
                <a href="mailto:tech@geshtalt.online"> tech@geshtalt.online</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  history: PropTypes.shape(),
};

Footer.defaultProps = {
  history: {},
};


export default withRouter(Footer);
