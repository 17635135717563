/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { routes } from '../../utils/routes';
import './BottomMobile.less';
import clientButtonsArray from './utils/clientButtonsArray';
import specialistButtonsArray from './utils/specialistButtonsArray';
import * as roles from '../../utils/roles';
import getUserRole from '../../utils/localeStorage/getUserRole';
import getIsValidClient from '../../utils/localeStorage/getIsValidClient';
import getIsValidSpecialist from '../../utils/localeStorage/getIsValidSpecialist';


@withRouter
class BottomMobile extends Component {
  handleButtonClick = (route, isDisabled) => {
    if (!isDisabled) {
      const { history } = this.props;
      history.push(route);
    }
  }

  render() {
    const { history } = this.props;
    const { pathname } = get(history, 'location', '');
    const isVisible = pathname !== routes.login && pathname !== routes.home;
    if (!isVisible) {
      return null;
    }
    let buttonsArray = [];
    switch (getUserRole()) {
      case roles.ADMIN:
        // buttonsArray = adminButtonsArray;
        break;
      case roles.CLIENT:
        buttonsArray = clientButtonsArray;
        break;
      case roles.SPECIALIST:
        buttonsArray = specialistButtonsArray;
        break;
      default: break;
    }
    let isDisabled = true;
    if (getUserRole() === roles.CLIENT && getIsValidClient() === 'true') {
      isDisabled = false;
    }
    if (getUserRole() === roles.ADMIN) {
      isDisabled = false;
    }
    if (getUserRole() === roles.SPECIALIST && getIsValidSpecialist() === 'true') {
      isDisabled = false;
    }
    return (
      <div className="bottomMobile-wrapper">
        {buttonsArray.map((button) => (
          <div className="bottomMobile-button-wrapper" onClick={() => this.handleButtonClick(button.route, isDisabled && button.id !== 'profile')} key={button.name}>
            <div>{button.icon}</div>
            <div
              className="bottomMobile-name"
              style={{ color: button.route.includes(history.location.pathname.split('/')[1]) ? '#DC7761' : '#ffffff' }}
            >
              {button.name}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

BottomMobile.propTypes = {
  history: PropTypes.shape(),
};

BottomMobile.defaultProps = {
  history: {},
};

export default BottomMobile;
