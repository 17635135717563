/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import moment from 'moment';
import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ru from 'react-phone-input-2/lang/es.json';
import {
  Form, Input, Radio, InputNumber, Select, DatePicker, Checkbox, Row, Col, Upload, Steps, Card, notification, Button, Avatar, Spin, Modal, Alert,
} from 'antd';
import {
  InboxOutlined, DownloadOutlined, DeleteOutlined, UserOutlined, LoadingOutlined,
} from '@ant-design/icons';
import PinInput from 'react-pin-input';
import {
  getTimeZoneCatalogue, editUserLogo, getSpecialistProfile, editSpecialistProfile, editEmailVerificationRequests, checkEmailCode, uploadFile,
  getLanguagesCatalogue, getSpecializationCatalogue, getShopCodeList, getCardIdList,
} from '../../utils/http';
import supervisionPeriod from './supervisionPeriod';
import getUserRole from '../../utils/localeStorage/getUserRole';
import getUserId from '../../utils/localeStorage/getUserId';
import getUserLogo from '../../utils/localeStorage/getUserLogo';
import { setLocalStorage as setStorage } from '../../utils/localeStorage/storage';
import { config } from '../../utils/config';
import './Anketa.css';


const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;
const { Step } = Steps;
class Anketa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
      step: 0,
      timeZoneCatalogue: [],
      languagesCatalogue: [],
      specializationCatalogue: [],
      userSpecializations: [],
      disabled: true,
      files: [],
      user: null,
      isShowApproveEmailInput: false,
      isLoadingEmailInput: false,
      isValidEmailInput: true,
      isGroupSession: null,
      supervision: null,
      therapy: null,
      shopCodeList: [],
      cardIdList: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    Promise.all([getTimeZoneCatalogue(), getSpecialistProfile(getUserId()), getLanguagesCatalogue(), getSpecializationCatalogue(), getShopCodeList(),
      getCardIdList()])
      .then((res) => {
        const { user } = this.props;
        this.setState({
          timeZoneCatalogue: res[0],
          user: user || res[1],
          disabled: user ? user.status === 'onApproval' : res[1].status === 'onApproval',
          languagesCatalogue: res[2],
          specializationCatalogue: res[3],
          userSpecializations: user ? user.specializationCatalogue_ids : res[1].specializationCatalogue_ids,
          files: user ? user.educationFiles : res[1].educationFiles,
          shopCodeList: res[4],
          cardIdList: res[5],
        });
      });
  }

  onSaveClick = () => {
    this.setState({ disabled: true });
    const {
      step, files, user, userSpecializations, cardIdList, timeZoneCatalogue,
    } = this.state;
    const values = this.formRef.current.getFieldsValue();
    if (values.shopCode && values.shopCode !== '') {
      values.isLegalPaymentValid = true;
      values.paymentType = 'legal';
    }
    if (values.CardId && values.CardId !== '') {
      values.isPhysicalPaymentValid = true;
      values.isSuccessAddCard = true;
      values.Pan = cardIdList.find((c) => c.CardId === values.CardId)?.Pan;
    }
    if (step === 1) {
      values.educationFiles_ids = files.map((f) => f.id).join(',');
    }
    if (step === 4) {
      values.specializationCatalogue_ids = userSpecializations.join(',');
    }
    editSpecialistProfile(values)
      .then((newUser) => {
        notification.success({ message: 'Сохранено успешно' });
        this.setState({ user: { ...newUser, isEmailVerified: user.email === newUser.email }, disabled: user.status === 'onApproval' });
        if (values.firstName) {
          setStorage({ firstName: values.firstName });
        }
        if (values.timeZone) {
          setStorage({ timeZoneTimeDifference: timeZoneCatalogue.find((e) => e.id === values.timeZone).timeDifference });
        }
      });
  }

  onChangePageClick = (newStep) => {
    const {
      step, files, user, userSpecializations, cardIdList, timeZoneCatalogue,
    } = this.state;
    if (newStep < step) {
      this.setState({ step: newStep });
    } if (user.status === 'onApproval') {
      if (step === 0 && getUserRole() === 'as2f2fc34') {
        this.formRef.current.validateFields()
          .then((values) => {
            editSpecialistProfile(values)
              .then((newUser) => {
                notification.success({ message: 'Сохранено успешно' });
                this.setState({ user: newUser, step: newStep });
              });
          })
          .catch(() => notification.error({ message: 'Заполните обязательные поля' }));
      } else {
        this.setState({ step: newStep });
      }
    } else if (user.status === 'approved') {
      this.setState({ step: newStep });
    } else {
      this.formRef.current.validateFields()
        .then((values) => {
          this.setState({ disabled: true });
          if (step === 0 && (!user.isEmailVerified || user.email !== values.email)) {
            notification.error({ message: 'Сохраните и подтвердите, пожалуйста, email' });
            this.setState({ disabled: false });
            return;
          }
          if (values.CardId && values.CardId !== '') {
            values.isPhysicalPaymentValid = true;
            values.isSuccessAddCard = true;
            values.Pan = cardIdList.find((c) => c.CardId === values.CardId)?.Pan;
          }
          if (values.shopCode && values.shopCode !== '') {
            values.isLegalPaymentValid = true;
            values.paymentType = 'legal';
          }
          if (step === 1) {
            values.educationFiles_ids = files.map((f) => f.id).join(',');
          }
          if (newStep === 5) {
            values.status = 'onApproval';
            values.specializationCatalogue_ids = userSpecializations.join(',');
          }
          editSpecialistProfile(values)
            .then((newUser) => {
              if (values.firstName) {
                setStorage({ firstName: values.firstName });
              }
              if (values.timeZone) {
                setStorage({ timeZoneTimeDifference: timeZoneCatalogue.find((e) => e.id === values.timeZone).timeDifference });
              }
              notification.success({ message: 'Сохранено успешно' });
              this.setState({ user: newUser, disabled: newStep === 5, step: newStep === 5 ? 0 : newStep });
            });
        })
        .catch(() => notification.error({ message: 'Заполните обязательные поля' }));
    }
  }

  onApproveEmailClick = () => {
    const values = this.formRef.current.getFieldsValue();
    const EmailMask = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (values.email && EmailMask.test(values.email)) {
      const { user } = this.state;
      editEmailVerificationRequests({ user_id: user.id, date: moment().toISOString(), email: values.email })
        .then(() => {
          this.setState({ isShowApproveEmailInput: true });
          notification.success({ message: 'Код отправлен на указанную почту' });
        });
    } else {
      notification.error({ message: 'Укажите корректный email, пожалуйста' });
    }
  }

  onCloseShowApproveEmailInput = () => {
    this.setState({ isShowApproveEmailInput: false });
  }

  onFinishApproveEmailInput = (userInputEmailVerificationCode) => {
    const { user } = this.state;
    this.setState({ isLoadingEmailInput: true });
    checkEmailCode({ id: user.id, userInputEmailVerificationCode })
      .then((isEmailVerified) => {
        if (isEmailVerified) {
          notification.success({ message: 'Спасибо!' });
          const values = this.formRef.current.getFieldsValue();
          editSpecialistProfile({
            id: user.id, isEmailVerified, email: values.email,
          })
            .then(() => {
              this.setState({
                isLoadingEmailInput: false,
                isShowApproveEmailInput: false,
                user: { ...user, isEmailVerified: true, email: values.email },
              });
            });
        } else {
          notification.error({ message: 'Неправильный код!' });
          this.setState({ isLoadingEmailInput: false });
        }
      });
  }

  onChangeEmailInput = () => {
    this.setState({ isValidEmailInput: true });
  }

  onUploadFile = (info) => {
    if (info.file.status === 'uploading' && !info.event) {
      const form = new FormData();
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const { user } = this.state;
      form.append('file', info.file.originFileObj);
      form.append('user_id', user.id);
      uploadFile(form, headers)
        .then((file) => this.setState((prevState) => ({ files: [...prevState.files, file] })));
    }
  }

  onRemoveFile = (file) => {
    this.setState((prevState) => ({ files: prevState.files.filter((f) => f.id !== file.id) }));
  }

  onLogoChanges = (info) => {
    this.setState({ disabled: true });
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const form = new FormData();
    const { user } = this.state;
    form.append('id', user.id);
    form.append('logo_id', info.file.originFileObj);
    editUserLogo(form, headers)
      .then((logoURL) => {
        setStorage({ logoURL });
        this.setState({ disabled: false });
      })
      .catch(() => {
        this.setState({ disabled: false });
      });
  }

  onSpecializaionCheckbox = (id, value) => {
    this.setState((prevState) => ({
      userSpecializations: value ? [...prevState.userSpecializations, id] : prevState.userSpecializations.filter((s) => s !== id),
    }));
  }

  onOpenProfile = () => {
    const { user } = this.state;
    window.open(`https://lk.gestalt.online/specialist/${user.id}`, '_blank');
  }

  render() {
    const {
      step, timeZoneCatalogue, disabled, files, user, isShowApproveEmailInput, isLoadingEmailInput, isValidEmailInput, shopCodeList,
      isGroupSession, supervision, therapy, languagesCatalogue, specializationCatalogue, userSpecializations, isMobile, cardIdList,
    } = this.state;
    const specGroups = [];
    specializationCatalogue.forEach((s) => {
      const group = specGroups.find((g) => g === s.group);
      if (!group) {
        specGroups.push(s.group);
      }
    });
    let isShowGroupSessionPrice = false;
    if (isGroupSession) {
      isShowGroupSessionPrice = true;
    } else if (isGroupSession === null) {
      isShowGroupSessionPrice = user && user.isGroupSession;
    }
    let isShowTherapy = false;
    if (therapy && therapy !== 'Нет') {
      isShowTherapy = true;
    } else if (therapy === null) {
      isShowTherapy = user && user.therapy && user.therapy !== 'Нет';
    }
    let isShowSupervision = false;
    if (supervision && supervision !== 'Нет') {
      isShowSupervision = true;
    } else if (supervision === null) {
      isShowSupervision = user && user.supervision && user.supervision !== 'Нет';
    }

    if (!user) {
      return (
        <div className="adminDataChangeRequestsPageDesktop-wrapper" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
        </div>
      );
    }
    const groupedShopCodeList = groupBy(shopCodeList, 'shopCode');
    const groupedShopCodeListKeys = Object.keys(groupedShopCodeList);
    const shopCodeListOptions = [];
    groupedShopCodeListKeys.forEach((key) => {
      shopCodeListOptions.push({
        key,
        label: groupedShopCodeList[key].map((c) => c.fullName).join(', '),
      });
    });

    const groupedCardIdList = groupBy(cardIdList, 'CardId');
    const groupedCardIdListKeys = Object.keys(groupedCardIdList);
    const cardIdListOptions = [];
    groupedCardIdListKeys.forEach((key) => {
      cardIdListOptions.push({
        key,
        label: `${groupedCardIdList[key][0].Pan} ${groupedCardIdList[key].map((c) => c.fullName).join(', ')}`,
      });
    });
    return (
      <Form
        ref={this.formRef}
        layout="vertical"
        className="anketa-wrapper"
      >
        <Form.Item
          name="id"
          initialValue={user.id}
          hidden
        >
          <Input />
        </Form.Item>
        <Col style={{ padding: isMobile ? '20px' : 0 }}>
          <Row className="anketa__page">
            {step === 0 && (
            <div className="anketa__left">
              <Card className="anketa__left_step-1">
                <div className="anketa__form-wrapper">
                  <div className="anketa__title">Общая информация</div>
                  {getUserRole() === 'as2f2fc34' && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        rules={[{ required: true, message: '' }]}
                        name="sessionPrice"
                        initialValue={user.sessionPrice}
                        label="Стоимость приема"
                      >
                        <InputNumber disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                      </Form.Item>
                    </Col>
                  </Row>
                  )}
                  {getUserRole() === 'as2f2fc34' && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        rules={[{ required: true, message: '' }]}
                        name="fee"
                        initialValue={user.fee}
                        label="% комиссии сервиса"
                      >
                        <InputNumber />
                      </Form.Item>
                    </Col>
                  </Row>
                  )}
                  {getUserRole() === 'as2f2fc34' && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        name="shopCode"
                        initialValue={user.shopCode}
                        label="ID точки в Тиньков"
                      >
                        <Select style={{ width: '700px' }}>
                          {shopCodeListOptions.map((u) => <Option key={u.key} value={u.key}>{u.label}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  )}
                  {getUserRole() === 'as2f2fc34' && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        name="CardId"
                        initialValue={user.CardId}
                        label="Номер привязанной карты"
                      >
                        <Select style={{ width: '700px' }}>
                          {cardIdListOptions.map((u) => <Option key={u.key} value={u.key}>{u.label}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  )}
                  {isShowGroupSessionPrice && getUserRole() === 'as2f2fc34' && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        rules={[{ required: true, message: '' }]}
                        name="groupSessionPrice"
                        initialValue={user.groupSessionPrice}
                        label="Стоимость приема пар"
                      >
                        <InputNumber disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                      </Form.Item>
                    </Col>
                  </Row>
                  )}
                  <div className="anketa__foto">
                    <div className="anketa__img">
                      {getUserLogo() || user.logo
                        ? <Avatar src={getUserLogo() || user.logo} shape="square" />
                        : <Avatar shape="square" icon={<UserOutlined />} />}
                    </div>
                    <div>
                      <Upload
                        showUploadList={false}
                        action={null}
                        onChange={this.onLogoChanges}
                      >
                        {(getUserRole() === 'as2f2fc34' || !disabled) && <Button className="btn-default btn_link btn_link_dotted">Загрузить изображение</Button>}
                      </Upload>
                    </div>
                  </div>
                  <div>
                    <Form.Item
                      className="anketa__input-hor anketa__input_input"
                      rules={[{ required: true, message: 'Пожалуйста, введите свою фамилию' }]}
                      name="lastName"
                      initialValue={user.lastName}
                      label="Фамилия"
                    >
                      <Input disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className="anketa__input-hor anketa__input_input"
                      rules={[{ required: true, message: '' }]}
                      name="firstName"
                      initialValue={user.firstName}
                      label="Имя"
                    >
                      <Input disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className="anketa__input-hor anketa__input_input"
                      rules={[{ required: true, message: '' }]}
                      name="middleName"
                      initialValue={user.middleName}
                      label="Отчество"
                    >
                      <Input disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className="anketa__input-hor anketa__input_age"
                      rules={[{ required: true, message: '' }]}
                      name="birthdayDate"
                      initialValue={user.birthdayDate}
                      label="Дата рождения"
                    >
                      <DatePicker disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className="anketa__input-hor anketa__input_gender"
                      rules={[{ required: true, message: '' }]}
                      name="gender"
                      initialValue={user.gender}
                      label="Пол"
                    >
                      <Select disabled={getUserRole() === 'as2f2fc34' ? false : disabled}>
                        {[{ id: 'Мужской', value: 'Мужской' }, { id: 'Женский', value: 'Женский' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="anketa__email">
                    <div span={!user.isEmailVerified && user.status !== 'onApproval' ? 20 : 24}>
                      <Form.Item
                        className="anketa__input-hor anketa__input_email"
                        rules={[{ required: true, message: '', type: 'email' }]}
                        validateStatus={!user.isEmailVerified && 'error'}
                        name="email"
                        initialValue={user.email}
                        label="E-mail"
                      >
                        <Input disabled={disabled || user.status === 'approved'} />
                      </Form.Item>
                    </div>
                    <div className="anketa__confirm-email">
                      {user.status !== 'onApproval' && user.status !== 'approved' && !user.isEmailVerified && <Button type="primary" className="btn-default btn_link" onClick={this.onApproveEmailClick}>Подтвердить</Button>}
                    </div>
                  </div>
                  <div>
                    <Form.Item
                      className="anketa__input-hor"
                      rules={[{ required: true, message: '' }]}
                      name="phone"
                      initialValue={user.phone}
                      label="Телефон"
                    >
                      <PhoneInput
                        localization={ru}
                        placeholder=""
                        country="ru"
                        disabled
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className="anketa__input-hor anketa__input_timezone"
                      rules={[{ required: true, message: '' }]}
                      name="timeZone"
                      initialValue={user.timeZone}
                      label="Часовой пояс"
                    >
                      <Select
                        disabled={getUserRole() === 'as2f2fc34' ? false : disabled}
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {timeZoneCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className="anketa__text"
                      rules={[{ required: true, message: '' }]}
                      name="descriptionClients"
                      initialValue={user.descriptionClients}
                      label="Расскажите клиентам о себе в свободной форме"
                      tooltip="Доступно клиентам"
                    >
                      <TextArea className="anketa__textarea" disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className="anketa__text"
                      rules={[{ required: true, message: '' }]}
                      name="descriptionModerator"
                      initialValue={user.descriptionModerator}
                      label="Расскажите нам о себе в свободной форме"
                      tooltip="Доступно модератору"
                    >
                      <TextArea className="anketa__textarea" disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                    </Form.Item>
                  </div>
                  <div className="anketa__btn-group">
                    {(getUserRole() === 'as2f2fc34' || !disabled) && <Col><Button onClick={this.onSaveClick} className="btn-default btn_green">Сохранить</Button></Col>}
                    <Button disabled={user.status === 'onApproval' ? false : disabled} type="primary" className="btn-default btn_transparent" onClick={() => this.onChangePageClick(1)}>Далее</Button>
                    {getUserRole() === 'as2f2fc34' && <Col><Button type="primary" className="btn-default btn_transparent" onClick={this.onOpenProfile}>Показать профиль</Button></Col>}
                  </div>
                </div>
              </Card>
            </div>
            )}
            {(step === 1 || isMobile) && (
            <div className="anketa__left">
              <Card>
                <div className="anketa__form-wrapper">
                  <div className="anketa__title">Образование</div>
                  <div>
                    <Form.Item
                      className="anketa__text"
                      rules={[{ required: true, message: '' }]}
                      name="mainEducation"
                      initialValue={user.mainEducation}
                      label="Ваше основное образование"
                      tooltip="Например, 2004 - МГУ - факультет психологии, клиническая психология - бакалавр"
                    >
                      <TextArea className="anketa__textarea anketa__textarea__main-educ" disabled={getUserRole() === 'as2f2fc34' ? false : disabled} rows={2} />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      className="anketa__text"
                      rules={[{ required: true, message: '' }]}
                      name="secondaryEducation"
                      initialValue={user.secondaryEducation}
                      label="Ваше дополнительное образование"
                      tooltip="Например, 2015 - МИГиП - факультет гештальт-терапии (4 года) - 150 часов"
                    >
                      <TextArea className="anketa__textarea" disabled={getUserRole() === 'as2f2fc34' ? false : disabled} rows={4} />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="anketa__subtitle">Пожалуйста, прикрепите фотографии развернутых дипломов и сертификатов</div>
                    <div className="anketa__drugblock">
                      <Dragger
                        action={null}
                        onChange={this.onUploadFile}
                        multiple
                        showUploadList={false}
                        disabled={getUserRole() === 'as2f2fc34' ? false : disabled}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Нажмите или перетащите файлы</p>
                      </Dragger>
                    </div>
                    {files.map((f) => (
                      <div className="drugblock__load-files" key={f.id}>
                        <div className="drugblock__load-files_open"><DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => window.open(f.urlLink)} /></div>
                        {!disabled && <div className="drugblock__load-files_remove"><DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => this.onRemoveFile(f)} /></div>}
                        <div className="drugblock__load-files_item">{f.originalFileName}</div>
                      </div>
                    ))}
                  </div>
                  {!isMobile && (
                  <Row justify="end">
                    <Col className="anketa__btn-group">
                      {(getUserRole() === 'as2f2fc34' || !disabled) && <Col><Button onClick={this.onSaveClick} className="btn-default btn_green">Сохранить</Button></Col>}
                      <Button disabled={user.status === 'onApproval' ? false : disabled} onClick={() => this.onChangePageClick(0)} className="btn-default btn_transparent">Назад</Button>
                      <Button disabled={user.status === 'onApproval' ? false : disabled} type="primary" className="btn-default btn_transparent" onClick={() => this.onChangePageClick(2)}>Далее</Button>
                    </Col>
                  </Row>
                  )}
                </div>
              </Card>
            </div>
            )}
            {(step === 2 || isMobile) && (
            <div className="anketa__left">
              <Card>
                <Col className="anketa__form-wrapper">
                  <Row justify="space-between">
                    <Col className="anketa__title">Опыт работы</Col>
                  </Row>
                  {/* <Row>
                    <Form.Item
                      className="anketa__input-hor anketa__input_input"
                      rules={[{ required: true, message: '' }]}
                      name="isLGBTQ"
                      initialValue={user.isLGBTQ}
                      label="Есть ли у вас опыт работы с LGBTQ+ людьми"
                      tooltip="В рамках КоАП РФ ст 6.21 «О пропаганде» мы показываем информацию о ЛГБТК+ клиентам старше 18 лет"
                    >
                      <Radio.Group
                        disabled={getUserRole() === 'as2f2fc34' ? false : disabled}
                        options={[
                          { label: 'Да', value: true },
                          { label: 'Нет', value: false },
                        ]}
                        optionType="button"
                        buttonStyle="solid"
                      />
                    </Form.Item>
                  </Row> */}
                  <Row>
                    <Form.Item
                      className="anketa__input-hor anketa__input_input"
                      rules={[{ required: true, message: '' }]}
                      name="experience"
                      initialValue={user.experience}
                      label="Сколько полных лет вы консультируете?"
                    >
                      <InputNumber disabled={getUserRole() === 'as2f2fc34' ? false : disabled} style={{ width: '100%' }} />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      className="anketa__input-hor anketa__input_input"
                      rules={[{ required: true, message: '' }]}
                      name="experienceOnline"
                      initialValue={user.experienceOnline}
                      label="Есть ли опыт работы онлайн? Если да, то сколько лет?"
                    >
                      <Input disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                    </Form.Item>
                  </Row>
                  {!isMobile && (
                  <Row justify="end" style={{ marginTop: '10px' }}>
                    <Col className="anketa__btn-group">
                      {(getUserRole() === 'as2f2fc34' || !disabled) && <Col><Button onClick={this.onSaveClick} className="btn-default btn_green">Сохранить</Button></Col>}
                      <Button disabled={user.status === 'onApproval' ? false : disabled} onClick={() => this.onChangePageClick(1)} className="btn-default btn_transparent">Назад</Button>
                      <Button disabled={user.status === 'onApproval' ? false : disabled} type="primary" style={{ marginLeft: '10px' }} onClick={() => this.onChangePageClick(3)} className="btn-default btn_transparent">Далее</Button>
                    </Col>
                  </Row>
                  )}
                </Col>
              </Card>
            </div>
            )}
            {(step === 3 || isMobile) && (
            <div className="anketa__left">
              <Card>
                <div className="anketa__form-wrapper">
                  <div className="anketa__title">Супервизия и Терапия</div>
                  <div>
                    <div>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        rules={[{ required: true, message: '' }]}
                        name="supervision"
                        initialValue={user.supervision}
                        label="Проходите ли вы супервизию"
                      >
                        <Radio.Group
                          disabled={getUserRole() === 'as2f2fc34' ? false : disabled}
                          options={[
                            { label: 'Да', value: 'Да' }, { label: 'Нет', value: 'Нет' }, { label: 'Ранее проходил(а)', value: 'Ранее проходил(а)' },
                          ]}
                          onChange={(e) => this.setState({ supervision: e.target.value })}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {isShowSupervision && (
                  <div>
                    <div>
                      <Form.Item
                        className="anketa__input-hor anketa__input_age"
                        rules={[{ required: true, message: '' }]}
                        name="supervisionStartDate"
                        initialValue={user.supervisionStartDate}
                        label="Начало прохождения супервизии"
                      >
                        <DatePicker picker="month" disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                      </Form.Item>
                    </div>
                  </div>
                  )}
                  {isShowSupervision && (
                  <div>
                    <div>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input anketa__input_fullage"
                        rules={[{ required: true, message: '' }]}
                        name="supervisionTime"
                        initialValue={user.supervisionTime}
                        label="Сколько полных лет супервизии пройдено"
                      >
                        <InputNumber disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                      </Form.Item>
                    </div>
                  </div>
                  )}
                  {isShowSupervision && (
                  <div>
                    <div>
                      <Form.Item
                        className="anketa__input-hor anketa__input_period"
                        rules={[{ required: true, message: '' }]}
                        name="supervisionPeriod"
                        initialValue={user.supervisionPeriod}
                        label="Периодичность"
                      >
                        <Select
                          disabled={getUserRole() === 'as2f2fc34' ? false : disabled}
                          mode="multiple"
                        >
                          {supervisionPeriod.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  )}
                  {isShowSupervision && (
                  <div>
                    <div>
                      <Form.Item
                        className="anketa__input-hor anketa__input_format"
                        rules={[{ required: true, message: '' }]}
                        name="supervisionFormat"
                        initialValue={user.supervisionFormat}
                        label="Формат"
                        mode="multiple"
                      >
                        <Select disabled={getUserRole() === 'as2f2fc34' ? false : disabled}>
                          {[{ id: 'Групповая', value: 'Групповая' }, { id: 'Индивидуальная', value: 'Индивидуальная' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  )}
                  {isShowSupervision && (
                  <div>
                    <div>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        rules={[{ required: true, message: '' }]}
                        name="supervisionSpecialist"
                        initialValue={user.supervisionSpecialist}
                        label="Супервизор (последний)"
                      >
                        <Input disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                      </Form.Item>
                    </div>
                  </div>
                  )}
                  <div>
                    <div>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        rules={[{ required: true, message: '' }]}
                        name="therapy"
                        initialValue={user.therapy}
                        label="Проходите ли вы терапию"
                      >
                        <Radio.Group
                          disabled={getUserRole() === 'as2f2fc34' ? false : disabled}
                          options={[
                            { label: 'Да', value: 'Да' }, { label: 'Нет', value: 'Нет' }, { label: 'Ранее проходил(а)', value: 'Ранее проходил(а)' },
                          ]}
                          onChange={(e) => this.setState({ therapy: e.target.value })}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {isShowTherapy && (
                  <div>
                    <div>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        rules={[{ required: true, message: '' }]}
                        name="therapyTime"
                        initialValue={user.therapyTime}
                        label="Объем пройденной терапии (в часах)"
                      >
                        <Input disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                      </Form.Item>
                    </div>
                  </div>
                  )}
                  {isShowTherapy && (
                  <div>
                    <div>
                      <Form.Item
                        className="anketa__input-hor anketa__input_period"
                        rules={[{ required: true, message: '' }]}
                        name="therapyPeriod"
                        initialValue={user.therapyPeriod}
                        label="Периодичность"
                      >
                        <Select
                          disabled={getUserRole() === 'as2f2fc34' ? false : disabled}
                          mode="multiple"
                        >
                          {[{ id: 'Раз в неделю', value: 'Раз в неделю' }, { id: 'Два раза в неделю', value: 'Два раза в неделю' }, { id: 'Раз в две недели', value: 'Раз в две недели' }, { id: 'По запросу', value: 'По запросу' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  )}
                  {isShowTherapy && (
                  <div>
                    <div>
                      <Form.Item
                        className="anketa__input-hor anketa__input_format"
                        rules={[{ required: true, message: '' }]}
                        name="therapyFormat"
                        initialValue={user.therapyFormat}
                        label="Формат"
                      >
                        <Select
                          disabled={getUserRole() === 'as2f2fc34' ? false : disabled}
                        >
                          {[{ id: 'Долгосрочная (>6 месяцев)', value: 'Долгосрочная (>6 месяцев)' }, { id: 'Краткосрочная', value: 'Краткосрочная' }].map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  )}
                  {isShowTherapy && (
                  <div>
                    <div>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        rules={[{ required: true, message: '' }]}
                        name="therapyApproach"
                        initialValue={user.therapyApproach}
                        label="В каком подходе вы проходите терапию?"
                      >
                        <Input disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                      </Form.Item>
                    </div>
                  </div>
                  )}
                  {!isMobile && (
                  <div className="anketa__btn-group">
                    {(getUserRole() === 'as2f2fc34' || !disabled) && <Col><Button onClick={this.onSaveClick} className="btn-default btn_green">Сохранить</Button></Col>}
                    <Button disabled={user.status === 'onApproval' ? false : disabled} onClick={() => this.onChangePageClick(2)} className="btn-default btn_transparent">Назад</Button>
                    <Button disabled={user.status === 'onApproval' ? false : disabled} type="primary" style={{ marginLeft: '10px' }} onClick={() => this.onChangePageClick(4)} className="btn-default btn_transparent">Далее</Button>
                  </div>
                  )}
                </div>
              </Card>
            </div>
            )}
            {(step === 4 || isMobile) && (
            <div className="anketa__left">
              <Card>
                <Col className="anketa__form-wrapper">
                  <Row justify="space-between">
                    <Col className="anketa__title">Консультации</Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        rules={[{ required: true, message: '' }]}
                        name="beforeEnrollTime"
                        initialValue={user.beforeEnrollTime}
                        label="Можно записаться за (часы)"
                      >
                        <InputNumber disabled={getUserRole() === 'as2f2fc34' ? false : disabled} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        className="anketa__input-hor anketa__input_lang anketa__input_period"
                        rules={[{ required: true, message: '' }]}
                        name="languagesCatalogue_ids"
                        initialValue={user.languagesCatalogue_ids}
                        label="На каких языках можете консультировать?"
                      >
                        <Select
                          mode="multiple"
                          disabled={getUserRole() === 'as2f2fc34' ? false : disabled}
                        >
                          {languagesCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.value}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        rules={[{ required: true, message: '' }]}
                        name="isGroupSession"
                        initialValue={user.isGroupSession}
                        label="Работаете ли вы с парами?"
                      >
                        <Radio.Group
                          disabled={getUserRole() === 'as2f2fc34' ? false : disabled}
                          options={[
                            { label: 'Да', value: true },
                            { label: 'Нет', value: false },
                          ]}
                          onChange={(e) => this.setState({ isGroupSession: e.target.value })}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        className="anketa__input-hor anketa__input_input"
                        rules={[{ required: true, message: '' }]}
                        name="isWorkWithChildren"
                        initialValue={user.isWorkWithChildren}
                        label="Работаю с детьми и подростками"
                      >
                        <Radio.Group
                          disabled={getUserRole() === 'as2f2fc34' ? false : disabled}
                          options={[
                            { label: 'Да', value: true },
                            { label: 'Нет', value: false },
                          ]}
                          optionType="button"
                          buttonStyle="solid"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Возможные состояния клиента, с которыми вы хотите работать</div>
                  <div style={{ fontSize: '12px' }}>Можно выбрать не более 7 пунктов</div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {specGroups.map((g) => (
                      <div key={g} style={{ display: 'flex', flexDirection: 'column', margin: '10px 0px' }}>
                        <div style={{ fontWeight: 'bold' }}>{g}</div>
                        <div>
                          {specializationCatalogue.filter((sp) => sp.group === g).map((s) => (
                            <Col key={s.id} span={12}>
                              <Checkbox
                                checked={userSpecializations.includes(s.id)}
                                onChange={(e) => this.onSpecializaionCheckbox(s.id, e.target.checked)}
                                disabled={(getUserRole() === 'as2f2fc34' ? false : disabled) || (userSpecializations.length === 7 && !userSpecializations.includes(s.id))}
                              >
                                {s.value}
                              </Checkbox>
                            </Col>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="anketa__btn-group">
                    {(getUserRole() === 'as2f2fc34' || !disabled) && <Col><Button onClick={this.onSaveClick} className="btn-default btn_green">Сохранить</Button></Col>}
                    {!isMobile && (<Button disabled={user.status === 'onApproval' ? false : disabled} onClick={() => this.onChangePageClick(3)} className="btn-default btn_transparent">Назад</Button>)}
                    {user.status !== 'onApproval' && user.status !== 'approved' && (
                    <Button disabled={disabled} type="primary" style={{ marginLeft: '10px' }} onClick={() => this.onChangePageClick(5)} className="btn-default btn_green">Отправить на рассмотрение</Button>
                    )}
                  </div>
                </Col>
              </Card>
            </div>
            )}
            <div className="anketa__right">
              {!isMobile && (
              <Row>
                <Col span={24}>
                  <Steps current={step} direction="vertical">
                    <Step title="Общая информация" description="Общая информация" />
                    <Step title="Образование" description="Информация об образовании" />
                    <Step title="Опыт работы" description="Детали по опыту работы" />
                    <Step title="Супервизия и Терапия" description="Супервизия и Терапия" />
                    <Step title="Консультации" description="Условия проведения консультаций" />
                  </Steps>
                </Col>
              </Row>
              )}
              {user.status === 'onApproval' && getUserRole() !== 'as2f2fc34' && (
              <Row>
                <Alert
                  message="Внимание!"
                  description="Анкета находится на рассмотрении. Проверка обычно занимает 1-3 рабочих дня. По результатам проверки, вы получите письмо на указанную в анкете почту"
                  type="warning"
                  showIcon
                />
              </Row>
              )}
              {user.status === 'declined' && getUserRole() !== 'as2f2fc34' && (
              <Row>
                <Alert
                  message="Ваша заявка требует доработки"
                  description={(
                    <div>
                      <div style={{ whiteSpace: 'break-spaces' }}>{user.moderatorComment}</div>
                    </div>
          )}
                  type="error"
                  showIcon
                />
              </Row>
              )}
              {user.status === 'blocked' && getUserRole() !== 'as2f2fc34' && (
              <Row>
                <Alert
                  message="Доступ запрещен"
                  style={{ marginBottom: '50px' }}
                  description={(
                    <div>
                      <div style={{ whiteSpace: 'break-spaces' }}>{user.moderatorComment}</div>
                    </div>
          )}
                  type="error"
                  showIcon
                />
              </Row>
              )}
            </div>
          </Row>
        </Col>
        {isShowApproveEmailInput
      && (
      <Modal
        title=""
        onCancel={this.onCloseShowApproveEmailInput}
        visible
        footer={null}
      >
        <div style={{ marginBottom: '20px' }}>Введите, пожалуйста, код, полученный на указанный email</div>
        <PinInput
          length={6}
          initialValue=""
          secret={false}
          type="numeric"
          inputMode="number"
          onChange={this.onChangeEmailInput}
          inputStyle={{
            color: 'black',
            backgroundColor: isLoadingEmailInput ? '#f0f0f0' : '#ffffff',
            borderColor: isValidEmailInput ? '#f0f0f0' : '#f5222d',
            borderRadius: '6px',
            border: isLoadingEmailInput ? '1px solid rgb(240, 240, 240)' : '1px solid',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
          }}
          inputFocusStyle={{
            borderColor: isValidEmailInput ? '#E79F8B' : '#f5222d',
          }}
          disabled={isLoadingEmailInput}
          onComplete={(value) => this.onFinishApproveEmailInput(value)}
          autoSelect
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </Modal>
      )}
      </Form>
    );
  }
}

Anketa.propTypes = {
  user: PropTypes.shape(),
};

Anketa.defaultProps = {
  user: null,
};

export default Anketa;
