/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Menu, Dropdown,
} from 'antd';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import { UserOutlined } from '@ant-design/icons';
import getUserLogo from '../../utils/localeStorage/getUserLogo';
import getUserRole from '../../utils/localeStorage/getUserRole';
import getUserId from '../../utils/localeStorage/getUserId';
import getSessionId from '../../utils/localeStorage/getSessionId';
import * as roles from '../../utils/roles';
import adminButtonsArray from '../SideBar/utils/adminButtonsArray';
import specialistButtonsArray from '../SideBar/utils/specialistButtonsArray';
import clientButtonArrow from '../../assets/icons/icon-arrow-bottom.svg';
import clientButtonsArray from '../SideBar/utils/clientButtonsArray';
import getIsValidClient from '../../utils/localeStorage/getIsValidClient';
import getIsValidSpecialist from '../../utils/localeStorage/getIsValidSpecialist';
import { routes } from '../../utils/routes';
import logout from '../../utils/logout';
import logo from '../../assets/icons/logo.svg';
import getNickName from '../../utils/localeStorage/getNickName';
import getUserFirstName from '../../utils/localeStorage/getUserFirstName';
import { editUser, getUserAfterLogin } from '../../utils/http';
import { setLocalStorage } from '../../utils/localeStorage/storage';
import { adminIds } from '../../utils/admins';
import './Header.css';


const noHeaderRoutes = [routes.home, routes.login, routes.homePage, routes.specialists];
class Header extends Component {
  handleMenuButtonClick = (route) => {
    const { history } = this.props;
    history.push(route);
  }

  handleLogoClick = () => {
    window.open('https://gestalt.online', '_self');
  }

  changeRole = () => {
    const { history } = this.props;
    editUser({ id: getUserId(), role: getUserRole() === roles.CLIENT ? roles.SPECIALIST : roles.CLIENT })
      .then(() => getUserAfterLogin(getUserId(), getSessionId())
        .then((user) => {
          const payload = {
            ...user,
            isValidSpecialist: user.isValidSpecialist && !!user.paymentType,
          };
          setLocalStorage(payload);
          // window.location.reload();
          history.push(routes.profile);
        }));
  }

  render() {
    const { history } = this.props;
    const pathname = get(history, 'location.pathname', '');
    const routesKeys = Object.keys(routes);
    const routesValues = [];
    routesKeys.forEach((k) => routesValues.push(routes[k]));
    routesValues.push('/specialist');
    routesValues.push('/emailVerification');
    const isNotVisible = noHeaderRoutes.includes(`/${pathname.split('/')[1]}`) || !routesValues.includes(`/${pathname.split('/')[1]}`);
    if (isNotVisible) {
      return null;
    }
    let buttonsArray = [];
    switch (getUserRole()) {
      case roles.ADMIN:
        buttonsArray = adminButtonsArray;
        break;
      case roles.CLIENT:
        buttonsArray = clientButtonsArray;
        break;
      case roles.SPECIALIST:
        buttonsArray = specialistButtonsArray;
        break;
      default: break;
    }
    let isDisabled = true;
    if (getUserRole() === roles.CLIENT && getIsValidClient() === 'true') {
      isDisabled = false;
    }
    if (getUserRole() === roles.ADMIN) {
      isDisabled = false;
    }
    if (getUserRole() === roles.SPECIALIST && getIsValidSpecialist() === 'true') {
      isDisabled = false;
    }
    let displayName = 'Аноним';
    switch (getUserRole()) {
      case roles.ADMIN:
        displayName = 'Администратор';
        break;
      case roles.CLIENT:
        displayName = getNickName();
        break;
      case roles.SPECIALIST:
        displayName = getUserFirstName();
        break;
      default: break;
    }
    const menu = (
      <Menu>
        {buttonsArray
          .filter((b) => b.id !== 'help')
          .map((b) => (
            <Menu.Item
              key={b.id}
              disabled={isDisabled}
              onClick={() => this.handleMenuButtonClick(b.route)}
            >
              {b.name}
            </Menu.Item>
          ))}
        <Menu.Divider />
        <Menu.Item key="1" onClick={() => this.handleMenuButtonClick(routes.help)}>Поддержка</Menu.Item>
        {getUserId() && <Menu.Divider />}
        {getUserId() && !adminIds.includes(getUserId()) && <Menu.Item key="2" onClick={this.changeRole}>Сменить роль</Menu.Item>}
        <Menu.Divider />
        <Menu.Item key="3" onClick={() => logout(history)} style={{ cursor: 'pointer' }}>Выйти</Menu.Item>
      </Menu>
    );
    return (
      <div className="header-wrapper">
        <div className="wrapper_margin-auto wrapper_horizontal_justify">
          <div className="logo">
            <img src={logo} className="header-logo" alt="Гештальт.онлайн" onClick={this.handleLogoClick} />
          </div>
          <div className="top-menu">
            <div className="homePageDesktop-header-wrapper-second">
              {/* <a href="https://gestalt.online/o-nas.html" className="homePageDesktop-header-buttons-title">О нас</a> */}
              {/* <a href="https://gestalt.online/antikrizisnoe-predlozhenie.html" className="homePageDesktop-header-buttons-title">Антикризисное предложение</a> */}
              <a href="https://gestalt.online/blog/" className="homePageDesktop-header-buttons-title">Блог</a>
            </div>
            <div className="top-menu__avatar">
              {getUserLogo()
                ? <Avatar src={getUserLogo()} />
                : <Avatar icon={<UserOutlined />} />}
            </div>
            <div className="top-menu__link">
              <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                <div>
                  <span>
                    {displayName}
                  </span>
                  <img src={clientButtonArrow} alt="Открыть меню" />
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  history: PropTypes.shape(),
};

Header.defaultProps = {
  history: {},
};

export default withRouter(Header);
