/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import { orderBy } from 'lodash';
import {
  Spin, Table, Tabs, Input, Col,
} from 'antd';
import getPersonalDataChangeRequestsColumns from '../utils/getPersonalDataChangeRequestsColumns';
import './AdminDataChangeRequestsPageDesktop.css';


const { TabPane } = Tabs;
const { Search } = Input;
class AdminDataChangeRequestsPageDesktop extends Component {
  render() {
    const {
      loading, personalDataChangeRequestsClient, personalDataChangeRequestsSpecialist, lockedFieldsClient, lockedFieldsSpec,
      onChangeView, view, onSearch,
    } = this.props;
    if (loading) {
      return (
        <div className="adminDataChangeRequestsPageDesktop-wrapper" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 140 }} spin />} />
        </div>
      );
    }
    return (
      <Col span={22} className="adminDataChangeRequestsPageDesktop-wrapper">
        <div style={{ marginBottom: '20px', fontSize: '20px' }}>Запросы на изменение данных</div>
        <Tabs defaultActiveKey={view} onChange={onChangeView}>
          <TabPane tab="Клиенты" key="client">
            <Table
              columns={orderBy(getPersonalDataChangeRequestsColumns(lockedFieldsClient), 'userName', 'asc')}
              rowKey="id"
              dataSource={personalDataChangeRequestsClient}
              pagination={false}
            />
          </TabPane>
          <TabPane tab="Специалисты" key="specialist">
            <Search
              placeholder="Имя"
              onChange={onSearch}
            />
            <Table
              columns={orderBy(getPersonalDataChangeRequestsColumns(lockedFieldsSpec), 'userName', 'asc')}
              rowKey="id"
              dataSource={personalDataChangeRequestsSpecialist}
              pagination={false}
            />
          </TabPane>
        </Tabs>
      </Col>
    );
  }
}


AdminDataChangeRequestsPageDesktop.propTypes = {
  loading: PropTypes.bool.isRequired,
  personalDataChangeRequestsClient: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  personalDataChangeRequestsSpecialist: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  lockedFieldsClient: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  lockedFieldsSpec: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default AdminDataChangeRequestsPageDesktop;
